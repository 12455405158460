import type { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, Link, useStaticQuery } from "gatsby";
import _ from "lodash";
import ProductUpdatesThumbnail from "components/blocks/ProductUpdatesThumbnail";
import Layout from "components/Layout";
import SubscribeToAyNewsletters from "components/SubscribeToAyNewsletters";
import closeIcon from "./images/tags-close-icon.svg";
import * as styles from "./tags.module.scss";

const TagTemplate: FC<{ pageContext: { tag: string } }> = ({ pageContext }) => {
    const data = useStaticQuery(graphql`
        query AllBlogPostsQuery {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
                sort: { frontmatter: { date: DESC } }
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        featuredImage
                        typeOfPost
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 1160, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const posts = data.allMarkdownRemark.nodes.filter((post: any) => {
        return post.frontmatter.tags?.includes(pageContext.tag);
    });

    return (
        <Layout bg="light" contentType={"blog-post"}>
            <div>
                <Container>
                    <div className={styles.tagsWrapper}>
                        <p className={styles.title}>Tags</p>
                        <div className={styles.tags}>
                            <Link to="/blog/all">
                                <img className={styles.close} src={closeIcon} alt="close icon" />
                            </Link>
                            <span className={styles.tag}>{pageContext.tag}</span>
                        </div>
                    </div>

                    <Row className="justify-content-start">
                        {posts.map((post: any) => {
                            return (
                                <Col key={post.id} xs={12} md={6} xl={6}>
                                    <ProductUpdatesThumbnail post={post} />
                                </Col>
                            );
                        })}
                    </Row>

                    <SubscribeToAyNewsletters />
                </Container>
            </div>
        </Layout>
    );
};

export default TagTemplate;
