import type { FC } from "react";
import "./styles.scss";
const MarkdownRenderer: FC<{ post: { html: string }; customClass?: string | undefined }> = ({
    post,
    customClass = "",
}) => {
    return <section className={`markdown_content ${customClass}`} dangerouslySetInnerHTML={{ __html: post.html }} />;
};

export default MarkdownRenderer;
