import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { openIntercomChat } from "components/intercom";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Smart on its own, Smarter when connected",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Not seeing an integration that’s important to you?",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Contact us",
    },
] as const;

const AppsAndIntegrationHeader: BFC<typeof inputs> = ({ title, subtitle, buttonText }) => {
    return (
        <div className={`container ${styles.wrapper}`}>
            <div className={`${styles.fistGlow} gradient`}>
                <StaticImage src="./images/apps-and-integration-header-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/apps-and-integration-header-second-glow.svg" alt="" />
            </div>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.subtitle}>{subtitle}</p>
            <CustomButton onClick={openIntercomChat}>{buttonText}</CustomButton>
        </div>
    );
};

Builder.registerComponent(AppsAndIntegrationHeader, {
    name: "appsAndIntegrationHeader",
    inputs: toMutable(inputs),
});
