import type { FC } from "react";
import type { PageProps } from "gatsby";
import ReadyToWin from "components/blocks/ReadyToWin";
import Layout from "components/Layout";
import LegalContentItem from "components/LegalContentItem";
import LegalContentLayout from "components/LegalContentLayout";
import LegalHeader from "components/LegalHeader";
import Seo from "components/seo";
import * as styles from "./styles.module.scss";

const Privacy = () => {
    return (
        <Layout bg="dark">
            <LegalHeader
                title="Privacy Policy"
                subHeading="Your privacy is important to us at Assertive Yield. We respect your privacy regarding any information we may collect from you across our website."
            />
            <LegalContentLayout>
                <LegalContentItem title="Scope">
                    <p className="mb-3">
                        This Privacy Policy explains to users the nature, scope and purposes of the collection and use
                        of personal data by the responsible provider on this site as well as suite.assertiveyield.com.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Dealing with personal data">
                    <p className="mb-3">
                        Personal data is information with the help of which a person can be identified, i.e. information
                        that can be traced back to a person. This includes the name, email address or telephone number.
                        But also data about preferences, hobbies, memberships or which websites were viewed by someone
                        count as personal data.
                    </p>
                    <p className="mb-3">
                        In principle, you can use our online offer without disclosing your identity. If you decide to
                        register, i.e. log in as a member (registered user), you can store personal information in your
                        individual user profile. It is subject to the free decision whether you enter this data. Since
                        we try to collect as little personal data as possible for the use of the offer, it is sufficient
                        to provide a name for registration - under which one is registered as a member and which does
                        not have to match the real name - and the e-mail address to which the confirmation e-mail is
                        sent.
                    </p>
                    <p className="mb-3">
                        In connection with the access to our pages, server-side data such as the name of the website
                        accessed, file, date and time of access, amount of data transferred, notification of successful
                        access, browser type and version, the user's operating system, referrer URL (the previously
                        visited page), IP address and the requesting provider are retrieved. The provider uses the log
                        data only for statistical evaluations for the purpose of operation, security and optimisation of
                        the offer. However, the provider reserves the right to subsequently check the log data if there
                        are concrete indications of justified suspicion of illegal use.
                    </p>
                    <p className="mb-3">
                        Personal data will only be collected, used and passed on by the provider if this is permitted by
                        law or if the user consents to the collection of such data.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Registration">
                    <p className="mb-3">
                        You can register on our website to use additional features on the site. We use the data entered
                        for this purpose only for the purpose of using the respective offer or service for which you
                        have registered. The mandatory information requested during registration must be provided in
                        full. Otherwise we will refuse the registration.
                    </p>
                    <p className="mb-3">
                        In the case of important changes, for example in the scope of our services or in the case of
                        technically necessary changes, we will use the e-mail address provided during registration to
                        inform you in this way.
                    </p>
                    <p className="mb-3">
                        The data entered during registration will be processed on the basis of your consent (Art. 6
                        para. 1 lit. a DSGVO). You can revoke your consent at any time. An informal e-mail notification
                        to us is sufficient for this purpose. The legality of the data processing already carried out
                        remains unaffected by the revocation.
                    </p>
                    <p className="mb-3">
                        The data collected during registration will be stored by us as long as you are registered on our
                        website and will subsequently be deleted. Legal retention periods remain unaffected.
                    </p>
                    <p className="mb-3">
                        Registration with third-party providers Instead of registering directly on our website, you can
                        register via a third party.
                    </p>
                    <p className="mb-3">
                        If you choose to register with a third party, you will be automatically redirected to that third
                        party's platform. There you can log in with your usage data. This links your third-party profile
                        to our website or services. This link gives us access to the information you have provided to
                        the third party. These are above all:
                        <br />
                        Name, profile picture, stored email address, ID, birthday, sex, country
                    </p>
                    <p className="mb-3">
                        This data is used to set up, provide and personalize your account.
                        <br />
                        Further information can be found in the respective terms of use and data protection.
                    </p>

                    <div className={styles.p_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Third party</th>
                                    <th>Address</th>
                                    <th>Terms of Use</th>
                                    <th>Privacy Statement</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Facebook</td>
                                    <td>Facebook Ireland Limited, 4 Grand Canal Square, Dublin 2, Irland</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/legal/terms/"
                                            rel="noopener noreferrer"
                                        >
                                            Terms
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://de-de.facebook.com/about/privacy/"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Google+</td>
                                    <td>Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://policies.google.com/terms?hl=de"
                                            rel="noopener noreferrer"
                                        >
                                            Terms
                                        </a>
                                        <br />
                                        <a
                                            target="_blank"
                                            href="https://www.google.com/intl/de/+/policy/pagesterms.html"
                                            rel="noopener noreferrer"
                                        >
                                            Terms 2
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://policies.google.com/privacy?hl=de"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Twitter</td>
                                    <td>Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA</td>
                                    <td>
                                        <a target="_blank" href="https://twitter.com/de/tos" rel="noopener noreferrer">
                                            Terms
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://twitter.com/de/privacy"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>GitHub</td>
                                    <td>GitHub, Inc, 88 Colin P Kelly Jr St, San Francisco, CA 94107, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://help.github.com/articles/github-terms-of-service/"
                                            rel="noopener noreferrer"
                                        >
                                            Terms
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://help.github.com/articles/github-privacy-statement/"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </LegalContentItem>
                <LegalContentItem title="Web Analytics">
                    <p className="mb-3">
                        This website uses web analysis services. Web analytics services use "cookies", which are text
                        files placed on your computer, to help the website analyze how users use the site. The
                        information generated by the cookie about your use of this website is generally transmitted to a
                        server in the USA and stored there. However, if IP anonymization is activated on this website,
                        your IP address will be shortened beforehand within member states of the European Union or in
                        other signatory states to the Agreement on the European Economic Area. Only in exceptional cases
                        is the full IP address transmitted to a server in the USA and shortened there. On behalf of the
                        operator of this website, the web analysis services will use this information to evaluate your
                        use of the website, to compile reports on the website activities and to provide further services
                        to the website operator in connection with the use of the website and the Internet. You may
                        refuse the use of cookies by selecting the appropriate settings on your browser, however please
                        note that if you do this you may not be able to use the full functionality of this website.
                    </p>

                    <div className={styles.p_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Third Party</th>
                                    <th>Address</th>
                                    <th>Privacy Statement</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Google Analytics</td>
                                    <td>Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.google.com/analytics/terms/us.html"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Segment.io</td>
                                    <td>
                                        Segment.io, Inc. 100 California Street, Suite 700 San Francisco, CA 94111, USA
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://segment.com/docs/legal/privacy/"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Intercom</td>
                                    <td>Intercom Inc., 55 2nd Street, 4th Floor, San Francisco, CA 94105, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.intercom.com/terms-and-policies#privacy"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Metrica</td>
                                    <td>Yandex Oy Limited Company, Moreenikatu 6, 04600 Mantsala, Finland</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://metrica.yandex.com/about/info/data-policy/"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mixpanel</td>
                                    <td>Mixpanel, Inc., 405 Howard St., 2nd Floor, San Francisco, CA 94105, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://mixpanel.com/legal/privacy-policy/"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </LegalContentItem>
                <LegalContentItem title="Transactional E-Mails/Push Notifications">
                    <p className="mb-3">
                        In the following we inform you about our use of transactional messages by e-mail and push
                        notifications within the scope of our offer as well as your rights of objection. With your
                        registration you agree with the receipt and the described procedure.
                    </p>
                    <p className="mb-3">
                        Content: We send transactional messages on important events such as "new features",
                        "alerts/events on your data" and summaries of events during your absence.
                    </p>
                    <p className="mb-3">
                        Cancellation: You can cancel the receipt of our transactional e-mails at any time in the
                        settings or at the end the e-mail.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Use of cookies">
                    <p className="mb-3">
                        We use cookies - small files with configuration information. They help to determine
                        user-specific settings and to implement special user functions. We do not collect any personal
                        data via cookies. All functions of the website can also be used without cookies, but some
                        user-defined properties and settings are then not available.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="How to contact us">
                    <p className="mb-3">
                        When contacting the provider (e.g. via contact form or e-mail), the user's details are stored
                        for the purpose of processing the enquiry and in the event that follow-up questions arise.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="ThirdParty Services">
                    <p className="mb-3">
                        Within the scope of our online offer, we act on the basis of our legitimate interests (i.e.
                        interest in the analysis, optimisation and economic operation of our online offer within the
                        meaning of Art. 6 Para. 1 lit. f. of the German Civil Code). DSGVO) content or service offers
                        from third parties in order to integrate their content and services, such as videos or fonts
                        (hereinafter uniformly referred to as "content"). This always presupposes that the third-party
                        providers of this content perceive the IP address of the user, since they would not be able to
                        send the content to their browser without the IP address. The IP address is therefore required
                        for the presentation of this content. We make every effort to use only those contents whose
                        respective providers only use the IP address to deliver the contents. Third party providers may
                        also use so-called pixel tags (invisible graphics, also known as "web beacons") for statistical
                        or marketing purposes. Pixel tags" can be used to evaluate information such as visitor traffic
                        on the pages of this website. The pseudonymous information may also be stored in cookies on the
                        user's device and may contain, among other things, technical information about the browser and
                        operating system, referring web pages, visit times and other information about the use of our
                        online services, as well as may be linked to such information from other sources.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="External payment service providers">
                    <p className="mb-3">
                        We use external payment service providers, through whose platforms the users and we can carry
                        out payment transactions.
                    </p>
                    <ul>
                        <li>Stripe (https://stripe.com/en-nl/privacy)</li>
                    </ul>
                    <p className="mb-3">
                        Within the framework of the performance of contracts, we shall use the payment services on the
                        basis of Art. 6 Para. 1 lit. b. DSGVO. We also use external payment service providers on the
                        basis of our legitimate interests pursuant to Art. 6 para. 1 lit. b. DSGVO in order to offer our
                        users effective and secure payment options.
                    </p>
                    <p className="mb-3">
                        The data processed by the payment service providers include inventory data such as name and
                        address, bank data such as account numbers or credit card numbers, passwords, TANs and checksums
                        as well as contract, sum and recipient details. The information is required to complete the
                        transactions. However, the data entered will only be processed and stored by the payment service
                        providers. This means that we do not receive any account- or credit card-related information,
                        but only information with confirmation or negative information about the payment. Under certain
                        circumstances, the data may be transmitted by the payment service provider to credit agencies.
                        The purpose of this transmission is to check identity and creditworthiness. Please refer to the
                        general terms and conditions and data protection information of the payment service providers.
                    </p>
                    <p className="mb-3">
                        For payment transactions, the terms and conditions and the data protection notices of the
                        respective payment service providers apply, which can be accessed within the respective websites
                        or transaction applications. We also refer to these for the purpose of further information and
                        assertion of revocation, information and other rights affected.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Re-/Marketing Services">
                    <p className="mb-3">
                        On the basis of our legitimate interests (i.e. interest in the analysis, optimisation and
                        economic operation of our online offer in the sense of Art. 6 Para. 1 lit. f. of the German
                        Civil Code), we make use of the information provided on this website. DSGVO) the marketing and
                        remarketing services of the following companies (table).
                    </p>
                    <p className="mb-3">
                        The marketing services allow us to display advertisements for and on our website in a more
                        targeted manner in order to present users only with advertisements that potentially correspond
                        to their interests. If, for example, a user is shown advertisements for products in which he or
                        she is interested on other websites, this is referred to as "remarketing". For these purposes,
                        when you visit our and other websites on which marketing services are active, the marketing
                        service directly executes a code from the marketing service and so-called (re)marketing tags
                        (invisible graphics or code, also known as "web beacons") are integrated into the website. With
                        their help, an individual cookie, i.e. a small file, is stored on the user's device (comparable
                        technologies can also be used instead of cookies). The cookies can be set by various domains,
                        including google.com, doubleclick.net, invitemedia.com, admeld.com, googlesyndication.com,
                        googleadservices.com and adnxs.com. In this file it is noted which websites the user visits,
                        which contents he is interested in and which offers he has clicked, furthermore technical
                        information about the browser and operating system, referring websites, visiting time as well as
                        further information about the use of the online offer. The IP address of the user is also
                        recorded and saved in abbreviated form. If the user subsequently visits other websites, the ads
                        tailored to the user's interests can be displayed.
                    </p>
                    <p className="mb-3">
                        The user's data is processed pseudonymously within the framework of the marketing service. This
                        means that the marketing service does not store and process, for example, the name or e-mail
                        address of the user, but processes the relevant data cookie-related within pseudonymous user
                        profiles. This means that from the point of view of the marketing services, the advertisements
                        are not administered and displayed for a specifically identified person, but for the cookie
                        holder, regardless of who this cookie holder is. This does not apply if a user has expressly
                        permitted a marketing service to process the data without this pseudonymisation. The information
                        collected about users by marketing services is transmitted to the marketing service and stored
                        on its servers.
                    </p>
                    <p className="mb-3">
                        One of the marketing services we use is the online advertising program "Google AdWords". In the
                        case of Google AdWords, each AdWords customer receives a different "conversion cookie". Cookies
                        can therefore not be tracked via the websites of AdWords customers. The information collected
                        through the cookie is used to generate conversion statistics for AdWords customers who have
                        opted for conversion tracking. AdWords customers learn the total number of users who clicked on
                        their ad and were directed to a page tagged with a conversion tracking tag. However, you will
                        not receive any information that personally identifies users.
                    </p>
                    <p className="mb-3">
                        We may include third-party advertisements based on the marketing service used. Marketing Service
                        uses cookies to enable the Marketing Service and its affiliate sites to serve ads based on
                        users' visits to this site or other sites on the Internet.
                    </p>
                    <p className="mb-3">
                        We may also use the "Google Tag Manager" to integrate and manage the Google analytics and
                        marketing services on our website.
                    </p>
                    <div className={styles.p_table}>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Marketing-Service</th>
                                    <th>Address</th>
                                    <th>Privacy Statement</th>
                                    <th>Opt-Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Google</td>
                                    <td>Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.google.com/policies/privacy"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="http://www.google.com/ads/preferences"
                                            rel="noopener noreferrer"
                                        >
                                            Opt-Out
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>AppNexus</td>
                                    <td>AppNexus Inc., 28 W 23rd Street, 4th floor, New York, NY – 10010, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.appnexus.com/en/company/platform-privacy-policy-de"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.appnexus.com/en/company/platform-privacy-policy-de#choices"
                                            rel="noopener noreferrer"
                                        >
                                            Opt-Out
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Facebook</td>
                                    <td>Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA</td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/about/privacy/"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.facebook.com/settings?tab=ads"
                                            rel="noopener noreferrer"
                                        >
                                            Opt-Out
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>LinkedIn</td>
                                    <td>
                                        LinkedIn Headquarters 2029 Stierlin Ct. Ste. 200 Mountain View, CA 94043, USA
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.linkedin.com/legal/privacy-policy"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                    </td>
                                    <td>
                                        <a
                                            target="_blank"
                                            href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"
                                            rel="noreferrer"
                                        >
                                            Opt-Out
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </LegalContentItem>
                <LegalContentItem title="ThirdParty Services">
                    <p className="mb-3">
                        Within the scope of our online offer, we act on the basis of our legitimate interests (i.e.
                        interest in the analysis, optimisation and economic operation of our online offer within the
                        meaning of Art. 6 Para. 1 lit. f. of the German Civil Code). DSGVO) content or service offers
                        from third parties in order to integrate their content and services, such as videos or fonts
                        (hereinafter uniformly referred to as "content"). This always presupposes that the third-party
                        providers of this content perceive the IP address of the user, since they would not be able to
                        send the content to their browser without the IP address. The IP address is therefore required
                        for the presentation of this content. We make every effort to use only those contents whose
                        respective providers only use the IP address to deliver the contents. Third party providers may
                        also use so-called pixel tags (invisible graphics, also known as "web beacons") for statistical
                        or marketing purposes. Pixel tags" can be used to evaluate information such as visitor traffic
                        on the pages of this website. The pseudonymous information may also be stored in cookies on the
                        user's device and may contain, among other things, technical information about the browser and
                        operating system, referring web pages, visit times and other information about the use of our
                        online services, as well as may be linked to such information from other sources.
                    </p>
                </LegalContentItem>
            </LegalContentLayout>
            <ReadyToWin />
        </Layout>
    );
};
export const Head: FC<PageProps> = ({ location }) => (
    <Seo noIndex title="Privacy" titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": "${location.pathname}",
          "url": "${location.pathname}",
          "name": "Privacy",
          "datePublished": "2023-01-16T23:37:53+00:00",
          "dateModified": "2023-03-21T16:10:45+00:00",
          "description": "Assertive Yield is a global company, created by publishers to publishers. Pioneer in offering Yield Revenue Management in Real-Time, from all revenue sources, with extraordinary granular data, as never seen before.",
          "inLanguage": "en-US",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.assertiveyield.com/#website",
            "url": "https://www.assertiveyield.com/",
            "name": "Assertive Yield",
            "description": "Investment Management Solutions",
            "inLanguage": "en-US",
          "publisher": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "image": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "URL": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
              },
              "query-input": {
                "@type": "PropertyValueSpecification",
                "valueRequired": "http://schema.org/True",
                "valueName": "search_term_string"
              }
            }
          }
        },
        "about": {
          "@type": "Organization",
          "@id": "https://www.assertiveyield.com/#organization",
          "name": "Assertive Yield",
          "url": "https://www.assertiveyield.com/",
          "sameAs": [
            "https://www.youtube.com/@assertiveyield",
            "https://www.linkedin.com/company/assertive-yield/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
            "inLanguage": "en-US",
            "url": "https://assertiveyield.com/img/og-image.png",
            "contentUrl": "https://assertiveyield.com/img/og-image.png",
            "width": 479,
            "height": 189,
            "caption": "Assertive Yield"
          }
      },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "@id": "https://www.assertiveyield.com/#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Assertive Yield",
              "item": {
                "@id": "https://www.assertiveyield.com/",
                "@type": "Thing"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Privacy"
            }
          ]
        },
        "potentialAction": {
          "@type": "ReadAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
          }
        }
      }
      `}
        </script>
    </Seo>
);

export default Privacy;
