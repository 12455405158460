import type { FC } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./statistics.module.scss";

const input = [
    {
        name: "title",
        type: "text",
        defaultValue: "We make your data work for you!",
    },

    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "+ 150 trillion",
                required: true,
            },
            {
                name: "subtitle",
                type: "text",
                defaultValue: "Data Points",
            },
        ],
    },
] as const;

const StatCol: FC<{ title: string; subtitle: string }> = ({ title, subtitle }) => (
    <div className={`col ${styles.stat_col}`}>
        <h2 className="">{title}</h2>
        <p className="">{subtitle}</p>
    </div>
);
const Statistics: BFC<typeof input> = ({ title, items }) => (
    <div className="component-wrapper">
        <div className={`gradient ${styles.left}`}>
            <StaticImage src="images/st-left-gradient.png" alt="" />
        </div>
        <div className={`container position-relative ${styles.statistics}`}>
            <div className={`${styles.videoouter}`}>
                <div className={`row text-center justify-content-center g-0 p-2`}>
                    <h2 className="text-center mb-3 mb-lg-5 mt-4">{title}</h2>
                    {items.map(it => (
                        <StatCol title={it.title} subtitle={it.subtitle ?? ""} key={it.subtitle} />
                    ))}
                </div>
            </div>
        </div>
    </div>
);
Builder.registerComponent(Statistics, {
    name: "Statistics",
    inputs: toMutable(input),
});
