import { Builder } from "@builder.io/react";
import allLogos from "images/logos/allLogos.svg";
import allLogosMobile from "images/logos/allLogosMobile.svg";
import { toMutable } from "types/common";
import "./expanded-clients.scss";
import rightArrow from "./images/right-arrow.svg";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "A next generation of data-driven teams",
    },
    {
        name: "linkText",
        type: "text",
        defaultValue: "See all clients",
    },
    {
        name: "linkTo",
        type: "string",
        defaultValue: "/clients",
    },
] as const;
const ExpandedClients: BFC<typeof inputs> = ({ title, linkText, linkTo }) => {
    return (
        <div className="component-wrapper">
            <div className="container">
                <div className="expanded-clients">
                    <section className="enable-animation marquee-clients">
                        {title ? <h2 className="text-center mb-5">{title}</h2> : ""}
                        <div className="marquee-desktop ">
                            <img src={allLogos} alt="" />
                        </div>

                        <div className="marquee-mobile">
                            <img src={allLogosMobile} alt="" />
                        </div>
                    </section>

                    {linkText ? (
                        <a href={linkTo} className="link-wrapper">
                            <span className="link-text">{linkText}</span>
                            <img src={rightArrow} alt="" />
                        </a>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(ExpandedClients, {
    name: "ExpandedClients",
    inputs: toMutable(inputs),
});
