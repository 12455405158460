import type { FC, ReactNode } from "react";
import { Link } from "gatsby";
import "./CustomButton.scss";

const STYLES = [
    "btn--primary", //blue
    "btn--secondary",
    "btn--yellow-red",
    "btn--blue-pink",
    "btn--green-pink",
    "btn--test",
] as const;
export type CustomButtonStyles = (typeof STYLES)[number];

const SIZES = ["btn--medium", "btn--large", "custom--huge"] as const;

export type CustomButtonSizes = (typeof SIZES)[number];

const CustomButton: FC<{
    children: ReactNode;
    to?: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
    onClick?: () => void;
    buttonStyle?: CustomButtonStyles;
    buttonSize?: CustomButtonSizes;
    customWidth?: string;
}> = ({ children, type, onClick, buttonStyle, buttonSize, to, customWidth }) => {
    const checkButtonStyle = STYLES.includes(buttonStyle!) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZES.includes(buttonSize!) ? buttonSize : SIZES[0];

    const btn = customWidth ? (
        <button
            className={"custom-button " + `${checkButtonStyle} ${checkButtonSize}`}
            style={{
                width: customWidth,
            }}
            onClick={onClick}
            type={type}
        >
            <span>{children}</span>
        </button>
    ) : (
        <button className={"custom-button " + `${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>
            <span>{children}</span>
        </button>
    );

    if (!to) {
        return <div className="btn-mobile">{btn}</div>;
    }

    if (String(to).startsWith("http")) {
        return (
            <a href={to} target="_blank" rel="noreferrer" className="btn-mobile">
                {btn}
            </a>
        );
    }

    return (
        <Link to={to!} className="btn-mobile">
            {btn}
        </Link>
    );
};

export default CustomButton;
