import smoothscroll from "smoothscroll-polyfill";
import "components/all.scss";
// eslint-disable-next-line import/no-unresolved
import "swiper/css";
// eslint-disable-next-line import/no-unresolved
import "swiper/css/navigation";
import "./gatsby-builder";
import "photoswipe/dist/photoswipe.css";

smoothscroll.polyfill();

const scrollToElement = id => {
    if (id === 0) {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });

        return;
    }

    const element = document.getElementById(id);

    if (element) {
        setTimeout(() => {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }, 600);
    }
};

const handleScroll = location => {
    if (location.hash.indexOf("#") === -1) {
        scrollToElement(0);

        return;
    }

    const id = location.hash.substring(1);

    if (document.readyState === "complete" || document.readyState === "interactive") {
        scrollToElement(id);
    } else {
        window.addEventListener("DOMContentLoaded", () => {
            scrollToElement(id);
        });
    }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
    handleScroll(location);

    return false;
};

export { wrapPageElement } from "./gatsby-shared.jsx";
