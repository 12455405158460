import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Time is Money",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "94-96% Revenue Prediction Accuracy",
    },
    {
        name: "description",
        type: "text",
        defaultValue:
            "We know how tedious and time consuming data collection can be. AY Machine Learning predicts delayed data with nearly 96% accuracy. AY Machine learning predicts delayed data with 94-96% accuracy and normalizes when data is available.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Free trial",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1269,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 809,
    },
] as const;

export const AllRevenueTimeIsMoney: BFC<typeof inputs> = ({
    suptitle,
    title,
    description,
    buttonText,
    switchMagnifier,
    linkTo,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="container">
            <div className="row align-items-center gx-5">
                <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                    <p className={styles.suptitle}>{suptitle}</p>
                    <h2>{title}</h2>
                    <p className="mb-5">{description}</p>
                    <CustomButton to={linkTo}>{buttonText}</CustomButton>
                </div>
                <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                    <div className="mask-container  position-relative ">
                        {imageOrGifHover ? (
                            <img
                                onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                src={imageOrGif}
                                alt={imageOrGifAlt}
                                className="img-fluid"
                            />
                        ) : (
                            <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                        )}
                        {switchMagnifier && !imageOrGifHover && (
                            <Gallery>
                                <Item
                                    width={imageOrGifWidth}
                                    height={imageOrGifHeight}
                                    original={imageOrGif}
                                    alt={imageOrGifAlt}
                                >
                                    {({ ref, open }) => (
                                        // @ts-expect-error
                                        <div className="image_mask" ref={ref} onClick={open}>
                                            <img src={glass} alt="zoom image" />
                                        </div>
                                    )}
                                </Item>
                            </Gallery>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AllRevenueTimeIsMoney, {
    name: "allRevenueTimeIsMoney",
    inputs: toMutable(inputs),
});
