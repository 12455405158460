import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective } from "../types";

const rawMetricsRequestReductionServerSamplesPredicted = `(predicted > __threshold)`;
const RAW_METRICS = {
    request_reduction_server_samples_wins: {
        field: "countIf(did_win)",
    },
    request_reduction_server_samples_bids: {
        field: "countIf(did_bid)",
    },
    request_reduction_server_samples_predicts: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted})`,
    },
    request_reduction_server_samples_bid_true_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND did_bid)`,
    },
    request_reduction_server_samples_bid_true_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_bid)`,
    },
    request_reduction_server_samples_bid_false_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_bid)`,
    },
    request_reduction_server_samples_bid_false_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND did_bid)`,
    },
    request_reduction_server_samples_win_true_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND did_win)`,
    },
    request_reduction_server_samples_win_true_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_win)`,
    },
    request_reduction_server_samples_win_false_positive: {
        field: `countIf(${rawMetricsRequestReductionServerSamplesPredicted} AND NOT did_win)`,
    },
    request_reduction_server_samples_win_false_negative: {
        field: `countIf(NOT ${rawMetricsRequestReductionServerSamplesPredicted} AND did_win)`,
    },
    request_reduction_server_samples_missed_revenue: {
        field: `sumIf(cost_cpm, did_win AND NOT ${rawMetricsRequestReductionServerSamplesPredicted})`,
    },
    request_reduction_server_samples_requests: {
        field: "count()",
    },
    request_reduction_server_samples_win_revenue: {
        field: "sumIf(cost_cpm, did_win)",
    },
    request_reduction_server_samples_bid_revenue: {
        field: "sumIf(predict_samples.bid_cpm, predict_samples.did_bid)",
    },
} as const satisfies Record<string, IRawMetric>;
export type RawRequestReductionServerSamplesMetrics = keyof typeof RAW_METRICS;

export const RequestReductionServerSamplesProfileMetricsIds = [
    "request_reduction_server_samples_requests",
    "request_reduction_server_samples_win_rate",
    "request_reduction_server_samples_bid_rate",
    "request_reduction_server_samples_wins",
    "request_reduction_server_samples_bids",
    "request_reduction_server_samples_win_revenue",
    "request_reduction_server_samples_bid_revenue",
    "request_reduction_server_samples_win_cpm",
    "request_reduction_server_samples_bid_cpm",
] as const;

export type RequestReductionServerSamplesProfileMetricsType =
    (typeof RequestReductionServerSamplesProfileMetricsIds)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(RequestReductionServerSamplesProfileMetricsIds)
    .withCalculatedMetrics({
        request_reduction_server_samples_bids: {
            label: "Bids",
            explanation: "Bids",
            formula: m => m.request_reduction_server_samples_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_bid_revenue: {
            label: "Bid revenue",
            explanation: "Bid revenue",
            formula: m => m.request_reduction_server_samples_bid_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_bid_rate: {
            label: "Bid rate",
            explanation: "Bid Responses from Bidder / Bid Requests to Bidder",
            formula: m => m.request_reduction_server_samples_bids / m.request_reduction_server_samples_requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 4,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_bid_cpm: {
            label: "Bid CPM",
            explanation: "Avg. CPM of Bid Responses from Bidder",
            formula: m =>
                (m.request_reduction_server_samples_bid_revenue / m.request_reduction_server_samples_bids) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },

        request_reduction_server_samples_wins: {
            label: "Wins",
            explanation: "Wins",
            formula: m => m.request_reduction_server_samples_wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_win_revenue: {
            label: "Win revenue",
            explanation: "Win revenue",
            formula: m => m.request_reduction_server_samples_win_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_win_rate: {
            label: "Win rate",
            explanation: "Impression Events from Bidder / Bid Requests to Bidder",
            formula: m => m.request_reduction_server_samples_wins / m.request_reduction_server_samples_requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 4,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_win_cpm: {
            label: "Win CPM",
            explanation: "Avg. CPM of Impression Events from Bidder (Revenue)",
            formula: m =>
                (m.request_reduction_server_samples_win_revenue / m.request_reduction_server_samples_wins) * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_requests: {
            label: "Requests",
            explanation: "Total requests",
            formula: m => m.request_reduction_server_samples_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
    });
