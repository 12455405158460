import { FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import AllContent from "components/AllContent";
import ProductUpdatesThumbnail from "components/blocks/ProductUpdatesThumbnail";
import ScrollToTopButton from "components/scroll-to-top-button/scroll-to-top-button";
import Seo from "components/seo";
import type { BlogPostData } from "types/common";
import * as styles from "./styles.module.scss";

const TITLE = "All Posts";

const AllBlogPostsPage = () => {
    const data = useStaticQuery(graphql`
        query AllBlogPostsQuery {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
                sort: { frontmatter: { date: DESC } }
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        featuredImage
                        typeOfPost
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 1160, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const options = [
        { label: "All", value: "all" },
        { label: "Efficiency & Sustainability", value: "Efficiency & Sustainability" },
        { label: "Data Intelligence", value: "Data Intelligence" },
        { label: "Ad Revenue Management", value: "Ad Revenue Management" },
        { label: "AI Optimization", value: "AI Optimization" },
        { label: "Programmatic Trends", value: "Programmatic Trends" },
    ];

    const posts: BlogPostData[] = data.allMarkdownRemark.nodes;
    const [filteredItems, setFilteredItems] = useState(posts);
    const [activePoint, setActivePoint] = useState("all");

    const filterItem = (selectedCategory: string | undefined) => {
        const filtered =
            selectedCategory === "all"
                ? posts
                : posts.filter(node => node.frontmatter.tags?.includes(selectedCategory!));
        setFilteredItems(filtered);
    };

    const colorStyles = {
        option: (styles: any, { isFocused }: { isFocused: boolean }) => ({
            ...styles,
            backgroundColor: isFocused ? "#F9FAFB" : null,
            color: "#000",
        }),
    };

    const handleCategoryChange = (selectedCategory: string) => {
        filterItem(selectedCategory);
        setActivePoint(selectedCategory);
    };

    return (
        <div>
            <AllContent newSubscribeToNews={true} showJourney={true} title={TITLE}>
                <p className={styles.description}>
                    Discover the latest advancements in our product features, cutting-edge Programmatic technology,
                    innovative solutions, and real-time updates.
                </p>

                <div className={styles.switcherWrapper}>
                    <ul className={`${styles.switcher} d-lg-inline-flex d-none`}>
                        {options.map(point => (
                            <li
                                key={point.value}
                                className={`${styles.switcherPoint} ${activePoint === point.value ? styles.active : ""}`}
                                onClick={() => handleCategoryChange(point.value)}
                            >
                                {point.label}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="row d-block d-lg-none">
                    <div className="col-lg-4">
                        <Select
                            options={options}
                            isSearchable={false}
                            defaultValue={options[0]}
                            className="mt-1 mb-5"
                            styles={colorStyles}
                            onChange={e => handleCategoryChange(e!.value)}
                            placeholder="Choose Tag to Filter by"
                            components={{ IndicatorSeparator: () => null }}
                        />
                    </div>
                </div>
                <Row className="justify-content-start">
                    {filteredItems.includes(posts[0]) && (
                        <Col key={posts[0].fields.slug} xs={12} md={6} xl={6}>
                            {posts[0] && <ProductUpdatesThumbnail post={posts[0]} />}
                        </Col>
                    )}
                    {filteredItems.includes(posts[1]) && (
                        <Col key={posts[1].fields.slug} xs={12} md={6} xl={6}>
                            {posts[1] && <ProductUpdatesThumbnail post={posts[1]} />}
                        </Col>
                    )}
                    {filteredItems.slice(2).map((post: BlogPostData) => (
                        <Col key={post.fields.slug} xs={12} md={4} xl={4}>
                            <ProductUpdatesThumbnail post={post} />
                        </Col>
                    ))}
                    {filteredItems.length <= 2 && (
                        <div className="col-12">
                            <p className="text-center">No posts found</p>
                        </div>
                    )}
                </Row>
                <ScrollToTopButton />
            </AllContent>
        </div>
    );
};

export const Head: FC<PageProps> = ({ location }) => <Seo title={TITLE} titleSuffixed canonical={location.pathname} />;
export default AllBlogPostsPage;
