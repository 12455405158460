import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Ready to Get Started with Us?",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a free demo",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
        required: true,
    },
] as const;

export const AboutUsEmpoweredCustomers: BFC<typeof inputs> = ({ title, buttonText, linkTo }) => {
    return (
        <div className="content-dark pb-6 pt-2">
            <div className="container">
                <div className={styles.formWrapper}>
                    <h2>{title}</h2>

                    <div className={styles.buttonWrapper}>
                        <CustomButton to={linkTo}>{buttonText}</CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsEmpoweredCustomers, {
    name: "aboutUsEmpoweredCustomers",
    inputs: toMutable(inputs),
});
