import type { OverlayChildren } from "react-bootstrap/esm/Overlay";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export const IconTrigger: React.FunctionComponent<{
    tooltip: OverlayChildren;
    children: React.ReactNode[] | React.ReactNode;
}> = ({ tooltip, children }) => {
    return (
        <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={tooltip}>
            <span>{children}</span>
        </OverlayTrigger>
    );
};
