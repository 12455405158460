import { Col, Container, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import EbookThumbnail from "components/EbookThumbnail";
import HrHeading from "components/HrHeading";
import TransparentGradientButton from "components/TransparentGradientButton";
import type { EbookData } from "types/common";
import * as styles from "./styles.module.scss";

const Ebooks = () => {
    const data = useStaticQuery(graphql`
        query EbooksQuery {
            allEbook(sort: { date: DESC }, limit: 3) {
                nodes {
                    id
                    link
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `);

    const ebooks = data.allEbook.nodes;

    return (
        <div className={[styles.container_wrapper, "pt-0"].join(" ")} id="ebooks">
            <Container className="py-5">
                <HrHeading
                    heading="Reports"
                    buttonText="All Reports"
                    buttonTo="/ebooks/all"
                    bg="light"
                    bgColor="#fbf8ff"
                    hideButtonOnMobile={true}
                />
                <Row>
                    {ebooks.map((ebook: EbookData, index: number) => {
                        return (
                            <Col key={ebook.id} xs={6} xl={4}>
                                <EbookThumbnail ebook={ebook} shouldHideXs={index === 2} />
                            </Col>
                        );
                    })}
                </Row>

                <div className="mx-auto d-flex d-lg-none justify-content-center mt-4 mb-4">
                    <TransparentGradientButton
                        text={"All Reports"}
                        bg={"light"}
                        bgColor={"#fff"}
                        width={"298px"}
                        to={"/ebooks/all/"}
                        contentWidth={"300px"}
                    />
                </div>
            </Container>
        </div>
    );
};

export default Ebooks;
