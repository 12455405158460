import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import integration from "./images/integration.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "longText",
        defaultValue:
            "Easy integrations with the main platforms for publisher-bought traffic. Implement and match each with a different key, multiple accounts, and sub-accounts supported.",
    },
] as const;

const trafficAcquisitionIntegration: BFC<typeof inputs> = ({ title }) => {
    return (
        <div className="component-wrapper">
            <div className="gradient right" style={{ top: "-500px", right: "0px" }}>
                <StaticImage src="./images/right.png" alt="" />
            </div>
            <div className="container">
                <div className="row align-items-center justify-content-between gx-5">
                    <div className="col-lg-6  order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                        <p className={styles.title}>{title}</p>
                    </div>
                    <div className="col-lg-6   order-2 order-lg-1  mt-lg-0">
                        <img src={integration} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(trafficAcquisitionIntegration, {
    name: "trafficAcquisitionIntegration",
    inputs: toMutable(inputs),
});
