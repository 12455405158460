import { useEffect, useState } from "react";
import { Link } from "gatsby";

const OFFSET = 360;
/**
 * Puts a notice on the bottom left of the page
 * telling the user this site uses cookies.
 *
 * If the user scrolls past {@link OFFSET} then
 * the notice becomes hidden and if the users scrolls
 * upwards until {@link OFFSET} then it becomes visible again.
 */
export const CookieNotice = () => {
    const [showing, setShowing] = useState(true);

    useEffect(() => {
        const scrollCallback = () => {
            if (window.pageYOffset > OFFSET) {
                setShowing(false);
            } else if (window.pageYOffset <= OFFSET) {
                setShowing(true);
            }
        };
        window.addEventListener("scroll", scrollCallback);

        return () => {
            window.removeEventListener("scroll", scrollCallback);
        };
    }, []);

    if (!showing) {
        return <></>;
    }

    return (
        <div
            style={{
                position: "fixed",
                left: "0",
                bottom: "0",
                padding: "0.3rem 0.5rem",
                backgroundColor: "white",
                borderTopRightRadius: "0.25rem",
                zIndex: 9999,
                color: "black",
            }}
        >
            This website uses cookies. &nbsp;&nbsp;
            <Link
                to="/privacy/"
                style={{
                    color: "black",
                    textDecoration: "none",
                    fontWeight: "bold",
                }}
            >
                Learn more
            </Link>
        </div>
    );
};
