const TwitterIcon = () => {
    return (
        <div className="careers-template-icon-wrapper">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_4817_10736)">
                    <path
                        d="M6.7918 18.1251C14.3371 18.1251 18.4652 11.8724 18.4652 6.45167C18.4652 6.27589 18.4613 6.0962 18.4535 5.92042C19.2566 5.33967 19.9496 4.62033 20.5 3.7962C19.7521 4.12896 18.958 4.34627 18.1449 4.44074C19.0011 3.92755 19.6421 3.12135 19.9492 2.17159C19.1438 2.64892 18.263 2.98563 17.3445 3.1673C16.7257 2.50976 15.9075 2.07439 15.0164 1.9285C14.1253 1.78261 13.211 1.93433 12.4148 2.3602C11.6186 2.78607 10.9848 3.46238 10.6115 4.28455C10.2382 5.10672 10.1462 6.02897 10.3496 6.9087C8.71874 6.82686 7.12328 6.40321 5.66665 5.6652C4.21002 4.9272 2.92474 3.89132 1.89414 2.62472C1.37033 3.52782 1.21005 4.59649 1.44586 5.61353C1.68167 6.63057 2.29589 7.51966 3.16367 8.10011C2.51219 8.07943 1.87498 7.90402 1.30469 7.58839V7.63917C1.3041 8.58691 1.63175 9.50561 2.23192 10.2391C2.8321 10.9726 3.66777 11.4756 4.59687 11.6626C3.99338 11.8277 3.35999 11.8518 2.7457 11.7329C3.00788 12.548 3.51798 13.2609 4.20481 13.7721C4.89164 14.2833 5.72093 14.5673 6.57695 14.5845C5.12369 15.726 3.32848 16.3452 1.48047 16.3423C1.15274 16.3418 0.825333 16.3217 0.5 16.2821C2.37738 17.4866 4.56128 18.1263 6.7918 18.1251Z"
                        fill="#98A2B3"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_4817_10736">
                        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default TwitterIcon;
