import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Take me there",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
        required: true,
    },
] as const;

const ByCompanyTypeYieldSolution: BFC<typeof inputs> = ({ buttonText, linkTo }) => {
    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h2 className={styles.title}>
                    Yield Solutions for Publishers with most massive data ever built <br /> exclusive deals depending on
                    your requirements!
                </h2>
                <Link to={linkTo} className={styles.linkWrapper}>
                    <p className={styles.linkText}>{buttonText}</p>
                    <div className={styles.linkIcon}>
                        <StaticImage src="./images/by-company-type-yield-soltion-arrow-right.svg" alt="" />
                    </div>
                </Link>
            </div>
        </div>
    );
};

Builder.registerComponent(ByCompanyTypeYieldSolution, {
    name: "byCompanyTypeYieldSolution",
    inputs: toMutable(inputs),
});
