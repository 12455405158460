// extracted by mini-css-extract-plugin
export var actionCard = "styles-module--actionCard--1570d";
export var actionCards = "styles-module--actionCards--c8719";
export var blueIcon = "styles-module--blueIcon--0fa69";
export var card = "styles-module--card--1bc36";
export var cardsWrapper = "styles-module--cardsWrapper--a2793";
export var contactUs = "styles-module--contactUs--a0d16";
export var currentRoute = "styles-module--current-route--7785d";
export var delayBlock = "styles-module--delayBlock--091cf";
export var delayBlocksWrapper = "styles-module--delayBlocksWrapper--5e489";
export var delaysWrapper = "styles-module--delaysWrapper--01804";
export var description = "styles-module--description--2d005";
export var greenIcon = "styles-module--greenIcon--5f1a0";
export var iconWrapper = "styles-module--iconWrapper--e977f";
export var journey = "styles-module--journey--b9673";
export var journeyArrowRight = "styles-module--journey-arrow-right--178cb";
export var journeyRoot = "styles-module--journey-root--963bc";
export var journeyWrapper = "styles-module--journey-wrapper--02afb";
export var lastChange = "styles-module--lastChange--ef0eb";
export var link = "styles-module--link--3c246";
export var mainWrapper = "styles-module--mainWrapper--26055";
export var redIcon = "styles-module--redIcon--f9e4c";
export var route = "styles-module--route--e25a6";
export var subContent = "styles-module--subContent--ac522";
export var supTitle = "styles-module--supTitle--29c58";
export var title = "styles-module--title--171ac";
export var wrapper = "styles-module--wrapper--e3c0d";