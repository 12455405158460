import { Builder } from "@builder.io/react";
import ProductsHero from "components/blocks/hero/ProductsHero";
import { SIGNUP_LINK } from "components/constants";
import { toMutable } from "types/common";
import gradientRight from "./images/hero-blur.svg";
import icon from "./images/yieldOptimize.svg";

const inputs = [
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "buttonTitle",
        type: "text",
        defaultValue: "Start for free",
        required: true,
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Empowered by AY Artificial Intelligence to increase revenue and protect your inventory from being sold below value with real-time flooring optimized for Prebid, Amazon A9, Open Bidding and AdX.",
    },
    {
        name: "heading",
        type: "text",
        defaultValue: "Let AI handle continuous hands-free optimization",
        required: true,
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
        required: true,
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
] as const;

const YieldOptimizerProductHero: BFC<typeof inputs> = ({
    heading,
    description,
    buttonTo,
    buttonTitle,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
}) => {
    return (
        <ProductsHero
            heading={heading}
            description={description ?? ""}
            buttonTo={buttonTo}
            buttonTitle={buttonTitle}
            heroimage={imageOrGif}
            heroimageHover={imageOrGifHover}
            heroimageAlt={imageOrGifAlt}
            showForm={false}
            buttonStyle="btn--blue-pink"
            icon={icon}
            iconTitle="Yield Optimizer"
            gradient={{
                right: gradientRight,
                rightTop: "-275px",
                rightBottom: "",
                left: "",
                leftTop: "0px",
                leftBottom: "",
            }}
            moreContent={true}
        />
    );
};

Builder.registerComponent(YieldOptimizerProductHero, {
    name: "YieldOptimizerProductHero",
    inputs: toMutable(inputs),
});
