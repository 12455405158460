import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Optimize on today’s data, not yesterday!",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Compliment all the real-time data from Yield Intelligence, by combining it with your bought traffic data. Last-hour data, across several dimensions. Empower your media buyers with a quick look that shows successful campaigns. Stop losing out due to long hours of waiting for information from multiple sources to get in sync.",
    },
    {
        name: "subtitle2",
        type: "longText",
        defaultValue: "You don’t have to rely on big techs data anymore!",
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a call",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
] as const;

const trafficAcquisitionWinGame: BFC<typeof inputs> = ({ title, subtitle, buttonTo, subtitle2, buttonText }) => {
    const { desktop, mobile } = useStaticQuery(graphql`
        query {
            mobile: file(relativePath: { eq: "yiglobe.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            desktop: file(relativePath: { eq: "yi.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const imageDesktop = getImage(desktop);
    const imageMobile = getImage(mobile);
    return (
        <div style={{ display: "grid" }} className={`${styles.products_video_height} component-wrapper  pb-0 `}>
            <GatsbyImage
                className="d-lg-none"
                style={{
                    gridArea: "1/1",
                    zIndex: -1,
                }}
                alt=""
                image={imageMobile!}
                objectPosition="50% bottom"
            />

            <GatsbyImage
                className="d-none d-lg-block"
                style={{
                    gridArea: "1/1",
                    zIndex: -1,
                }}
                alt=""
                image={imageDesktop!}
            />
            <div
                className="container h-100"
                style={{
                    gridArea: "1/1",
                }}
            >
                <div className="row align-items-center h-100">
                    <div className="col-12 col-lg-7">
                        <h2 className="mt-3 mb-3">{title}</h2>
                        <p>{subtitle}</p>
                        <p className="font-weight-bold mb-4">{subtitle2}</p>
                        <CustomButton buttonStyle="btn--primary" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(trafficAcquisitionWinGame, {
    name: "trafficAcquisitionWinGame",
    inputs: toMutable(inputs),
});
