import type { FC } from "react";
import { graphql, Link, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { micromark } from "micromark";
import AdBannerWrapperHit from "components/adBannerWrapperHit";
import { useCopyToClipboard } from "components/hooks/use-copy-to-clipboard";
import Layout from "components/Layout";
import MarkdownRenderer from "components/MarkdownRenderer";
import Seo from "components/seo";
import { SuccessStoriesCarousel } from "components/successStoriesCarousel/successStoriesCarousel.builder";
import UnlockRemarkableResults from "components/unlockRemarkableResults/unlockRemarkableResults";
import type { SuccessStoriesData } from "types/common";
import * as styles from "./success-stories.module.scss";

const CopyLinkButton: FC<{
    onClick: () => void;
    wasCopied: boolean;
}> = ({ onClick, wasCopied }) => (
    <button
        className={[
            styles.white_rounded_button,
            wasCopied ? styles.copy_link_icon_before_was_copied : styles.copy_link_icon_before_default_state,
            styles.copy_link_icon_before,
        ].join(" ")}
        onClick={onClick}
    >
        Copy Link
    </button>
);

const FileIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.6654 1.6665H4.9987C4.55667 1.6665 4.13275 1.8421 3.82019 2.15466C3.50763 2.46722 3.33203 2.89114 3.33203 3.33317V16.6665C3.33203 17.1085 3.50763 17.5325 3.82019 17.845C4.13275 18.1576 4.55667 18.3332 4.9987 18.3332H14.9987C15.4407 18.3332 15.8646 18.1576 16.1772 17.845C16.4898 17.5325 16.6654 17.1085 16.6654 16.6665V6.6665M11.6654 1.6665L16.6654 6.6665M11.6654 1.6665V6.6665H16.6654M13.332 10.8332H6.66536M13.332 14.1665H6.66536M8.33203 7.49984H6.66536"
                stroke="#344054"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
};

type TSiteData = {
    siteMetadata: {
        siteUrl: string;
    };
};

type TSuccessStoriesAds = {
    title: string;
    horizontalAd: string;
    verticalAd: string;
    horizontalAdLink: string;
    verticalAdLink: string;
    verticalAdFileExternal: {
        childImageSharp: {
            gatsbyImageData: any;
        };
    };
    horizontalAdFileExternal: {
        childImageSharp: {
            gatsbyImageData: any;
        };
    };
};

const SuccessStoriesTemplate: FC<
    PageProps<{ successStories: SuccessStoriesData; site: TSiteData; successStoriesAds: TSuccessStoriesAds }>
> = ({ data }) => {
    const { wasCopied, onCopy } = useCopyToClipboard();
    const { successStoriesAds: ads } = data;
    const verticalAd = getImage(ads.verticalAdFileExternal);
    const horizontalAd = getImage(ads.horizontalAdFileExternal);

    const {
        description,
        featuredImageFileExternal,
        body,
        sidebar,
        relatedFeatures,
        sidebarImageFileExternal,
        video,
        pdf,
    } = data.successStories;

    const downloadPdf = () => {
        if (!pdf) {
            return;
        }

        const link = document.createElement("a");
        link.href = pdf;
        link.download = pdf;
        link.click();
    };

    const image = getImage(featuredImageFileExternal);
    const sidebarImage = getImage(sidebarImageFileExternal);

    const addFeatureBorder = (feature: string) => {
        if (feature === "Yield Intelligence") {
            return styles.yieldIntelligence;
        } else if (feature === "Yield Manager") {
            return styles.yieldManager;
        } else if (feature === "Yield Optimizer" || feature === "Dynamic Flooring") {
            return styles.yieldOptimizer;
        } else if (
            feature === "Traffic Shaping" ||
            feature === "Traffic Shaping for Pubs" ||
            feature === "Traffic Shaping for SSPs" ||
            feature === "AY Industry Insights" ||
            feature === "AY Prebid Server"
        ) {
            return styles.trafficShaping;
        } else {
            return styles.defaultBorder;
        }
    };

    const addLinkToProduct = (feature: string) => {
        if (feature === "Yield Intelligence") {
            return "/yield-intelligence";
        } else if (feature === "Yield Manager") {
            return "/yield-manager";
        } else if (feature === "Yield Optimizer") {
            return "/yield-optimizer";
        } else if (feature === "Traffic Shaping") {
            return "/traffic-shaping";
        } else {
            return "";
        }
    };

    const getPostUrl = () => `${data.site?.siteMetadata.siteUrl}/success-stories${data.successStories.fields.slug}`;

    const getAllHeadingsFromMarkdown = (markdown: string) => {
        const regex = /^#([^#\n]*[^#\s])$/gm;
        const matches = markdown.match(regex);

        return matches?.map(match => match.replace("#", "").trim());
    };

    const allHeadings = getAllHeadingsFromMarkdown(body);

    return (
        <Layout bg="dark">
            <div
                className="component-wrapper position-absolute m-0 p-0"
                style={{
                    top: "0",
                    width: "100%",
                    minHeight: "350px",
                }}
            >
                <GatsbyImage image={image!} alt="" style={{ width: "100%", minHeight: "350px" }} />
            </div>
            <div className="content-light">
                <div className="component-wrapper mb-0 mt-0">
                    <div className="container mt-5 mt-lg-6">
                        <div className="row">
                            <div className="col">
                                <h1 className="mb-6 text-light" style={{ whiteSpace: "pre-line" }}>
                                    {description}
                                </h1>
                            </div>
                        </div>
                        <div className="row gx-5 mt-lg-5 align-items-start mb-lg-5">
                            <div className="col-lg-8 order-2 order-lg-1">
                                <div className="stories-content">
                                    {video ? (
                                        <MarkdownRenderer
                                            post={{
                                                html: video,
                                            }}
                                        />
                                    ) : null}
                                    <div
                                        className={`${styles.actionsWrapper} ${
                                            video ? "" : styles.actionsWrapperVideo
                                        }`}
                                    >
                                        <div>
                                            {relatedFeatures ? (
                                                <p className={styles.sidebarProducts}>AY Products</p>
                                            ) : null}
                                            <div className={styles.sidebarRelatedFeatures}>
                                                {relatedFeatures?.map((relatedFeature: string, index: number) => (
                                                    <Link
                                                        to={addLinkToProduct(relatedFeature) ?? "#"}
                                                        key={index}
                                                        className={[
                                                            styles.feature,
                                                            addFeatureBorder(relatedFeature),
                                                        ].join(" ")}
                                                    >
                                                        {relatedFeature}
                                                    </Link>
                                                ))}
                                            </div>
                                        </div>
                                        <div className={styles.socialWrapper}>
                                            <CopyLinkButton
                                                onClick={() => onCopy(getPostUrl())}
                                                wasCopied={wasCopied}
                                            />
                                        </div>
                                    </div>
                                    <MarkdownRenderer
                                        customClass={styles.storyContent}
                                        post={{
                                            html:
                                                body == null
                                                    ? ""
                                                    : micromark(body, {
                                                          allowDangerousHtml: true,
                                                      }),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={`col-lg-4 order-1 order-lg-2 mb-5 mb-lg-0 ${styles.sidebarWrapper}`}>
                                <div className="side-bar">
                                    <div className={styles.sidebarImage}>
                                        <GatsbyImage image={sidebarImage!} alt="" />
                                    </div>
                                    <MarkdownRenderer
                                        customClass={styles.sidebar}
                                        post={{
                                            html: sidebar == null ? "" : micromark(sidebar),
                                        }}
                                    />
                                    {allHeadings ? (
                                        <>
                                            <hr className={styles.gradientHr} />
                                            <div className={styles.tableOfContents}>
                                                <p className={styles.title}>Table of contents</p>
                                                <ul className={styles.navStructure}>
                                                    {allHeadings?.map((heading, index) => (
                                                        <li key={index} className={styles.item}>
                                                            {heading}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <hr className={styles.gradientHr} />
                                        </>
                                    ) : null}
                                    {pdf ? (
                                        <button className={styles.downloadPdf} onClick={downloadPdf}>
                                            <FileIcon />
                                            <span>Download PDF</span>
                                        </button>
                                    ) : null}
                                </div>

                                <div className={styles.ads}>
                                    <AdBannerWrapperHit
                                        link={ads.verticalAdLink ?? "/"}
                                        style={{
                                            marginBottom: "68px",
                                        }}
                                    >
                                        <GatsbyImage image={verticalAd!} alt="vertical ad" />
                                    </AdBannerWrapperHit>
                                </div>

                                <div className={styles.ads}>
                                    <AdBannerWrapperHit link={ads.horizontalAdLink}>
                                        <GatsbyImage image={horizontalAd!} alt="horizontal ad" />
                                    </AdBannerWrapperHit>
                                </div>
                            </div>
                        </div>

                        <div className={styles.mobileAdsWrapper}>
                            <div className={styles.mobileAds}>
                                <AdBannerWrapperHit
                                    link={ads.verticalAdLink ?? "/"}
                                    style={{
                                        marginBottom: "68px",
                                    }}
                                >
                                    <GatsbyImage image={verticalAd!} alt="vertical ad" />
                                </AdBannerWrapperHit>
                            </div>

                            <div className={styles.mobileAds}>
                                <AdBannerWrapperHit link={ads.horizontalAdLink}>
                                    <GatsbyImage image={horizontalAd!} alt="horizontal ad" />
                                </AdBannerWrapperHit>
                            </div>
                        </div>

                        <UnlockRemarkableResults />
                        <SuccessStoriesCarousel
                            title="Don’t just take our word for it"
                            subtitle="See How Assertive Yield Has Empowered Publishers For Ultimate Revenue Growth"
                            buttonText="Discover More Customer Stories"
                            buttonLink="/success-stories/"
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const Head: FC<PageProps<any>> = ({ location, data: { successStories: post } }) => {
    const image = post.featuredImageFileExternal.childImageSharp.gatsbyImageData;

    const cleanSpacesOnTheLeftAndRight = (str: string) => {
        if (!str) return "";

        const regex = /(?:^\s+|\s+$|\n)/gm;

        return str.replace(regex, "");
    };

    return (
        <Seo title={post.title} description={post.description} canonical={location.pathname} titleSuffixed={false}>
            <meta property="og:image:width" content={String(image.width)} />
            <meta property="og:image:height" content={String(image.height)} />
            <meta property="og:image" content={image.images.fallback.src} />
            <meta property="twitter:image" content={image.images.fallback.src} />
            <meta property="og:type" content="article" />
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "copyrightYear": "2023",
            "description": "${cleanSpacesOnTheLeftAndRight(post.description)}",
            "headline": "${cleanSpacesOnTheLeftAndRight(post.title)}",
            "inLanguage": "en-us",
            "mainEntityOfPage": "${location.pathname}",
            "name": "${cleanSpacesOnTheLeftAndRight(post.title)}",
            "url": "${location.pathname}",
            "author": {
              "@type": "Person",
              "name": "Assertive Yield",
              "worksFor": {
                "@type": "Organization",
                "name": "Assertive Yield"
              }
            },
            "copyrightHolder": {
              "@type": "Organization",
              "name": "Assertive Yield",
              "URL": "https://www.assertiveyield.com/",
              "sameAs": [
                "https://www.youtube.com/@assertiveyield",
                "https://www.linkedin.com/company/assertive-yield"
              ]
            },
            "image": {
              "@type": "ImageObject",
              "URL": "${image.images.fallback.src}",
              "height": "${image.height}",
              "width": "${image.width}"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Assertive Yield",
              "URL": "https://www.assertiveyield.com/"
            }
          }
        `}
            </script>
        </Seo>
    );
};

export default SuccessStoriesTemplate;

export const pageQuery = graphql`
    query story($id: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }

        successStoriesAds {
            title
            horizontalAd
            verticalAd
            horizontalAdLink
            verticalAdLink
            verticalAdFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
            horizontalAdFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
        }

        successStories(id: { eq: $id }) {
            id
            description
            featuredImage
            video
            pdf
            featuredImageFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
            title
            body
            sidebar
            relatedFeatures
            sidebarImage
            sidebarImageFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
            fields {
                slug
            }
        }
    }
`;
