import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { openIntercomChat } from "components/intercom";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Still have questions?",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Can’t find the answer you’re looking for? Please chat to our friendly team.",
    },
    {
        name: "icon",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "altText",
        type: "text",
        defaultValue: "Help Centre People",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Contact support",
    },
] as const;

const HelpCentreStillHaveQuestions: BFC<typeof inputs> = ({ title, subtitle, buttonText, icon }) => {
    return (
        <div className={styles.wrapper}>
            <div className="container">
                <div className={styles.gradientBorder}>
                    <div className={styles.peopleImage}>
                        <img src={icon} alt="" loading="lazy" />
                    </div>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.subtitle}>{subtitle}</p>
                    <div className={styles.buttonWrapper}>
                        <CustomButton onClick={openIntercomChat}>{buttonText}</CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpCentreStillHaveQuestions;

Builder.registerComponent(HelpCentreStillHaveQuestions, {
    name: "helpCentreStillHaveQuestions",
    inputs: toMutable(inputs),
});
