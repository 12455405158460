import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective } from "../types";

const RAW_METRICS = {
    requests: {
        field: "count()",
    },
    waste: {
        field: "countIf(didBid = 0)",
    },
    bids: {
        field: "countIf(didBid = 1)",
    },
    wins: {
        field: "countIf(rendered = 1)",
    },
    saved_waste: {
        field: "countIf(didBid = 0 AND shouldBid = 0)",
    },
    untouched_bids: {
        field: "countIf(didBid = 1 AND shouldBid = 1)",
    },
    lost_bids: {
        field: "countIf(didBid = 1 AND shouldBid = 0)",
    },
    lost_wins: {
        field: "countIf(rendered = 1 AND shouldBid = 0)",
    },
    lost_bid_volume: {
        field: "sumIf(bid, shouldBid = 0)",
    },
    bid_volume: {
        field: "sum(bid)",
    },
    lost_won_volume: {
        field: "sumIf(bid, shouldBid = 0 AND rendered = 1)",
    },
    won_volume: {
        field: "sumIf(bid, rendered = 1)",
    },
    untouched_bid_volume: {
        field: "sumIf(bid, shouldBid = 1)",
    },
    left_waste: {
        field: "countIf(didBid = 0 AND shouldBid = 1)",
    },
    auctions: {
        field: "uniq(prebid_auctionId)",
    },
} as const satisfies Record<string, IRawMetric>;
export type RawRequestReductionClientV2Metrics = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "request_reduction_client_requests",
    "request_reduction_client_waste_ratio",
    "request_reduction_client_saved_waste_ratio",
    "request_reduction_client_lost_bids_ratio",
    "request_reduction_client_lost_wins_ratio",
    "request_reduction_client_bid_volume_loss_ratio",
    "request_reduction_client_won_volume_loss_ratio",
    "request_reduction_client_untouched_bid_volume",
    "request_reduction_client_bid_volume_total",
    "request_reduction_client_bid_cpm",
    "request_reduction_client_bids",
    "request_reduction_client_wins",
    "request_reduction_client_saved_waste",
    "request_reduction_client_untouched_bids",
    "request_reduction_client_lost_bids",
    "request_reduction_client_lost_wins",
    "request_reduction_client_left_waste",
] as const;
export type RequestReductionClientMetricsType = (typeof CALC_IDS)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        // auctions
        request_reduction_client_requests: {
            label: "Bid Requests",
            explanation: "Requests for a bid",
            formula: m => m.requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        request_reduction_client_waste_ratio: {
            label: "Waste",
            explanation: "Ratio of no-bids / bid requests",
            formula: m => m.waste / m.requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_bids: {
            label: "Bids",
            explanation: "Bids made by bidders",
            formula: m => m.bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_wins: {
            label: "Wins",
            explanation: "Wins made by bidders",
            formula: m => m.wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_saved_waste: {
            label: "Saved Waste",
            explanation: "Requests there weren't bids that would be filtered",
            formula: m => m.saved_waste,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_saved_waste_ratio: {
            label: "Waste Reduction",
            explanation: "Ratio of saved waste / total waste",
            formula: m => m.saved_waste / m.waste,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_untouched_bids: {
            label: "Untouched Bids",
            explanation: "Bids that wouldn't be filtered",
            formula: m => m.untouched_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_lost_bids: {
            label: "Lost Bids",
            explanation: "Bids that would be filtered",
            formula: m => m.lost_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_lost_wins: {
            label: "Lost Wins",
            explanation: "Wins that would be filtered",
            formula: m => m.lost_wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_lost_bids_ratio: {
            label: "Lost Bids",
            explanation: "Ratio of lost bids / bids",
            formula: m => m.lost_bids / m.bids,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_lost_wins_ratio: {
            label: "Lost Wins Ratio",
            explanation: "Ratio of lost wins / wins",
            formula: m => m.lost_wins / m.wins,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_bid_volume_loss_ratio: {
            label: "Lost Bid Volume",
            explanation: "Ratio of lost bid volume (sum of lost bids) / total bid volume (sum of bids)",
            formula: m => m.lost_bid_volume / m.bid_volume,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_won_volume_loss_ratio: {
            label: "Lost Won Volume Ratio",
            explanation: "Ratio of lost won volume (sum of winning bids) / total won volume (sum of bids)",
            formula: m => m.lost_won_volume / m.won_volume,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_client_untouched_bid_volume: {
            label: "Untouched Bid Volume",
            explanation: "Bid Volume (sum of bids) that was left untouched",
            formula: m => m.untouched_bid_volume / 1_000_000_000,
            calculationType: CalculationType.Sum,
            decimalPlaces: 2,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_bid_volume_total: {
            label: "Total Bid Volume",
            explanation: "Total Bid Volume (sum of bids)",
            formula: m => m.bid_volume / 1_000_000_000,
            calculationType: CalculationType.Sum,
            decimalPlaces: 2,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_bid_cpm: {
            label: "Bid CPM",
            explanation: "Bid Volume (sum of bids) / Number of Bids * 1000",
            formula: m => m.bid_volume / m.bids / 1_000_000,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_client_left_waste: {
            label: "Left Waste",
            explanation: "Requests there weren't bids that wouldn't be filtered",
            formula: m => m.left_waste,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
    });
