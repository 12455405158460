import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Be smart, be data-driven, be competitive and be a winner",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Select the timeframe for your analysis between a specific Month, Quarter or Year, or by a custom range, grouped by day, month or week. Cross-analyze market revenue: by Bidder x Creative Size x Country x Device x Browser. Choose between the options ahead, analyze on screen or download.",
    },
] as const;

export const BeSmartBeDataDriven: BFC<typeof inputs> = ({ title, subtitle }) => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{title}</h2>
            <p>{subtitle}</p>
        </div>
    );
};

Builder.registerComponent(BeSmartBeDataDriven, {
    name: "beSmartBeDataDriven",
    inputs: toMutable(inputs),
});
