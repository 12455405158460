import { Link } from "gatsby";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "General application",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Can’t find a career you’re looking for? We want to hear from you,  Please send your application to our career team on",
    },
    {
        name: "email",
        type: "text",
        defaultValue: "Send your own application",
    },
] as const;

const CareersGeneralApplication: BFC<typeof inputs> = ({ title, subtitle, email }) => {
    return (
        <div className={styles.wrapper}>
            <div className="container">
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>
                <Link to="/apply" className={styles.email}>
                    {email}
                </Link>
            </div>
        </div>
    );
};

Builder.registerComponent(CareersGeneralApplication, {
    name: "careersGeneralApplication",
    inputs: toMutable(inputs),
});
