import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Slice & Dice Data Cube",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Slice and Dice the most granular datasets like never before",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "PreBid, Dynamic Allocation and Direct massive and unrevealed data to cross-analyze from different perspectives through multidimensional, accurate metrics, and unlimited filters. Break All Your Paradigms",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Try it Out, It's Free",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
        required: true,
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
] as const;

const SliceAndDiceHero: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    buttonText,
    linkTo,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
}) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/second-glow.svg" alt="" />
            </div>
            <div className={`${styles.thirdGlow} gradient`}>
                <StaticImage src="./images/third-glow.svg" alt="" />
            </div>
            <div className={`${styles.fourthGlow} gradient`}>
                <StaticImage src="./images/fourth-glow.svg" alt="" />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.mainContent}>
                    <p className={styles.suptitle}>{suptitle}</p>
                    <h1 className={styles.title}>{title}</h1>
                    <p className={styles.subtitle}>{subtitle}</p>
                    <div className={styles.btn}>
                        <CustomButton to={linkTo}>{buttonText}</CustomButton>
                    </div>
                    <p className={styles.note}>No credit card required</p>
                </div>
                <div className={styles.image}>
                    {imageOrGifHover ? (
                        <img
                            onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                            onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                            src={imageOrGif}
                            alt={imageOrGifAlt}
                            className={styles.image}
                        />
                    ) : (
                        <BuilderImage altText={imageOrGifAlt} image={imageOrGif} />
                    )}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(SliceAndDiceHero, {
    name: "sliceAndDiceHero",
    inputs: toMutable(inputs),
});
