export interface IssStat {
    avg: number;
    std: number;
}

type ManualIssStatsKeys =
    | "nativo_revenue_per_nativo_impression"
    | "nativo_revenue_per_nativo_pageView"
    | "nativo_revenue_per_nativo_session"
    | "adSense_revenue_per_adSense_impression"
    | "adSense_revenue_per_adSense_pageView"
    | "adSense_revenue_per_adSense_session"
    | "ayMediation_revenue_per_ayMediation_impression"
    | "ayMediation_revenue_per_ayMediation_pageView"
    | "ayMediation_revenue_per_ayMediation_session"
    | "aps_revenue_per_aps_impression"
    | "aps_revenue_per_aps_pageView"
    | "aps_revenue_per_aps_session"
    | "buy_spent_per_click"
    | "buy_revenue_per_click"
    | "buy_spent_per_new_sessions"
    | "buy_spent_per_impressions"
    | "buy_ffi_per_session_starts"
    | "site_spent_per_click"
    | "site_revenue_per_click"
    | "site_spent_per_new_sessions"
    | "site_spent_per_impressions"
    | "site_ffi_per_session_starts"
    | "spent_by_section_spent_per_click"
    | "spent_by_section_revenue_per_click"
    | "spent_by_section_spent_per_new_sessions"
    | "spent_by_section_spent_per_impressions"
    | "spent_by_section_ffi_per_session_starts"
    | "prebid_report_floor_cpm"
    | "prebid_user_id_providers_avg"
    | "mock_web_avg";

// if any metric is from 1 entity is to avoid confusion with different currencies
export const manualISSStats: Record<ManualIssStatsKeys, IssStat> = {
    prebid_user_id_providers_avg: {
        /*
        Mocking any data for prebid_report_floor_cpm, once CI re-calculates this we can drop this mock
        */
        avg: 1,
        std: 1,
    },
    prebid_report_floor_cpm: {
        /*
        Mocking any data for prebid_report_floor_cpm, once CI re-calculates this we can drop this mock
        */
        avg: 1,
        std: 1,
    },
    nativo_revenue_per_nativo_impression: {
        /*
        SELECT 
            avg(nativo_revenue), 
            stddevPop(nativo_revenue)
        FROM 
        (
            SELECT
                if(event_impression AND (source = 'nativo') AND (nativo_ssp_advertiser_impressions > 0), nativo_ssp_advertiser_revenue / nativo_ssp_advertiser_impressions, 0) AS nativo_revenue
            FROM events
            ANY LEFT JOIN 
            (
                SELECT
                    SUM(revenue) AS nativo_ssp_advertiser_revenue,
                    SUM(revenue / revenue_correction) AS nativo_ssp_advertiser_revenue_no_correction,
                    SUM(cpm_impressions) AS nativo_ssp_advertiser_impressions,
                    toStartOfHour(timestamp, 'UTC') AS nativo_ssp_advertiser_startOfHour,
                    entityId AS nativo_ssp_advertiser_entityId,
                    placement_id AS nativo_ssp_advertiser_placement,
                    country AS nativo_ssp_advertiser_country_name,
                    browser AS nativo_ssp_advertiser_browser_modified,
                    device AS nativo_ssp_advertiser_os
                FROM nativoSspAdvertiser
                FINAL
                WHERE (entityId IN ('pjiFg24eQKg2ZzrYE')) AND (normalized = 1)
                GROUP BY (nativo_ssp_advertiser_entityId, nativo_ssp_advertiser_startOfHour, nativo_ssp_advertiser_placement, nativo_ssp_advertiser_country_name, nativo_ssp_advertiser_browser_modified, nativo_ssp_advertiser_os)
            ) AS nativoSspAdvertiser ON (nativo_ssp_advertiser_entityId = entityId) AND (nativo_ssp_advertiser_startOfHour = toStartOfHour(timestamp, 'UTC')) AND (dfp_adUnitPath = nativo_ssp_advertiser_placement) AND (country_name = nativo_ssp_advertiser_country_name) AND (multiIf(browser_name = 'Microsoft Edge', 'Chrome', browser_name = 'Chrome Mobile', 'Chrome', browser_name = 'Chrome Mobile iOS', 'Chrome', browser_name = 'Mobile Safari', 'Safari', browser_name = 'Google Search App', 'In-App Browser', browser_name = 'Chrome Webview', 'In-App Browser', browser_name) = nativo_ssp_advertiser_browser_modified) AND (multiIf(os_name IN ('Windows', 'Mac', 'GNU/Linux'), 'Desktop', os_name IN ('Android', 'Chrome OS'), 'Android', os_name = 'iOS', 'iOS', '') = nativo_ssp_advertiser_os)
            WHERE (entityId = 'pjiFg24eQKg2ZzrYE') AND ((timestamp >= toDateTime('2021-11-02T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-11-08T23:59:59', 'UTC'))) AND event_impression AND source = 'nativo'
        )
        */
        avg: 0.003791637823054129,
        std: 0.0018507854928320896,
    },
    nativo_revenue_per_nativo_pageView: {
        /*
        SELECT 
            avg(nativo_revenue), 
            stddevPop(nativo_revenue)
        FROM 
        (
            SELECT
                sumIf(nativo_ssp_advertiser_revenue / nativo_ssp_advertiser_impressions, event_impression AND (source = 'nativo') AND (nativo_ssp_advertiser_impressions > 0)) AS nativo_revenue
            FROM events
            ANY LEFT JOIN 
            (
                SELECT
                    SUM(revenue) AS nativo_ssp_advertiser_revenue,
                    SUM(revenue / revenue_correction) AS nativo_ssp_advertiser_revenue_no_correction,
                    SUM(cpm_impressions) AS nativo_ssp_advertiser_impressions,
                    toStartOfHour(timestamp, 'UTC') AS nativo_ssp_advertiser_startOfHour,
                    entityId AS nativo_ssp_advertiser_entityId,
                    placement_id AS nativo_ssp_advertiser_placement,
                    country AS nativo_ssp_advertiser_country_name,
                    browser AS nativo_ssp_advertiser_browser_modified,
                    device AS nativo_ssp_advertiser_os
                FROM nativoSspAdvertiser
                FINAL
                WHERE (entityId IN ('pjiFg24eQKg2ZzrYE')) AND (normalized = 1)
                GROUP BY (nativo_ssp_advertiser_entityId, nativo_ssp_advertiser_startOfHour, nativo_ssp_advertiser_placement, nativo_ssp_advertiser_country_name, nativo_ssp_advertiser_browser_modified, nativo_ssp_advertiser_os)
            ) AS nativoSspAdvertiser ON (nativo_ssp_advertiser_entityId = entityId) AND (nativo_ssp_advertiser_startOfHour = toStartOfHour(timestamp, 'UTC')) AND (dfp_adUnitPath = nativo_ssp_advertiser_placement) AND (country_name = nativo_ssp_advertiser_country_name) AND (multiIf(browser_name = 'Microsoft Edge', 'Chrome', browser_name = 'Chrome Mobile', 'Chrome', browser_name = 'Chrome Mobile iOS', 'Chrome', browser_name = 'Mobile Safari', 'Safari', browser_name = 'Google Search App', 'In-App Browser', browser_name = 'Chrome Webview', 'In-App Browser', browser_name) = nativo_ssp_advertiser_browser_modified) AND (multiIf(os_name IN ('Windows', 'Mac', 'GNU/Linux'), 'Desktop', os_name IN ('Android', 'Chrome OS'), 'Android', os_name = 'iOS', 'iOS', '') = nativo_ssp_advertiser_os)
            WHERE (entityId = 'pjiFg24eQKg2ZzrYE') AND ((timestamp >= toDateTime('2021-11-02T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-11-08T23:59:59', 'UTC'))) AND event_impression AND source = 'nativo'
            GROUP BY pageViewId
        )
        */
        avg: 0.005105854088481795,
        std: 0.003453066230381102,
    },
    nativo_revenue_per_nativo_session: {
        /*
        SELECT 
            avg(nativo_revenue), 
            stddevPop(nativo_revenue)
        FROM 
        (
            SELECT
                sumIf(nativo_ssp_advertiser_revenue / nativo_ssp_advertiser_impressions, event_impression AND (source = 'nativo') AND (nativo_ssp_advertiser_impressions > 0)) AS nativo_revenue
            FROM events
            ANY LEFT JOIN 
            (
                SELECT
                    SUM(revenue) AS nativo_ssp_advertiser_revenue,
                    SUM(revenue / revenue_correction) AS nativo_ssp_advertiser_revenue_no_correction,
                    SUM(cpm_impressions) AS nativo_ssp_advertiser_impressions,
                    toStartOfHour(timestamp, 'UTC') AS nativo_ssp_advertiser_startOfHour,
                    entityId AS nativo_ssp_advertiser_entityId,
                    placement_id AS nativo_ssp_advertiser_placement,
                    country AS nativo_ssp_advertiser_country_name,
                    browser AS nativo_ssp_advertiser_browser_modified,
                    device AS nativo_ssp_advertiser_os
                FROM nativoSspAdvertiser
                FINAL
                WHERE (entityId IN ('pjiFg24eQKg2ZzrYE')) AND (normalized = 1)
                GROUP BY (nativo_ssp_advertiser_entityId, nativo_ssp_advertiser_startOfHour, nativo_ssp_advertiser_placement, nativo_ssp_advertiser_country_name, nativo_ssp_advertiser_browser_modified, nativo_ssp_advertiser_os)
            ) AS nativoSspAdvertiser ON (nativo_ssp_advertiser_entityId = entityId) AND (nativo_ssp_advertiser_startOfHour = toStartOfHour(timestamp, 'UTC')) AND (dfp_adUnitPath = nativo_ssp_advertiser_placement) AND (country_name = nativo_ssp_advertiser_country_name) AND (multiIf(browser_name = 'Microsoft Edge', 'Chrome', browser_name = 'Chrome Mobile', 'Chrome', browser_name = 'Chrome Mobile iOS', 'Chrome', browser_name = 'Mobile Safari', 'Safari', browser_name = 'Google Search App', 'In-App Browser', browser_name = 'Chrome Webview', 'In-App Browser', browser_name) = nativo_ssp_advertiser_browser_modified) AND (multiIf(os_name IN ('Windows', 'Mac', 'GNU/Linux'), 'Desktop', os_name IN ('Android', 'Chrome OS'), 'Android', os_name = 'iOS', 'iOS', '') = nativo_ssp_advertiser_os)
            WHERE (entityId = 'pjiFg24eQKg2ZzrYE') AND ((timestamp >= toDateTime('2021-11-02T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-11-08T23:59:59', 'UTC'))) AND event_impression AND source = 'nativo'
            GROUP BY sessionId
        )
        */
        avg: 0.03172814008085117,
        std: 0.07015245420866856,
    },
    adSense_revenue_per_adSense_impression: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT revenue AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'adSense') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
        )
        */
        avg: 7363612.407539085,
        std: 14419654.806717891,
    },
    adSense_revenue_per_adSense_pageView: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT SUM(revenue) AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'adSense') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
            GROUP BY pageViewId
        )
        */
        avg: 7363867.721005774,
        std: 14423591.098343825,
    },
    adSense_revenue_per_adSense_session: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT SUM(revenue) AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'adSense') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
            GROUP BY sessionId
        )
        */
        avg: 79184699.17269774,
        std: 184979466.5136247,
    },
    ayMediation_revenue_per_ayMediation_impression: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT revenue AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'ayMediation') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
        )
        */
        avg: 7363612.407539085,
        std: 14419654.806717891,
    },
    ayMediation_revenue_per_ayMediation_pageView: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT SUM(revenue) AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'ayMediation') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
            GROUP BY pageViewId
        )
        */
        avg: 7363867.721005774,
        std: 14423591.098343825,
    },
    ayMediation_revenue_per_ayMediation_session: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT SUM(revenue) AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'ayMediation') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
            GROUP BY sessionId
        )
        */
        avg: 79184699.17269774,
        std: 184979466.5136247,
    },
    aps_revenue_per_aps_impression: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT revenue AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'adSense') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
        )
        */
        avg: 7363612.407539085,
        std: 14419654.806717891,
    },
    aps_revenue_per_aps_pageView: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT SUM(revenue) AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'adSense') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
            GROUP BY pageViewId
        )
        */
        avg: 7363867.721005774,
        std: 14423591.098343825,
    },
    aps_revenue_per_aps_session: {
        /*
        SELECT
            avg(revenue),
            stddevPop(revenue)
        FROM
        (
            SELECT SUM(revenue) AS revenue
            FROM events
            WHERE (entityId = 'QLAqDdvz4k72xF6bb') AND (source = 'adSense') AND event_impression AND (timestamp >= toDateTime('2022-11-11T00:00:00', 'Europe/Amsterdam')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'Europe/Amsterdam'))
            GROUP BY sessionId
        )
        */
        avg: 79184699.17269774,
        std: 184979466.5136247,
    },
    buy_spent_per_click: {
        /* 
        SELECT avg(rate), stddevPop(rate)
        FROM
        (
            SELECT 
                (dictGetFloat64('currencyConversion', 'multiplier', (date, currency, 'USD')) * spent) AS spent, 
                (spent.clicks) AS clicks_bought, 
                (spent/clicks_bought) * 1000 as rate
            FROM spent
            FINAL
            WHERE ((timestamp >= toDateTime('2021-03-10T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-16T23:59:59', 'UTC'))) AND (entityId IN ('p7BWTE3SRJ45bccvW'))
            AND clicks_bought!=0
        )
        */
        avg: 45.16352999471093,
        std: 41.594045909922855,
    },
    buy_spent_per_new_sessions: {
        /*
        SELECT avg(rate), stddevPop(rate)
        FROM
        (
            SELECT 
                (dictGetFloat64('currencyConversion', 'multiplier', (date, currency, 'USD')) * spent) AS spent, 
                spent/sess * 1000 as rate,
                date AS spent_date
            FROM spent
            FINAL
            INNER JOIN 
            (
                SELECT 
                    utm_medium AS _group, 
                    date AS _date, 
                    'p7BWTE3SRJ45bccvW' AS _table,
                    countIf(session_impressionCount = 1) as sess
                FROM p7BWTE3SRJ45bccvW
                WHERE ((timestamp >= toDateTime('2021-03-10T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-16T23:59:59', 'UTC'))) 
                GROUP BY 
                    _group, 
                    _date
                HAVING sess != 0
            ) AS yield ON (spent_date = _date) AND (multiIf((entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'taboola'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'zemanta'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'twitter'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'facebook'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'yahoo'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'snapchat'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'outbrain'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'revcontent'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'google_ads'), campaign_name, '') = _group) AND (entityId = _table)
            WHERE ((timestamp >= toDateTime('2021-03-08T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-15T23:59:59', 'UTC'))) AND (entityId IN ('p7BWTE3SRJ45bccvW'))
        )

        */
        avg: 1.585648035412046,
        std: 3.9612475872182578,
    },
    buy_spent_per_impressions: {
        /*
        SELECT avg(rate), stddevPop(rate)
        FROM
        (
            SELECT 
                (dictGetFloat64('currencyConversion', 'multiplier', (date, currency, 'USD')) * spent) AS spent, 
                spent/imp * 1000 as rate,
                date AS spent_date
            FROM spent
            FINAL
            INNER JOIN 
            (
                SELECT 
                    utm_medium AS _group, 
                    date AS _date, 
                    'p7BWTE3SRJ45bccvW' AS _table,
                    count() as imp
                FROM p7BWTE3SRJ45bccvW
                WHERE ((timestamp >= toDateTime('2021-03-10T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-16T23:59:59', 'UTC')))
                GROUP BY 
                    _group, 
                    _date
                HAVING imp != 0
            ) AS yield ON (spent_date = _date) AND (multiIf((entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'taboola'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'zemanta'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'twitter'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'facebook'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'yahoo'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'snapchat'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'outbrain'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'revcontent'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'google_ads'), campaign_name, '') = _group) AND (entityId = _table)
            WHERE ((timestamp >= toDateTime('2021-03-08T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-15T23:59:59', 'UTC'))) AND (entityId IN ('p7BWTE3SRJ45bccvW'))
            AND spent > 0
        )

        */
        avg: 0.06072201200732988,
        std: 0.1494630841752645,
    },
    buy_revenue_per_click: {
        /*
        SELECT 
            avg(spc), 
            stddevPop(spc)
        FROM 
        (
            SELECT 
                (prebid_won_revenue + dynamicAllocation_revenue) + direct_revenue AS rev, 
                spent.clicks AS clicks_bought, 
                if(clicks_bought = 0, 0, rev / clicks_bought) AS spc, 
                date AS spent_date
            FROM spent
            FINAL
            INNER JOIN 
            (
                SELECT 
                    utm_medium AS _group, 
                    date AS _date, 
                    countIf(session_impressionCount = 1) AS session_starts, 
                    (SUM(multiIf(preBidWon = 1, highestPreBid, 0)) / 1000) / 1000 AS prebid_won_revenue, 
                    SUM(if(dfp_isBackfill = 1, if(da_impressions > 0, da_revenue / da_impressions, 0), 0)) / 1000 AS dynamicAllocation_revenue, 
                    (sumIf(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId))), (dfp_isBackfill = 0) AND (preBidWon = 0)) / 1000) / 1000 AS direct_revenue, 
                    count() AS imp, 
                    'p7BWTE3SRJ45bccvW' AS _table
                FROM p7BWTE3SRJ45bccvW
                    ANY LEFT JOIN 
                    (
                        SELECT 
                            SUM(revenue) AS da_revenue, 
                            SUM(impressions) AS da_impressions, 
                            cityHash64(toStartOfHour(timestamp), adUnitPath, creative_width, creative_height, dfp_hash, if(device_type = 'Android Tablet (Google)', 'Android Phone (Google)', CAST(device_type, 'String'))) AS joinKey
                        FROM dynamicAllocation
                        WHERE (entityId = 'p7BWTE3SRJ45bccvW') AND (withOpenBidding = 0) AND (normalized = 0) AND ((timestamp >= toDateTime('2021-03-08T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-15T23:59:59', 'UTC')))
                        GROUP BY 
                            dfp_hash, 
                            joinKey
                    ) AS da ON joinKey = cityHash64(toStartOfHour(timestamp), adUnitPath, creative_width, creative_height, dfp_hash, if('Android Tablet (Google)' = dictGetStringOrDefault('ayOSDeviceToGoogleDeviceType', 'device_type', (assumeNotNull(device_name), assumeNotNull(os_name)), concat(assumeNotNull(device_name), ';', assumeNotNull(os_name))), 'Android Phone (Google)', dictGetStringOrDefault('ayOSDeviceToGoogleDeviceType', 'device_type', (assumeNotNull(device_name), assumeNotNull(os_name)), concat(assumeNotNull(device_name), ';', assumeNotNull(os_name)))))
                WHERE (timestamp >= toDateTime('2021-03-10T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-16T23:59:59', 'UTC'))
                
                GROUP BY 
                    _group, 
                    _date
                ORDER BY 
                    _date ASC, 
                    _group ASC
            ) AS yield ON (spent_date = _date) AND (multiIf((entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'taboola'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'zemanta'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'twitter'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'facebook'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'yahoo'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'snapchat'), adset_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'outbrain'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'revcontent'), campaign_name, (entityId = 'p7BWTE3SRJ45bccvW') AND (platform = 'google_ads'), campaign_name, '') = _group) AND (entityId = _table)
            WHERE ((timestamp >= toDateTime('2021-03-08T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-15T23:59:59', 'UTC'))) AND (entityId IN ('p7BWTE3SRJ45bccvW'))
            AND clicks_bought != 0
        )


        */
        avg: 2296.953043745495,
        std: 4197.39001254295,
    },
    buy_ffi_per_session_starts: {
        /*
        SELECT 
            avg(revenue), 
            stddevPop(revenue)
        FROM 
        (
            SELECT sumIf(highestPreBid, session_impressionCount < 6) AS revenue
            FROM z5HTPZ4M3K57wr62X
            WHERE (timestamp >= toDateTime('2021-03-08T00:00:00', 'UTC')) AND (timestamp <= toDateTime('2021-03-14T23:59:59', 'UTC'))
            GROUP BY sessionUUID
        )
        */
        avg: 3344480.642256703,
        std: 5805145.083752478,
    },
    site_spent_per_click: {
        /* 
        SELECT avg(rate), stddevPop(rate)
        FROM
        (
            SELECT 
                (spent/clicks) * 1000 as rate
            FROM spent_by_site
            FINAL
            WHERE ((date >= toDate('2022-11-11T00:00:00', 'UTC')) AND (date <= toDate('2022-12-11T23:59:59', 'UTC'))) AND (entityId IN ('pjiFg24eQKg2ZzrYE'))
            AND clicks!=0
        )
        */
        avg: 165.18179166049933,
        std: 66.61186389057558,
    },
    site_spent_per_new_sessions: {
        /*
        SELECT
            avg(rate),
            stddevPop(rate)
        FROM
        (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                spentMergedTimestamp AS _date,
                (site_report_platform, site_report_site_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                spent / session_starts AS rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    site_report_platform AS site_report_platform,
                    site_report_site_name AS site_report_site_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        site_report_platform AS site_report_platform,
                        site_report_site_name AS site_report_site_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo'), toUInt32(2592000), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            site_report_platform AS site_report_platform,
                            site_report_site_name AS site_report_site_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'taboola'), site_id, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'yahoo'), site_name, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'outbrain'), site_name, '') AS join_group,
                                platform AS site_report_platform,
                                site_name AS site_report_site_name
                            FROM spent_by_site
                            FINAL
                            WHERE ((date >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('oiSY34t65EMZ9GTZq'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                site_report_platform,
                                site_report_site_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        site_report_platform,
                        site_report_site_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    site_report_platform,
                    site_report_site_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'oiSY34t65EMZ9GTZq' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'oiSY34t65EMZ9GTZq') AND event_impression AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'oiSY34t65EMZ9GTZq') AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING session_starts > 0
            ORDER BY clicks_bought DESC
        )
        */
        avg: 0.3564414747478823,
        std: 1.5368843930975082,
    },
    site_spent_per_impressions: {
        /*
        SELECT avg(rate), stddevPop(rate) FROM (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                spentMergedTimestamp AS _date,
                (site_report_platform, site_report_site_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                spent/impressions as rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    site_report_platform AS site_report_platform,
                    site_report_site_name AS site_report_site_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        site_report_platform AS site_report_platform,
                        site_report_site_name AS site_report_site_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo'), toUInt32(2592000), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            site_report_platform AS site_report_platform,
                            site_report_site_name AS site_report_site_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'taboola'), site_id, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'yahoo'), site_name, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'outbrain'), site_name, '') AS join_group,
                                platform AS site_report_platform,
                                site_name AS site_report_site_name
                            FROM spent_by_site
                            FINAL
                            WHERE ((date >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('oiSY34t65EMZ9GTZq'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                site_report_platform,
                                site_report_site_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        site_report_platform,
                        site_report_site_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    site_report_platform,
                    site_report_site_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'oiSY34t65EMZ9GTZq' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'oiSY34t65EMZ9GTZq') AND event_impression AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'oiSY34t65EMZ9GTZq') AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING (impressions > 0)
            ORDER BY clicks_bought DESC
        )
        */
        avg: 0.051898581894765185,
        std: 0.273960546764922,
    },
    site_revenue_per_click: {
        /*
        SELECT
            avg(rate),
            stddevPop(rate)
        FROM
        (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                SUM(yield.prebid_won_revenue) AS prebid_won_revenue,
                SUM(yield.dynamicAllocation_revenue) AS dynamicAllocation_revenue,
                SUM(yield.direct_revenue) AS direct_revenue,
                SUM(yield.outbrain_revenue) AS outbrain_revenue,
                SUM(yield.taboola_revenue) AS taboola_revenue,
                SUM(yield.yahooGemini_revenue) AS yahooGemini_revenue,
                spentMergedTimestamp AS _date,
                (site_report_platform, site_report_site_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                (((((assumeNotNull(prebid_won_revenue) + assumeNotNull(dynamicAllocation_revenue)) + assumeNotNull(direct_revenue)) + assumeNotNull(outbrain_revenue)) + assumeNotNull(taboola_revenue)) + assumeNotNull(yahooGemini_revenue)) / clicks_bought AS rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    site_report_platform AS site_report_platform,
                    site_report_site_name AS site_report_site_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        site_report_platform AS site_report_platform,
                        site_report_site_name AS site_report_site_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo'), toUInt32(2592000), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            site_report_platform AS site_report_platform,
                            site_report_site_name AS site_report_site_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'taboola'), site_id, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'yahoo'), site_name, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'outbrain'), site_name, '') AS join_group,
                                platform AS site_report_platform,
                                site_name AS site_report_site_name
                            FROM spent_by_site
                            FINAL
                            WHERE ((date >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('oiSY34t65EMZ9GTZq'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                site_report_platform,
                                site_report_site_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        site_report_platform,
                        site_report_site_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    site_report_platform,
                    site_report_site_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'oiSY34t65EMZ9GTZq' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    sumIf(prebid_highestBid, event_impression AND prebid_won) AS prebid_won_revenue,
                    sumIf(revenue, source = 'dynamicAllocation') / 1000 AS dynamicAllocation_revenue,
                    sumIf(if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0) * if(dictGetStringOrDefault('lineItem', 'currency', toUInt64(assumeNotNull(dfp_lineItemId)), '') = '', 1., dictGetFloat64('currencyConversion2', 'value', (toDate(_date), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(_date), dictGetStringOrDefault('lineItem', 'currency', toUInt64(assumeNotNull(dfp_lineItemId)), '')))), toFloat64(assumeNotNull(revenue))) / 1000, source = 'lineitem') AS direct_revenue,
                    sumIf(revenue, source = 'outbrain') / 1000 AS outbrain_revenue,
                    sumIf(revenue, source = 'taboola') / 1000 AS taboola_revenue,
                    sumIf(revenue, source = 'yahooGemini') / 1000 AS yahooGemini_revenue,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'oiSY34t65EMZ9GTZq') AND event_impression AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'oiSY34t65EMZ9GTZq') AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING clicks_bought > 0
            ORDER BY clicks_bought DESC
        )
        */
        avg: 562313.8077382864,
        std: 4703959.122725625,
    },
    site_ffi_per_session_starts: {
        /*
        SELECT avg(rate), stddevPop(rate) FROM (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                SUM(yield.first_five_indicator) AS first_five_indicator,
                spentMergedTimestamp AS _date,
                (site_report_platform, site_report_site_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                first_five_indicator / session_starts as rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    site_report_platform AS site_report_platform,
                    site_report_site_name AS site_report_site_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        site_report_platform AS site_report_platform,
                        site_report_site_name AS site_report_site_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo'), toUInt32(2592000), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            site_report_platform AS site_report_platform,
                            site_report_site_name AS site_report_site_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'taboola'), site_id, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'yahoo'), site_name, (entityId = 'oiSY34t65EMZ9GTZq') AND (platform = 'outbrain'), site_name, '') AS join_group,
                                platform AS site_report_platform,
                                site_name AS site_report_site_name
                            FROM spent_by_site
                            FINAL
                            WHERE ((date >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('oiSY34t65EMZ9GTZq'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                site_report_platform,
                                site_report_site_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        site_report_platform,
                        site_report_site_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    site_report_platform,
                    site_report_site_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'oiSY34t65EMZ9GTZq' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    sumIf(prebid_highestBid, (session_impressionCount < 6) AND event_impression) AS first_five_indicator,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'oiSY34t65EMZ9GTZq') AND event_impression AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'oiSY34t65EMZ9GTZq') AND ((timestamp >= toDateTime('2022-11-12T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING (session_starts > 0)
            ORDER BY clicks_bought DESC
        )
        */
        avg: 3314887.5406080913,
        std: 3280224.354853315,
    },
    spent_by_section_spent_per_click: {
        /* 
        SELECT avg(rate), stddevPop(rate)
        FROM
        (
            SELECT 
                (spent/clicks) * 1000 as rate
            FROM spent_by_section
            FINAL
            WHERE ((date >= toDate('2022-11-11T00:00:00', 'UTC')) AND (date <= toDate('2022-12-11T23:59:59', 'UTC'))) AND (entityId IN ('wBYietxRhKezRFFDD'))
            AND clicks!=0
        )
        */
        avg: 186.76316164409081,
        std: 82.59047210820155,
    },
    spent_by_section_spent_per_new_sessions: {
        /*
        SELECT avg(rate), stddevPop(rate) FROM (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                spentMergedTimestamp AS _date,
                (spent_by_section_report_platform, spent_by_section_report_section_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                spent / session_starts AS rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    spent_by_section_report_platform AS spent_by_section_report_platform,
                    spent_by_section_report_section_name AS spent_by_section_report_section_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        spent_by_section_report_platform AS spent_by_section_report_platform,
                        spent_by_section_report_section_name AS spent_by_section_report_section_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo'), toUInt32(604800), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            spent_by_section_report_platform AS spent_by_section_report_platform,
                            spent_by_section_report_section_name AS spent_by_section_report_section_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'wBYietxRhKezRFFDD') AND (platform = 'outbrain'), section_name, '') AS join_group,
                                platform AS spent_by_section_report_platform,
                                section_name AS spent_by_section_report_section_name
                            FROM spent_by_section
                            FINAL
                            WHERE ((date >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('wBYietxRhKezRFFDD'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                spent_by_section_report_platform,
                                spent_by_section_report_section_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        spent_by_section_report_platform,
                        spent_by_section_report_section_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    spent_by_section_report_platform,
                    spent_by_section_report_section_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'wBYietxRhKezRFFDD' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'wBYietxRhKezRFFDD') AND event_impression AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'wBYietxRhKezRFFDD') AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING (session_starts > 0)
            ORDER BY clicks_bought DESC
        )
        */
        avg: 0.1432360596828065,
        std: 0.14448855280821918,
    },
    spent_by_section_spent_per_impressions: {
        /*
        SELECT avg(rate), stddevPop(rate) FROM (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                spentMergedTimestamp AS _date,
                (spent_by_section_report_platform, spent_by_section_report_section_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                spent/impressions as rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    spent_by_section_report_platform AS spent_by_section_report_platform,
                    spent_by_section_report_section_name AS spent_by_section_report_section_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        spent_by_section_report_platform AS spent_by_section_report_platform,
                        spent_by_section_report_section_name AS spent_by_section_report_section_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo'), toUInt32(604800), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            spent_by_section_report_platform AS spent_by_section_report_platform,
                            spent_by_section_report_section_name AS spent_by_section_report_section_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'wBYietxRhKezRFFDD') AND (platform = 'outbrain'), section_name, '') AS join_group,
                                platform AS spent_by_section_report_platform,
                                section_name AS spent_by_section_report_section_name
                            FROM spent_by_section
                            FINAL
                            WHERE ((date >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('wBYietxRhKezRFFDD'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                spent_by_section_report_platform,
                                spent_by_section_report_section_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        spent_by_section_report_platform,
                        spent_by_section_report_section_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    spent_by_section_report_platform,
                    spent_by_section_report_section_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'wBYietxRhKezRFFDD' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'wBYietxRhKezRFFDD') AND event_impression AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'wBYietxRhKezRFFDD') AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING (impressions > 0)
            ORDER BY clicks_bought DESC
        )
        */
        avg: 0.021144445598799144,
        std: 0.04116071377631576,
    },
    spent_by_section_revenue_per_click: {
        /*
        SELECT avg(rate), stddevPop(rate) FROM (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                SUM(yield.prebid_won_revenue) AS prebid_won_revenue,
                SUM(yield.dynamicAllocation_revenue) AS dynamicAllocation_revenue,
                SUM(yield.direct_revenue) AS direct_revenue,
                SUM(yield.outbrain_revenue) AS outbrain_revenue,
                SUM(yield.taboola_revenue) AS taboola_revenue,
                SUM(yield.yahooGemini_revenue) AS yahooGemini_revenue,
                spentMergedTimestamp AS _date,
                (spent_by_section_report_platform, spent_by_section_report_section_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                (((((assumeNotNull(prebid_won_revenue) + assumeNotNull(dynamicAllocation_revenue)) + assumeNotNull(direct_revenue)) + assumeNotNull(outbrain_revenue)) + assumeNotNull(taboola_revenue)) + assumeNotNull(yahooGemini_revenue)) / clicks_bought AS rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    spent_by_section_report_platform AS spent_by_section_report_platform,
                    spent_by_section_report_section_name AS spent_by_section_report_section_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        spent_by_section_report_platform AS spent_by_section_report_platform,
                        spent_by_section_report_section_name AS spent_by_section_report_section_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo'), toUInt32(604800), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            spent_by_section_report_platform AS spent_by_section_report_platform,
                            spent_by_section_report_section_name AS spent_by_section_report_section_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'wBYietxRhKezRFFDD') AND (platform = 'outbrain'), section_name, '') AS join_group,
                                platform AS spent_by_section_report_platform,
                                section_name AS spent_by_section_report_section_name
                            FROM spent_by_section
                            FINAL
                            WHERE ((date >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('wBYietxRhKezRFFDD'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                spent_by_section_report_platform,
                                spent_by_section_report_section_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        spent_by_section_report_platform,
                        spent_by_section_report_section_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    spent_by_section_report_platform,
                    spent_by_section_report_section_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'wBYietxRhKezRFFDD' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    sumIf(prebid_highestBid, event_impression AND prebid_won) AS prebid_won_revenue,
                    sumIf(revenue, source = 'dynamicAllocation') / 1000 AS dynamicAllocation_revenue,
                    sumIf(if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0) * if(dictGetStringOrDefault('lineItem', 'currency', toUInt64(assumeNotNull(dfp_lineItemId)), '') = '', 1., dictGetFloat64('currencyConversion2', 'value', (toDate(_date), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(_date), dictGetStringOrDefault('lineItem', 'currency', toUInt64(assumeNotNull(dfp_lineItemId)), '')))), toFloat64(assumeNotNull(revenue))) / 1000, source = 'lineitem') AS direct_revenue,
                    sumIf(revenue, source = 'outbrain') / 1000 AS outbrain_revenue,
                    sumIf(revenue, source = 'taboola') / 1000 AS taboola_revenue,
                    sumIf(revenue, source = 'yahooGemini') / 1000 AS yahooGemini_revenue,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'wBYietxRhKezRFFDD') AND event_impression AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'wBYietxRhKezRFFDD') AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING (clicks_bought > 0)
            ORDER BY clicks_bought DESC
        )
        */
        avg: 63945026.08475168,
        std: 1144140531.9950964,
    },
    spent_by_section_ffi_per_session_starts: {
        /*
        SELECT avg(rate), stddevPop(rate) FROM (
            SELECT
                SUM(yield.impressions) AS impressions,
                SUM(yield.session_starts) AS session_starts,
                SUM(yield.first_five_indicator) AS first_five_indicator,
                spentMergedTimestamp AS _date,
                (spent_by_section_report_platform, spent_by_section_report_section_name) AS _group,
                SUM(spentMergedClicks) AS clicks_bought,
                SUM((dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), 'USD')) / dictGetFloat64('currencyConversion2', 'value', (toDate(spentMergedTimestamp, 'UTC'), currency))) * spentMergedSpent) AS spent,
                first_five_indicator / session_starts as rate
            FROM
            (
                SELECT
                    entityId AS entityId,
                    platform AS platform,
                    currency AS currency,
                    SUM(spentMergedClicks) AS spentMergedClicks,
                    SUM(spentMergedSpent) AS spentMergedSpent,
                    spentMergedTimestamp AS spentMergedTimestamp,
                    join_group AS join_group,
                    spent_by_section_report_platform AS spent_by_section_report_platform,
                    spent_by_section_report_section_name AS spent_by_section_report_section_name
                FROM
                (
                    SELECT
                        entityId AS entityId,
                        platform AS platform,
                        currency AS currency,
                        SUM(norm_metrics.1) AS spentMergedClicks,
                        SUM(norm_metrics.2) AS spentMergedSpent,
                        toDate(norm_metrics.3, 'America/Sao_Paulo') AS spentMergedTimestamp,
                        join_group AS join_group,
                        spent_by_section_report_platform AS spent_by_section_report_platform,
                        spent_by_section_report_section_name AS spent_by_section_report_section_name
                    FROM
                    (
                        WITH (
                                SELECT arrayMap(i -> (0, 0., i), timeSlots(toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo'), toUInt32(604800), toUInt32(86400)))
                            ) AS allPossibleTimes
                        SELECT
                            arrayJoin(arrayConcat(allPossibleTimes, [(clicks, spent, toDateTime(timestampAgg, 'America/Sao_Paulo'))])) AS norm_metrics,
                            entityId AS entityId,
                            platform AS platform,
                            currency AS currency,
                            join_group AS join_group,
                            spent_by_section_report_platform AS spent_by_section_report_platform,
                            spent_by_section_report_section_name AS spent_by_section_report_section_name
                        FROM
                        (
                            SELECT
                                entityId AS entityId,
                                platform AS platform,
                                currency AS currency,
                                SUM(clicks) AS clicks,
                                SUM(spent) AS spent,
                                toDate(date, 'America/Sao_Paulo') AS timestampAgg,
                                multiIf((entityId = 'wBYietxRhKezRFFDD') AND (platform = 'outbrain'), section_name, '') AS join_group,
                                platform AS spent_by_section_report_platform,
                                section_name AS spent_by_section_report_section_name
                            FROM spent_by_section
                            FINAL
                            WHERE ((date >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (date <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo'))) AND (entityId IN ('wBYietxRhKezRFFDD'))
                            GROUP BY
                                timestampAgg,
                                entityId,
                                platform,
                                currency,
                                join_group,
                                spent_by_section_report_platform,
                                spent_by_section_report_section_name
                        )
                    )
                    GROUP BY
                        spentMergedTimestamp,
                        entityId,
                        platform,
                        currency,
                        spent_by_section_report_platform,
                        spent_by_section_report_section_name,
                        join_group
                )
                GROUP BY
                    spentMergedTimestamp,
                    entityId,
                    platform,
                    currency,
                    spent_by_section_report_platform,
                    spent_by_section_report_section_name,
                    join_group
            ) AS main_alias
            ANY LEFT JOIN
            (
                SELECT
                    session_utm_term AS _group,
                    'wBYietxRhKezRFFDD' AS yield_entity_id,
                    toDate(timestamp, 'America/Sao_Paulo') AS _date,
                    countIf((session_impressionCount = 1) AND event_impression) AS session_starts,
                    sumIf(prebid_highestBid, (session_impressionCount < 6) AND event_impression) AS first_five_indicator,
                    countIf(event_impression) AS impressions
                FROM events
                WHERE ((_group IN (
                    SELECT session_utm_term AS _selection_utmTerm
                    FROM events
                    WHERE (entityId = 'wBYietxRhKezRFFDD') AND event_impression AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                    GROUP BY _selection_utmTerm
                    ORDER BY COUNT() DESC
                    LIMIT 2000
                )) OR (_group IS NULL)) AND (entityId = 'wBYietxRhKezRFFDD') AND ((timestamp >= toDateTime('2022-12-05T00:00:00', 'America/Sao_Paulo')) AND (timestamp <= toDateTime('2022-12-11T23:59:59', 'America/Sao_Paulo')))
                GROUP BY
                    _group,
                    _date
                ORDER BY
                    _date ASC,
                    _group ASC
            ) AS yield ON (entityId = yield.yield_entity_id) AND (spentMergedTimestamp = yield._date) AND (join_group = yield._group)
            GROUP BY
                _group,
                _date
            HAVING (session_starts > 0)
            ORDER BY clicks_bought DESC
        )
        */
        avg: 3803335.885432714,
        std: 4985504.357906782,
    },
    mock_web_avg: {
        /*
        Mocking any data, once CI re-calculates this we can drop this mock
        */
        avg: 1,
        std: 1,
    },
};
