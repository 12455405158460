import { useState } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "mainTitle",
        type: "text",
        defaultValue: "Enabling teams to make independent decisions with more efficiency",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Increase Productivity Through Transparency",
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "AdOps teams often face issues due to loads of manual work, delays in getting data, troubleshooting, data aggregation discrepancies in different timeframes, & unavailability of high level data. Worry no more! Assertive Yield has developed a set of unique products each specialized to provide accurate, high quality data-driven solutions to predict change in real-time and implement solutions before any monetary loss occurs.",
    },
    {
        name: "hideText",
        type: "longText",
        defaultValue:
            "Closely monitor programmatic performance, get access to real-time prediction for Google’s Dynamic allocation before Google Ad Manager reports are out, monitor many domains and several revenue and spend sources at one space. Explore direct campaigns data with intuitive charts.",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByTeamTypeIncreaseProductivity: BFC<typeof inputs> = ({
    mainTitle,
    title,
    subtitle,
    switchMagnifier,
    hideText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className={`${styles.firstGlow} gradient`}>
                        <StaticImage src="./images/by-company-type-data-is-power-first-glow.svg" alt="" />
                    </div>
                    <h2 className={styles.mainTitle}>{mainTitle}</h2>
                    <div className="row align-items-start gx-5">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                            {showMore && (
                                <div className={styles.fadeInDown}>
                                    <p>{hideText}</p>
                                </div>
                            )}
                            {!showMore && (
                                <div onClick={() => setShowMore(true)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage src="./images/by-company-type-data-is-power-icon-add.svg" alt="" />
                                    </div>
                                    <p className={styles.showMoreText}>Show more</p>
                                </div>
                            )}

                            {showMore && (
                                <div onClick={() => setShowMore(false)} className={styles.showMoreWrapper}>
                                    <div className={styles.showMoreIcon}>
                                        <StaticImage
                                            src="./images/by-company-type-data-is-power-icon-remove.svg"
                                            alt=""
                                        />
                                    </div>
                                    <p className={styles.showMoreText}>Show less</p>
                                </div>
                            )}
                        </div>

                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByTeamTypeIncreaseProductivity, {
    name: "byTeamTypeIncreaseProductivity",
    inputs: toMutable(inputs),
});
