import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective } from "../types";

const RAW_METRICS = {
    traffic_shaping_server_flooring_samples: {
        field: "sum(samples)",
    },
    traffic_shaping_server_flooring_bids: {
        field: "sum(bids)",
    },
    traffic_shaping_server_flooring_wins: {
        field: "sum(wins)",
    },
    traffic_shaping_server_flooring_revenue: {
        field: "(sum(revenue) / 1000)",
    },
} as const satisfies Record<string, IRawMetric>;
export type RawTrafficShapingServerFlooringMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "traffic_shaping_server_flooring_samples",
    "traffic_shaping_server_flooring_bids",
    "traffic_shaping_server_flooring_wins",
    "traffic_shaping_server_flooring_revenue",
    "traffic_shaping_server_flooring_request_rpmm",
] as const;
export type TrafficShapingServerFlooringMetrics = (typeof CALC_IDS)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        traffic_shaping_server_flooring_revenue: {
            label: "Revenue",
            explanation: "Revenue",
            formula: m => m.traffic_shaping_server_flooring_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_samples: {
            label: "Samples",
            explanation: "Samples",
            formula: m => m.traffic_shaping_server_flooring_samples,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_bids: {
            label: "Bids",
            explanation: "Bids",
            formula: m => m.traffic_shaping_server_flooring_bids,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_wins: {
            label: "Wins",
            explanation: "Wins",
            formula: m => m.traffic_shaping_server_flooring_wins,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        traffic_shaping_server_flooring_request_rpmm: {
            label: "Request RPMM",
            explanation: "Revenue / Samples * 1000 * 1000",
            formula: m =>
                (m.traffic_shaping_server_flooring_revenue / m.traffic_shaping_server_flooring_samples) * 1000 * 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
    });
