import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import optgradright from "./images/right-gradient.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Unique Features",
    },
    {
        name: "description",
        type: "text",
        defaultValue: "For transparency and unprecedented revenue.",
    },
] as const;

const UniqueFeaturesAyPrebid: BFC<typeof inputs> = ({ title, description }) => (
    <div className="component-wrapper mb-0">
        <img src={optgradright} className={`gradient right`} style={{ top: "-750px" }} alt="gradient" />
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
    </div>
);

Builder.registerComponent(UniqueFeaturesAyPrebid, {
    name: "UniqueFeaturesAyPrebid",
    inputs: toMutable(inputs),
});
