const StartVideIconSmall = () => {
    return (
        <svg className="icon" width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.9"
                d="M26.5 0C11.8648 0 0 11.8648 0 26.5C0 41.1352 11.8648 53 26.5 53C41.1352 53 53 41.1352 53 26.5C53 11.8648 41.1352 0 26.5 0ZM38.9863 29.9089L22.7671 39.273C22.1504 39.6283 21.4746 39.8066 20.7989 39.8066C20.1231 39.8066 19.4474 39.6283 18.8307 39.273C17.5984 38.5611 16.8624 37.2879 16.8624 35.8641V17.1359C16.8624 15.7133 17.5984 14.4389 18.8307 13.727C20.0629 13.0151 21.5337 13.0151 22.7659 13.727L38.9851 23.0911C40.2174 23.803 40.9533 25.0762 40.9533 26.5C40.9533 27.9238 40.2186 29.197 38.9863 29.9089Z"
                fill="#paint0_linear_7844_10629"
            />
            <defs className="iconHover">
                <linearGradient
                    id="paint0_linear_7844_10629"
                    x1="13.25"
                    y1="53"
                    x2="43.0139"
                    y2="1.76225"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00520833" stopColor="#FFCC00" />
                    <stop offset="0.989583" stopColor="#FF008E" />
                </linearGradient>
            </defs>

            <defs className="iconClick">
                <linearGradient
                    id="paint0_linear_7784_239532"
                    x1="13.25"
                    y1="53"
                    x2="43.0139"
                    y2="1.76225"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.00520833" stopColor="#00C9FF" />
                    <stop offset="0.989583" stopColor="#6800FF" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default StartVideIconSmall;
