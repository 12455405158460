export interface IColor {
    r: number;
    g: number;
    b: number;
}

export const colorToRgb = ({ r, g, b }: IColor) => `rgb(${r}, ${g}, ${b})`;
export const colorToRgba =
    (a: number) =>
    ({ r, g, b }: IColor) =>
        `rgba(${r}, ${g}, ${b}, ${a})`;
export const rgb = (r: number, g: number, b: number): IColor => ({ r, g, b });

const clamp = (min: number, max: number) => (n: number) => Math.min(Math.max(n, min), max);
const clampColors = clamp(0, 255);
export const colorToAutoGradient =
    (deg = 45, add = 12) =>
    ({ r, g, b }: IColor) =>
        `linear-gradient(${deg}deg, rgba(${r}, ${g}, ${b}, 1), rgba(${clampColors(r + add)}, ${clampColors(
            g + add
        )}, ${clampColors(b + add)}, 1))`;

export const colorAdd =
    (add: number) =>
    ({ r, g, b }: IColor): IColor => ({
        r: clampColors(r + add),
        g: clampColors(g + add),
        b: clampColors(b + add),
    });
