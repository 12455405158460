import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

export const SliderRenderer: React.FunctionComponent<{
    value: number;
    onChange: (value: number) => void;
}> = ({ value, onChange }) => {
    return (
        <Nouislider
            style={{ marginTop: 60, marginBottom: 30 }}
            range={{
                min: [1],
                "10%": [10, 1],
                "20%": [50, 1],
                "30%": [150, 5],
                "70%": [500, 10],
                "80%": [1000, 25],
                max: [10000],
            }}
            step={1}
            start={value}
            tooltips={[
                {
                    to: (v: number) => {
                        return `${Math.round(v)} million`;
                    },
                    // Isn't actually used but we're providing
                    // because it's signed as needed on Typescript.
                    from: (v: string) => parseInt(v.replace("mm", ""), 10),
                },
            ]}
            onChange={(_: any, __: any, values: number[]) => {
                onChange(values[0]);
            }}
        />
    );
};
