import { navigate } from "gatsby";
import CustomButton from "components/CustomButton";
import TransparentGradientButton from "components/TransparentGradientButton";
import illustration from "./images/illustration.svg";
import * as styles from "./styles.module.scss";
const NotFound404 = () => (
    <div className={["component-wrapper", styles.content_wrapper].join(" ")}>
        <div className="container panel">
            <div className="row justify-content-center align-items-center gx-5">
                <div className="col-md-6 ">
                    <div className="d-flex flex-column align-items-center d-sm-inline">
                        <h1 className="product-title mb-5">Page not found</h1>
                        <p className="product-desc mb-4 mb-md-5">
                            Sorry, the page you are looking for doesn't exist.
                            <br />
                            Here are some helpful links:
                        </p>
                    </div>
                    <div className="d-sm-flex">
                        <TransparentGradientButton
                            text="< Go back"
                            to="/"
                            bg={"dark"}
                            bgColor={"#3a0070"}
                            onClick={ev => {
                                ev.preventDefault();
                                navigate(-1);
                            }}
                        />
                        <div className="mt-3 mt-sm-0 ms-sm-2">
                            <CustomButton buttonSize="btn--large" to={"/"}>
                                Take me home
                            </CustomButton>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 position-relative order-md-2 mt-4 mb-4 mb-md-0 mt-md-0 text-center">
                    <img className="img-fluid my-5 my-md-0" src={illustration} alt="404 illustration" />
                </div>
            </div>
        </div>
    </div>
);
export default NotFound404;
