import type { FC } from "react";
import type { PageProps } from "gatsby";
import ReadyToWin from "components/blocks/ReadyToWin";
import Layout from "components/Layout";
import LegalContentItem from "components/LegalContentItem";
import LegalContentLayout from "components/LegalContentLayout";
import LegalHeader from "components/LegalHeader";
import Seo from "components/seo";

const Terms = () => {
    return (
        <Layout bg="dark">
            <LegalHeader
                title="Terms and Conditions"
                subHeading="By accessing our website, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws."
            />
            <LegalContentLayout>
                <LegalContentItem title="Use of Service">
                    <p className="mb-3">
                        By using our service, you declare that you have the applicable rights to integrate the affected
                        websites. Furthermore you warrant that the affected website and its content does not violate any
                        applicable law. In addition you warrant the use of the product within the following Fair Use
                        definition. The use of our service is governed by these Terms, by using our service you agree to
                        be bound by these Terms.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Prohibited Actions & Fair Use">
                    <p>
                        It is prohibited to violate any applicable law or any rights of others by using our service.
                        <br />
                        Furthermore it is prohibited to damage the system and to hinder other users and/or cause them
                        damage.
                    </p>
                    <p>Among other things, you will not, and will not allow any third party, to:</p>
                    <ul>
                        <li>
                            Perform any unintended actions (e.g. monitoring or crawling systems) which could impair or
                            disrupt the service.
                        </li>
                        <li>
                            Supply any data that could personally identify and/or personally track individual end users
                            or any other persons.
                        </li>
                        <li>Supply more than 1 million unique variations per key & month (e.g. custom values).</li>
                        <li>
                            Invite and send any form of emails from the system where you do not have the receivers
                            explicit consent.
                        </li>
                        <li>Resell any parts or access to the system.</li>
                    </ul>
                    <p>
                        We are allowed to terminate and/or suspend your access to our service immediately, without any
                        notice, whenever you violate any restriction as mentioned before.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Subscription, Payment & Invoicing">
                    <p>
                        The subscription will last for one month, unless stated otherwise. At the end of every
                        subscription term, the subscription will continually be renewed tacitly with new periods of the
                        subscription term, unless one of the parties cancels the subscription in writing, with a
                        cancellation period of one month.
                    </p>
                    <p>
                        A subscription can consist of fixed fees as well as metered/usage-based fees. The fixed fees
                        will be invoiced in advance, usage/metered-based fees will be invoiced at the end of each
                        subscription term. In case of cancellation by one of the parties, the usage based fees can be
                        invoiced immediately.
                        <br />
                        VAT is not included in the fees (unless stated otherwise). Transfer costs and wiring costs are
                        not included in the fees and have to be paid separately by you. We are allowed to change the
                        fees every month.
                    </p>
                    <p>
                        The amount that has to be paid is solely calculated based on the records maintained by us. Any
                        disputes concerning the invoice will have to be received by us within 7 days after the date of
                        the invoice. Disputes that are received after this period, will not be processed.
                    </p>
                    <p>
                        Invoices have to be paid (without deduction, discount or set-off) within 14 days after the date
                        of the invoice, in the currency as stated on the invoice.
                    </p>
                    <p>
                        In case of no or tardily payment, you’ll, in addition, have to pay all costs that we have to
                        make to collect the payment (both judicial and extrajudicial, including extra administration
                        costs), all costs to be increased with statutory (commercial) interest. The extrajudicial costs
                        amount at least 10% of the original amount on the invoice(s), with an absolute minimum of € 250
                        (VAT excluded). In addition, we are allowed to suspend our service without any notice.
                    </p>
                    <p>We can claim payment of the invoice immediately, without any notice, as soon as:</p>
                    <ul>
                        <li>you’re bankrupt;</li>
                        <li>you’ve asked the court for suspension of payment;</li>
                        <li>(a part of) your assets are seized;</li>
                        <li>you’re in liquidation (if you’re a company);</li>
                        <li>you’re dissolved (if you’re a company).</li>
                    </ul>
                </LegalContentItem>
                <LegalContentItem title="Intellectual Property">
                    <p>
                        All rights concerning the service belong exclusively to us, and will not be transferred to you
                        in any way. The rights concerning the data you process with our service (“your data”) belong to
                        you, but we are allowed to use your data for the purposes as mentioned in the next article.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Availability of the Service">
                    <p>
                        Although we make an effort to let our service be available as often as possible, we cannot give
                        any guarantee about the availability. Furthermore we are always permitted to shut down (parts
                        of) our service on behalf of maintenance, adjustments and/or improvements to our service.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Data Use">
                    <p>
                        We are allowed to use your data to detect, prevent or otherwise address fraud and security
                        issues. Furthermore we are allowed to use your data to protect the system, ourselves and/or any
                        third party against (imminent) harm to rights, property and/or safety.{" "}
                    </p>
                    <p>
                        We are allowed to aggregate your data (both during the subscription and afterwards) with data
                        from other users for our own commercial use (e.g. for an Industry Report), provided that it is
                        not possible to identify any natural person and/or any user that contributed to the data set.
                        We’re not obliged to delete your data after the termination of the use of our service.
                    </p>
                    <p>We are only allowed to use your data for as far as permitted by applicable law.</p>
                </LegalContentItem>
                <LegalContentItem title="Termination">
                    <p>
                        We reserve the right to take actions including service termination against any account at any
                        time. You have the right to terminate your use of this service at any time. In the event that
                        the parties terminate their collaboration, you are obligated to immediately remove and
                        permanently delete all data and integration provided by us.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Governing Law">
                    <p>
                        Any disputes and these terms of service are governed by Dutch law, excluding the UN Sales
                        Convention. The exclusive place of jurisdiction for all disputes is Amsterdam (Netherlands).
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Liability">
                    <p>
                        THE SOFTWARE/SERVICE IS PROVIDED "AS IS" AND FOR AS FAR AS IT IS ALLOWED UNDER THE APPLICABLE
                        LAW, THE SOFTWARE/SERVICE IS PROVIDED WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
                        INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
                        AND NONINFRINGEMENT. FOR AS FAR AS IT IS ALLOWED UNDER THE APPLICABLE LAW, THE AUTHORS OR
                        COPYRIGHT HOLDERS SHALL BE LIABLE IN NO EVENT FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER
                        IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
                        SOFTWARE AND SERVICE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE. FOR AS FAR AS IT IS ALLOWED
                        UNDER THE APPLICABLE LAW, YOU INDEMNIFY THE AUTHORS AND/OR COPYRIGHT HOLDERS FOR ANY CLAIM MADE
                        BY, DAMAGE SUFFERED BY OR ANY OTHER LIABILITY TO THIRD PARTIES.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Severability">
                    <p>
                        If a part of this agreement is or becomes invalid in a legal manner, the remainders validity
                        shall not be affected thereby. Invalid parts shall be replaced if legally valid provisions which
                        come as close as possible to the intended result with a focus on the economically base. The same
                        goes for gaps.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Term Updates">
                    <p>These terms may be updated at any time without prior notice.</p>
                </LegalContentItem>
            </LegalContentLayout>
            <ReadyToWin />
        </Layout>
    );
};
export const Head: FC<PageProps> = ({ location }) => (
    <Seo noIndex title="Terms" titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": "${location.pathname}",
          "url": "${location.pathname}",
          "name": "Terms",
          "datePublished": "2023-01-16T23:37:53+00:00",
          "dateModified": "2023-03-21T16:10:45+00:00",
          "description": "Assertive Yield is a global company, created by publishers to publishers. Pioneer in offering Yield Revenue Management in Real-Time, from all revenue sources, with extraordinary granular data, as never seen before.",
          "inLanguage": "en-US",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.assertiveyield.com/#website",
            "url": "https://www.assertiveyield.com/",
            "name": "Assertive Yield",
            "description": "Investment Management Solutions",
            "inLanguage": "en-US",
          "publisher": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "image": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "URL": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
              },
              "query-input": {
                "@type": "PropertyValueSpecification",
                "valueRequired": "http://schema.org/True",
                "valueName": "search_term_string"
              }
            }
          }
        },
        "about": {
          "@type": "Organization",
          "@id": "https://www.assertiveyield.com/#organization",
          "name": "Assertive Yield",
          "url": "https://www.assertiveyield.com/",
          "sameAs": [
            "https://www.youtube.com/@assertiveyield",
            "https://www.linkedin.com/company/assertive-yield/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
            "inLanguage": "en-US",
            "url": "https://assertiveyield.com/img/og-image.png",
            "contentUrl": "https://assertiveyield.com/img/og-image.png",
            "width": 479,
            "height": 189,
            "caption": "Assertive Yield"
          }
      },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "@id": "https://www.assertiveyield.com/#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Assertive Yield",
              "item": {
                "@id": "https://www.assertiveyield.com/",
                "@type": "Thing"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Terms"
            }
          ]
        },
        "potentialAction": {
          "@type": "ReadAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
          }
        }
      }
      `}
        </script>
    </Seo>
);

export default Terms;
