import type { FC } from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import type { BlogPostData } from "types/common";
import arrowIcon from "./images/blog-thumbnail-arrow-icon.svg";
import * as styles from "./styles.module.scss";

const BlogThumbnail: FC<{ post: BlogPostData; isProductUpdate?: boolean }> = ({ post, isProductUpdate = false }) => {
    const url = post.frontmatter.pageUrl ? post.frontmatter.pageUrl : "/blog" + post.fields.slug;
    let image;

    if (
        (post.featuredImageFileExternal as any).childImageSharp === null &&
        post.frontmatter.featuredImage?.includes(".gif")
    ) {
        image = post.frontmatter.featuredImage;
    } else {
        image = getImage(post.featuredImageFileExternal!);
    }

    return (
        <div className={styles.blog_thumb_wrapper} onClick={() => navigate(url)}>
            {typeof image === "string" ? (
                <img className="card-img mt-5" src={image} alt="Card image" loading="lazy" />
            ) : (
                <GatsbyImage image={image!} alt={"heading image"} className="card-img mt-5" />
            )}
            {isProductUpdate ? (
                <div className={styles.product_update_wrapper}>
                    <h3 className={styles.blog_title}>{post.frontmatter.title}</h3>
                    <img src={arrowIcon} alt="arrow-right" />
                </div>
            ) : (
                <>
                    <div className={["mt-4", "mb-3", "figma-h3", styles.blog_title].join(" ")}>
                        {post.frontmatter.title}
                    </div>
                    <p className={styles.blog_tags}>{post.frontmatter.relatedFeatures}</p>
                </>
            )}
        </div>
    );
};

export default BlogThumbnail;
