import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Maximize Revenue, Increase Efficiency,Predict Changes: All in real-time",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a Call with us",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
        required: true,
    },
] as const;

const ByCompanyTypeMaximizeRevenue: BFC<typeof inputs> = ({ title, buttonText, linkTo }) => {
    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.button}>
                    <CustomButton buttonSize="custom--huge" buttonStyle="btn--yellow-red" to={linkTo}>
                        {buttonText}
                    </CustomButton>
                </div>
                <p className={styles.subtitle}>
                    Forget limited and long running A/B testing
                    <br /> Test scenario without involving development teams
                </p>
            </div>
        </div>
    );
};

Builder.registerComponent(ByCompanyTypeMaximizeRevenue, {
    name: "byCompanyTypeMaximizeRevenue",
    inputs: toMutable(inputs),
});
