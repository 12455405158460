import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Careers",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Find the right role for you",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Want to work with some of the best global talent and build software used by all the companies you know and love? Join the team — we’re hiring!",
    },
] as const;

const CareersHero: BFC<typeof inputs> = ({ suptitle, title, subtitle }) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/careers-hero-first-glow.svg" alt="" />
            </div>
            <div>
                <div className={styles.mainContent}>
                    <p className={styles.suptitle}>{suptitle}</p>
                    <h1 className={styles.title}>{title}</h1>
                    <p className={styles.subtitle}>{subtitle}</p>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(CareersHero, {
    name: "careersHero",
    inputs: toMutable(inputs),
});
