import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Why the name ‘Assertive Yield’?",
    },
    {
        name: "boldParagraph",
        type: "text",
        defaultValue:
            "Assertive Yield suggests that we want to empower our customers to make assertive data-driven choices to maximize their ads revenue opportunities, while they can rely on us and our cutting-edge tech to solve all their data challenges.",
    },
    {
        name: "paragraphWithBlueLetters",
        type: "text",
        defaultValue:
            "The upward arrow signifies continuous growth and it’s an “A” from assertive, as we believe in assertive and sustainable growth. The “Y” inside means, of course, yield, but also a road bifurcation, as we allow you to compare and make better choices.",
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

export const AboutUsWhyName: BFC<typeof inputs> = ({
    title,
    boldParagraph,
    paragraphWithBlueLetters,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    const findQuotes = (text: string) => {
        if (!text) return;

        const regex = /“(.*?)”/g;
        const matches = text.match(regex);

        if (!matches) return text;

        let highlightedText = text;

        matches.forEach(match => {
            const highlightedMatch = match.replace(/([A-Za-z])/g, `<span class="${styles.specialColor}">$1</span>`);
            const highlightedQuote = highlightedMatch.replace(
                /“(.*?)”/g,
                `<span class="${styles.specialColor}">“$1”</span>`
            );
            highlightedText = highlightedText.replace(match, highlightedQuote);
        });

        return highlightedText;
    };

    const highlightedText = findQuotes(paragraphWithBlueLetters);

    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/about-us-why-name-first-glow.svg" alt="" />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.mainContent}>
                    <h2 className={styles.title}>{title}</h2>

                    <p className={styles.boldParagraph}>{boldParagraph}</p>
                    {highlightedText ? <p dangerouslySetInnerHTML={{ __html: highlightedText }} /> : null}
                </div>
                <div className={styles.image}>
                    <div className="position-relative">
                        {imageOrGifHover ? (
                            <img
                                onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                src={imageOrGif}
                                alt={imageOrGifAlt}
                                className="img-fluid"
                            />
                        ) : (
                            <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                        )}
                        {switchMagnifier && !imageOrGifHover && (
                            <Gallery>
                                <Item
                                    width={imageOrGifWidth}
                                    height={imageOrGifHeight}
                                    original={imageOrGif}
                                    alt={imageOrGifAlt}
                                >
                                    {({ ref, open }) => (
                                        // @ts-expect-error
                                        <div className="image_mask" ref={ref} onClick={open}>
                                            <img src={glass} alt="zoom image" />
                                        </div>
                                    )}
                                </Item>
                            </Gallery>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsWhyName, {
    name: "aboutUsWhyName",
    inputs: toMutable(inputs),
});
