import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import SubscribeToAyNews from ".";

const inputs = [
    {
        name: "text_color",
        type: "string",
        defaultValue: "white",
        enum: ["white", ""],
    },
] as const;

const SubscribeToAyNewsBlock: BFC<typeof inputs> = ({ text_color }) => (
    <SubscribeToAyNews text_color={text_color} title="Subscribe to AY News" />
);

Builder.registerComponent(SubscribeToAyNewsBlock, {
    name: "subscribeToAyNewsBlock",
    inputs: toMutable(inputs),
});
