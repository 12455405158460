import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import bidrequest from "./images/bidrequest.svg";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Smart Bid Request Optimization",
        required: true,
    },
] as const;
const TrafficShapingComponentBlocks: BFC<typeof inputs> = ({ title }) => {
    return (
        <>
            <div className="component-wrapper mt-180">
                <div className="container ">
                    <div className="row justify-content-center text-center">
                        <div className="col">
                            <h2 className="mb-5">{title}</h2>
                            <img src={bidrequest} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
Builder.registerComponent(TrafficShapingComponentBlocks, {
    name: "TrafficShapingComponentBlocks",
    inputs: toMutable(inputs),
});
