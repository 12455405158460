import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Say Hello to RTD Performance Monitoring",
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Dealing with traffic spikes, low CPMs caused by AdX double-click penalties, buggy websites, and revenue losses can all be mitigated with our traffic monitoring and customizable alerts that allows you to react swiftly",
    },
] as const;

const MonitoringSayHello = () => {
    return (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>Say Hello to RTD Performance Monitoring</h2>
            <p>
                Dealing with traffic spikes, low CPMs caused by AdX double-click penalties, buggy websites, and revenue
                losses can all be mitigated with our traffic monitoring and customizable alerts that allows you to react
                swiftly
            </p>
        </div>
    );
};

Builder.registerComponent(MonitoringSayHello, {
    name: "monitoringSayHello",
    inputs: toMutable(inputs),
});
