import CustomButton from "components/CustomButton";
import useEmailSubscribe from "components/hooks/use-email-subscribe";
import SubscribeInput from "components/SubscribeInput";
import * as styles from "./styles.module.scss";

export const SubscribeToAyNewsletters = () => {
    const { email, setEmail, onSubscribe, isValid, hasSubscribed } = useEmailSubscribe();

    return (
        <div className="container">
            <div className={styles.wrapper}>
                <div className="w-0 w-lg-50 me-0 me-lg-6">
                    <h2 className={styles.title}>Subscribe to AY Newsletters</h2>
                    <p className={styles.description}>
                        Get the coolest, most insightful and latest in sell-side Adtech delivered to your inbox each
                        month.
                    </p>
                </div>
                <div className="w-0 w-lg-50">
                    {hasSubscribed ? (
                        <div className={styles.thanks}>Thanks for subscribing!</div>
                    ) : (
                        <div className={styles.ctaWrapper}>
                            <div className="me-0 me-lg-3 mb-4 mb-lg-0">
                                <SubscribeInput email={email} setEmail={setEmail} isValid={isValid} />
                                <p className={styles.message}>We care about your data in our privacy policy.</p>
                            </div>
                            <div className="d-none d-lg-block">
                                <CustomButton buttonSize="btn--large" onClick={onSubscribe}>
                                    Subscribe
                                </CustomButton>
                            </div>
                            <div className="d-block d-lg-none">
                                <CustomButton customWidth="300px" onClick={onSubscribe}>
                                    Subscribe
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubscribeToAyNewsletters;
