// extracted by mini-css-extract-plugin
export var animation = "styles-module--animation--43f2b";
export var arrow_back = "styles-module--arrow_back--360c2";
export var arrow_back_animation = "styles-module--arrow_back_animation--53010";
export var arrow_forward = "styles-module--arrow_forward--2fd72";
export var arrow_forward_animation = "styles-module--arrow_forward_animation--f6a3f";
export var blinking_ball = "styles-module--blinking_ball--e147f";
export var blinking_ball_animation = "styles-module--blinking_ball_animation--c2367";
export var inRealTimeWrapper = "styles-module--inRealTimeWrapper--1c5b8";
export var items = "styles-module--items--74b15";
export var random_acceleration = "styles-module--random_acceleration--2ded2";
export var random_acceleration_animation = "styles-module--random_acceleration_animation--275db";
export var responsive_container = "styles-module--responsive_container--25829";