import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "cards",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
            },
            {
                name: "icon",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
            {
                name: "description",
                type: "text",
            },
            {
                name: "link",
                type: "text",
                required: true,
            },
        ],
    },
] as const;

const AyHelpCenterCategories: BFC<typeof inputs> = ({ cards }) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/ay-help-center-categories-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/ay-help-center-categories-second-glow.svg" alt="" />
            </div>
            <div className={styles.cards}>
                {cards?.map(({ title, description, icon, link }, index) => (
                    <Link to={link} className={styles.card} key={index}>
                        <h3 className={styles.cardTitle}>{title}</h3>
                        <div className={styles.cardIcon}>
                            <BuilderImage image={icon} alt={title} lazy />
                        </div>
                        <p className={styles.cardDescription}>{description}</p>
                    </Link>
                ))}
            </div>
        </div>
    );
};

Builder.registerComponent(AyHelpCenterCategories, {
    name: "ayHelpCenterCategories",
    inputs: toMutable(inputs),
});
