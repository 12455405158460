import { Gallery, Item } from "react-photoswipe-gallery";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/by-company-type-bid-caching-glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Prompt Yield Manager",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Bid Caching",
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Keep the best value for the same ad unit, across different pages. With Bid Caching, you can transfer your high bids onto the next page and increase your overall Session RPM.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Learn More",
    },
    {
        name: "buttonLink",
        type: "text",
        defaultValue: "/",
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByCompanyTypeBidCaching: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    buttonText,
    buttonLink,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle}</p>
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                            <CustomButton buttonStyle="btn--yellow-red" to={buttonLink}>
                                {buttonText}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByCompanyTypeBidCaching, {
    name: "byCompanyTypeBidCaching",
    inputs: toMutable(inputs),
});
