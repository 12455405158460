import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Unique Data for every unique team!",
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Explore in depth the numerous data points available in Yield Intelligence Analytics. Create separate dashboards, with numerous charts, filters and metrics. Share them with the team or clients and save time on pulling numerous data sources and visualizing them each time.",
    },
] as const;

export const UniqueDataForUniqueTeam: BFC<typeof inputs> = ({ title, subtitle }) => {
    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h2 className="mb-4">{title}</h2>
                <p>{subtitle}</p>
            </div>
        </div>
    );
};

Builder.registerComponent(UniqueDataForUniqueTeam, {
    name: "uniqueDataForUniqueTeam",
    inputs: toMutable(inputs),
});
