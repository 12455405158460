// extracted by mini-css-extract-plugin
export var alphabetLetter = "styles-module--alphabet-letter--c7ee5";
export var alphabetLetterActive = "styles-module--alphabet-letter-active--73648";
export var alphabetSelect = "styles-module--alphabet-select--ce7a3";
export var alphabetWrapper = "styles-module--alphabet-wrapper--e20e2";
export var firstGlow = "styles-module--first-glow--27464";
export var knowledgeDeskLetter = "styles-module--knowledge-desk-letter--5c29a";
export var knowledgeDeskLetterArrow = "styles-module--knowledge-desk-letter-arrow--3ecf6";
export var knowledgeDeskLetterContent = "styles-module--knowledge-desk-letter-content--66b8a";
export var knowledgeDeskLetterItemWrapper = "styles-module--knowledge-desk-letter-item-wrapper--8d397";
export var knowledgeDeskLetterTitle = "styles-module--knowledge-desk-letter-title--f3858";
export var knowledgeDeskLetters = "styles-module--knowledge-desk-letters--ba5b3";
export var knowledgeDeskWrapper = "styles-module--knowledge-desk-wrapper--560d7";
export var letterA = "styles-module--letter-A--7d226";
export var lettersWrapper = "styles-module--letters-wrapper--14416";
export var searchBar = "styles-module--search-bar--27ba3";
export var searchBarWrapper = "styles-module--search-bar-wrapper--2dc44";
export var searchIcon = "styles-module--search-icon--60803";
export var secondGlow = "styles-module--second-glow--d5f78";
export var selectLabel = "styles-module--select-label--d8ae0";
export var selectWrapper = "styles-module--select-wrapper--c84d4";
export var seventhGlow = "styles-module--seventh-glow--370a0";
export var sixthGlow = "styles-module--sixth-glow--58f06";
export var subtitle = "styles-module--subtitle--6ac18";
export var thirdGlow = "styles-module--third-glow--1b22f";
export var title = "styles-module--title--65fe9";