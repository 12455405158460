import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import datadrivengradient from "./images/datadriven-gradient.svg";
import nextgen from "./images/nextgen.svg";
import * as styles from "./nextgen.module.scss";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "A next generation of data-driven teams",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "With a single view of yield data to generate more revenue, monitor performance and prevent troubleshooting.",
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Media Buying Teams",
                required: true,
            },
            {
                name: "description",
                type: "longText",
                defaultValue:
                    "Adjust spending, enhance campaign performance and return of investment in real-time from different sources by identifying performance issues faster.",
            },
        ],
    },
] as const;
const NextGeneration: BFC<typeof inputs> = ({ title, subtitle, items }) => {
    const stylesMap = {
        0: styles.one,
        1: styles.two,
        2: styles.three,
        3: styles.four,
        4: styles.five,
    };
    return (
        <div className="component-wrapper">
            <img src={datadrivengradient} className={`gradient left`} style={{ top: "200px" }} alt="gradient" />
            <div className="container">
                <div className="row text-center mb-100">
                    <div className="col">
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </div>
                </div>
                <div className={`${styles.next_gen_wrapper} position-relative`}>
                    <img src={nextgen} alt="" className={`${styles.next_img} d-none d-lg-block img-fluid`} />
                    {items.map((it, index) => {
                        return (
                            <div className={`${styles.nextgenBlock} ${(stylesMap as any)[index]}`} key={it.title}>
                                <h4 className="mb-3">{it.title}</h4>
                                <p>{it.description}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(NextGeneration, {
    name: "NextGeneration",
    inputs: toMutable(inputs),
});
