import type { FC } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import type { SuccessStoriesData } from "types/common";
import arrow from "./images/arrow.svg";
import * as styles from "./styles.module.scss";

const SuccessStoriesCard: FC<{ story: SuccessStoriesData }> = ({ story }) => {
    const bgImage = getImage(story.clientLogoFileExternal);
    let minifiedDescription = story.description;

    if (minifiedDescription.length > 85) {
        minifiedDescription = minifiedDescription.substring(0, 85);

        minifiedDescription = minifiedDescription.substring(
            0,
            Math.min(minifiedDescription.length, minifiedDescription.lastIndexOf(" "))
        );

        minifiedDescription += "...";
    }

    return (
        <Link to={`/success-stories${story.fields.slug}`} className={styles.cardWrapper}>
            <div className={styles.bgImage}>
                <GatsbyImage image={bgImage!} alt="Success stories item" className={`img-fluid ${styles.image}`} />
            </div>
            <div className={styles.hover} />
            <div className={styles.content}>
                <img className={styles.image} src={story.clientLogoHover} alt="" />
                <h3 className={styles.title}>{minifiedDescription}</h3>
            </div>
            <div className={styles.learnMore}>
                <p className={styles.text}>Learn more</p>
                <img className={styles.arrow} src={arrow} alt="arrow" />
            </div>
        </Link>
    );
};

export default SuccessStoriesCard;
