import { Builder } from "@builder.io/react";
import ProductsHero from "components/blocks/hero/ProductsHero";
import { SIGNUP_LINK } from "components/constants";
import { toMutable } from "types/common";
import gradient from "./images/gradient.svg";
import icon from "./images/yieldIntel.svg";

const inputs = [
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "buttonTitle",
        type: "text",
        defaultValue: "Try out a free demo",
        required: true,
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "All revenue sources, in real-time, with tremendous granularity you never thought possible. Easy to set-up AY Tracker On Page is empowered with artificial intelligence predictions (96-99% accuracy) and normalized with 3rd party data when data becomes available.",
    },
    {
        name: "heading",
        type: "text",
        defaultValue: "Your data, your game",
        required: true,
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
        required: true,
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
] as const;

const YieldIntelligenceProductHero: BFC<typeof inputs> = ({
    heading,
    description,
    buttonTitle,
    buttonTo,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
}) => {
    return (
        <ProductsHero
            heading={heading}
            description={description ?? ""}
            buttonTitle={buttonTitle}
            buttonTo={buttonTo}
            icon={icon}
            iconTitle="Yield Intelligence"
            heroimage={imageOrGif}
            heroimageHover={imageOrGifHover}
            heroimageAlt={imageOrGifAlt}
            gradient={{
                right: gradient,
                rightTop: "130px",
                rightBottom: "",
                left: "",
                leftTop: "0px",
                leftBottom: "",
            }}
            showForm={false}
            buttonStyle="btn--primary"
        />
    );
};

Builder.registerComponent(YieldIntelligenceProductHero, {
    name: "YieldIntelligenceProductHero",
    inputs: toMutable(inputs),
});
