import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Empowering Teams to Increase Productivity with Smart AI Solutions",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "AY provides a one stop solution for AdOperations Audience Development, Product and Content & Editorial teams to make wise decisions on the basis of real-time optimized and programmatic data. Every team will now get the freedom to smoothen their workflow without waiting for approvals or change requests that require involving development or other technical teams",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByTeamTypeHero: BFC<typeof inputs> = ({
    title,
    subtitle,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/by-team-type-first-glow.svg" alt="" />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.mainContent}>
                    <h1 className={styles.title}>{title}</h1>
                    <p className={styles.subtitle}>{subtitle}</p>
                </div>
                <div className={styles.image}>
                    <div className="mask-container  position-relative ">
                        {imageOrGifHover ? (
                            <img
                                onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                src={imageOrGif}
                                alt={imageOrGifAlt}
                                className="img-fluid"
                            />
                        ) : (
                            <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                        )}
                        {switchMagnifier && !imageOrGifHover && (
                            <Gallery>
                                <Item
                                    width={imageOrGifWidth}
                                    height={imageOrGifHeight}
                                    original={imageOrGif}
                                    alt={imageOrGifAlt}
                                >
                                    {({ ref, open }) => (
                                        // @ts-expect-error
                                        <div className="image_mask" ref={ref} onClick={open}>
                                            <img src={glass} alt="zoom image" />
                                        </div>
                                    )}
                                </Item>
                            </Gallery>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(ByTeamTypeHero, {
    name: "byTeamTypeHero",
    inputs: toMutable(inputs),
});
