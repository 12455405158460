import { useState } from "react";
import Markdown from "markdown-to-jsx";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import minusIcon from "./images/blog-post-minus-icon.svg";
import minusIconLight from "./images/blog-post-minus-icon-light.svg";
import plusIcon from "./images/blog-post-plus-icon.svg";
import plusIconLight from "./images/blog-post-plus-icon-light.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "string",
        defaultValue: "Frequently asked questions",
    },
    {
        name: "description",
        type: "string",
        defaultValue: "Everything you need to know about the product and billing.",
    },
    {
        name: "faqData",
        type: "list",
        subFields: [
            { name: "question", type: "string", defaultValue: "What is Yield Management?" },
            {
                name: "answer",
                type: "richText",
                defaultValue: "test",
            },
        ],
    },
    {
        name: "light",
        type: "boolean",
        defaultValue: false,
    },
] as const;

const FAQ: BFC<typeof inputs> = ({ title, description, faqData, light }) => {
    const [openFaq, setOpenFaq] = useState<number[] | undefined>();

    return (
        <div className={`${styles.faqWrapper} container`}>
            <h2 className={[styles.title, light ? styles.light : ""].join(" ")}>{title}</h2>
            <p className={[styles.mainDescription, light ? styles.light : ""].join(" ")}>{description}</p>

            <div className={styles.faq}>
                {faqData?.map((item: any, index: number) => (
                    <div
                        className={[
                            styles.item,
                            openFaq?.includes(index) ? (light ? styles.bg : styles.darkBg) : "",
                        ].join(" ")}
                        style={{
                            borderBottom: light ? "1px solid rgba(91, 119, 248, 0.20)" : "1px solid #EAECF0",
                        }}
                        onClick={() => {
                            if (openFaq?.includes(index)) {
                                setOpenFaq(prev => prev?.filter(item => item !== index));
                            } else {
                                setOpenFaq(prev => [...(prev || []), index]);
                            }
                        }}
                        key={index}
                    >
                        <div className={styles.header}>
                            <img
                                src={
                                    openFaq?.includes(index)
                                        ? light
                                            ? minusIconLight
                                            : minusIcon
                                        : light
                                          ? plusIconLight
                                          : plusIcon
                                }
                                alt="action icon"
                                className={styles.interactIcon}
                            />
                            <h3 className={[styles.title, light ? styles.light : ""].join(" ")}>{item.question}</h3>
                        </div>
                        {
                            <p
                                className={[
                                    light ? styles.descriptionLight : styles.descriptionDark,
                                    styles.description,
                                    openFaq?.includes(index) ? styles.open : styles.close,
                                    light ? styles.light : "",
                                ].join(" ")}
                            >
                                <Markdown>{item.answer}</Markdown>
                            </p>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

Builder.registerComponent(FAQ, {
    name: "FAQ",
    inputs: toMutable(inputs),
});
