import type { FC, ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const AllInOneSolutions: FC<{
    children: ReactNode;
    page?: "home";
    leftGradient?: ReactNode;
    rightGradient?: ReactNode;
    isLight?: boolean;
}> = ({
    page,
    leftGradient = (
        <div className={`gradient left`}>
            <StaticImage src="./images/sol-left-gradient.png" alt="gradient" />
        </div>
    ),
    rightGradient = (
        <div className={`gradient right`}>
            <StaticImage src="./images/sol-right-gradient.png" alt="gradient" />
        </div>
    ),
    children,
    isLight,
}) => {
    return (
        <div className={`${isLight ? styles.light : ""}`}>
            <div className={`component-wrapper ${page === "home" ? "mb-0" : ""}`}>
                {leftGradient}
                {rightGradient}
                <div className="container">{children}</div>
            </div>
        </div>
    );
};
export default AllInOneSolutions;
