// extracted by mini-css-extract-plugin
export var active = "styles-module--active--2f042";
export var buttonWrapper = "styles-module--buttonWrapper--a0428";
export var card = "styles-module--card--2bdf4";
export var cardDescription = "styles-module--cardDescription--5d221";
export var cardImage = "styles-module--cardImage--fe768";
export var cardTitle = "styles-module--cardTitle--e2ad8";
export var cardsWrapper = "styles-module--cardsWrapper--99705";
export var checkMark = "styles-module--checkMark--e0807";
export var closeIcon = "styles-module--closeIcon--dd986";
export var fixedHeader = "styles-module--fixedHeader--b8b3f";
export var header = "styles-module--header--25ca7";
export var headerText = "styles-module--headerText--3c9d1";
export var modalBodyStyles = "styles-module--modalBodyStyles--b81ef";
export var modalStyles = "styles-module--modalStyles--bf208";
export var modalTitle = "styles-module--modalTitle--44870";
export var selectContainer = "styles-module--selectContainer--3e0af";
export var selectItem = "styles-module--selectItem--f5596";
export var selectWrapper = "styles-module--selectWrapper--843e4";
export var table = "styles-module--table--80dec";
export var tableGroup = "styles-module--tableGroup--fd53c";
export var tableTitle = "styles-module--tableTitle--2f081";
export var title = "styles-module--title--ccfe6";
export var wrapper = "styles-module--wrapper--1cff6";