import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Book your FREE DEMO",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Interested in a free consultation on how to yield more ad revenue with the help of Assertive Yield Analytics?",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book now",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
] as const;

const freeDemo: BFC<typeof inputs> = ({ title, subtitle, buttonTo, buttonText }) => {
    return (
        <div className={`component-wrapper`}>
            <div className="container">
                <div className="row justify-content-center text-lg-center">
                    <div className="col-12 col-lg-7">
                        <h2 className="mt-3 mb-3">{title}</h2>

                        <p className="mb-4">{subtitle}</p>
                        <CustomButton buttonSize="btn--large" buttonStyle="btn--primary" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(freeDemo, {
    name: "freeDemo",
    inputs: toMutable(inputs),
});
