import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Are you ready to change the game with us?",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "We believe changes could be a good thing, especially if they are Assertive changes",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Talk to our Sales Team",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
        required: true,
    },
] as const;

const ByCompanyTypeChangeTheGame: BFC<typeof inputs> = ({ title, subtitle, buttonText, linkTo }) => {
    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>
                <div>
                    <CustomButton buttonSize="custom--huge" to={linkTo}>
                        {buttonText}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(ByCompanyTypeChangeTheGame, {
    name: "byCompanyTypeChangeTheGame",
    inputs: toMutable(inputs),
});
