/**
 * Here we inline all ion-icons that we are going to use.
 * Since they share the same viewbox we can use {@link BaseSvg}
 * to set up the SVG configuration and provide an uniform
 * customization interface across the entire application.
 * @packageDocumentation
 */

import { colorToRgb, IColor } from "./color";

export interface IIconArgs {
    color: IColor;
    size: number | string;
}
export type IIconComponent = React.FunctionComponent<IIconArgs & { children?: React.ReactNode }>;

const BaseSvg: IIconComponent = ({ color, size, children }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={String(size)}
            height={String(size)}
            viewBox="0 0 512 512"
            style={{ fill: colorToRgb(color) }}
        >
            {children}
        </svg>
    );
};

export const MapIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M48.17,113.34A32,32,0,0,0,32,141.24V438a32,32,0,0,0,47,28.37c.43-.23.85-.47,1.26-.74l84.14-55.05a8,8,0,0,0,3.63-6.72V46.45a8,8,0,0,0-12.51-6.63Z" />
            <path d="M212.36,39.31A8,8,0,0,0,200,46V403.56a8,8,0,0,0,3.63,6.72l96,62.42A8,8,0,0,0,312,466V108.67a8,8,0,0,0-3.64-6.73Z" />
            <path d="M464.53,46.47a31.64,31.64,0,0,0-31.5-.88,12.07,12.07,0,0,0-1.25.74l-84.15,55a8,8,0,0,0-3.63,6.72V465.51a8,8,0,0,0,12.52,6.63l107.07-73.46a32,32,0,0,0,16.41-28v-296A32.76,32.76,0,0,0,464.53,46.47Z" />
        </BaseSvg>
    );
};
export const StopWatchIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M232 306.667h48V176h-48v130.667z" />
            <path d="M407.67 170.271l30.786-30.786-33.942-33.941-30.785 30.786C341.217 111.057 300.369 96 256 96 149.961 96 64 181.961 64 288s85.961 192 192 192 192-85.961 192-192c0-44.369-15.057-85.217-40.33-117.729zm-45.604 223.795C333.734 422.398 296.066 438 256 438s-77.735-15.602-106.066-43.934C121.602 365.735 106 328.066 106 288s15.602-77.735 43.934-106.066C178.265 153.602 215.934 138 256 138s77.734 15.602 106.066 43.934C390.398 210.265 406 247.934 406 288s-15.602 77.735-43.934 106.066zM192 32h128v48H192z" />
        </BaseSvg>
    );
};
export const FlaskIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M452.32,365,327.4,167.12A48.07,48.07,0,0,1,320,141.48V64h15.56c8.61,0,16-6.62,16.43-15.23A16,16,0,0,0,336,32H176.45c-8.61,0-16,6.62-16.43,15.23A16,16,0,0,0,176,64h16v77.48a47.92,47.92,0,0,1-7.41,25.63L59.68,365a74,74,0,0,0-2.5,75.84C70.44,465.19,96.36,480,124.13,480H387.87c27.77,0,53.69-14.81,66.95-39.21A74,74,0,0,0,452.32,365ZM211.66,184.2A79.94,79.94,0,0,0,224,141.48V68a4,4,0,0,1,4-4h56a4,4,0,0,1,4,4v73.48a79.94,79.94,0,0,0,12.35,42.72l57.8,91.53A8,8,0,0,1,351.37,288H160.63a8,8,0,0,1-6.77-12.27Z" />
        </BaseSvg>
    );
};
export const EyeIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <circle cx="256" cy="256" r="64" />
            <path d="M490.84,238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349,110.55,302,96,255.66,96c-42.52,0-84.33,12.15-124.27,36.11C90.66,156.54,53.76,192.23,21.71,238.18a31.92,31.92,0,0,0-.64,35.54c26.41,41.33,60.4,76.14,98.28,100.65C162,402,207.9,416,255.66,416c46.71,0,93.81-14.43,136.2-41.72,38.46-24.77,72.72-59.66,99.08-100.92A32.2,32.2,0,0,0,490.84,238.6ZM256,352a96,96,0,1,1,96-96A96.11,96.11,0,0,1,256,352Z" />
        </BaseSvg>
    );
};
export const ShuffleIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M405.9 288.1c-2.6-2.6-6.2-4.1-9.9-4.1s-7.2 1.4-9.9 4.1c-2.7 2.6-4.1 6.2-4.1 9.9s1.5 7.2 4.1 9.9l16.3 16.2h-75.5l-124-155-.1-.1c-2.6-3.1-6.7-5-10.8-5H78c-7.7 0-14 6.3-14 14s6.3 14 14 14h107.4l52.5 66-52.5 66H78c-7.7 0-14 6.3-14 14s6.3 14 14 14h114c4.1 0 8-1.8 10.7-5l.1-.1 53.2-66.8 53.2 66.8.1.1c2.7 3.2 6.6 5 10.7 5h84.4l-16.3 16.2c-2.7 2.6-4.1 6.1-4.1 9.9 0 3.7 1.5 7.2 4.1 9.9 2.6 2.6 6.2 4.1 9.9 4.1s7.2-1.4 9.9-4.1l33.6-33.4c4.2-4.1 6.5-9.6 6.5-15.5s-2.3-11.4-6.5-15.5l-35.6-35.5z" />
            <path d="M279.4 235.4c1.1 1.4 2.8 2.1 4.6 2.1h.1c1.8 0 3.5-.8 4.6-2.2l37.9-47.3h75.8l-16.3 16.2c-2.7 2.6-4.1 6.1-4.1 9.9 0 3.7 1.5 7.2 4.1 9.9 2.6 2.6 6.2 4.1 9.9 4.1s7.2-1.4 9.9-4.1l35.6-35.4c4.2-4.1 6.5-9.7 6.5-15.5 0-5.9-2.3-11.4-6.5-15.5l-33.6-33.4c-2.6-2.6-6.2-4.1-9.9-4.1s-7.2 1.4-9.9 4.1c-2.7 2.6-4.1 6.2-4.1 9.9s1.5 7.2 4.1 9.9l16.3 16.2H320c-4.1 0-8 1.8-10.7 5l-.1.1-40.3 50.2c-1.8 2.2-1.8 5.4.1 7.6l10.4 12.3z" />
        </BaseSvg>
    );
};
export const PulseIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M434.6 272.1c-22.4-1.2-41.6 13.2-48.2 32.9h-38.9l-28.4-85.4c-2.2-6.6-8.3-11-15.2-11h-.3c-7 .1-13.1 4.8-15 11.6l-44.5 155.3-52.3-314.1c-1.2-7.5-7.6-13.1-15.2-13.4-7.6-.3-14.3 4.8-16.2 12.1l-53 244.9H48c-8.8 0-16 7.2-16 16s7.2 16 16 16h72c7.3 0 13.8-5 15.5-12.1l37.4-182.2 51.3 307.9c1.2 7.4 7.4 12.9 14.9 13.3h.9c7.1 0 13.4-4.7 15.4-11.6l49.7-173.6 15.7 47.3c2.2 6.5 8.3 11 15.2 11h51.1c6.9 18 24.4 31 44.9 31 27 0 48.8-22.3 48-49.5-.8-24.8-20.7-45.1-45.4-46.4z" />
        </BaseSvg>
    );
};
export const PersonAddIcons: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M288,256c52.79,0,99.43-49.71,104-110.82,2.27-30.7-7.36-59.33-27.12-80.6C345.33,43.57,318,32,288,32c-30.24,0-57.59,11.5-77,32.38-19.63,21.11-29.2,49.8-27,80.78C188.49,206.28,235.12,256,288,256Z" />
            <path d="M495.38,439.76c-8.44-46.82-34.79-86.15-76.19-113.75C382.42,301.5,335.83,288,288,288s-94.42,13.5-131.19,38c-41.4,27.6-67.75,66.93-76.19,113.75-1.93,10.73.69,21.34,7.19,29.11A30.94,30.94,0,0,0,112,480H464a30.94,30.94,0,0,0,24.21-11.13C494.69,461.1,497.31,450.49,495.38,439.76Z" />
            <path d="M104,288V248h40a16,16,0,0,0,0-32H104V176a16,16,0,0,0-32,0v40H32a16,16,0,0,0,0,32H72v40a16,16,0,0,0,32,0Z" />
        </BaseSvg>
    );
};
export const FunnelIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M296,464a23.88,23.88,0,0,1-7.55-1.23l-80.15-26.67A23.92,23.92,0,0,1,192,413.32V294.11a.44.44,0,0,0-.09-.13L23.26,97.54A30,30,0,0,1,46.05,48H466a30,30,0,0,1,22.79,49.54L320.09,294a.77.77,0,0,0-.09.13V440a23.93,23.93,0,0,1-24,24Z" />
        </BaseSvg>
    );
};
export const LogoUSDIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M240,480V443.58C160.53,439,112.25,398.06,112,336h72c1.77,26.34,23.86,46.45,56,50V288L213.23,281c-61-14.18-93.64-49.39-93.64-102.08C119.59,116.81,164.08,76.08,240,70V32h32V70c77.39,6.3,119,47.74,120,106H320c-.76-24.06-15.83-43.39-48-46v92l30.82,7.28C367.61,243.46,400,277,400,332c0,64.34-43.74,105.88-128,111.32V480Zm0-264V130c-27.59,1.52-47.27,18.47-47.27,42.53C192.73,194.83,209.12,209.41,240,216Zm32,78v92c38.15-1.54,56.38-18.92,56.38-45.77C328.38,315.65,310.15,299.1,272,294Z" />
        </BaseSvg>
    );
};
export const CalculatorIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M416,80a48.05,48.05,0,0,0-48-48H144A48.05,48.05,0,0,0,96,80V432a48.05,48.05,0,0,0,48,48H368a48.05,48.05,0,0,0,48-48ZM168,432a24,24,0,1,1,24-24A24,24,0,0,1,168,432Zm0-80a24,24,0,1,1,24-24A24,24,0,0,1,168,352Zm0-80a24,24,0,1,1,24-24A24,24,0,0,1,168,272Zm88,160a24,24,0,1,1,24-24A24,24,0,0,1,256,432Zm0-80a24,24,0,1,1,24-24A24,24,0,0,1,256,352Zm0-80a24,24,0,1,1,24-24A24,24,0,0,1,256,272ZM368,408a24,24,0,0,1-48,0V328a24,24,0,0,1,48,0ZM344,272a24,24,0,1,1,24-24A24,24,0,0,1,344,272Zm19.31-100.69A16,16,0,0,1,352,176H160a16,16,0,0,1-16-16V96a16,16,0,0,1,16-16H352a16,16,0,0,1,16,16v64A16,16,0,0,1,363.31,171.31Z" />
        </BaseSvg>
    );
};

export const CheckmarkIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <polyline
                points="416 128 192 384 96 288"
                style={{
                    fill: "none",
                    stroke: colorToRgb(color),
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};
export const CodeSlashOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <polyline
                points="160 368 32 256 160 144"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <polyline
                points="352 368 480 256 352 144"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="304"
                y1="96"
                x2="208"
                y2="416"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};

export const CloudUploadIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M320,367.79h76c55,0,100-29.21,100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69,0-113.44,45.79-128,91.2-60,5.7-112,43.88-112,106.4s54,106.4,120,106.4h56"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <polyline
                points="320 255.79 256 191.79 192 255.79"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="256"
                y1="448.21"
                x2="256"
                y2="207.79"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};

export const BarChartOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M32,32V464a16,16,0,0,0,16,16H480"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <rect
                x="96"
                y="224"
                width="80"
                height="192"
                rx="20"
                ry="20"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <rect
                x="240"
                y="176"
                width="80"
                height="240"
                rx="20"
                ry="20"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <rect
                x="383.64"
                y="112"
                width="80"
                height="304"
                rx="20"
                ry="20"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};
export const PeopleOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);
    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M402,168c-2.93,40.67-33.1,72-66,72s-63.12-31.32-66-72c-3-42.31,26.37-72,66-72S405,126.46,402,168Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M336,304c-65.17,0-127.84,32.37-143.54,95.41-2.08,8.34,3.15,16.59,11.72,16.59H467.83c8.57,0,13.77-8.25,11.72-16.59C463.85,335.36,401.18,304,336,304Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeMiterlimit: 10,
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M200,185.94C197.66,218.42,173.28,244,147,244S96.3,218.43,94,185.94C91.61,152.15,115.34,128,147,128S202.39,152.77,200,185.94Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M206,306c-18.05-8.27-37.93-11.45-59-11.45-52,0-102.1,25.85-114.65,76.2C30.7,377.41,34.88,384,41.72,384H154"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeMiterlimit: 10,
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};
export const AnalyticsOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <line
                x1="344"
                y1="280"
                x2="432"
                y2="192"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="232"
                y1="216"
                x2="296"
                y2="280"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="80"
                y1="320"
                x2="184"
                y2="216"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="456"
                cy="168"
                r="24"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="320"
                cy="304"
                r="24"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="208"
                cy="192"
                r="24"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="56"
                cy="344"
                r="24"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};
export const PersonAddOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M376,144c-3.92,52.87-44,96-88,96s-84.15-43.12-88-96c-4-55,35-96,88-96S380,90,376,144Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M288,304c-87,0-175.3,48-191.64,138.6-2,10.92,4.21,21.4,15.65,21.4H464c11.44,0,17.62-10.48,15.65-21.4C463.3,352,375,304,288,304Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeMiterlimit: 10,
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="88"
                y1="176"
                x2="88"
                y2="288"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="144"
                y1="232"
                x2="32"
                y2="232"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};

export const WarningIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M449.07,399.08,278.64,82.58c-12.08-22.44-44.26-22.44-56.35,0L51.87,399.08A32,32,0,0,0,80,446.25H420.89A32,32,0,0,0,449.07,399.08Zm-198.6-1.83a20,20,0,1,1,20-20A20,20,0,0,1,250.47,397.25ZM272.19,196.1l-5.74,122a16,16,0,0,1-32,0l-5.74-121.95v0a21.73,21.73,0,0,1,21.5-22.69h.21a21.74,21.74,0,0,1,21.73,22.7Z" />
        </BaseSvg>
    );
};

export const InformationCircleIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M256,56C145.72,56,56,145.72,56,256s89.72,200,200,200,200-89.72,200-200S366.28,56,256,56Zm0,82a26,26,0,1,1-26,26A26,26,0,0,1,256,138Zm48,226H216a16,16,0,0,1,0-32h28V244H228a16,16,0,0,1,0-32h32a16,16,0,0,1,16,16V332h28a16,16,0,0,1,0,32Z" />
        </BaseSvg>
    );
};
export const NuclearIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M258.9,48C141.92,46.42,46.42,141.92,48,258.9,49.56,371.09,140.91,462.44,253.1,464c117,1.6,212.48-93.9,210.88-210.88C462.44,140.91,371.09,49.56,258.9,48ZM429,239.92l-93.08-.1a2,2,0,0,1-1.95-1.57,80.08,80.08,0,0,0-27.44-44.17,2,2,0,0,1-.54-2.43l41.32-83.43a2,2,0,0,1,2.87-.81A176.2,176.2,0,0,1,431,237.71,2,2,0,0,1,429,239.92ZM208.2,260.38a48,48,0,1,1,43.42,43.42A48,48,0,0,1,208.2,260.38ZM164.65,108.22,206,191.65a2,2,0,0,1-.54,2.43A80.08,80.08,0,0,0,178,238.25a2,2,0,0,1-2,1.57l-93.08.1a2,2,0,0,1-2-2.21,176.2,176.2,0,0,1,80.82-130.3A2,2,0,0,1,164.65,108.22Zm-.37,295.34,56.31-74.09a2,2,0,0,1,2.43-.6,79.84,79.84,0,0,0,66,0,2,2,0,0,1,2.43.6l56.31,74.09a2,2,0,0,1-.54,2.92,175.65,175.65,0,0,1-182.36,0A2,2,0,0,1,164.28,403.56Z" />
        </BaseSvg>
    );
};
export const BusinessOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <line
                x1="176"
                y1="416"
                x2="176"
                y2="480"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M80,32H272a32,32,0,0,1,32,32V476a4,4,0,0,1-4,4H48a0,0,0,0,1,0,0V64A32,32,0,0,1,80,32Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M320,192H432a32,32,0,0,1,32,32V480a0,0,0,0,1,0,0H304a0,0,0,0,1,0,0V208A16,16,0,0,1,320,192Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path d="M98.08,431.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,431.87Z" />
            <path d="M98.08,351.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,351.87Z" />
            <path d="M98.08,271.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,271.87Z" />
            <path d="M98.08,191.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,191.87Z" />
            <path d="M98.08,111.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,98.08,111.87Z" />
            <path d="M178.08,351.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,351.87Z" />
            <path d="M178.08,271.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,271.87Z" />
            <path d="M178.08,191.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,191.87Z" />
            <path d="M178.08,111.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,178.08,111.87Z" />
            <path d="M258.08,431.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,431.87Z" />
            <path d="M258.08,351.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,351.87Z" />
            <path d="M258.08,271.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,271.87Z" />
            <ellipse cx="256" cy="176" rx="15.95" ry="16.03" transform="translate(-49.47 232.56) rotate(-45)" />
            <path d="M258.08,111.87a16,16,0,1,1,13.79-13.79A16,16,0,0,1,258.08,111.87Z" />
            <path d="M400,400a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z" />
            <path d="M400,320a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z" />
            <path d="M400,240a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z" />
            <path d="M336,400a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z" />
            <path d="M336,320a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z" />
            <path d="M336,240a16,16,0,1,0,16,16,16,16,0,0,0-16-16Z" />
        </BaseSvg>
    );
};

export const ArrowForwardIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <polyline
                points="268 112 412 256 268 400"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "48px",
                }}
            />
            <line
                x1="392"
                y1="256"
                x2="100"
                y2="256"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "48px",
                }}
            />
        </BaseSvg>
    );
};

export const ArrowBackIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <polyline
                points="244 400 100 256 244 112"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "48px",
                }}
            />
            <line
                x1="120"
                y1="256"
                x2="412"
                y2="256"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "48px",
                }}
            />
        </BaseSvg>
    );
};

export const TimerOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M112.91,128A191.85,191.85,0,0,0,64,254c-1.18,106.35,85.65,193.8,192,194,106.2.2,192-85.83,192-192,0-104.54-83.55-189.61-187.5-192A4.36,4.36,0,0,0,256,68.37V152"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path d="M233.38,278.63l-79-113a8.13,8.13,0,0,1,11.32-11.32l113,79a32.5,32.5,0,0,1-37.25,53.26A33.21,33.21,0,0,1,233.38,278.63Z" />
        </BaseSvg>
    );
};

// export const CashOutlineIcon: IIconComponent = ({ color, size }) => {
//   const stroke = colorToRgb(color);

//   return (
//     <BaseSvg color={color} size={size}>
//       <rect
//         x="32"
//         y="80"
//         width="448"
//         height="256"
//         rx="16"
//         ry="16"
//         transform="translate(512 416) rotate(180)"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <line
//         x1="64"
//         y1="384"
//         x2="448"
//         y2="384"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <line
//         x1="96"
//         y1="432"
//         x2="416"
//         y2="432"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <circle
//         cx="256"
//         cy="208"
//         r="80"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <path
//         d="M480,160a80,80,0,0,1-80-80"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <path
//         d="M32,160a80,80,0,0,0,80-80"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <path
//         d="M480,256a80,80,0,0,0-80,80"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <path
//         d="M32,256a80,80,0,0,1,80,80"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//     </BaseSvg>
//   );
// };

export const FunnelOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);
    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M35.4,87.12,204.05,283.56A16.07,16.07,0,0,1,208,294V413.32a7.93,7.93,0,0,0,5.39,7.59l80.15,26.67A7.94,7.94,0,0,0,304,440V294A16.07,16.07,0,0,1,308,283.56L476.6,87.12A14,14,0,0,0,466,64H46.05A14,14,0,0,0,35.4,87.12Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};

export const EyeOffOutlineIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path d="M432,448a15.92,15.92,0,0,1-11.31-4.69l-352-352A16,16,0,0,1,91.31,68.69l352,352A16,16,0,0,1,432,448Z" />
            <path d="M255.66,384c-41.49,0-81.5-12.28-118.92-36.5-34.07-22-64.74-53.51-88.7-91l0-.08c19.94-28.57,41.78-52.73,65.24-72.21a2,2,0,0,0,.14-2.94L93.5,161.38a2,2,0,0,0-2.71-.12c-24.92,21-48.05,46.76-69.08,76.92a31.92,31.92,0,0,0-.64,35.54c26.41,41.33,60.4,76.14,98.28,100.65C162,402,207.9,416,255.66,416a239.13,239.13,0,0,0,75.8-12.58,2,2,0,0,0,.77-3.31l-21.58-21.58a4,4,0,0,0-3.83-1A204.8,204.8,0,0,1,255.66,384Z" />
            <path d="M490.84,238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349,110.55,302,96,255.66,96a227.34,227.34,0,0,0-74.89,12.83,2,2,0,0,0-.75,3.31l21.55,21.55a4,4,0,0,0,3.88,1A192.82,192.82,0,0,1,255.66,128c40.69,0,80.58,12.43,118.55,37,34.71,22.4,65.74,53.88,89.76,91a.13.13,0,0,1,0,.16,310.72,310.72,0,0,1-64.12,72.73,2,2,0,0,0-.15,2.95l19.9,19.89a2,2,0,0,0,2.7.13,343.49,343.49,0,0,0,68.64-78.48A32.2,32.2,0,0,0,490.84,238.6Z" />
            <path d="M256,160a95.88,95.88,0,0,0-21.37,2.4,2,2,0,0,0-1,3.38L346.22,278.34a2,2,0,0,0,3.38-1A96,96,0,0,0,256,160Z" />
            <path d="M165.78,233.66a2,2,0,0,0-3.38,1,96,96,0,0,0,115,115,2,2,0,0,0,1-3.38Z" />
        </BaseSvg>
    );
};

export const SwapVerticalOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <polyline
                points="464 208 352 96 240 208"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="352"
                y1="113.13"
                x2="352"
                y2="416"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <polyline
                points="48 304 160 416 272 304"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="160"
                y1="398"
                x2="160"
                y2="96"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};

// export const StatsChartOutlineIcon: IIconComponent = ({ color, size }) => {
//   const stroke = colorToRgb(color);

//   return (
//     <BaseSvg color={color} size={size}>
//       <rect
//         x="64"
//         y="320"
//         width="48"
//         height="160"
//         rx="8"
//         ry="8"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <rect
//         x="288"
//         y="224"
//         width="48"
//         height="256"
//         rx="8"
//         ry="8"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <rect
//         x="400"
//         y="112"
//         width="48"
//         height="368"
//         rx="8"
//         ry="8"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//       <rect
//         x="176"
//         y="32"
//         width="48"
//         height="448"
//         rx="8"
//         ry="8"
//         style={{
//           fill: "none",
//           stroke,
//           strokeLinecap: "round",
//           strokeLinejoin: "round",
//           strokeWidth: "32px",
//         }}
//       />
//     </BaseSvg>
//   );
// };

export const NotificationsOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M427.68,351.43C402,320,383.87,304,383.87,217.35,383.87,138,343.35,109.73,310,96c-4.43-1.82-8.6-6-9.95-10.55C294.2,65.54,277.8,48,256,48S217.79,65.55,212,85.47c-1.35,4.6-5.52,8.71-9.95,10.53-33.39,13.75-73.87,41.92-73.87,121.35C128.13,304,110,320,84.32,351.43,73.68,364.45,83,384,101.61,384H410.49C429,384,438.26,364.39,427.68,351.43Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M320,384v16a64,64,0,0,1-128,0V384"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};

// export const WifiIcon: IIconComponent = ({ color, size }) => {
//   return (
//     <BaseSvg color={color} size={size}>
//       <path d="M346.65,304.3a136,136,0,0,0-180.71,0,21,21,0,1,0,27.91,31.38,94,94,0,0,1,124.89,0,21,21,0,0,0,27.91-31.4Z" />
//       <path d="M256.28,183.7a221.47,221.47,0,0,0-151.8,59.92,21,21,0,1,0,28.68,30.67,180.28,180.28,0,0,1,246.24,0,21,21,0,1,0,28.68-30.67A221.47,221.47,0,0,0,256.28,183.7Z" />
//       <path d="M462,175.86a309,309,0,0,0-411.44,0,21,21,0,1,0,28,31.29,267,267,0,0,1,355.43,0,21,21,0,0,0,28-31.31Z" />
//       <circle cx="256.28" cy="393.41" r="32" />
//     </BaseSvg>
//   );
// };

export const WifiWeakIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M346.65,304.3a136,136,0,0,0-180.71,0,21,21,0,1,0,27.91,31.38,94,94,0,0,1,124.89,0,21,21,0,0,0,27.91-31.4Z"
                opacity={0.6}
            />
            <path
                d="M256.28,183.7a221.47,221.47,0,0,0-151.8,59.92,21,21,0,1,0,28.68,30.67,180.28,180.28,0,0,1,246.24,0,21,21,0,1,0,28.68-30.67A221.47,221.47,0,0,0,256.28,183.7Z"
                opacity={0.2}
            />
            <path
                d="M462,175.86a309,309,0,0,0-411.44,0,21,21,0,1,0,28,31.29,267,267,0,0,1,355.43,0,21,21,0,0,0,28-31.31Z"
                opacity={0.1}
            />
            <circle cx="256.28" cy="393.41" r="32" />
        </BaseSvg>
    );
};

export const FlashOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <path
                d="M315.27,33,96,304H224L192.49,477.23a2.36,2.36,0,0,0,2.33,2.77h0a2.36,2.36,0,0,0,1.89-.95L416,208H288L319.66,34.75A2.45,2.45,0,0,0,317.22,32h0A2.42,2.42,0,0,0,315.27,33Z"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};

export const CodeDownloadOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <polyline
                points="160 368 32 256 160 144"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <polyline
                points="352 368 480 256 352 144"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <polyline
                points="192 288.1 256 352 320 288.1"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="256"
                y1="160"
                x2="256"
                y2="336.03"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};
export const MedalOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <circle
                cx="256"
                cy="352"
                r="112"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="256"
                cy="352"
                r="48"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <path
                d="M147,323,41.84,159.32a32,32,0,0,1-1.7-31.61l31-62A32,32,0,0,1,99.78,48H412.22a32,32,0,0,1,28.62,17.69l31,62a32,32,0,0,1-1.7,31.61L365,323"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="371"
                y1="144"
                x2="37"
                y2="144"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="428.74"
                y1="52.6"
                x2="305"
                y2="250"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="140.55"
                y1="144"
                x2="207"
                y2="250"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};
export const ListOutlineIcon: IIconComponent = ({ color, size }) => {
    const stroke = colorToRgb(color);

    return (
        <BaseSvg color={color} size={size}>
            <line
                x1="160"
                y1="144"
                x2="448"
                y2="144"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="160"
                y1="256"
                x2="448"
                y2="256"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <line
                x1="160"
                y1="368"
                x2="448"
                y2="368"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="80"
                cy="144"
                r="16"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="80"
                cy="256"
                r="16"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
            <circle
                cx="80"
                cy="368"
                r="16"
                style={{
                    fill: "none",
                    stroke,
                    strokeLinecap: "round",
                    strokeLinejoin: "round",
                    strokeWidth: "32px",
                }}
            />
        </BaseSvg>
    );
};
export const ChatIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <g>
                <g transform="translate(0.000000,480.000000) scale(0.225000,-0.210000)">
                    <path
                        d="M911 2175 c-173 -31 -369 -114 -502 -215 -95 -71 -216 -204 -273
        -298 -27 -45 -64 -126 -82 -179 -93 -275 -60 -551 97 -806 30 -48 30 -49 19
        -125 -25 -182 -68 -311 -136 -405 -40 -56 -38 -79 8 -85 71 -9 412 90 547 157
        l76 38 55 -19 c172 -60 406 -82 586 -55 729 112 1143 806 843 1411 -135 272
        -401 478 -726 562 -125 33 -379 42 -512 19z"
                    />
                </g>
            </g>
        </BaseSvg>
    );
};

export const EmailIcon: IIconComponent = ({ color, size }) => {
    return (
        <BaseSvg color={color} size={size}>
            <g transform="translate(0.000000,480.000000) scale(0.225000,-0.210000)">
                <path
                    d="M119 1992 c-39 -20 -62 -41 -84 -77 l-30 -48 0 -741 0 -741 27 -45
        c29 -50 66 -81 117 -98 25 -9 283 -12 981 -12 l946 0 51 25 c56 28 95 77 112
        140 7 27 11 269 11 731 0 763 0 759 -62 823 -18 19 -51 42 -73 52 -38 18 -92
        19 -991 19 l-952 0 -53 -28z m555 -472 c262 -164 452 -278 459 -274 16 10 835
        523 865 542 l22 14 0 -114 0 -115 -446 -279 c-245 -153 -449 -279 -452 -278
        -4 0 -208 126 -454 280 l-447 279 -1 113 c0 61 2 112 4 112 2 0 205 -126 450
        -280z"
                />
            </g>
        </BaseSvg>
    );
};

export const AyGreenLine = () => {
    return (
        <svg
            style={{
                zIndex: 0,
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="1"
            height="93"
            viewBox="0 0 1 93"
            fill="none"
        >
            <path d="M0.5 0V93" stroke="#00F4BE" stroke-dasharray="8 8" />
        </svg>
    );
};
