import * as React from "react";
import { Suspense, useState } from "react";
import type { PageProps } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/seo";

const HubspotForm = React.lazy(
    () =>
        // @ts-expect-error
        import("react-hubspot-form")
);

const ContactUs = () => {
    const [formReady, setFormReady] = useState(false);
    return (
        <Layout bg="dark">
            <div className="content-dark">
                <div className="container p-6">
                    <h1 className="text-center pb-4">How can we help you?</h1>
                </div>
            </div>
            <div className={["content-light", formReady ? "mb-5" : ""].join(" ")}>
                <div className="container  pt-5">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 text-center">
                            <h2 className="text-dark pt-3">Get in touch</h2>
                            <p className="text-dark mb-4">Our team would love to hear from you.</p>
                            <Suspense fallback={<></>}>
                                <HubspotForm
                                    portalId="26553047"
                                    formId="cc02bdb0-220c-4f99-a696-bba1c1e0aa94"
                                    onReady={() => setFormReady(true)}
                                    loading={<div className="text-dark text-center p-5">Loading...</div>}
                                />
                            </Suspense>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export const Head: React.FC<PageProps> = ({ location }) => (
    <Seo title="Contact Us" titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {`
          {
            "@context": "https://schema.org",
            "@type": "WebPage",
            "@id": "${location.pathname}",
            "url": "${location.pathname}",
            "name": "Contact Us",
            "datePublished": "2023-01-16T23:37:53+00:00",
            "dateModified": "2023-03-21T16:10:45+00:00",
            "description": "Assertive Yield is a global company, created by publishers to publishers. Pioneer in offering Yield Revenue Management in Real-Time, from all revenue sources, with extraordinary granular data, as never seen before.",
            "inLanguage": "en-US",
            "isPartOf": {
              "@type": "WebSite",
              "@id": "https://www.assertiveyield.com/#website",
              "url": "https://www.assertiveyield.com/",
              "name": "Assertive Yield",
              "description": "Investment Management Solutions",
              "inLanguage": "en-US",
            "publisher": {
              "@type": "Organization",
              "@id": "https://www.assertiveyield.com/#organization",
              "name": "Assertive Yield",
              "url": "https://www.assertiveyield.com/",
              "sameAs": [
                "https://www.youtube.com/@assertiveyield",
                "https://www.linkedin.com/company/assertive-yield/"
              ],
              "logo": {
                "@type": "ImageObject",
                "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                "inLanguage": "en-US",
                "url": "https://assertiveyield.com/img/og-image.png",
                "contentUrl": "https://assertiveyield.com/img/og-image.png",
                "width": 479,
                "height": 189,
                "caption": "Assertive Yield"
              },
              "image": {
                "@type": "ImageObject",
                "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                "inLanguage": "en-US",
                "URL": "https://assertiveyield.com/img/og-image.png",
                "contentUrl": "https://assertiveyield.com/img/og-image.png",
                "width": 479,
                "height": 189,
                "caption": "Assertive Yield"
              },
              "potentialAction": {
                "@type": "SearchAction",
                "target": {
                  "@type": "EntryPoint",
                  "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
                },
                "query-input": {
                  "@type": "PropertyValueSpecification",
                  "valueRequired": "http://schema.org/True",
                  "valueName": "search_term_string"
                }
              }
            }
          },
          "about": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            }
        },
          "breadcrumb": {
            "@type": "BreadcrumbList",
            "@id": "https://www.assertiveyield.com/#breadcrumb",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": 1,
                "name": "Assertive Yield",
                "item": {
                  "@id": "https://www.assertiveyield.com/",
                  "@type": "Thing"
                }
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Contact Us"
              }
            ]
          },
          "potentialAction": {
            "@type": "ReadAction",
            "target": {
              "@type": "EntryPoint",
              "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
            }
          }
        }
        `}
        </script>
    </Seo>
);

export default ContactUs;
