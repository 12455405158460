import { Builder } from "@builder.io/react";
import MessagesList from "components/byTeamTypeMessages/byTeamTypeMessages";
import { toMutable } from "types/common";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue:
            "Media Buying Teams use AY to measure the campaign return of investment in real-time and adjust spending.",
        required: true,
    },
    {
        name: "messages",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                required: true,
            },
        ],
        defaultValue: [
            {
                title: "What impact of yield did product change Y have?",
            },
            {
                title: "How does product version A compare to B?",
            },
            {
                title: "What variation provides the highest user value?",
            },
        ],
        required: true,
    },
] as const;

const ByTeamTypeProductTeamsMessages: BFC<typeof inputs> = ({ title, messages }) => {
    return (
        <div className="container">
            <MessagesList title={title} messages={messages} delay={1000} />
        </div>
    );
};

Builder.registerComponent(ByTeamTypeProductTeamsMessages, {
    name: "byTeamTypeProductTeamsMessages",
    inputs: toMutable(inputs),
});
