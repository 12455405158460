// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--d8924";
export var bgImage = "styles-module--bgImage--f274e";
export var cardWrapper = "styles-module--cardWrapper--6c978";
export var client_story = "styles-module--client_story--01373";
export var content = "styles-module--content--9072e";
export var hover = "styles-module--hover--b164e";
export var hover_wrapper = "styles-module--hover_wrapper--51ff1";
export var image = "styles-module--image--0359d";
export var learnMore = "styles-module--learnMore--778a4";
export var normal = "styles-module--normal--f34e1";
export var text = "styles-module--text--24762";
export var title = "styles-module--title--68372";