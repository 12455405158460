import type { FC } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import type { VideoData } from "types/common";
import * as styles from "./styles.module.scss";

const VideoThumbnail: FC<{ video: VideoData }> = ({ video }) => {
    const image = getImage(video.featuredImageFileExternal);
    const url = video.link;
    return (
        <a className={styles.video_watch_now} href={url} target="_blank" rel="noreferrer">
            <div className={styles.video_thumb_wrapper}>
                <GatsbyImage image={image!} alt="Video Thumbnail" />
                <div className={["mt-4", "mb-3", "figma-h3", styles.video_title].join(" ")}>{video.title}</div>
                <span>Watch Now</span>
            </div>
        </a>
    );
};

export default VideoThumbnail;
