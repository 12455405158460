import type { FC } from "react";
import { Link } from "gatsby";
import { micromark } from "micromark";
import MarkdownRenderer from "components/MarkdownRenderer";
import type { PodcastsData } from "types/common";
import ArrowTopIcon from "./images/podcast-thumbnail-arrow-top.svg";
import * as styles from "./styles.module.scss";

const PodcastThumbnail: FC<{ podcastItem: PodcastsData }> = ({ podcastItem }) => {
    const minifiedDescription =
        podcastItem.description.length > 145
            ? podcastItem.description.substring(0, 145) + "..."
            : podcastItem.description;

    return (
        <div className={styles.wrapper}>
            <div className={styles.podcasts}>
                <MarkdownRenderer
                    post={{
                        html:
                            podcastItem.body == null
                                ? ""
                                : micromark(podcastItem.body, {
                                      allowDangerousHtml: true,
                                  }),
                    }}
                />
                <h3 className={styles.title}>{podcastItem.title}</h3>
                <p className={styles.plannedTime}>{podcastItem.plannedTime}</p>
                <p className={styles.description}>{minifiedDescription}</p>
                <Link to={podcastItem.linkURL} className={styles.linkWrapper}>
                    <p className={styles.linkText}>{podcastItem.linkText}</p>
                    <img src={ArrowTopIcon} alt="arrow top" />
                </Link>
            </div>
        </div>
    );
};

export default PodcastThumbnail;
