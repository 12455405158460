// extracted by mini-css-extract-plugin
export var boldSubtitle = "styles-module--boldSubtitle--90bc3";
export var btn = "styles-module--btn--38ff6";
export var firstGlow = "styles-module--first-glow--874cc";
export var image = "styles-module--image--887c8";
export var mainContent = "styles-module--main-content--0ca6e";
export var note = "styles-module--note--9801f";
export var secondGlow = "styles-module--second-glow--9999f";
export var subtitle = "styles-module--subtitle--59cc2";
export var suptitle = "styles-module--suptitle--f95f9";
export var title = "styles-module--title--aaa31";
export var wrapper = "styles-module--wrapper--5900a";