import { Link } from "gatsby";
import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "./constants";

const inputs = [
    {
        name: "to",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "color",
        type: "color",
        defaultValue: "ffffff",
        required: true,
    },
    {
        name: "children",
        type: "text",
        defaultValue: "Try out a free demo",
        required: true,
    },
] as const;

const GatsbyLink: BFC<typeof inputs> = ({ to, color, children }) => (
    <Link to={to} style={{ color }}>
        {children}
    </Link>
);

Builder.registerComponent(GatsbyLink, {
    name: "Link",
    inputs: [...inputs],
    noWrap: true,
});
