import { Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import BookCallButton from "components/BookCallButton";
import * as styles from "./styles.module.scss";

const ReadyToWin = () => {
    return (
        <div className={styles.wrapper}>
            <Container>
                <div className={["d-none", "d-xxl-block", styles.world_img].join(" ")}>
                    <StaticImage src="images/world.png" alt="" />
                </div>
                <div className={["figma-h2", "mb-4"].join(" ")}>Ready to win? Discover Now</div>
                <div className="mb-4">
                    Essential for premium publishers, SSPs, Networks who seek to disrupt their operations
                </div>
                <div className="mb-5">You don't have to rely on big techs data anymore!</div>
                <BookCallButton />
            </Container>
        </div>
    );
};

export default ReadyToWin;
