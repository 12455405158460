// extracted by mini-css-extract-plugin
export var blob_left_1 = "blog-module--blob_left_1--ec661";
export var blob_left_2 = "blog-module--blob_left_2--fb476";
export var blob_left_3 = "blog-module--blob_left_3--ad718";
export var blob_left_4 = "blog-module--blob_left_4--c94c8";
export var blob_right_1 = "blog-module--blob_right_1--4951c";
export var blob_right_2 = "blog-module--blob_right_2--dd19b";
export var blob_right_3 = "blog-module--blob_right_3--c9e25";
export var blob_right_4 = "blog-module--blob_right_4--67cb1";
export var blur_blob = "blog-module--blur_blob--4330b";
export var content_wrapper = "blog-module--content_wrapper--6f432";