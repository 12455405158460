// extracted by mini-css-extract-plugin
export var adBannerWrapper = "styles-module--adBannerWrapper--8d648";
export var adBannerWrapperCentralized = "styles-module--adBannerWrapperCentralized--a4df3";
export var closeButtonIcon = "styles-module--closeButtonIcon--1c6f9";
export var closeButtonIconCentralized = "styles-module--closeButtonIconCentralized--f8027";
export var content = "styles-module--content--019f0";
export var expand = "styles-module--expand--08a62";
export var expandAnimation = "styles-module--expandAnimation--2a2f7";
export var expandCentralized = "styles-module--expandCentralized--48d06";
export var expandedInfo = "styles-module--expandedInfo--1d39a";
export var infoButtonIcon = "styles-module--infoButtonIcon--1389f";
export var infoButtonIconCentralized = "styles-module--infoButtonIconCentralized--42428";