// extracted by mini-css-extract-plugin
export var blob_left_1 = "hero-module--blob_left_1--4226b";
export var blob_right_1 = "hero-module--blob_right_1--81f2b";
export var closeIcon = "hero-module--closeIcon--23dc8";
export var component_wrapper_hero = "hero-module--component_wrapper_hero--769d3";
export var customButtonWrapper = "hero-module--customButtonWrapper--dbca9";
export var globe_wrapper = "hero-module--globe_wrapper--4c627";
export var glow = "hero-module--glow--ac30e";
export var granular = "hero-module--granular--c6f8b";
export var hero_animation_pos = "hero-module--hero_animation_pos--ccb77";
export var hero_description = "hero-module--hero_description--4b9bc";
export var icon = "hero-module--icon--0c4cf";
export var modalBodyStyles = "hero-module--modalBodyStyles--db6dc";
export var modalStyles = "hero-module--modalStyles--de143";
export var pricing = "hero-module--pricing--95a5b";
export var realtime = "hero-module--realtime--c2a34";
export var revenue = "hero-module--revenue--a0c54";
export var right = "hero-module--right--4397c";
export var show_with = "hero-module--show_with--adc4d";
export var text = "hero-module--text--7bb97";
export var videoBg = "hero-module--videoBg--63736";
export var watchVideoWrapper = "hero-module--watchVideoWrapper--9c38e";
export var with_indent = "hero-module--with_indent--59e35";