// extracted by mini-css-extract-plugin
export var arrow = "styles-module--arrow--264d7";
export var dynamicParagraphs = "styles-module--dynamicParagraphs--77673";
export var firstGlow = "styles-module--first-glow--ff23d";
export var image = "styles-module--image--16fe2";
export var link = "styles-module--link--1ff90";
export var linkText = "styles-module--link-text--e061f";
export var linkWrapper = "styles-module--link-wrapper--adb36";
export var mainContent = "styles-module--main-content--1a3a4";
export var paragraph = "styles-module--paragraph--8e2e3";
export var paragraphQuote = "styles-module--paragraph-quote--7725e";
export var quoteWrapper = "styles-module--quote-wrapper--3a60b";
export var shortVerticalLine = "styles-module--short-vertical-line--57d7a";
export var shortVerticalLineDescription = "styles-module--short-vertical-line-description--bb51c";
export var verticalLine = "styles-module--vertical-line--2be4e";
export var wrapper = "styles-module--wrapper--eabc1";