import React, { useEffect, useState } from "react";
import arrowUp from "./images/arrow-up.svg";
import * as styles from "./styles.module.scss";

const ScrollToTopButton: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        setIsVisible(scrollTop > 360);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return isVisible ? (
        <button className={styles.circleButton} onClick={scrollToTop}>
            <img src={arrowUp} alt="go to top" />
        </button>
    ) : null;
};

export default ScrollToTopButton;
