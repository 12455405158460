import { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomButton from "components/CustomButton";
import PodcastThumbnail from "components/PodcastThumbnail";
import type { PodcastsData } from "types/common";
import prev from "./images/podcasts-page-arrow-left.svg";
import next from "./images/podcasts-page-arrow-right.svg";
import * as styles from "./styles.module.scss";

const Podcasts = () => {
    const data = useStaticQuery(graphql`
        query allPodcast {
            allPodcast(sort: { date: DESC }) {
                nodes {
                    id
                    date
                    title
                    body
                    description
                    plannedTime
                    linkURL
                    linkText
                }
            }
        }
    `);

    const posts: PodcastsData[] = data.allPodcast.nodes;

    const buttonPrevRef = useRef<any>(null);
    const buttonNextRef = useRef<any>(null);
    const swiperInstance = useRef<any>(null);

    useEffect(() => {
        swiperInstance.current.params.navigation.prevEl = buttonPrevRef.current;
        swiperInstance.current.params.navigation.nextEl = buttonNextRef.current;
        swiperInstance.current.navigation.destroy();
        swiperInstance.current.navigation.init();
        swiperInstance.current.navigation.update();
    }, []);

    return (
        <div className={[styles.content_wrapper].join(" ")}>
            <Container>
                <>
                    <div className={styles.row}>
                        <h4 className={[styles.newH4, styles.text_dark].join(" ")}>Podcasts</h4>
                        <div className="d-lg-flex justify-content-center d-none">
                            <CustomButton to="/podcasts/all">View all podcast</CustomButton>
                        </div>
                    </div>
                    <hr className={[styles.hr_light_bg, "mb-5", "mt-2"].join(" ")} />
                </>
                <Swiper
                    modules={[Navigation, FreeMode]}
                    spaceBetween={30}
                    slidesPerView="auto"
                    freeMode={true}
                    navigation={{
                        prevEl: buttonPrevRef.current,
                        nextEl: buttonNextRef.current,
                    }}
                    onBeforeInit={swiper => {
                        swiperInstance.current = swiper;
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 2.2,
                        },
                        992: {
                            slidesPerView: 2,
                        },
                    }}
                >
                    {posts.map((post, index) => (
                        <SwiperSlide key={index}>{post && <PodcastThumbnail podcastItem={post} />}</SwiperSlide>
                    ))}
                </Swiper>
                <div className="d-flex d-none d-lg-flex justify-content-end">
                    <div ref={buttonPrevRef} className="btn--circle-light me-4">
                        <img src={prev} alt="" />
                    </div>

                    <div ref={buttonNextRef} className="btn--circle-light">
                        <img src={next} alt="" />
                    </div>
                </div>
                <div className="d-flex justify-content-center d-lg-none">
                    <CustomButton customWidth="300px" to="/podcasts/all">
                        View all podcast
                    </CustomButton>
                </div>
            </Container>
        </div>
    );
};

export default Podcasts;
