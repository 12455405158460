import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import { useStatuspageStatus } from "ay-components-bundle";
import { Link } from "gatsby";
import { Builder } from "@builder.io/react";
import { RECAPTCHA_KEY } from "components/constants";
import CustomButton from "components/CustomButton";
import { openIntercomChat } from "components/intercom";
import { toMutable } from "types/common";
import arrowRight from "./images/ay-support-content-arrow-right.svg";
import bugFixesImage from "./images/ay-support-content-bug-fixes.svg";
import criticalDelay from "./images/ay-support-content-critical-delay.svg";
import successDelay from "./images/ay-support-content-delay-success.svg";
import emailUsImage from "./images/ay-support-content-email-us.svg";
import engineeringImage from "./images/ay-support-content-engineering.svg";
import eyeImage from "./images/ay-support-content-eye.svg";
import goToAppImage from "./images/ay-support-content-go-to-app.svg";
import majorDelay from "./images/ay-support-content-major-delay.svg";
import minorDelay from "./images/ay-support-content-minor-delay.svg";
import operationImage from "./images/ay-support-content-operations.svg";
import performanceImage from "./images/ay-support-content-performance.svg";
import problemReportingImage from "./images/ay-support-content-product-updates.svg";
import roadmapAndUpgradeImage from "./images/ay-support-content-roadmap-and-upgrade.svg";
import securityImage from "./images/ay-support-content-security.svg";
import liveChatImage from "./images/ay-support-content-submit-ticket.svg";
import systemAuditsImage from "./images/ay-support-content-system-audits.svg";
import * as styles from "./styles.module.scss";

const email = "aWEuZXZpdHJlc3NhQHNzZWNjdXNyZW1vdHN1YzpvdGxpYW0=";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "The Support You Need. When You Need It.",
    },
    {
        name: "description",
        type: "text",
        defaultValue:
            "Our support services goes beyond traditional assistance, ensuring continuous value throughout your journey. Empowering you to drive innovation with our platform, we take an open and collaborative approach.",
    },
    {
        name: "actionCards",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
            },
            {
                name: "link",
                type: "text",
                defaultValue: "/",
            },
            {
                name: "description",
                type: "text",
            },
        ],
    },
    {
        name: "benefitCards",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
            },
            {
                name: "description",
                type: "text",
            },
        ],
    },
    {
        name: "platformStatusAndDelay",
        type: "object",
        subFields: [
            {
                name: "platformStatusLink",
                type: "text",
                defaultValue: "/",
                required: true,
            },
            {
                name: "processingDelayLink",
                type: "text",
                defaultValue: "/",
                required: true,
            },
        ],
        defaultValue: {
            platformStatusLink: "/",
            processingDelayLink: "/",
        },
        required: true,
    },
] as const;

type TPlatformStatus = {
    delay: number;
    timestamp: number;
};

export const AySupportContent: BFC<typeof inputs> = ({
    title,
    description,
    actionCards,
    benefitCards,
    platformStatusAndDelay,
}) => {
    const [isValid, setIsValid] = useState(false);

    const parseEmail = (email: string) => {
        return atob(email).split("").reverse().join("");
    };

    const handleCaptcha = (token: string | null) => {
        if (token) {
            setIsValid(true);
        }
    };

    const [platformStatus, setPlatformStatus] = useState<TPlatformStatus>({} as TPlatformStatus);

    const images = [<img key="3" src={goToAppImage} alt="" className={styles.redIcon} />];

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const benefitCardsImages = [
        <img key="4" src={eyeImage} alt="" className={styles.blueIcon} />,
        <img key="5" src={problemReportingImage} alt="" className={styles.redIcon} />,
        <img key="6" src={operationImage} alt="" className={styles.greenIcon} />,
        <img key="7" src={performanceImage} alt="" className={styles.redIcon} />,
        <img key="8" src={bugFixesImage} alt="" className={styles.greenIcon} />,
        <img key="9" src={securityImage} alt="" className={styles.blueIcon} />,
        <img key="10" src={roadmapAndUpgradeImage} alt="" className={styles.greenIcon} />,
        <img key="11" src={systemAuditsImage} alt="" className={styles.blueIcon} />,
        <img key="12" src={engineeringImage} alt="" className={styles.redIcon} />,
    ];

    useEffect(() => {
        async function getDelay() {
            const data = await fetch("/.netlify/functions/getDelay").then(res => res.json());

            setPlatformStatus(data.processingDelay.yieldAnalytics);
        }

        getDelay();
    }, []);

    function parseTimestamp(timestamp: number) {
        const currentTime = Math.floor(Date.now() / 1000);
        const timeDifference = currentTime - timestamp;

        const timeUnits = [
            { unit: "day", seconds: 86400 },
            { unit: "hour", seconds: 3600 },
            { unit: "minute", seconds: 60 },
            { unit: "second", seconds: 1 },
        ];

        for (const unit of timeUnits) {
            const value = Math.floor(timeDifference / unit.seconds);
            if (value >= 1) {
                return `${value} ${unit.unit}${value > 1 ? "s" : ""} ago`;
            }
        }

        return "Just now";
    }

    function parseDelay(delay: number) {
        const delayInMinutes = delay / 60000;

        const delayRanges = [
            { range: [0, 2], label: "No Delay" },
            { range: [2, 10], label: "Minor Delay" },
            { range: [10, 60], label: "Major Delay" },
            { range: [60, Infinity], label: "Critical Delay" },
        ];

        for (const range of delayRanges) {
            if (delayInMinutes >= range.range[0] && delayInMinutes < range.range[1]) {
                return range.label;
            }
        }

        return "No Delay";
    }

    const { indicator } = useStatuspageStatus();

    const indicatorParse = (indicator: string | undefined) => {
        switch (indicator) {
            case "minor": {
                return "Minor issue";
            }

            case "major": {
                return "Major issue";
            }

            case "critical": {
                return "Critical issue";
            }

            case "none": {
                return "No Issue";
            }

            default: {
                // eslint-disable-next-line no-console
                console.error("unknown indicator = " + indicator);
                return "No Issue";
            }
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className="container">
                <div className={styles.journeyWrapper}>
                    <div className={styles.journeyRoot}>
                        <Link className={styles.route} to="/help-center">
                            Help Center
                        </Link>
                    </div>
                    <div className={styles.journeyArrowRight}>
                        <img src={arrowRight} alt="" />
                    </div>
                    <div className={styles.journey}>
                        <p className={styles.currentRoute}>AY Support</p>
                    </div>
                </div>

                <div className={styles.mainWrapper}>
                    <div className={styles.actionCards}>
                        <Link
                            onClick={ev => {
                                ev.preventDefault();
                                openIntercomChat();
                            }}
                            to={"#"}
                            className={styles.actionCard}
                        >
                            <div className={styles.iconWrapper}>
                                <img src={liveChatImage} alt="" className={styles.blueIcon} />
                            </div>

                            <h4 className={styles.title}>Live Chat</h4>
                            <p className={styles.description}>
                                Connect with our experts to assist you with any questions or concerns
                            </p>
                        </Link>

                        <div onClick={handleShow} className={styles.actionCard}>
                            <div className={styles.iconWrapper}>
                                <img src={emailUsImage} alt="" className={styles.blueIcon} />
                            </div>

                            <h4 className={styles.title}>Email us</h4>
                            <p className={styles.description}>Get in touch with our support team by emailing us</p>
                        </div>

                        {actionCards?.map(({ title, description, link }, index) => (
                            <Link to={link!} key={index} className={styles.actionCard}>
                                <div className={styles.iconWrapper}>{images[index]}</div>

                                <h4 className={styles.title}>{title}</h4>
                                <p className={styles.description}>{description}</p>
                            </Link>
                        ))}
                    </div>

                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.description}>{description}</p>

                    <div className={styles.cardsWrapper}>
                        {benefitCards?.map(({ title, description }, index) => (
                            <div className={styles.card} key={index}>
                                <div className={styles.iconWrapper}>{benefitCardsImages[index]}</div>

                                <h3 className={styles.title}>{title}</h3>
                                <p className={styles.description}>{description}</p>
                                <div className={styles.contactUs}>
                                    <CustomButton
                                        buttonSize="btn--large"
                                        buttonStyle={"btn--primary"}
                                        onClick={openIntercomChat}
                                    >
                                        Contact Us
                                    </CustomButton>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className={styles.delaysWrapper}>
                        <h2 className={styles.title}>Platform Status & Delay</h2>

                        <div className={styles.delayBlocksWrapper}>
                            <div className={styles.delayBlock}>
                                <div className={styles.wrapper}>
                                    <div>
                                        <p className={styles.supTitle}>Platform Status</p>
                                        <h2 className={styles.title}>{indicatorParse(indicator)}</h2>
                                    </div>

                                    <div>
                                        <img
                                            src={
                                                indicatorParse(indicator) === "No Issue"
                                                    ? successDelay
                                                    : indicatorParse(indicator) === "Minor issue"
                                                      ? minorDelay
                                                      : indicatorParse(indicator) === "Major issue"
                                                        ? majorDelay
                                                        : indicatorParse(indicator) === "Critical issue"
                                                          ? criticalDelay
                                                          : ""
                                            }
                                            alt="success"
                                        />
                                    </div>
                                </div>
                                <div className={styles.subContent}>
                                    <a
                                        href={(platformStatusAndDelay as any).platformStatusLink}
                                        className={styles.link}
                                    >
                                        Learn more
                                    </a>

                                    <span className={styles.lastChange}>Last change: Just now</span>
                                </div>
                            </div>

                            <div className={styles.delayBlock}>
                                <div className={styles.wrapper}>
                                    <div>
                                        <p className={styles.supTitle}>Processing Delay</p>
                                        <h2 className={styles.title}>{parseDelay(platformStatus.delay)}</h2>
                                    </div>

                                    <div>
                                        <img
                                            src={
                                                parseDelay(platformStatus.delay) === "No Delay"
                                                    ? successDelay
                                                    : parseDelay(platformStatus.delay) === "Minor Delay"
                                                      ? minorDelay
                                                      : parseDelay(platformStatus.delay) === "Major Delay"
                                                        ? majorDelay
                                                        : parseDelay(platformStatus.delay) === "Critical Delay"
                                                          ? criticalDelay
                                                          : ""
                                            }
                                            alt="success"
                                        />
                                    </div>
                                </div>
                                <div className={styles.subContent}>
                                    <a
                                        href={(platformStatusAndDelay as any).processingDelayLink}
                                        className={styles.link}
                                    >
                                        Learn more
                                    </a>

                                    <span className={styles.lastChange}>
                                        Last change: {parseTimestamp(platformStatus.timestamp)}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Please verify you are a human</Modal.Title>
                </Modal.Header>
                <div
                    style={{
                        margin: "0 auto",
                        padding: "20px",
                    }}
                >
                    <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={handleCaptcha} />
                </div>
                {isValid && (
                    <Modal.Footer>
                        <CustomButton to={parseEmail(email) ?? ""} buttonSize="btn--large">
                            Email Us
                        </CustomButton>
                    </Modal.Footer>
                )}
            </Modal>
        </div>
    );
};

Builder.registerComponent(AySupportContent, {
    name: "aySupportContent",
    inputs: toMutable(inputs),
});
