import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import * as allStyles from "components/all.module.scss";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import closeIcon from "./images/hero-close-icon.svg";
import StartVideIconSmall from "./images/HeroWatchVideoSmallIcon";
import shortPreviewVideo from "./videos/short-video-optimized.webm";
import HeroAnimation from "./Animation";
import * as styles from "./hero.module.scss";
const inputs = [
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Try out a free demo",
        required: true,
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Assertive Yield is a total revenue management system, reporting not only on revenue generated, but also delivering the necessary tools to manage inventory quicker and more efficiently.",
    },
] as const;
const Hero: BFC<typeof inputs> = ({ description, buttonText, buttonTo }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <video loop autoPlay muted className={styles.videoBg}>
                <source src={shortPreviewVideo} type="video/mp4" />
            </video>
            <div className={allStyles.component_wrapper_hero}>
                <div className={["d-none", "d-xxl-block", styles.blob_left_1].join(" ")}>
                    <StaticImage src="images/hero-left-gradient.png" alt="" />
                </div>
                <div className={["d-none", "d-xxl-block", styles.blob_right_1].join(" ")}>
                    <StaticImage src="images/right-gradient.png" alt="" />
                </div>
                <div className={`container panel`}>
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-8 ">
                            <h1 className={["mb-3 position-relative"].join(" ")}>
                                <span className={[].join(" ")}>
                                    Maximize Ad <span className="d-none d-lg-inline">Revenue</span>{" "}
                                    <span className={styles.show_with}>
                                        <span>
                                            <span className="d-inline d-lg-none">Revenue</span> &
                                        </span>
                                    </span>
                                </span>
                                <div style={{ position: "relative", display: "flex" }}>
                                    <span className={styles.with_indent}>&</span>
                                    <div className=" position-relative w-100">
                                        <HeroAnimation />
                                    </div>
                                </div>
                            </h1>

                            <p className={["mb-5", styles.hero_description].join(" ")}>{description}</p>

                            <div className="row ">
                                <div className="col text-md-left mt-lg-0 mt-md-4 d-flex align-items-center flex-column flex-md-row">
                                    <div className={styles.customButtonWrapper}>
                                        <CustomButton buttonSize="btn--large" buttonStyle="btn--primary" to={buttonTo}>
                                            {buttonText}
                                        </CustomButton>
                                    </div>

                                    <div
                                        onClick={() => setIsModalOpen(true)}
                                        className={`${styles.watchVideoWrapper} watchVideoWrapper`}
                                    >
                                        <div className={`${styles.icon}`}>
                                            <StartVideIconSmall />
                                        </div>
                                        <p className={styles.text}>Watch Video</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                onHide={() => setIsModalOpen(false)}
                show={isModalOpen}
                size="lg"
                dialogClassName={styles.modalStyles}
                centered
            >
                <div className={`${styles.closeIcon} close-icon-home`} onClick={() => setIsModalOpen(false)}>
                    <img src={closeIcon} alt="" />
                </div>
                <Modal.Body className={styles.modalBodyStyles}>
                    <iframe
                        title="Hero Video Player"
                        loading="lazy"
                        src="https://www.youtube.com/embed/M351wbLACTY?si=F_mStlVpw0--ip5Z&autoplay=1"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};
Builder.registerComponent(Hero, {
    name: "Hero",
    inputs: [...inputs],
});
