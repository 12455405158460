import { Gallery, Item } from "react-photoswipe-gallery";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import granulargradient from "./images/granular-gradient.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle1",
        type: "text",
        defaultValue: "The Power of Yield Intelligence",
    },
    {
        name: "title1",
        type: "text",
        defaultValue: "Single Source of Truth",
        required: true,
    },
    {
        name: "subtitle1",
        type: "longText",
        defaultValue:
            "Vital to manage all your revenue data in one place in real-time across hundreds of dimensions and metrics extended with your custom data segments and audiences. Surreal Granular Data across all Dimensions with uncountable Metrics",
        required: true,
    },
    {
        name: "buttonText1",
        type: "text",
        defaultValue: "Learn more",
        required: true,
    },
    {
        name: "buttonTo1",
        type: "text",
        defaultValue: "/",
        required: true,
    },
    {
        name: "suptitle2",
        type: "text",
        defaultValue: "The Power of Yield Intelligence",
    },
    {
        name: "title2",
        type: "text",
        defaultValue: "Revenue Attribution",
        required: true,
    },
    {
        name: "subtitle2",
        type: "longText",
        defaultValue:
            "Deep layers of revenue attribution in PageView and content level. Revenue Forecast Predictions with 96% accuracy and automated discrepancy handling. Increase your profits by analyzing unrevealed data, optimizing, testing, monitoring performance and preventing troubleshooting all with real-time data.",
        required: true,
    },
    {
        name: "buttonText2",
        type: "text",
        defaultValue: "Learn more",
        required: true,
    },
    {
        name: "buttonTo2",
        type: "text",
        defaultValue: "/",
        required: true,
    },
    {
        name: "switchMagnifier1",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif1",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover1",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt1",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth1",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight1",
        type: "number",
        defaultValue: 714,
    },
    {
        name: "switchMagnifier2",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif2",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover2",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt2",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth2",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight2",
        type: "number",
        defaultValue: 714,
    },
] as const;
const ByCompanyTypeSingleSourceOfTruth: BFC<typeof inputs> = ({
    suptitle1,
    title1,
    subtitle1,
    buttonTo1,
    buttonText1,
    suptitle2,
    title2,
    subtitle2,
    buttonTo2,
    buttonText2,
    switchMagnifier1,
    imageOrGif1,
    imageOrGifHover1,
    imageOrGifAlt1,
    imageOrGifWidth1,
    imageOrGifHeight1,
    switchMagnifier2,
    imageOrGif2,
    imageOrGifHover2,
    imageOrGifAlt2,
    imageOrGifWidth2,
    imageOrGifHeight2,
}) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6  order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover1 ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover1)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif1!)}
                                        src={imageOrGif1}
                                        alt={imageOrGifAlt1}
                                        className="img-fluid"
                                    />
                                ) : (
                                    <BuilderImage image={imageOrGif1} lazy sizes="100%" altText={imageOrGifAlt1} />
                                )}
                                {switchMagnifier1 && !imageOrGifHover1 && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth1}
                                            height={imageOrGifHeight1}
                                            original={imageOrGif1}
                                            alt={imageOrGifAlt1}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6   order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle1}</p>
                            <h2>{title1}</h2>
                            <p>{subtitle1}</p>
                            <CustomButton buttonStyle="btn--primary" to={buttonTo1}>
                                {buttonText1}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
            <div className="component-wrapper">
                <div className="container ">
                    <img src={granulargradient} className={`gradient right`} style={{ top: "-200px" }} alt="gradient" />
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6    order-2 order-lg-1 ">
                            <p className={styles.suptitle}>{suptitle2}</p>
                            <h2>{title2}</h2>
                            <p>{subtitle2}</p>
                            <CustomButton buttonStyle="btn--primary" to={buttonTo2}>
                                {buttonText2}
                            </CustomButton>
                        </div>
                        <div className="col-lg-6  order-lg-1 mt-5 mt-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover2 ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover2)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif2!)}
                                        src={imageOrGif2}
                                        alt={imageOrGifAlt2}
                                        className="img-fluid"
                                    />
                                ) : (
                                    <BuilderImage image={imageOrGif2} lazy sizes="100%" altText={imageOrGifAlt2} />
                                )}
                                {switchMagnifier2 && !imageOrGifHover2 && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth2}
                                            height={imageOrGifHeight2}
                                            original={imageOrGif2}
                                            alt={imageOrGifAlt2}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByCompanyTypeSingleSourceOfTruth, {
    name: "byCompanyTypeSingleSourceOfTruth",
    inputs: toMutable(inputs),
});
