import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Sounds interesting, right?",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Talk to our Sales Team",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
        required: true,
    },
] as const;

const ByCompanyTypeSoundsInteresting: BFC<typeof inputs> = ({ title, buttonText, linkTo }) => {
    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <div>
                    <CustomButton buttonSize="custom--huge" to={linkTo}>
                        {buttonText}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(ByCompanyTypeSoundsInteresting, {
    name: "byCompanyTypeSoundsInteresting",
    inputs: toMutable(inputs),
});
