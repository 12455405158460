import { zScoreToUnitRatio } from "../../utils/ztable";
import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective } from "../types";

const RAW_METRICS = {
    weighed_stouffer: {},
    agg_count: {},
    impressions: {},
} as const satisfies Record<string, IRawMetric>;
export type RawAdRevenueMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["ad_revenue_index_percentage", "ad_revenue_index_impressions"] as const;
export type AddRevenueIndexMetricsType = (typeof CALC_IDS)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        ad_revenue_index_percentage: {
            label: "Score",
            explanation: "Following a normal distribution, n% of samples would perform worse than this group.",
            formula: (m, opname) => {
                // This report is not handled by get Daily Details, so the metrics (m parameter)
                // becomes different.

                // Total in this case should always be 50% because it is
                // the probability that a random sample would be smaller than the average.
                // If we didn't limit the # of rows returned by the query
                // we wouldn't have to fix total to be 50.0
                if (opname === "total") {
                    return 50.0;
                }
                // When we're not aggregating (for the chart, for instance), this is just the stouffer mean calculated in clickhouse
                // transformed to percentage.

                // When aggregating, we aggregate the many stouffer z values with another stouffer weighed average.

                // So a country report, for Canada row, does this:
                // For each day ->
                // ---- For all entities ->
                // -------- For Canada -> Get Canada Average
                // -------- For Entity -> Get Entity Average and Standard Deviation
                // -------- Return Z-Score = (Canada Avg - Entity Avg) / Entity Standard Deviation
                // -------- Return how many impressions Canada had for this entity
                // ---- Join all entities values with Stouffer method:
                // ----       Stouffer Z Score = sum(z_score * impression) / sqrt(sum(impressions ** 2))
                // ---- Return day z-score
                // ---- Return day impressions
                // @@@@@@@@@@@@@@@
                // @@@@@@@@@@@@@@@ We're now at the formula (this function) part of the processing. @@@@@@@@@@@
                // @@@@@@@@@@@@@@@ At this point if we have only one item to aggregate (for the chart, for instance)
                // @@@@@@@@@@@@@@@ then the following code does nothing but convert z-score to percentage.
                // @@@@@@@@@@@@@@@

                // Get the weighed average of the many z scores.
                // Since the z-scores for a given country, or given browser
                // don't change too much, we can take their average.
                let zScore = m.weighed_stouffer / m.impressions;
                if (opname === "groupTotal") {
                    // If we're aggregating 1 day, this will decrease the item by 3 standard deviations.
                    // If we have 30 days, the division goes to almost nothing.
                    const correctionForUndersampling = 3 / Math.pow(m.agg_count, 1.5);
                    zScore = zScore - correctionForUndersampling;
                }
                return zScoreToUnitRatio(zScore) * 100;
            },
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        ad_revenue_index_impressions: {
            label: "Impressions",
            explanation: "This item impressions / Total impressions",
            formula: (m, opName, total, totalByDate) => {
                if (opName === "groupTotal") {
                    return m.impressions / total.impressions;
                }

                if (opName === "total") {
                    return 1;
                }

                if (opName === "groupByDate" || opName === "byDate") {
                    if (totalByDate != null) {
                        return m.impressions / totalByDate.impressions;
                    }
                }

                return 1;
            },
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
    });
