import { useEffect, useState } from "react";
import axios from "axios";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { TCareers, toMutable } from "types/common";
import firstGlow from "./images/careers-available-jobs-first-glow.svg";
import secondGlow from "./images/careers-available-jobs-second-glow.svg";
import * as styles from "./styles.module.scss";

const inputs = [] as const;

const CareersAvailableJobs: BFC<typeof inputs> = () => {
    const data = useStaticQuery(graphql`
        query careers {
            allCareers {
                nodes {
                    id
                    title
                    description
                    body
                    sidebar
                    category
                    emailReceiver
                    location
                    isFullTime
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const renderData: TCareers[] = data.allCareers.nodes;

    const [activePoint, setActivePoint] = useState("View all");

    const [switcherPoints, setSwitcherPoints] = useState<string[]>([]);

    useEffect(() => {
        axios
            .get("/.netlify/functions/hubSpotProperties", {
                params: {
                    propertyName: "ay_team___career",
                },
            })
            .then(response => {
                const { data } = response;
                const { options } = data;
                const points = options.map((item: any) => item.label);
                setSwitcherPoints(["View all", ...points]);
            });
    }, []);

    const filteredData =
        activePoint === "View all" ? renderData : renderData.filter(item => item.category.includes(activePoint));

    return (
        <div className={styles.wrapper}>
            <div className={`${styles.firstGlow} gradient`}>
                <img src={firstGlow} alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <img src={secondGlow} alt="" />
            </div>
            <div className="container">
                <div className={styles.switcherWrapper}>
                    <ul className={`${styles.switcher} d-lg-inline-flex d-none`}>
                        {switcherPoints.map(point => (
                            <li
                                key={point}
                                className={`${styles.switcherPoint} ${activePoint === point ? styles.active : ""}`}
                                onClick={() => setActivePoint(point)}
                            >
                                {point}
                            </li>
                        ))}
                    </ul>
                    <select
                        className={`${styles.switcher} d-lg-none`}
                        value={activePoint}
                        onChange={event => setActivePoint(event.target.value)}
                    >
                        {switcherPoints.map(point => (
                            <option key={point} value={point}>
                                {point}
                            </option>
                        ))}
                    </select>
                </div>

                <div className={styles.jobs}>
                    {filteredData.map(item => (
                        <Link to={`/careers${item.fields.slug}`} className={styles.job} key={item.id}>
                            <div>
                                <p className={styles.suptitle}>{item.category}</p>
                                <h3 className={styles.title}>{item.title}</h3>
                                <p className={styles.description}>{item.description}</p>
                                <div className={styles.jobInfoWrapper}>
                                    <div className={styles.jobInfo}>
                                        <StaticImage src="./images/careers-available-jobs-is-full-time.svg" alt="" />
                                        <p className={styles.jobInfoText}>
                                            {item.isFullTime ? "Full time" : "Part time"}
                                        </p>
                                    </div>
                                    <div className={styles.jobInfo}>
                                        <StaticImage src="./images/careers-available-jobs-location.svg" alt="" />
                                        <p className={styles.jobInfoText}>{item.location}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.jobNavigation}>
                                <p className={styles.jobNavigationText}>View job</p>
                                <StaticImage src="./images/careers-available-jobs-arrow-top.svg" alt="" />
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(CareersAvailableJobs, {
    name: "careersAvailableJobs",
    inputs: toMutable(inputs),
});
