import { FC, ReactNode, useState } from "react";
import closeIcon from "./images/ad-banner-wrapper-close-icon.svg";
import infoIcon from "./images/ad-banner-wrapper-info-icon.svg";
import * as styles from "./styles.module.scss";

const AdBannerWrapper: FC<{
    link: string;
    children: ReactNode;
    style?: React.CSSProperties;
    isCentralized?: boolean;
}> = ({ children, link, style, isCentralized = true }) => {
    const [isInfoExpanded, setInfoExpanded] = useState(false);
    const [iconPosition, setIconPosition] = useState(0);

    const expandInfo = () => {
        setInfoExpanded(!isInfoExpanded);
        setIconPosition(isInfoExpanded ? 0 : -78);
    };

    return (
        <div style={style} className={isCentralized ? styles.adBannerWrapperCentralized : styles.adBannerWrapper}>
            <div>
                <div
                    style={{ transform: `translateX(${iconPosition}px)` }}
                    onClick={expandInfo}
                    className={isCentralized ? styles.infoButtonIconCentralized : styles.infoButtonIcon}
                >
                    <img src={infoIcon} alt="info" />
                </div>
                <a href={link} className={isCentralized ? styles.closeButtonIconCentralized : styles.closeButtonIcon}>
                    <img src={closeIcon} alt="close" />
                </a>
            </div>
            {isInfoExpanded && (
                <p
                    className={`${styles.expandedInfo} ${
                        isInfoExpanded ? (isCentralized ? styles.expandCentralized : styles.expand) : ""
                    }`}
                >
                    Ads by AY
                </p>
            )}
            <a className={styles.content} href={link}>
                {children}
            </a>
        </div>
    );
};

export default AdBannerWrapper;
