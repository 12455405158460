import type { FC } from "react";
import type { PageProps } from "gatsby";
import NotFound404 from "components/blocks/404";
import Layout from "components/Layout";
import Seo from "components/seo";

const NotFoundPage = () => (
    <Layout bg="dark">
        <NotFound404 />
    </Layout>
);

export const Head: FC<PageProps> = ({ location }) => (
    <Seo title="404: Not Found" titleSuffixed canonical={location.pathname} />
);

export default NotFoundPage;
