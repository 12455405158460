import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import TransparentGradientButton from "components/TransparentGradientButton";
import type { SuccessStoriesData } from "types/common";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Check our Customers Success Stories",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "View All",
        required: true,
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/success-stories",
        required: true,
    },
] as const;

const SuccessStoriesPromote: BFC<typeof inputs> = ({ title, buttonText, linkTo }) => {
    const { allSuccessStories }: { allSuccessStories: { nodes: SuccessStoriesData[] } } = useStaticQuery(graphql`
        query successStories {
            allSuccessStories {
                nodes {
                    id
                    clientLogoHover
                    clientLogoFileExternal {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
                        }
                    }
                    video
                    pdf
                    relatedFeatures
                    sidebarImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(quality: 90)
                        }
                    }
                    title
                    description
                    category
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const stories: SuccessStoriesData[] = allSuccessStories.nodes;

    return (
        <div className="container">
            <div className={styles.header}>
                <h2 className={styles.title}>{title}</h2>
                <div className="d-none d-md-block">
                    <TransparentGradientButton to={linkTo} text={buttonText} bg="dark" bgColor="#1f0746" />
                </div>
            </div>

            <div className={styles.stories}>
                {stories.map(story => {
                    const image = getImage(story.clientLogoFileExternal);

                    return (
                        <div className={`${styles.story} mb-5`} key={story.id}>
                            <Link to={`/success-stories${story.fields.slug}`} className={styles.storyLink}>
                                <div className={styles.client_story}>
                                    <GatsbyImage image={image!} alt="Success stories item" className="img-fluid" />
                                    <div className={styles.hover_wrapper}>
                                        <div className={styles.hover_logo_wrapper}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${story.clientLogoHover})`,
                                                }}
                                                className={styles.hover_logo}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.linkWrapper}>
                                    <p className={`text-white ${styles.storyTitle}`}>{story.title}</p>
                                    <StaticImage src="./images/success-stories-promote-arrow.svg" alt="arrow" />
                                </div>
                            </Link>
                        </div>
                    );
                })}
            </div>
            <div className="d-block d-md-none">
                <CustomButton to={linkTo}>{buttonText}</CustomButton>
            </div>
        </div>
    );
};

Builder.registerComponent(SuccessStoriesPromote, {
    name: "successStoriesPromote",
    inputs: toMutable(inputs),
});
