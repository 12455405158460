import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Let's win this new game?",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Incredibly high RPS (thousands per core), low memory consumption (400 MB) and fast response times (microseconds).",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a call",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
] as const;
const TrafficShapingWinGame: BFC<typeof inputs> = ({ title, subtitle, buttonTo, buttonText }) => {
    return (
        <div className="component-wrapper">
            <div className="container">
                <div className="row justify-content-center mb-5 text-center">
                    <div className="col-12 col-lg-9">
                        <h2>{title}</h2>
                        <p className="mb-3">{subtitle}</p>
                        <CustomButton buttonSize="btn--large" buttonStyle={"btn--blue-pink"} to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(TrafficShapingWinGame, {
    name: "TrafficShapingWinGame",
    inputs: toMutable(inputs),
});
