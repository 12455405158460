import { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import type { TermsData } from "types/common";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [] as const;

export const AyGlossaryKnowledgeDesk = () => {
    const data = useStaticQuery(graphql`
        query terms {
            allTerms {
                nodes {
                    id
                    title
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    type TCopyObj = { [key: string]: { name: string; link: string }[] };

    const [selectedLetter, setSelectedLetter] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
    const nodes: TermsData[] = data.allTerms.nodes;

    const knowledgeDesk = nodes.reduce((acc, node) => {
        const letter = node.title[0];

        if (!acc[letter]) {
            acc[letter] = [];
        }

        acc[letter].push({
            name: node.title,
            link: `/ay-glossary${node.fields.slug}`,
        });

        const sorted = Object.keys(acc)
            .sort()
            .reduce(function (obj: TCopyObj, key) {
                obj[key] = acc[key];

                return obj;
            }, {});

        return sorted;
    }, {} as TCopyObj);

    return (
        <>
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-first-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-second-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.thirdGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-third-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.sixthGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-sixth-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.seventhGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-seventh-glow.svg" alt="glow" />
            </div>
            <div className="container">
                <h1 className={styles.title}>AY Glossary - Knowledge Desk</h1>
                <p className={styles.subtitle}>
                    Browse our knowledge desk for deep-dive industry knowledge on common terminologies you will come
                    across on our tools.
                </p>
                <div className={styles.searchBarWrapper}>
                    <div className={styles.searchIcon}>
                        <StaticImage src="./images/ay-glossary-loop.svg" alt="search" />
                    </div>
                    <input
                        placeholder="Search all help and learning resources"
                        className={styles.searchBar}
                        onBlur={e => setSearchValue(e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.alphabetWrapper}>
                <div className="container">
                    <div className={`d-flex ${styles.selectWrapper} d-lg-none container`}>
                        <label className={styles.selectLabel} htmlFor="letter-select">
                            Filter
                        </label>
                        <select
                            id="letter-select"
                            value={selectedLetter}
                            onChange={e => setSelectedLetter(e.target.value)}
                            className={styles.alphabetSelect}
                        >
                            <option value="">All</option>
                            {alphabet.map(letter => (
                                <option key={letter} value={letter}>
                                    {letter}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className={`d-none d-lg-flex ${styles.lettersWrapper}`}>
                        {alphabet.map(letter => (
                            <AnchorLink to={`#letter-${letter}`} key={letter} className={styles.alphabetLetter}>
                                {letter}
                            </AnchorLink>
                        ))}
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={styles.knowledgeDeskWrapper}>
                    {Object.keys(
                        selectedLetter
                            ? { [selectedLetter]: knowledgeDesk[selectedLetter] }
                            : searchValue
                              ? {
                                    [searchValue[0].toUpperCase()]: knowledgeDesk[searchValue[0].toUpperCase()],
                                }
                              : knowledgeDesk
                    )?.map(letter => {
                        if (!knowledgeDesk[selectedLetter || letter]) {
                            return (
                                <h2 key={letter} className="text-center">
                                    No content yet
                                </h2>
                            );
                        }

                        return (
                            <div className={styles.knowledgeDeskLetter} id={`letter-${letter}`} key={letter}>
                                <p className={styles.knowledgeDeskLetterTitle}>{letter}</p>
                                <div className={styles.knowledgeDeskLetterContent}>
                                    {knowledgeDesk[selectedLetter || letter]
                                        .filter(
                                            ({ name }) =>
                                                !searchValue || name.toLowerCase().includes(searchValue.toLowerCase())
                                        )
                                        .map(({ name, link }) => (
                                            <Link
                                                key={name}
                                                to={link}
                                                className={styles.knowledgeDeskLetterItemWrapper}
                                            >
                                                <p className="mb-0">{name}</p>
                                                <div className={styles.knowledgeDeskLetterArrow}>
                                                    <StaticImage
                                                        src="./images/ay-glossary-right-arrow.svg"
                                                        alt="arrow"
                                                    />
                                                </div>
                                            </Link>
                                        ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(AyGlossaryKnowledgeDesk, {
    name: "ayGlossaryKnowledgeDesk",
    inputs: toMutable(inputs),
});
