import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Our Vision",
    },
    {
        name: "textParagraphs",
        type: "list",
        defaultValue: [
            {
                paragraph:
                    "Assertive Yield solves big data challenges by collecting and analyzing data in every timezone with effective and cutting edge tech solutions so that AdOperations, Media Buying, Sales, Content, Editorial, and Product teams can explore new revenue opportunities while focusing on the growth of their core business. We aim to become the eye for data enabling publishers and SSPs to make the best customer experience for their audience and to boost their revenue earnings.",
            },
            {
                paragraph:
                    "Our vision is to enable a new game in the Publishing Technology Industry and empower industry experts to break out of their old practices to new innovations. AY brings a huge change in advertising data from relying on 3rd data silos integration to an all-in-one 1st party data solution, from data obscurity to enormous granular data transparency providing cross-analysis across over 100 dimensions and metrics.",
            },
        ],
        subFields: [
            {
                name: "paragraph",
                type: "text",
            },
        ],
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const AboutUsOurVision: BFC<typeof inputs> = ({
    title,
    textParagraphs,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/about-us-our-vision-first-glow.svg" alt="" />
            </div>
            <div className="row">
                <div className={`${styles.image} col-md-12 col-lg-6 mx-auto`}>
                    <div className="position-relative">
                        {imageOrGifHover ? (
                            <img
                                onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                src={imageOrGif}
                                alt={imageOrGifAlt}
                                className="img-fluid"
                            />
                        ) : (
                            <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                        )}
                        {switchMagnifier && !imageOrGifHover && (
                            <Gallery>
                                <Item
                                    width={imageOrGifWidth}
                                    height={imageOrGifHeight}
                                    original={imageOrGif}
                                    alt={imageOrGifAlt}
                                >
                                    {({ ref, open }) => (
                                        // @ts-expect-error
                                        <div className="image_mask" ref={ref} onClick={open}>
                                            <img src={glass} alt="zoom image" />
                                        </div>
                                    )}
                                </Item>
                            </Gallery>
                        )}
                    </div>
                </div>
                <div className={`${styles.mainContent} col-md-12 col-lg-6`}>
                    <h2 className={styles.title}>{title}</h2>
                    {textParagraphs?.map(({ paragraph }, index: number) => (
                        <p key={index} className={styles.paragraph}>
                            {paragraph}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsOurVision, {
    name: "aboutUsOurVision",
    inputs: toMutable(inputs),
});
