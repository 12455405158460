import { uniq } from "lodash";
import type { SavedReport } from "../codecs/saved-report.codecs";
import { includesPlacements, type ReportPlacementKeyType } from "../constants/placement.constants";
import { PLACEMENTS_METRIC_CONFIG } from "./all";
import {
    type AllPossibleMetricsMap,
    CalculationType,
    type MainMetricsType,
    type MetricCalculationFormula,
    OptimizationObjective,
    type RawMetricsType,
} from "./types";

export const formatIntOrFloat = (d: any, decimalPlaces: number = 2) => {
    if (d == null) {
        return null;
    }
    if (Number.isNaN(d)) {
        return null;
    }
    if (!Number.isFinite(d)) {
        return null;
    }
    return Number.isInteger(d)
        ? d.toLocaleString()
        : parseFloat(d).toLocaleString(undefined, {
              minimumFractionDigits: decimalPlaces,
              maximumFractionDigits: decimalPlaces,
          });
};

export namespace MetricsService {
    export const getOptimizationObjective = (
        placement: ReportPlacementKeyType,
        key: MainMetricsType
    ): OptimizationObjective => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.objective ?? OptimizationObjective.NoObjective;
    };

    export const getCalculationType = (placement: ReportPlacementKeyType, key: MainMetricsType): CalculationType => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.calculationType ?? CalculationType.Sum;
    };
    export const getIssStats = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.issStats;
    };

    export const getAlertConfigSpecial = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.config;
    };

    export const getHideFromAlerts = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.hideFromAlerts;
    };

    export const getTimespan = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.timeSpan;
    };
    export const getFormula = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.formula;
    };

    export const getFormulaDependencies = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.formulaDependencies;
    };

    export const getMetricLabel = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.label ?? `~${key}~`;
    };

    export const getKeepFormulaFalsyAsFalsy = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.keepFormulaFalsyAsFalsy ?? false;
    };

    export const getMetricCalculationFormulaObject = (
        placement: ReportPlacementKeyType,
        key: MainMetricsType
    ): MetricCalculationFormula => {
        const formula = getFormula(placement, key) ?? (() => null);
        const placementMetrics = getCalculatedMetricsByPlacement(placement);

        return {
            formula,
            keepFormulaFalsyAsFalsy: getKeepFormulaFalsyAsFalsy(placement, key),
            id: key,
            rawDependencies: placementMetrics[key]?.rawDependencies ?? [],
            formulaDependencies: getFormulaDependencies(placement, key) ?? [],
        };
    };

    export const getShareOfVoiceChildren = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.shareOfVoiceChildren;
    };

    export const getHideFromApiDocs = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.hideFromApiDocs;
    };

    const getCalculatedMetricsByPlacement = (placement: ReportPlacementKeyType): AllPossibleMetricsMap => {
        return PLACEMENTS_METRIC_CONFIG[placement].metrics;
    };

    export const getCalculatedMetricsIdsByPlacement = (placement: ReportPlacementKeyType): MainMetricsType[] => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return Object.keys(placementMetrics) as MainMetricsType[];
    };

    export const getAlertMetrics = (placement: ReportPlacementKeyType): MainMetricsType[] => {
        return getCalculatedMetricsIdsByPlacement(placement).filter(key => !getHideFromAlerts(placement, key));
    };

    export const isCalculatedMetricKey = (placement: ReportPlacementKeyType, key: string): key is MainMetricsType => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return (placementMetrics as any)[key] != null;
    };

    export const getMetricRawDependencies = (
        placement: ReportPlacementKeyType,
        metrics: readonly MainMetricsType[]
    ): RawMetricsType[] => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        const dependencies: RawMetricsType[] = metrics.flatMap(
            metric => placementMetrics[metric]?.rawDependencies ?? []
        );
        return uniq(dependencies).sort((a, b) => a.localeCompare(b));
    };
    export const getMetricsFromSavedReport = (savedReport: SavedReport | null): MainMetricsType[] | null =>
        savedReport?.metrics ?? null;

    export const shouldUseInitialMetrics = (placement: ReportPlacementKeyType) =>
        includesPlacements(["industry", "ad_revenue"], placement);

    export const isDFPConnectionRequired = (selectedMetrics: MainMetricsType[]) =>
        selectedMetrics.some(metric => metric.startsWith("dynamicAllocation"));

    export const getLabel = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.label ?? `~${key}~`;
    };
    export const isSumKey = (placement: ReportPlacementKeyType, key: MainMetricsType): boolean => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.calculationType === CalculationType.Sum;
    };
    export const getExplanation = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.explanation;
    };
    export const getDecimalPlaces = (placement: ReportPlacementKeyType, key: MainMetricsType) => {
        const placementMetrics = getCalculatedMetricsByPlacement(placement);
        return placementMetrics[key]?.decimalPlaces ?? 2;
    };

    export const formatMetric = (
        placement: ReportPlacementKeyType,
        key: MainMetricsType,
        value: number | undefined | null | string
    ): string | null => {
        const decimalPlaces = getDecimalPlaces(placement, key);
        return formatIntOrFloat(value, decimalPlaces);
    };
}
