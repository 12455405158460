import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Turn Weeks into Minutes",
        required: true,
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Schedule a demonstration with our experts",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Start for free",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
] as const;

const YieldManagerCTA: BFC<typeof inputs> = ({ title, subtitle, buttonTo, buttonText }) => {
    return (
        <div className={`component-wrapper`}>
            <div className="container">
                <div className="row justify-content-center text-lg-center">
                    <div className="col-12 col-lg-7">
                        <h2 className="mt-3 mb-3">{title}</h2>
                        <p>{subtitle}</p>
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <div className="col-12 col-lg-auto text-lg-center mt-lg-0 ">
                        <CustomButton buttonSize="btn--large" buttonStyle="btn--yellow-red" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(YieldManagerCTA, {
    name: "YieldManagerCTA",
    inputs: toMutable(inputs),
});
