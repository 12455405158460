import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Why AY Traffic Shaping for Publishers?",
    },
    {
        name: "items",
        type: "list",
        subFields: [
            { name: "description", type: "longText" },
            { name: "image", type: "file", allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"] },
        ],
    },
] as const;

const trafficShapingForPubsWhy: BFC<typeof inputs> = ({ title, items }) => {
    return (
        <div className="component-wrapper">
            <div className="container">
                <h2 className={styles.title}>{title}</h2>

                <div className={styles.cards}>
                    {items?.map((item, index) => (
                        <div className={styles.card} key={index}>
                            <div className={styles.cardImage}>
                                <img src={item.image} alt="" />
                            </div>
                            <div>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(trafficShapingForPubsWhy, {
    name: "trafficShapingForPubsWhy",
    inputs: toMutable(inputs),
});
