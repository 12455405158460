import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import CustomButton from "components/CustomButton";
import HrHeading from "components/HrHeading";
import playButton from "img/play_button.svg";
import * as styles from "./styles.module.scss";

const Videos = () => {
    const data = useStaticQuery(graphql`
        query allNewVideos {
            allNewVideo(sort: { date: ASC }) {
                nodes {
                    title
                    videosList {
                        title
                        link
                        featuredImage
                    }
                }
            }
        }
    `);

    const firstVideoGroup = data.allNewVideo.nodes[0];
    const secondVideoGroup = data.allNewVideo.nodes[1];

    if (firstVideoGroup == null) {
        return <></>;
    }

    return (
        <div
            style={{
                paddingTop: 0,
            }}
            className={[styles.container_wrapper].join(" ")}
        >
            <Container className="py-5">
                <HrHeading
                    heading="AY Videos"
                    buttonText="All Videos"
                    buttonTo="https://www.youtube.com/@assertiveyield"
                    bg="dark"
                    bgColor="#000"
                    hideButtonOnMobile={true}
                />
                <p className={styles.groupTitle}>{firstVideoGroup.title}</p>
                <div className={styles.videosWrapper}>
                    <div className={styles.videosGroup}>
                        <a
                            href={firstVideoGroup.videosList[0].link}
                            className={[styles.no_text_decoration, styles.card].join(" ")}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className={[styles.video_thumb_wrapper, "mb-4"].join(" ")}>
                                <img
                                    className={styles.videoPreview}
                                    src={firstVideoGroup.videosList[0].featuredImage}
                                    alt="video image"
                                />
                                <img src={playButton} className={styles.play_button_overlay} />
                            </div>
                            <div className={[styles.video_title, "figma-h3"].join(" ")}>
                                {firstVideoGroup.videosList[0].title}
                            </div>
                        </a>

                        <a
                            href={firstVideoGroup.videosList[1].link}
                            className={[styles.no_text_decoration, styles.card].join(" ")}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className={[styles.video_thumb_wrapper, "mb-4"].join(" ")}>
                                <img
                                    className={styles.videoPreview}
                                    src={firstVideoGroup.videosList[1].featuredImage}
                                    alt="video image"
                                />
                                <img src={playButton} className={styles.play_button_overlay} />
                            </div>
                            <div className={[styles.video_title, "figma-h3"].join(" ")}>
                                {firstVideoGroup.videosList[1].title}
                            </div>
                        </a>
                    </div>
                </div>

                <p className={styles.secondGroupTitle}>{secondVideoGroup.title}</p>
                <div className={styles.videosWrapper}>
                    <div className={styles.videosGroup}>
                        <a
                            href={secondVideoGroup.videosList[0].link}
                            className={[styles.no_text_decoration, styles.card].join(" ")}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className={[styles.video_thumb_wrapper, "mb-4"].join(" ")}>
                                <img
                                    className={styles.videoPreview}
                                    src={secondVideoGroup.videosList[0].featuredImage}
                                    alt="video image"
                                />
                                <img src={playButton} className={styles.play_button_overlay} />
                            </div>
                            <div className={[styles.video_title, "figma-h3"].join(" ")}>
                                {secondVideoGroup.videosList[0].title}
                            </div>
                        </a>

                        <a
                            href={secondVideoGroup.videosList[1].link}
                            className={[styles.no_text_decoration, styles.card].join(" ")}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className={[styles.video_thumb_wrapper, "mb-4"].join(" ")}>
                                <img
                                    className={styles.videoPreview}
                                    src={secondVideoGroup.videosList[1].featuredImage}
                                    alt="video image"
                                />
                                <img src={playButton} className={styles.play_button_overlay} />
                            </div>
                            <div className={[styles.video_title, "figma-h3"].join(" ")}>
                                {secondVideoGroup.videosList[1].title}
                            </div>
                        </a>
                    </div>
                </div>

                <div className="mx-auto d-flex d-lg-none justify-content-center mt-4 mb-4">
                    <CustomButton customWidth="300px" to="https://www.youtube.com/@assertiveyield">
                        All Videos
                    </CustomButton>
                </div>
            </Container>
        </div>
    );
};

export default Videos;
