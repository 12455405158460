import * as React from "react";
import TransparentGradientButton from "components/TransparentGradientButton";
import type { Bg } from "types/common";
import * as styles from "./HrHeading.module.scss";

const HrHeading: React.FC<{
    headingAs?: string;
    heading: string;
    buttonText: string;
    buttonTo: string;
    bg: Bg;
    bgColor: string;
    width?: string;
    marginBottom?: string;
    hideButtonOnMobile?: boolean;
}> = ({
    heading,
    buttonText,
    buttonTo,
    bg,
    bgColor,
    headingAs = "h4",
    width = "auto",
    marginBottom = "18px",
    hideButtonOnMobile = false,
}) => {
    const headingComponent = React.createElement(
        headingAs,
        {
            style: {
                marginBottom,
            },
            className: [styles.newH4, bg === "light" ? styles.text_dark : styles.text_light].join(" "),
        },
        heading
    );
    return (
        <>
            <div className={styles.row}>
                {headingComponent}
                <div className={hideButtonOnMobile ? "d-none d-lg-block" : ""}>
                    <TransparentGradientButton
                        to={buttonTo}
                        text={buttonText}
                        bg={bg}
                        bgColor={bgColor}
                        width={width}
                    />
                </div>
            </div>
            <hr className={[bg === "light" ? styles.hr_light_bg : styles.hr_dark_bg, "mt-2"].join(" ")} />
        </>
    );
};

export default HrHeading;
