import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "It’s time to win the game",
        required: true,
    },
    {
        name: "description",
        type: "text",
        defaultValue:
            "Essential for premium Publishers, SSPs, Networks who seek to disrupt their operations through accurate 1st party data with incredible transparency in real-time.",
    },
    {
        name: "description2",
        type: "text",
        defaultValue: "You don’t have to rely on big techs data anymore!",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a call",
        required: true,
    },
    {
        name: "videoId",
        type: "text",
        defaultValue: "wk95wBopKhI",
    },
] as const;
const trafficShapingWinGame: BFC<typeof inputs> = ({
    title,
    description,
    description2,
    buttonTo,
    buttonText,
    videoId,
}) => {
    const { desktop, mobile } = useStaticQuery(graphql`
        query {
            mobile: file(relativePath: { eq: "yiglobe.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            desktop: file(relativePath: { eq: "yi.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const imageDesktop = getImage(desktop);
    const imageMobile = getImage(mobile);
    return (
        <div className="content-dark">
            <div style={{ display: "grid" }} className={`${styles.products_video_height} component-wrapper pb-0`}>
                <GatsbyImage
                    className="d-lg-none"
                    style={{
                        gridArea: "1/1",
                        zIndex: -1,
                    }}
                    alt=""
                    image={imageMobile!}
                    objectPosition="50% bottom"
                />

                <GatsbyImage
                    className="d-none d-lg-block"
                    style={{
                        gridArea: "1/1",
                        zIndex: -1,
                    }}
                    alt=""
                    image={imageDesktop!}
                />
                <div
                    className="container h-100"
                    style={{
                        gridArea: "1/1",
                    }}
                >
                    <div className="row align-items-center h-100">
                        <div className="col-12 col-lg-6 order-lg-1 order-2">
                            <h2 className="mt-3 mb-3">{title}</h2>
                            <p>{description}</p>
                            <p className="mb-5">{description2}</p>
                            <CustomButton buttonStyle="btn--primary" to={buttonTo}>
                                {buttonText}
                            </CustomButton>
                        </div>

                        {videoId ? (
                            <div className="col-12 order-lg-2 order-1 col-lg-6 mb-lg-0 mb-4">
                                <div className="d-flex justify-content-center">
                                    <div className="w-100">
                                        <iframe
                                            title="Old Game vs New Game"
                                            loading="lazy"
                                            className="embed-responsive-item-block"
                                            src={`https://www.youtube.com/embed/${videoId}`}
                                            frameBorder="0"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(trafficShapingWinGame, {
    name: "trafficShapingWinGame",
    inputs: toMutable(inputs),
});
