import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import templatesImage from "./images/templates.png";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Add Sub text",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Templates",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue: "Start with a template and customize data to your system preference",
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Learn more",
        required: true,
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
] as const;
const IndustryInsightsTemplates: BFC<typeof inputs> = ({ suptitle, title, subtitle, linkTo, buttonText }) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle}</p>
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                            <CustomButton buttonStyle="btn--primary" to={linkTo}>
                                {buttonText}
                            </CustomButton>
                        </div>

                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                <StaticImage src="./images/templates.png" alt="" className="img-fluid " />

                                <Gallery>
                                    <Item original={templatesImage} width="1161" height="714">
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(IndustryInsightsTemplates, {
    name: "industryInsightsTemplates",
    inputs: toMutable(inputs),
});
