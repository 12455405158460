import { useState } from "react";
import Select from "react-select";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import DebouncedInput from "components/debounceInput";
import { TAcademy, toMutable } from "types/common";
import arrowRight from "./images/academy-posts-arrow-right.svg";
import arrowUp from "./images/academy-posts-arrow-up.svg";
import dot from "./images/academy-posts-dot.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "topics",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "AY Academy",
            },
        ],
    },
] as const;

const colorStyles = {
    option: (styles: any, { isFocused }: { isFocused: boolean }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#F9FAFB" : null,
            color: "#000",
        };
    },
};

const AcademyPosts: BFC<typeof inputs> = ({ topics }) => {
    const [searchValue, setSearchValue] = useState("");
    const [selectedTopic, setSelectedTopic] = useState<string | undefined>("View all");
    const data = useStaticQuery(graphql`
        query academy {
            allAcademy {
                nodes {
                    title
                    description
                    author
                    duration
                    amountOfLectures
                    level
                    link
                    linkText
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    featuredImage
                    topic
                }
            }
        }
    `);

    const academyData: TAcademy[] = data.allAcademy.nodes;

    const searchData = academyData.reduce((acc: { [key: string]: TAcademy[] }, academy) => {
        if (!acc[academy.topic]) {
            acc[academy.topic] = [];
        }

        acc[academy.topic].push({
            title: academy.title,
            description: academy.description,
            link: academy.link,
            author: academy.author,
            duration: academy.duration,
            amountOfLectures: academy.amountOfLectures,
            level: academy.level,
            linkText: academy.linkText,
            featuredImage: academy.featuredImage,
            featuredImageFileExternal: academy.featuredImageFileExternal,
            topic: academy.topic,
        });

        return acc;
    }, {});

    const filteredData = Object.entries(searchData).reduce(
        (result: { [key: string]: TAcademy[] }, [category, items]) => {
            const filteredItems = items.filter(
                item =>
                    item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
                    item.description.toLowerCase().includes(searchValue.toLowerCase())
            );

            if (selectedTopic !== "View all") {
                result[category] = filteredItems.filter(item =>
                    topics?.map(topic => topic.title?.includes(item.title))
                );
            } else if (filteredItems.length) {
                result[category] = filteredItems;
            }

            return result;
        },
        {}
    );

    const hasResults = Object.keys(filteredData).length > 0;

    return (
        <div className={styles.wrapper}>
            <div className="container">
                <div className={styles.journeyWrapper}>
                    <div className={styles.journeyRoot}>
                        <Link className={styles.route} to="/help-center">
                            Help Center
                        </Link>
                    </div>
                    <div className={styles.journeyArrowRight}>
                        <img src={arrowRight} alt="" />
                    </div>
                    <div className={styles.journey}>
                        <p className={styles.currentRoute}>AY Academy</p>
                    </div>
                </div>

                <div className={styles.mainContent}>
                    <Select
                        options={topics?.map(topic => ({ value: topic.title, label: topic.title }))}
                        isSearchable={false}
                        defaultValue={topics?.map(topic => ({ value: topic.title, label: topic.title }))[0]}
                        className={`mt-1 mb-5 ${styles.select}`}
                        onChange={value => setSelectedTopic(value?.value)}
                        components={{
                            IndicatorSeparator: () => null,
                        }}
                        styles={colorStyles}
                    />

                    <div className={styles.sidebar}>
                        <div className={styles.searchBarContainer}>
                            <div className={styles.searchBarWrapper}>
                                <DebouncedInput
                                    placeholder="Search"
                                    className={styles.searchBar}
                                    debounceDelay={500}
                                    value={searchValue}
                                    onChange={value => setSearchValue(String(value))}
                                />
                            </div>
                            {searchValue.length > 0 && hasResults && (
                                <div className={styles.dropDownWrapper}>
                                    {Object.entries(filteredData).map(([category, items]) => {
                                        return items?.map((item, index) => (
                                            <Link key={index} to={`${item.link}`} className={styles.dropDownItem}>
                                                <span className={styles.dropDownTitle}>{item.title}</span>
                                                <span className={styles.dropDownCategory}>{category}</span>
                                            </Link>
                                        ));
                                    })}
                                </div>
                            )}
                            {!hasResults && searchValue.length > 0 && (
                                <div className={styles.dropDownItem}>
                                    <span className={styles.dropDownTitle}>No result found</span>
                                    <span className={styles.dropDownCategory}>
                                        Try a different search term or select any of the options below other resources
                                    </span>
                                </div>
                            )}
                        </div>

                        <div>
                            <p className={styles.topicsTitle}>Topics</p>
                            <div>
                                {topics?.map((topic, index) => (
                                    <p
                                        key={index}
                                        className={[
                                            styles.topic,
                                            selectedTopic === topic.title ? styles.topicActive : "",
                                        ].join(" ")}
                                        onClick={() => setSelectedTopic(topic.title)}
                                    >
                                        {topic.title}
                                    </p>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={styles.content}>
                        <div>
                            {Object.entries(filteredData).map(([_, items]) => {
                                return items?.map((item, index) => {
                                    if (!item.topic.includes(selectedTopic!) && selectedTopic !== "View all") {
                                        return null;
                                    }

                                    let image;

                                    if (
                                        (item.featuredImageFileExternal as any).childImageSharp === null &&
                                        (item.featuredImage as any).includes(".gif")
                                    ) {
                                        image = item.featuredImage;
                                    } else {
                                        image = getImage(item.featuredImageFileExternal);
                                    }

                                    return (
                                        <a
                                            href={item.link}
                                            className={styles.postWrapper}
                                            key={index}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <div className={styles.post}>
                                                <div className={styles.postImage}>
                                                    {typeof image === "string" ? (
                                                        <img className="card-img" src={image} alt="" loading="lazy" />
                                                    ) : (
                                                        <GatsbyImage image={image!} alt={item.title} />
                                                    )}
                                                </div>
                                                <div className={styles.postContent}>
                                                    <p className={styles.postTitle}>{item.title}</p>
                                                    <p className={styles.postDescription}>{item.description}</p>
                                                    <p className={styles.postAuthor}>{item.author}</p>
                                                    <div className={styles.postInfo}>
                                                        <p className={styles.postInfoItem}>{item.duration}</p>
                                                        <div className={styles.postInfoItem}>
                                                            <img src={dot} alt="" />
                                                        </div>
                                                        <p className={styles.postInfoItem}>{item.amountOfLectures}</p>
                                                        <div className={styles.postInfoItem}>
                                                            <img src={dot} alt="" />
                                                        </div>
                                                        <p className={styles.postInfoItem}>{item.level}</p>
                                                    </div>
                                                    <div className={styles.postLink}>
                                                        <p className={styles.postLinkText}>{item.linkText}</p>
                                                        <img src={arrowUp} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                });
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AcademyPosts, {
    name: "academyPosts",
    inputs: toMutable(inputs),
});
