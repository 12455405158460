import { Builder } from "@builder.io/react";
import ProductsHero from "components/blocks/hero/ProductsHero";
import { SIGNUP_LINK } from "components/constants";
import { toMutable } from "types/common";
import icon from "./images/icon.svg";
import left from "./images/left.svg";

const inputs = [
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "buttonTitle",
        type: "text",
        defaultValue: "Start for free",
        required: true,
    },
    {
        name: "iconTitle",
        type: "text",
        defaultValue: "AI Layouts",
        required: true,
    },
    {
        name: "description",
        type: "longText",
        defaultValue: "Real-time, normalized data combining revenue and spend",
    },
    {
        name: "heading",
        type: "text",
        defaultValue: "Scale with data in mind",
        required: true,
    },
    {
        name: "showNoteWithLinkTrafficAcquisition",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                label: "Yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
        required: true,
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
] as const;

const AyPrebidProductHero: BFC<typeof inputs> = ({
    iconTitle,
    heading,
    description,
    buttonTo,
    buttonTitle,
    showNoteWithLinkTrafficAcquisition,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
}) => (
    <ProductsHero
        heading={heading}
        description={description ?? ""}
        buttonTo={buttonTo}
        buttonTitle={buttonTitle}
        heroimage={imageOrGif}
        heroimageHover={imageOrGifHover}
        heroimageAlt={imageOrGifAlt}
        showForm={false}
        buttonStyle="btn--yellow-red"
        icon={icon}
        iconTitle={iconTitle}
        gradient={{
            right: "",
            rightTop: "100px",
            rightBottom: "",
            left: left,
            leftTop: "0px",
            leftBottom: "",
        }}
        showNoteWithLinkTrafficAcquisition={showNoteWithLinkTrafficAcquisition}
    />
);

Builder.registerComponent(AyPrebidProductHero, {
    name: "AyPrebidProductHero",
    inputs: toMutable(inputs),
});
