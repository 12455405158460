import animationbackground from "./animationbackground.svg";
import assets1 from "./assets1.svg";
import { colorAdd, colorToAutoGradient, colorToRgb, colorToRgba, IColor } from "./color";
import { DIMENSIONS, EXCHANGES, METRICS, MovingDataColor } from "./data";
import { ArrowBackIcon, ArrowForwardIcon } from "./icons";
import { AyGreenLine } from "./icons";
import website from "./website.svg";
import * as styles from "./styles.module.scss";

const TOTAL_HEIGHT = 512;
const WEBSITE_BALL_SIZE = 64;
const AY_BALL_SIZE = 96;

const movingArrowsCommonStyle: React.CSSProperties = {
    position: "absolute",
    left: "32%",
    zIndex: 5,
};
const MovingArrows: React.FunctionComponent<{
    color: IColor;
    translate: number;
}> = ({ color, translate }) => {
    const translateMovingArrows: React.CSSProperties = {
        transform: `translate(${translate}px, 0px)`,
    };
    return (
        <>
            <div
                className={styles.arrow_back}
                style={{
                    ...movingArrowsCommonStyle,
                    top: TOTAL_HEIGHT / 2 - WEBSITE_BALL_SIZE / 4 - 12,
                }}
            >
                <div style={translateMovingArrows}>
                    <ArrowBackIcon size={32} color={color} />
                </div>
            </div>
            <div
                className={styles.arrow_forward}
                style={{
                    ...movingArrowsCommonStyle,
                    top: TOTAL_HEIGHT / 2 - WEBSITE_BALL_SIZE / 4 + 12,
                }}
            >
                <div style={translateMovingArrows}>
                    <ArrowForwardIcon size={32} color={color} />
                </div>
            </div>
        </>
    );
};

const BusinessBall: React.FunctionComponent<{
    color: IColor;
    translate: number;
}> = ({ color, translate }) => {
    return (
        <div
            style={{
                position: "absolute",
                left: "30%",
                top: TOTAL_HEIGHT / 2 - WEBSITE_BALL_SIZE / 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                zIndex: 5,
                transform: `translate(${translate}px, 0px)`,
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: WEBSITE_BALL_SIZE,
                    width: WEBSITE_BALL_SIZE,
                }}
            >
                <img src={website} alt="" className="img-fluid" />
            </div>
            <span
                style={{
                    color: colorToRgb(color),
                    marginTop: "18px",
                }}
            >
                WEBSITE
            </span>
        </div>
    );
};

const HalfCircleMask: React.FunctionComponent = () => {
    return (
        <div
            style={{
                height: TOTAL_HEIGHT,
                width: TOTAL_HEIGHT / 2,
                position: "absolute",
                left: "26%",
                zIndex: 2,
                transform: `translate(${-AY_BALL_SIZE / 2}px, 0px)`,
            }}
        >
            <InRealTime color={MovingDataColor.redBlinking} background={MovingDataColor.background} />
            <img loading="lazy" src={assets1} alt="Mask over items" height="512" />
        </div>
    );
};

const random = (seed: number) => {
    const x = Math.sin(seed) * 10000;
    return x - Math.floor(x);
};

const getTranslateX = ({ idx, row, total }: { idx: number; row: number; total: number }): number => {
    const MAX = TOTAL_HEIGHT * 5;
    const randomX = random((idx + 1) * (row + 1));
    return (MAX / total) * idx + randomX * 64;
};

const getTranslateY = ({
    idx,
    row,
}: // total,
{
    idx: number;
    row: number;
    total: number;
}): number => {
    const randomY = random(1 / ((idx + 1) * (row + 1)));

    return 128 - 32 + row * 128 + randomY * 32;
};
const MovingItem: React.FunctionComponent<{
    value: string;
    idx: number;
    color: IColor;
    row: number;
    total: number;
}> = ({ value, idx, row, total }) => {
    return (
        <div className={(idx + 1 + (row + 1)) % 2 === 0 ? styles.random_acceleration : ""}>
            <div
                style={{
                    // backgroundColor: colorToRgb(color),
                    // backgroundImage: colorToAutoGradient(180 - 45, -35)(color),
                    background: "linear-gradient(214.98deg, #15002B 5.95%, #460085 94.83%)",
                    padding: "8px 16px",
                    color: "white",
                    borderRadius: 64,
                    fontWeight: 700,
                    position: "absolute",
                    transform: `translate(${getTranslateX({
                        idx,
                        row,
                        total,
                    })}px, ${getTranslateY({ idx, row, total })}px)`,
                }}
                className="text-nowrap"
            >
                {value}
            </div>
        </div>
    );
};

const MovingItems: React.FunctionComponent<{ hideOverflow: boolean }> = ({ hideOverflow }) => {
    return (
        <div
            className="moving_container"
            style={{
                position: "absolute",
                left: "33%",
                transform: `translate(${-WEBSITE_BALL_SIZE}px, 0px)`,
                zIndex: 1,
                height: "467px",
                width: "100%",
                overflow: hideOverflow ? "hidden" : undefined,
                background: `url(${animationbackground})`,
                backgroundRepeat: "no-repeat",
                top: "20px",
                backgroundPosition: "left",
            }}
        >
            <div className={styles.items}>
                {EXCHANGES.map((v, idx) => (
                    <MovingItem
                        key={v + idx}
                        value={v}
                        idx={idx}
                        color={MovingDataColor.exchanges}
                        row={0}
                        total={EXCHANGES.length}
                    />
                ))}
                {METRICS.map((v, idx) => (
                    <MovingItem
                        key={v + idx}
                        value={v}
                        idx={idx}
                        color={MovingDataColor.metrics}
                        row={1}
                        total={METRICS.length}
                    />
                ))}
                {DIMENSIONS.map((v, idx) => (
                    <MovingItem
                        key={v + idx}
                        value={v}
                        idx={idx}
                        color={MovingDataColor.dimensions}
                        row={2}
                        total={DIMENSIONS.length}
                    />
                ))}
            </div>
        </div>
    );
};

const Subtitle: React.FunctionComponent<{
    text: string;
    color: IColor;
    top: number;
}> = ({ text, color, top }) => {
    return (
        <>
            <div
                className={text}
                style={{
                    position: "absolute",
                    top,
                    color: colorToRgba(0.75)(color),
                    zIndex: 99,
                    right: 0,
                    marginRight: 8,
                }}
            >
                {text}
            </div>
            <div
                className={(styles as any)[`line${text}`]}
                style={{
                    height: 2,
                    width: "100%",
                    // backgroundColor: colorToRgba(0.4)(color),
                    zIndex: 1,
                    top: top + 24,
                    position: "absolute",
                    left: "35%",
                    transform: `translate(${AY_BALL_SIZE / 2}px, 0px)`,
                    backgroundImage: "linear-gradient(270deg,#00f4be -.05%,rgba(0,244,190,0) 95.6%)",
                }}
            />
        </>
    );
};

const InRealTime: React.FunctionComponent<{
    color: IColor;
    background: IColor;
}> = ({ color }) => {
    return (
        <div
            style={{
                fontWeight: 700,
                fontSize: "0.75rem",
                position: "absolute",
                top: "79px",
                left: "22%",
                zIndex: 99,
                display: "flex",
                alignItems: "center",
                transform: `translate(8px, 0px)`,
                // textShadow: `0 0 10px ${colorToRgb(background)}`,
            }}
        >
            <div className={styles.inRealTimeWrapper}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "15px",
                    }}
                >
                    <div
                        className={styles.blinking_ball}
                        style={{
                            height: "0.75rem",
                            width: "0.75rem",
                            backgroundImage: colorToAutoGradient(-45, 25)(color),
                            // backgroundColor: colorToRgb(color),
                            borderRadius: "100%",
                            marginRight: 4,
                        }}
                    />
                    <span
                        style={{
                            color: colorToRgb(color),
                        }}
                    >
                        REAL-TIME
                    </span>
                </div>
                <div>
                    <AyGreenLine />
                </div>
            </div>
        </div>
    );
};

export const MovingData: React.FunctionComponent = () => {
    // logo: width and height must be AY_BALL_SIZE
    // mask: width = TOTAL_HEIGHT/2, height = TOTAL_HEIGHT

    const exchangeLineTop = 256 - 64 - 16;
    return (
        <section id="real-time" className={styles.responsive_container}>
            <div
                style={{
                    position: "relative",
                    height: TOTAL_HEIGHT,
                    overflow: "hidden",
                }}
            >
                {/* <RightGradient color={MovingDataColor.background} /> */}
                {/* <LeftMask color={MovingDataColor.background} /> */}

                <div className="d-sm-none">
                    <BusinessBall color={MovingDataColor.website} translate={-86} />
                    <MovingArrows color={colorAdd(-100)(MovingDataColor.website)} translate={-32} />
                </div>
                <div className="d-none d-sm-block">
                    <BusinessBall color={MovingDataColor.website} translate={-128 - 32} />
                    <MovingArrows color={MovingDataColor.website} translate={-96} />
                </div>

                {/* <AssertiveYieldBall logoFixed={data.logo} /> */}
                <HalfCircleMask />

                <Subtitle color={MovingDataColor.exchanges} top={exchangeLineTop} text="Exchanges" />
                <Subtitle color={MovingDataColor.metrics} top={exchangeLineTop + 128} text="Metrics" />
                <Subtitle color={MovingDataColor.dimensions} top={exchangeLineTop + 256} text="Dimensions" />
                <MovingItems hideOverflow={true} />
                {/* we repeat all items to make it loop perfectly */}
                <div
                    style={{
                        transform: "translate(2560px, 0px)",
                    }}
                >
                    <MovingItems hideOverflow={false} />
                </div>
            </div>
        </section>
    );
};
