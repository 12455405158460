import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "./constants";
import CustomButton from "./CustomButton";

Builder.registerComponent(CustomButton, {
    name: "CustomButton",
    inputs: [
        {
            name: "buttonStyle",
            type: "text",
            enum: [
                "btn--primary", //blue
                "btn--secondary",
                "btn--yellow-red",
                "btn--blue-pink",
                "btn--green-pink",
                "btn--test",
            ],
            defaultValue: "btn--primary",
        },
        {
            name: "buttonSize",
            type: "text",
            enum: ["btn--medium", "btn--large"],
            defaultValue: "btn--large",
        },
        {
            name: "to",
            type: "text",
            defaultValue: SIGNUP_LINK,
        },
        {
            name: "children",
            type: "text",
            defaultValue: "Try out a free demo",
        },
    ],
});
