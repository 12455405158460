// extracted by mini-css-extract-plugin
export var boldSubtitle = "styles-module--bold-subtitle--d01fb";
export var btn = "styles-module--btn--c47cb";
export var firstGlow = "styles-module--first-glow--14278";
export var image = "styles-module--image--a48f9";
export var mainContent = "styles-module--main-content--838c5";
export var note = "styles-module--note--c3c78";
export var secondGlow = "styles-module--second-glow--45671";
export var subtitle = "styles-module--subtitle--6629b";
export var suptitle = "styles-module--suptitle--16d8b";
export var title = "styles-module--title--f2556";
export var wrapper = "styles-module--wrapper--aa4bc";