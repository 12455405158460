// extracted by mini-css-extract-plugin
export var active = "styles-module--active--0e07b";
export var arrowUp = "styles-module--arrow-up--09298";
export var circleButton = "styles-module--circle-button--8b416";
export var currentRoute = "styles-module--current-route--b7f13";
export var description = "styles-module--description--95782";
export var journey = "styles-module--journey--324c8";
export var journeyArrowRight = "styles-module--journey-arrow-right--0d8e7";
export var journeyRoot = "styles-module--journey-root--64e36";
export var journeyWrapper = "styles-module--journey-wrapper--ca53d";
export var route = "styles-module--route--72782";
export var switcher = "styles-module--switcher--ac37c";
export var switcherPoint = "styles-module--switcher-point--fe9d0";
export var switcherWrapper = "styles-module--switcher-wrapper--c52dc";