import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Still not finding what you need? Ask us, we might be building it!",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a call",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
] as const;

export const StillNotFindingWhatYouNeed: BFC<typeof inputs> = ({ title, buttonText, linkTo }) => {
    return (
        <div className="container">
            <div className="text-center">
                <h2 className={styles.title}>{title}</h2>
                <CustomButton buttonStyle="btn--primary" to={linkTo}>
                    {buttonText}
                </CustomButton>
            </div>
        </div>
    );
};

Builder.registerComponent(StillNotFindingWhatYouNeed, {
    name: "stillNotFindingWhatYouNeed",
    inputs: toMutable(inputs),
});
