import { useState } from "react";
import { Col, Tooltip } from "react-bootstrap";
import { InformationCircleIcon } from "components/blocks/moving_data/icons";
import { CONTACT_US_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { SliderRenderer } from "components/pricing/slider";
import { rgb } from "components/pricing/util/color";
import { IconTrigger } from "./icon_trigger";
import * as styles from "./content.module.scss";

const serviceFeeTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
        {/* Fixed fee based on the total company wide impression volume */}
        Fixed monthly base fee
    </Tooltip>
);

const impressionCostTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
        The cost of processed impressions (rendered impressions tracked)
    </Tooltip>
);

const storageCostTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
        The cost of stored impressions
    </Tooltip>
);

const formatCost = (cost: number, digits = 2) => {
    return cost.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};

const calcServiceFee = (_args: { multiplicator: number; slider: number }) => {
    return 200;
    // if (multiplicator === 1 && slider < 10) {
    //   multiplicator = 0;
    // }
    // let serviceFee = Math.ceil(slider / 10 / 5) * 50;
    // serviceFee = serviceFee === 50 ? 100 : serviceFee;
    // const serviceFeeCost =
    //   (serviceFee > 4000 ? 4000 : serviceFee) * multiplicator;

    // return serviceFeeCost;
};

function calcImpressionCost(slider: number) {
    const tiers = [
        { limit: 250, cost: 14 },
        { limit: 1000, cost: 9 },
        { limit: Infinity, cost: 7 },
    ];

    const impressionsBelow250 = slider <= 250 ? slider : 250;
    const impressionsBetween250And1000 = slider <= 250 ? 0 : slider <= 1000 ? slider - 250 : 750;
    const impressionsAbove1000 = slider <= 1000 ? 0 : slider - 1000;

    const impressionsBelow250Amount = impressionsBelow250 * tiers[0].cost;
    const impressionsBetween250And1000Amount = impressionsBetween250And1000 * tiers[1].cost;
    const impressionsAbove1000Amount = impressionsAbove1000 * tiers[2].cost;

    const totalCost =
        impressionsBelow250 * tiers[0].cost +
        impressionsBetween250And1000 * tiers[1].cost +
        impressionsAbove1000 * tiers[2].cost;

    return {
        impressionsBelow250,
        impressionsBetween250And1000,
        impressionsAbove1000,
        impressionsBelow250Amount: formatCost(impressionsBelow250Amount),
        impressionsBetween250And1000Amount: formatCost(impressionsBetween250And1000Amount),
        impressionsAbove1000Amount: formatCost(impressionsAbove1000Amount),
        totalCost,
    };
}

const calcDataRetentionCost = ({ multiplicator, slider }: { multiplicator: number; slider: number }) => {
    let dataRetentionCost = 0.1 * slider;
    if (multiplicator === 1 && slider < 10) {
        dataRetentionCost = 0;
    }
    return dataRetentionCost;
};

const totalCostFomatted = (args: { multiplicator: number; slider: number }) => {
    const serviceFeeCost = calcServiceFee(args);
    const impressionCost = calcImpressionCost(args.slider).totalCost;
    const dataRetentionCost = calcDataRetentionCost(args);
    const totalCost = serviceFeeCost + impressionCost + dataRetentionCost;
    const totalCPM = totalCost / args.slider / 1000;
    return (
        <>
            {formatCost(totalCost)}
            <sup>1</sup>
            <br />
            <small>{formatCost(totalCPM, 4)} CPM</small>
        </>
    );
};

const SLIDER_DEFAULT_VALUE = 250;

export const AnalyticsPricing: React.FunctionComponent = () => {
    const [slider, setSlider] = useState(SLIDER_DEFAULT_VALUE);

    const serviceFee = (multiplicator: number) => formatCost(calcServiceFee({ multiplicator, slider }));

    const dataRetention = (multiplicator: number) => formatCost(calcDataRetentionCost({ multiplicator, slider }));

    const totalCost = (multiplicator: number) => totalCostFomatted({ multiplicator, slider });

    const {
        impressionsBelow250,
        impressionsBetween250And1000,
        impressionsAbove1000,
        impressionsBelow250Amount,
        impressionsBetween250And1000Amount,
        impressionsAbove1000Amount,
    } = calcImpressionCost(slider);

    return (
        <>
            <Col md={12} className="text-dark text-center">
                <big>Use the slider below to calculate the cost for your volume of rendered impressions?</big>
            </Col>

            <SliderRenderer value={slider} onChange={value => setSlider(Math.round(value))} />
            <Col md={12} style={{ overflowX: "auto" }}>
                <table className="table  text-dark" style={{ minWidth: "500px" }}>
                    <thead>
                        <tr>
                            <th style={{ width: "30%" }}>Plan</th>
                            <th
                                style={{
                                    width: "15%",
                                }}
                            />
                            <th
                                style={{
                                    width: "15%",
                                }}
                            />
                            <th style={{ textAlign: "center", width: "20%" }}>Pay As You Grow</th>
                            <th style={{ textAlign: "center", width: "20%" }}>Enterprise</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr id="calc_serviceFee" className={styles.table_cell}>
                            <td>
                                <IconTrigger tooltip={serviceFeeTooltip}>
                                    <span className="mr-1">Service Fee</span>
                                    <InformationCircleIcon color={rgb(128, 128, 128)} size={17} />
                                </IconTrigger>
                            </td>
                            <td />
                            <td />
                            <td>{serviceFee(2)}</td>
                            <td
                                rowSpan={5}
                                style={{
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                    width: "30%",
                                }}
                            >
                                up to 50% cheaper
                                <br />
                                <small>(based on volume with commitment)</small>
                                <br />
                                <br />
                                <div style={{ minWidth: "120px" }}>
                                    <CustomButton to={CONTACT_US_LINK}>Contact us</CustomButton>
                                </div>
                            </td>
                        </tr>
                        <tr id="calc_impressionCost" className={[styles.table_cell, styles.table_graduated].join(" ")}>
                            <th
                                style={{
                                    width: "20%",
                                }}
                            >
                                <span>Impressions</span>
                                <IconTrigger tooltip={impressionCostTooltip}>
                                    <InformationCircleIcon color={rgb(128, 128, 128)} size={17} />
                                </IconTrigger>
                            </th>
                            <th
                                style={{
                                    width: "15%",
                                }}
                            >
                                Qty
                            </th>
                            <th
                                style={{
                                    width: "15%",
                                }}
                            >
                                Price
                            </th>
                            <th
                                style={{
                                    width: "20%",
                                }}
                            >
                                Amount
                            </th>
                        </tr>
                        <tr className={styles.table_graduated}>
                            <td>First 250 million</td>
                            <td>{impressionsBelow250}</td>
                            <td>0.014 CPM</td>
                            <td>{impressionsBelow250Amount}</td>
                        </tr>
                        <tr className={styles.table_graduated}>
                            <td>Next 750 million</td>
                            <td>{impressionsBetween250And1000}</td>
                            <td>0.009 CPM</td>
                            <td>{impressionsBetween250And1000Amount}</td>
                        </tr>
                        <tr className={styles.table_graduated}>
                            <td>Above 1 billion</td>
                            <td>{impressionsAbove1000}</td>
                            <td>0.007 CPM</td>
                            <td>{impressionsAbove1000Amount}</td>
                        </tr>
                        <tr id="calc_dataRetentionCost" className={styles.table_cell}>
                            <td>
                                <IconTrigger tooltip={storageCostTooltip}>
                                    <span className="mr-1">Storage Cost</span>
                                    <InformationCircleIcon color={rgb(128, 128, 128)} size={17} />
                                </IconTrigger>
                                <br />
                                <small>metered storage rate</small>
                            </td>
                            <td />
                            <td />
                            <td>
                                {dataRetention(2)}
                                <sup>2</sup>
                                <br />
                                <small>0.0001 CPM</small>
                            </td>
                            <td />
                        </tr>
                    </tbody>
                    <tfoot className={styles.table_foot}>
                        <tr id="calc_totalCost" className={styles.table_cell}>
                            <td>Total (USD)</td>
                            <td />
                            <td />
                            <td>{totalCost(2)}</td>
                            <td>Starting at 1200,-</td>
                        </tr>
                    </tfoot>
                </table>
            </Col>
        </>
    );
};
