import { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./testimonial.module.scss";

const inputs = [
    {
        name: "bg",
        type: "text",
        enum: ["content-light", "content-dark"],
        defaultValue: "content-light",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Don’t just take our word for it",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue: "See How Assertive Yield Has Empowered Publishers For Ultimate Revenue Growth",
        required: true,
    },
    {
        name: "slides",
        type: "list",
        subFields: [
            {
                name: "text",
                type: "longText",
                defaultValue:
                    "AY has saved us thousands of hours of work. We’re able to spin up data and features faster. AY has saved us thousands of hours of work. We’re able to spin up data and features faster. AY has saved us thousands of hours of work. We’re able to spin up data and features faster.",
                required: true,
            },
            {
                name: "image",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg"],
                defaultValue: "https://via.placeholder.com/150",
                required: true,
            },
            {
                name: "name",
                type: "text",
                defaultValue: "John Doe",
                required: true,
            },
            {
                name: "position",
                type: "text",
                defaultValue: "CEO, Company",
                required: true,
            },
        ],
        defaultValue: [
            {
                text: "AY has saved us thousands of hours of work. We’re able to spin up data and features faster. AY has saved us thousands of hours of work. We’re able to spin up data and features faster. AY has saved us thousands of hours of work. We’re able to spin up data and features faster.",
                image: "https://via.placeholder.com/150",
                name: "John Doe",
                position: "CEO, Company",
            },
            {
                text: "AY has saved us thousands of hours of work. We’re able to spin up data and features faster. AY has saved us thousands of hours of work. We’re able to spin up data and features faster. AY has saved us thousands of hours of work. We’re able to spin up data and features faster.",
                image: "https://via.placeholder.com/150",
                name: "John Doe",
                position: "CEO, Company",
            },
        ],
    },
] as const;

const Testimonial: BFC<typeof inputs> = ({ bg, title, subtitle, slides }) => {
    const buttonPrevRef = useRef<any>(null);
    const buttonNextRef = useRef<any>(null);
    const swiperInstance = useRef<any>(null);

    useEffect(() => {
        swiperInstance.current.params.navigation.prevEl = buttonPrevRef.current;
        swiperInstance.current.params.navigation.nextEl = buttonNextRef.current;
        swiperInstance.current.navigation.destroy();
        swiperInstance.current.navigation.init();
        swiperInstance.current.navigation.update();
    }, []);
    return (
        <div
            className={`component-wrapper ${
                bg === "content-dark" ? "content-dark" : "common-bg"
            } change-margin-to-padding`}
        >
            <div className={`gradient ${styles.left}`}>
                <StaticImage src="images/test-left-gradient.png" alt="" />
            </div>
            <div className="container position-relative">
                <div ref={buttonPrevRef} className="swiper-prev" />

                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 text-center">
                        <h2 className="quote text-white mb-2 mt-4">{title}</h2>
                        <p className="mb-5">{subtitle}</p>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-10">
                                <Swiper
                                    modules={[Navigation]}
                                    navigation={{
                                        prevEl: buttonPrevRef.current,
                                        nextEl: buttonNextRef.current,
                                    }}
                                    onBeforeInit={swiper => {
                                        swiperInstance.current = swiper;
                                    }}
                                >
                                    {slides?.map((slide, index) => (
                                        <SwiperSlide key={index}>
                                            <p className="text-start">{slide.text}</p>
                                            <div className="d-flex align-items-center mt-5">
                                                <div className={styles.builderImageWrapper}>
                                                    <BuilderImage src={slide.image} alt="" lazy />
                                                </div>
                                                <div className="mx-4">
                                                    <h4 className="text-start">{slide.name}</h4>
                                                    <p>{slide.position}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <div ref={buttonNextRef} className="swiper-next" />
            </div>
        </div>
    );
};
export default Testimonial;

Builder.registerComponent(Testimonial, {
    name: "testimonial",
    inputs: toMutable(inputs),
});
