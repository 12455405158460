import type { FC } from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import type { BlogPostData } from "types/common";
import arrowIcon from "./images/blog-thumbnail-arrow-icon.svg";
import * as styles from "./styles.module.scss";

const BlogThumbnail: FC<{ post: BlogPostData; isProductUpdate?: boolean; smallPost?: boolean }> = ({
    post,
    isProductUpdate = false,
    smallPost = false,
}) => {
    const url = post.frontmatter.pageUrl ? "/blog/" + post.frontmatter.pageUrl : "/blog" + post.fields.slug;
    let image;

    if (
        (post.featuredImageFileExternal as any).childImageSharp === null &&
        post.frontmatter.featuredImage?.includes(".gif")
    ) {
        image = post.frontmatter.featuredImage;
    } else {
        image = getImage(post.featuredImageFileExternal!);
    }

    return (
        <div className={styles.blog_thumb_wrapper} onClick={() => navigate(url)}>
            {typeof image === "string" ? (
                <img className={styles.image} src={image} alt="Card image" loading="lazy" />
            ) : (
                <GatsbyImage image={image!} alt={"heading image"} className={styles.image} />
            )}
            {isProductUpdate ? (
                <div className={styles.product_update_wrapper}>
                    <h3 className={styles.blog_title}>{post.frontmatter.title}</h3>
                    <img src={arrowIcon} alt="arrow-right" />
                </div>
            ) : (
                <>
                    <div className={smallPost ? styles.text_wrapper : styles.text_wrapper_big}>
                        <p className={smallPost ? styles.blog_tags : styles.blog_tags_big}>
                            {post.frontmatter.tags ? post.frontmatter.tags[0] : ""}
                        </p>
                        <p className={smallPost ? styles.blog_title : styles.blog_title_big}>
                            {post.frontmatter.title}
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

export default BlogThumbnail;
