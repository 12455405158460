// extracted by mini-css-extract-plugin
export var content = "styles-module--content--fcf88";
export var description = "styles-module--description--c7977";
export var firstGlow = "styles-module--first-glow--d125f";
export var image = "styles-module--image--37382";
export var mainContent = "styles-module--main-content--54890";
export var note = "styles-module--note--1bb5a";
export var secondGlow = "styles-module--second-glow--c39b3";
export var subtitle = "styles-module--subtitle--05342";
export var suptitle = "styles-module--suptitle--11980";
export var title = "styles-module--title--d6919";