import { EdaPlacementBuilder } from "../lib";
import { CalculationType, OptimizationObjective } from "../types";
import request_reduction_server_samples_profile, {
    RequestReductionServerSamplesProfileMetricsIds,
} from "./request_reduction_server_samples_profile";

const CALC_IDS = [
    ...RequestReductionServerSamplesProfileMetricsIds,
    "request_reduction_server_samples_req_reduction_rate",
    "request_reduction_server_samples_lost_bids_rate",
    "request_reduction_server_samples_lost_wins_rate",
    "request_reduction_server_samples_lost_revenue_rate",
    "request_reduction_server_samples_missed_revenue",
    "request_reduction_server_samples_predicts",
    "request_reduction_server_samples_rpb",
    "request_reduction_server_samples_reduction_efficiency",
    "request_reduction_server_samples_bid_accuracy",
    "request_reduction_server_samples_bid_precision",
    "request_reduction_server_samples_bid_recall",
    "request_reduction_server_samples_bid_specificity",
    "request_reduction_server_samples_win_accuracy",
    "request_reduction_server_samples_win_precision",
    "request_reduction_server_samples_win_recall",
    "request_reduction_server_samples_win_specificity",
] as const;
export type RequestReductionServerSamplesPredictMetricsType = (typeof CALC_IDS)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(request_reduction_server_samples_profile.rawMetrics)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        ...request_reduction_server_samples_profile.metrics,
        request_reduction_server_samples_reduction_efficiency: {
            label: "Reduction Efficiency",
            explanation: "Reduction Efficiency correlates the reduction rate with revenue loss. (higher is better)",
            formula: m =>
                1 -
                m.request_reduction_server_samples_req_reduction_rate *
                    m.request_reduction_server_samples_lost_revenue_rate *
                    100,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },

        request_reduction_server_samples_req_reduction_rate: {
            label: "Req. Reduction Rate",
            explanation: "Predicted as False / Requests",
            formula: m =>
                (m.request_reduction_server_samples_bid_true_negative +
                    m.request_reduction_server_samples_bid_false_negative) /
                m.request_reduction_server_samples_requests,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_samples_lost_bids_rate: {
            label: "Lost Bids Rate",
            explanation: "Reduced / Requests",
            formula: m =>
                m.request_reduction_server_samples_bid_false_negative / m.request_reduction_server_samples_bids,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_lost_wins_rate: {
            label: "Lost Wins Rate",
            explanation: "Missed Wins / Wins",
            formula: m =>
                m.request_reduction_server_samples_win_false_negative / m.request_reduction_server_samples_wins,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_lost_revenue_rate: {
            label: "Lost Revenue Rate",
            explanation: "Missed Revenue / Revenue",
            formula: m =>
                m.request_reduction_server_samples_missed_revenue / m.request_reduction_server_samples_win_revenue,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_missed_revenue: {
            label: "Missed Revenue",
            explanation: "Missed Revenue",
            formula: m => m.request_reduction_server_samples_missed_revenue / 1000,
            calculationType: CalculationType.Sum,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_predicts: {
            label: "Predicted as True",
            explanation: "Predicted as True",
            formula: m => m.request_reduction_server_samples_predicts,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        request_reduction_server_samples_rpb: {
            label: "Revenue per Billion",
            explanation: "Revenue per Billion Wins",
            formula: m =>
                (m.request_reduction_server_samples_win_revenue / m.request_reduction_server_samples_wins) *
                1_000_000_000,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        // accuracy - shows how often the classifier is correct
        request_reduction_server_samples_bid_accuracy: {
            label: "Bid Accuracy %",
            explanation: "How often the classifier is correct",
            formula: m =>
                100 *
                ((m.request_reduction_server_samples_bid_true_positive +
                    m.request_reduction_server_samples_bid_true_negative) /
                    m.request_reduction_server_samples_requests),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        // precision - shows when classifying positives, how often is correct
        request_reduction_server_samples_bid_precision: {
            label: "Bid Precision %",
            explanation: "When classifying positives, how often is correct",
            formula: m =>
                100 *
                (m.request_reduction_server_samples_bid_true_positive /
                    (m.request_reduction_server_samples_bid_true_positive +
                        m.request_reduction_server_samples_bid_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        // recall how - often the classifier detects actual positives
        request_reduction_server_samples_bid_recall: {
            label: "Bid Recall %",
            explanation: "How often the classifier detects actual positives",
            formula: m =>
                100 *
                (m.request_reduction_server_samples_bid_true_positive /
                    (m.request_reduction_server_samples_bid_true_positive +
                        m.request_reduction_server_samples_bid_false_negative)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        // specificity - how often does the classifier detects actual negatives
        request_reduction_server_samples_bid_specificity: {
            label: "Bid Specificity %",
            explanation: "How often does the classifier detects actual negatives",
            formula: m =>
                100 *
                (m.request_reduction_server_samples_bid_true_negative /
                    (m.request_reduction_server_samples_bid_true_negative +
                        m.request_reduction_server_samples_bid_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_win_accuracy: {
            label: "Win Accuracy %",
            explanation: "How often the classifier is correct",
            formula: m =>
                100 *
                ((m.request_reduction_server_samples_win_true_positive +
                    m.request_reduction_server_samples_win_true_negative) /
                    m.request_reduction_server_samples_requests),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_win_precision: {
            label: "Win Precision %",
            explanation: "When classifying positives, how often is correct",
            formula: m =>
                100 *
                (m.request_reduction_server_samples_win_true_positive /
                    (m.request_reduction_server_samples_win_true_positive +
                        m.request_reduction_server_samples_win_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_win_recall: {
            label: "Win Recall %",
            explanation: "How often the classifier detects actual positives",
            formula: m =>
                100 *
                (m.request_reduction_server_samples_win_true_positive /
                    (m.request_reduction_server_samples_win_true_positive +
                        m.request_reduction_server_samples_win_false_negative)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_samples_win_specificity: {
            label: "Win Specificity %",
            explanation: "How often does the classifier detects actual negatives",
            formula: m =>
                100 *
                (m.request_reduction_server_samples_win_true_negative /
                    (m.request_reduction_server_samples_win_true_negative +
                        m.request_reduction_server_samples_win_false_positive)),
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Minimize,
        },
    });
