import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import yieldIntelco from "./images/yieldIntel.svg";
const inputs = [
    {
        name: "heading",
        type: "text",
        defaultValue: "Discover New Ways to Monetize",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Yield Intelligence",
        required: true,
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Your data, your game",
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Real-time, granular and first-party data you never thought possible. All your revenue data in one place in real-time across hundreds of dimensions and metrics extended with your custom data segments and audiences",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Discover more",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: "/yield-intelligence/",
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1391,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 523,
    },
] as const;
const IntelligenceBlock: BFC<typeof inputs> = ({
    heading,
    title,
    subtitle,
    description,
    buttonText,
    buttonTo,
    switchMagnifier,
    imageOrGif,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
    imageOrGifHover,
}) => (
    <div className="component-wrapper common-bg">
        <div className="gradient left">
            <StaticImage src="images/intel-left-gradient.png" alt="" />
        </div>

        <div className="container panel">
            {heading ? <h2 className="text-center mb-0 mb-lg-6">{heading}</h2> : null}
            <div className="row justify-content-center align-items-center gx-5">
                <div className="col-lg-6  order-2 order-lg-1">
                    <div className="prod-ico d-flex align-items-center mb-3">
                        <img src={yieldIntelco} alt="" />
                        <h4 className="ms-3 mb-0">{title}</h4>
                    </div>
                    <h2 className="product-title ">{subtitle}</h2>
                    <p className="product-desc mb-4 mb-lg-5">{description}</p>
                    <CustomButton buttonSize="btn--large" to={buttonTo}>
                        {buttonText}
                    </CustomButton>
                </div>
                <div className="col-lg-6 position-relative order-lg-2 mt-4 mb-4 mb-lg-0 mt-lg-0 text-center">
                    <div className="mask-container  position-relative ">
                        {imageOrGifHover ? (
                            <img
                                onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                src={imageOrGif}
                                alt={imageOrGifAlt}
                                className="img-fluid"
                            />
                        ) : (
                            <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                        )}
                        {switchMagnifier && !imageOrGifHover && (
                            <Gallery>
                                <Item
                                    width={imageOrGifWidth}
                                    height={imageOrGifHeight}
                                    original={imageOrGif}
                                    alt={imageOrGifAlt}
                                >
                                    {({ ref, open }) => (
                                        // @ts-expect-error
                                        <div className="image_mask" ref={ref} onClick={open}>
                                            <img src={glass} alt="zoom image" />
                                        </div>
                                    )}
                                </Item>
                            </Gallery>
                        )}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

Builder.registerComponent(IntelligenceBlock, {
    name: "IntelligenceBlock",
    inputs: toMutable(inputs),
});
