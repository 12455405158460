// extracted by mini-css-extract-plugin
export var descriptionWrapper = "styles-module--description-wrapper--a74bb";
export var firstGlow = "styles-module--first-glow--a28a7";
export var image = "styles-module--image--904b2";
export var item = "styles-module--item--60387";
export var list = "styles-module--list--3a298";
export var name = "styles-module--name--26f0c";
export var position = "styles-module--position--59208";
export var secondGlow = "styles-module--second-glow--bb592";
export var subtitle = "styles-module--subtitle--a1b0f";
export var teamTitle = "styles-module--team-title--f426e";
export var title = "styles-module--title--c04dd";
export var wrapper = "styles-module--wrapper--5587f";