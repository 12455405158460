import type { FC } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import type { NewsData } from "types/common";
import arrowIcon from "./images/news-thumbnail-arrow-icon.svg";
import * as styles from "./styles.module.scss";

const NewsThumbnail: FC<{ newsItem: NewsData }> = ({ newsItem }) => {
    const image = getImage(newsItem.featuredImageFileExternal);
    return (
        <a href={newsItem.link} className={styles.thumb_wrapper} target="_blank" rel="noreferrer">
            <div className={styles.all_wrapper}>
                <GatsbyImage
                    image={image!}
                    alt="Card image"
                    style={{
                        aspectRatio: "1.77",
                    }}
                />
                <div className={styles.hover_wrapper}>
                    <div className={styles.hover_logo_wrapper}>
                        <div style={{ backgroundImage: `url(${newsItem.hoverLogo})` }} className={styles.hover_logo} />
                    </div>
                </div>
            </div>
            <div className={styles.description}>
                <div className={["mt-4", "mb-3", "figma-h3", styles.title].join(" ")}>{newsItem.title}</div>
                <img src={arrowIcon} alt="arrow-right" />
            </div>
        </a>
    );
};

export default NewsThumbnail;
