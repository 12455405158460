import { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import DebouncedInput from "components/debounceInput";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "AY Help Center Brings Support To Your Fingertip.",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Welcome to our help center. Your one-stop knowledge base. We are here to help.",
    },
    {
        name: "placeholder",
        type: "text",
        defaultValue: "Search",
    },
] as const;

const AyHelpCenterHero: BFC<typeof inputs> = ({ title, subtitle, placeholder }) => {
    const data = useStaticQuery(graphql`
        query terms {
            allTerms {
                nodes {
                    id
                    title
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    type TSearchData = {
        [key: string]: {
            title: string;
            link: string;
            baseUrl: string;
        }[];
    };

    const searchData: TSearchData = {
        "AY Glossary & FAQ": data.allTerms.nodes.map((term: { title: string; fields: { slug: string } }) => ({
            title: term.title,
            link: term.fields.slug,
            baseUrl: "/ay-glossary",
        })),
    };

    const [searchValue, setSearchValue] = useState("");

    const filteredData = Object.entries(searchData).reduce((result, [category, items]) => {
        const filteredItems = items.filter(item => item.title.toLowerCase().includes(searchValue.toLowerCase()));

        if (filteredItems.length) {
            result[category] = filteredItems;
        }

        return result;
    }, {} as TSearchData);

    const hasResults = Object.keys(filteredData).length > 0;

    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/ay-help-center-hero-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/ay-help-center-hero-second-glow.svg" alt="" />
            </div>
            <h1 className={styles.title}>{title}</h1>
            <p className={styles.subtitle}>{subtitle}</p>
            <div className={styles.searchBarContainer}>
                <div className={styles.searchBarWrapper}>
                    <DebouncedInput
                        placeholder={placeholder}
                        className={styles.searchBar}
                        debounceDelay={500}
                        value={searchValue}
                        onChange={value => setSearchValue(String(value))}
                    />
                </div>
                {hasResults && searchValue.length > 0 && (
                    <div className={styles.dropDown}>
                        <div className={styles.dropDownList}>
                            {searchValue.length > 0 &&
                                hasResults &&
                                Object.entries(filteredData).map(([category, items]) =>
                                    items?.map((item, index) => (
                                        <Link
                                            key={index}
                                            to={`${item.baseUrl}${item.link}`}
                                            className={styles.dropDownItem}
                                        >
                                            <span className={styles.dropDownTitle}>{item.title}</span>
                                            <span className={styles.dropDownCategory}>{category}</span>
                                        </Link>
                                    ))
                                )}
                        </div>
                    </div>
                )}
                {!hasResults && searchValue.length > 0 && (
                    <div className={styles.dropDown}>
                        <div className={styles.dropDownList}>
                            <div className={styles.dropDownItem}>
                                <span className={styles.dropDownTitle}>No result found</span>
                                <span className={styles.dropDownCategory}>
                                    Try a different search term or select any of the options below other resources
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

Builder.registerComponent(AyHelpCenterHero, {
    name: "ayHelpCenterHero",
    inputs: toMutable(inputs),
});
