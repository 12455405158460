import type { FC } from "react";
import * as styles from "./styles.module.scss";

const SubscribeInput: FC<{
    email: string;
    setEmail: (it: string) => void;
    isValid: boolean;
}> = ({ email, setEmail, isValid }) => {
    return (
        <div>
            <input
                className={[styles.subscribe_input, isValid ? "" : styles.invalid].join(" ")}
                placeholder="Enter your email"
                value={email}
                onChange={ev => setEmail(ev.target.value)}
            />
            {isValid ? <></> : <span className={styles.invalid_text}>Please provide a valid email</span>}
        </div>
    );
};

export default SubscribeInput;
