import type { FC, ReactNode } from "react";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import AllSomethingHeader from "components/AllSomethingHeader";
import SubscribeToAyNews from "components/blocks/SubscribeToAyNews";
import GoBackLink from "components/GoBackLink";
import Layout from "components/Layout";
import SubscribeToAyNewsletters from "components/SubscribeToAyNewsletters";
import arrowRight from "./images/all-content-posts-arrow-right.svg";
import * as styles from "./styles.module.scss";

const AllContent: FC<{ title: string; children: ReactNode; showJourney?: boolean; newSubscribeToNews?: boolean }> = ({
    children,
    title,
    showJourney = false,
    newSubscribeToNews = false,
}) => {
    const blobClasses = ["d-none", "d-xxl-block", styles.blur_blob];

    return (
        <Layout bg="light" contentType="listing-page">
            <div className={styles.all_content_wrapper}>
                <div className={[...blobClasses, styles.blob_left_1].join(" ")}>
                    <StaticImage src="images/all_blob_left_1.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_left_2].join(" ")}>
                    <StaticImage src="images/all_blob_left_2.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_left_3].join(" ")}>
                    <StaticImage src="images/all_blob_left_3.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_right_1].join(" ")}>
                    <StaticImage src="images/all_blob_right_1.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_right_2].join(" ")}>
                    <StaticImage src="images/all_blob_right_2.png" alt="" />
                </div>
                <div className={[...blobClasses, styles.blob_right_3].join(" ")}>
                    <StaticImage src="images/all_blob_right_3.png" alt="" />
                </div>
                <Container>
                    {showJourney ? (
                        <div className={styles.journeyWrapper}>
                            <div className={styles.journeyRoot}>
                                <Link className={styles.route} to="/blog">
                                    AY Blog
                                </Link>
                            </div>
                            <div className={styles.journeyArrowRight}>
                                <img src={arrowRight} alt="" />
                            </div>
                            <div className={styles.journey}>
                                <p className={styles.currentRoute}>All</p>
                            </div>
                        </div>
                    ) : (
                        <GoBackLink />
                    )}
                    <AllSomethingHeader text={title} className="mb-5" />
                    {children}
                    <div className={styles.subscribe_wrapper}>
                        {newSubscribeToNews ? (
                            <SubscribeToAyNewsletters />
                        ) : (
                            <SubscribeToAyNews title="Subscribe to AY News" />
                        )}
                    </div>
                </Container>
            </div>
        </Layout>
    );
};

export default AllContent;
