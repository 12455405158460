import { Col, Container, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import BlogThumbnail from "components/BlogThumbnail";
import HrHeading from "components/HrHeading";
import TransparentGradientButton from "components/TransparentGradientButton";
import * as styles from "./styles.module.scss";

const ProductUpdates = () => {
    const data = useStaticQuery(graphql`
        query ProductUpdatesPostsQuery {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" }, typeOfPost: { eq: ProductUpdates } } }
                sort: { frontmatter: { date: DESC } }
                limit: 3
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        typeOfPost
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 1160, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const posts = data.allMarkdownRemark.nodes;

    if (data.allMarkdownRemark.nodes.length === 0) return null;

    return (
        <div
            style={{
                paddingBottom: 0,
            }}
            className={[styles.content_wrapper].join(" ")}
        >
            <Container>
                <HrHeading
                    heading="Product Updates"
                    buttonText="All Updates"
                    buttonTo="/product-updates/all"
                    bg="light"
                    bgColor="#fff"
                    headingAs="h1"
                    width="118px"
                    hideButtonOnMobile={true}
                />
                <Row>
                    {posts.length <= 2 && (
                        <>
                            <Col xs={12} xl={6}>
                                {posts[0] && <BlogThumbnail isProductUpdate={true} post={posts[0]} />}
                            </Col>
                            <Col xs={12} xl={6}>
                                {posts[1] && <BlogThumbnail isProductUpdate={true} post={posts[1]} />}
                            </Col>
                        </>
                    )}
                    {posts.length > 2 && (
                        <>
                            <Col xs={12} xl={4}>
                                {posts[0] && <BlogThumbnail isProductUpdate={true} post={posts[0]} />}
                            </Col>
                            <Col xs={12} xl={3}>
                                {posts[1] && <BlogThumbnail isProductUpdate={true} post={posts[1]} />}
                            </Col>
                            <Col xs={12} xl={5}>
                                {posts[2] && <BlogThumbnail isProductUpdate={true} post={posts[2]} />}
                            </Col>
                        </>
                    )}
                </Row>
                <Row>
                    {posts.length > 3 && posts.length <= 5 && (
                        <>
                            <Col xs={12} xl={6}>
                                {posts[3] && <BlogThumbnail isProductUpdate={true} post={posts[3]} />}
                            </Col>
                            <Col xs={12} xl={6}>
                                {posts[4] && <BlogThumbnail isProductUpdate={true} post={posts[4]} />}
                            </Col>
                        </>
                    )}
                    {posts.length > 5 && (
                        <>
                            <Col xs={12} xl={4}>
                                {posts[3] && <BlogThumbnail isProductUpdate={true} post={posts[3]} />}
                            </Col>
                            <Col xs={12} xl={3}>
                                {posts[4] && <BlogThumbnail isProductUpdate={true} post={posts[4]} />}
                            </Col>
                            <Col xs={12} xl={5}>
                                {posts[5] && <BlogThumbnail isProductUpdate={true} post={posts[5]} />}
                            </Col>
                        </>
                    )}
                </Row>

                <div className="mx-auto d-flex d-lg-none justify-content-center mt-4 mb-4">
                    <TransparentGradientButton
                        text={"All Updates"}
                        bg={"light"}
                        bgColor={"#fff"}
                        width={"298px"}
                        to={"/product-updates/all/"}
                        contentWidth={"300px"}
                    />
                </div>
            </Container>
        </div>
    );
};

export default ProductUpdates;
