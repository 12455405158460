import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";
const inputs = [
    {
        name: "heading",
        type: "text",
        defaultValue: "What is Traffic Acquisition all about?",
    },
    {
        name: "subheading",
        type: "longText",
        defaultValue:
            "Buying traffic should be easy, based on data, and in real-time and we’ve found a way to enable that!",
    },

    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Combine Revenue and Spend sources, normalize the data and the RPS, CPC, and ROI (margin) you are achieving for each campaign, adset, ad, publisher, section, or account.",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1338,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 636,
    },
] as const;
const trafficAcquisitionAllAbout: BFC<typeof inputs> = ({
    heading,
    subheading,
    subtitle,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="component-wrapper mt-lg-6">
            <div className="gradient right" style={{ top: "-300px", right: "0px" }}>
                <StaticImage src="./images/allabout_grad.png" alt="" />
            </div>
            <div className="container">
                <div className="row align-items-center gx-5">
                    <div className="col-lg-6   order-2 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                        <h2>{heading}</h2>
                        <p className={styles.subheading}>{subheading}</p>
                        <p className={styles.subtitle}>{subtitle}</p>
                    </div>
                    <div className="col-lg-6  order-lg-1 mt-5 mt-lg-0 mb-4 mb-lg-0">
                        <div className="mask-container  position-relative ">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(trafficAcquisitionAllAbout, {
    name: "trafficAcquisitionAllAbout",
    inputs: toMutable(inputs),
});
