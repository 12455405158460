import type { FC, ReactNode } from "react";
import { Container } from "react-bootstrap";
import * as styles from "./styles.module.scss";

const LegalContentLayout: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <div className={styles.wrapper}>
            <Container>{children}</Container>
        </div>
    );
};

export default LegalContentLayout;
