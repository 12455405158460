import { FC, useState } from "react";
import type { PageProps } from "gatsby";
import { navigate } from "gatsby";
import Layout from "components/Layout";
import Seo from "components/seo";
import * as styles from "./styles.module.scss";

const TITLE = "Schedule meeting";

const renderCurrentRegionForm = (currentRegion: string) => {
    switch (currentRegion) {
        case "europe":
            return (
                <iframe
                    title={"hubspot"}
                    style={{
                        width: "100%",
                        height: "900px",
                    }}
                    src={
                        "https://meetings-eu1.hubspot.com/frederic-lamoulie/demo-call?uuid=70a43ffc-c9de-4cca-bdaa-16ff55eb4939"
                    }
                />
            );
        case "america":
            return (
                <iframe
                    title={"hubspot"}
                    style={{
                        width: "100%",
                        height: "900px",
                    }}
                    src={
                        "https://meetings-eu1.hubspot.com/ryan-abrahams/salesdemo-call?uuid=bfda7642-9195-4f33-a583-9ad837b80177"
                    }
                />
            );
        case "asia":
            return (
                <iframe
                    title={"hubspot"}
                    style={{
                        width: "100%",
                        height: "900px",
                    }}
                    src={
                        "https://meetings-eu1.hubspot.com/frederic-lamoulie/demo-call?uuid=70a43ffc-c9de-4cca-bdaa-16ff55eb4939"
                    }
                />
            );
        default:
            return null;
    }
};

const ScheduleMeetingPage: FC<PageProps> = ({ location }) => {
    const defineRegionByLocation = () => {
        const currentLocation = location.hash;

        if (currentLocation === "#europe") {
            return "europe";
        } else if (currentLocation === "#america") {
            return "america";
        } else if (currentLocation === "#asia") {
            return "asia";
        } else {
            return "";
        }
    };

    const [currentRegion, setCurrentRegion] = useState(defineRegionByLocation);

    return (
        <Layout bg="light" contentType="listing-page">
            <div className={styles.wrapper}>
                <h1 className={styles.mainTitle}>Schedule a meeting</h1>

                <div className={styles.cardsWrapper}>
                    <div
                        className={`${styles.card} ${currentRegion === "europe" ? styles.activeRegion : ""}`}
                        onClick={() => {
                            setCurrentRegion("europe");
                            navigate("#europe");
                        }}
                    >
                        <h5 className={styles.title}>Europe</h5>
                        <p className={styles.description}>Schedule a call with our expert in Europe</p>
                    </div>
                    <div
                        className={`${styles.card} ${currentRegion === "america" ? styles.activeRegion : ""}`}
                        onClick={() => {
                            setCurrentRegion("america");
                            navigate("#america");
                        }}
                    >
                        <h5 className={styles.title}>America</h5>
                        <p className={styles.description}>Schedule a call with our expert in America</p>
                    </div>
                    <div
                        className={`${styles.card} ${currentRegion === "asia" ? styles.activeRegion : ""}`}
                        onClick={() => {
                            setCurrentRegion("asia");
                            navigate("#asia");
                        }}
                    >
                        <h5 className={styles.title}>Asia</h5>
                        <p className={styles.description}>Schedule a call with our expert in Asia</p>
                    </div>
                </div>

                {renderCurrentRegionForm(currentRegion)}
            </div>
        </Layout>
    );
};

export const Head: FC<PageProps> = ({ location }) => (
    <Seo title={TITLE} titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": "${location.pathname}",
          "url": "${location.pathname}",
          "name": "${TITLE}",
          "datePublished": "2023-01-16T23:37:53+00:00",
          "dateModified": "2023-03-21T16:10:45+00:00",
          "description": "Assertive Yield is a global company, created by publishers to publishers. Pioneer in offering Yield Revenue Management in Real-Time, from all revenue sources, with extraordinary granular data, as never seen before.",
          "inLanguage": "en-US",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.assertiveyield.com/#website",
            "url": "https://www.assertiveyield.com/",
            "name": "Assertive Yield",
            "description": "Investment Management Solutions",
            "inLanguage": "en-US",
          "publisher": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "image": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "URL": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
              },
              "query-input": {
                "@type": "PropertyValueSpecification",
                "valueRequired": "http://schema.org/True",
                "valueName": "search_term_string"
              }
            }
          }
        },
        "about": {
          "@type": "Organization",
          "@id": "https://www.assertiveyield.com/#organization",
          "name": "Assertive Yield",
          "url": "https://www.assertiveyield.com/",
          "sameAs": [
            "https://www.youtube.com/@assertiveyield",
            "https://www.linkedin.com/company/assertive-yield/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
            "inLanguage": "en-US",
            "url": "https://assertiveyield.com/img/og-image.png",
            "contentUrl": "https://assertiveyield.com/img/og-image.png",
            "width": 479,
            "height": 189,
            "caption": "Assertive Yield"
          }
      },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "@id": "https://www.assertiveyield.com/#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Assertive Yield",
              "item": {
                "@id": "https://www.assertiveyield.com/",
                "@type": "Thing"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "${TITLE}"
            }
          ]
        },
        "potentialAction": {
          "@type": "ReadAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
          }
        }
      }
      `}
        </script>
    </Seo>
);

export default ScheduleMeetingPage;
