import type { FC } from "react";
import { graphql, Link, navigate, PageProps } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { micromark } from "micromark";
import { useLocation } from "@reach/router";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import GoBackLink from "components/GoBackLink";
import Layout from "components/Layout";
import MarkdownRenderer from "components/MarkdownRenderer";
import gmailImage from "./images/terms-gmail.svg";
import instagramImage from "./images/terms-instagram.svg";
import intercomImage from "./images/terms-intercom.svg";
import linkedinImage from "./images/terms-linkedin.svg";
import twitterImage from "./images/terms-twitter.svg";
import * as styles from "./terms.module.scss";

type Props = {
    terms: {
        body: string;
        title: string;
    };
    allTerms: {
        nodes: {
            id: string;
            title: string;
            description: string;
            fields: {
                slug: string;
            };
        }[];
    };
};

const TermsTemplate: FC<PageProps<Props>> = ({ data }) => {
    const { body, title } = data.terms;
    const listImages = {
        gmailImage,
        intercomImage,
        linkedinImage,
        twitterImage,
        instagramImage,
    };

    const list: {
        img: keyof typeof listImages;
        title: string;
        subtitle: string;
    }[] = [
        {
            img: "gmailImage",
            title: "Gmail",
            subtitle: "customersuccess@assertive.ai",
        },
        {
            img: "intercomImage",
            title: "Intercom",
            subtitle: "Assertive Yield B.V",
        },
        {
            img: "linkedinImage",
            title: "LinkedIn",
            subtitle: "Assertive Yield B.V",
        },
        {
            img: "twitterImage",
            title: "Twitter",
            subtitle: "Assertive Yield B.V",
        },
        {
            img: "instagramImage",
            title: "Instagram",
            subtitle: "Assertive Yield B.V",
        },
    ];

    const location = useLocation();
    const currentLetter = location.pathname.split("/")[2][0];
    const firstNodeOfNextLetterList = data.allTerms.nodes
        .filter(item => currentLetter !== item.title[0].toLowerCase())
        .sort((a, b) => a.title.localeCompare(b.title))
        .find(item => currentLetter < item.title[0].toLowerCase());

    return (
        <Layout bg="light">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/terms-first-glow.svg" alt="glow" />
            </div>
            <div className="container">
                <div className={styles.moreTermsWrapper}>
                    <div className={styles.moreTermsSidebar}>
                        <GoBackLink />

                        <h3 className={styles.moreTermsSidebarTitle}>More terms</h3>

                        <ul className={`d-none d-md-block ${styles.moreTermsSidebarList}`}>
                            {data.allTerms.nodes
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map(item => {
                                    if (item.title[0].toLowerCase() !== currentLetter) {
                                        return null;
                                    }

                                    return (
                                        <li key={item.id} className={styles.moreTermsSidebarItem}>
                                            <Link
                                                to={`/ay-glossary${item.fields.slug}`}
                                                className={styles.moreTermsSidebarLink}
                                            >
                                                <p
                                                    className={`${
                                                        location.pathname === `/ay-glossary${item.fields.slug}`
                                                            ? styles.activeTerm
                                                            : ""
                                                    } mb-0`}
                                                >
                                                    {item.title}
                                                </p>
                                                <div className={styles.moreTermsSidebarArrow}>
                                                    <StaticImage src="./images/terms-right-arrow.svg" alt="arrow" />
                                                </div>
                                            </Link>
                                        </li>
                                    );
                                })}
                        </ul>
                        <select
                            onChange={e => navigate(e.target.value)}
                            defaultValue={location.pathname}
                            className={`d-block d-md-none ${styles.moreTermsSidebarSelect}`}
                        >
                            {data.allTerms.nodes
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map(item => {
                                    if (item.title[0].toLowerCase() !== currentLetter) {
                                        return null;
                                    }

                                    return (
                                        <option
                                            key={item.id}
                                            value={`/ay-glossary${item.fields.slug}`}
                                            className={styles.moreTermsSidebarItem}
                                        >
                                            {item.title}
                                        </option>
                                    );
                                })}
                        </select>
                        {firstNodeOfNextLetterList?.title[0].toUpperCase() && (
                            <div className="d-none d-md-block">
                                <Link
                                    to={`/ay-glossary${firstNodeOfNextLetterList?.fields.slug}`}
                                    className={styles.moreTermsSidebarLinkBlue}
                                >
                                    <p className={`mb-0 ${styles.moreTermsSidebarLinkBlueText}`}>
                                        To the letter {firstNodeOfNextLetterList?.title[0].toUpperCase()}
                                    </p>
                                    <div className={styles.moreTermsSidebarArrow}>
                                        <StaticImage src="./images/terms-right-blue-arrow.svg" alt="arrow" />
                                    </div>
                                </Link>
                            </div>
                        )}
                    </div>

                    <div>
                        <h2>{title}</h2>
                        <MarkdownRenderer
                            post={{
                                html:
                                    body == null
                                        ? ""
                                        : micromark(body, {
                                              allowDangerousHtml: true,
                                          }),
                            }}
                        />
                    </div>
                </div>
            </div>

            <div className={styles.wrapper}>
                <div className="container">
                    <div className={styles.contentWrapper}>
                        <div className={styles.mainContent}>
                            <h2 className={styles.title}>Haven’t found what you’re looking for?</h2>
                            <p>Contact Customer Success for further assistance.</p>
                            <CustomButton to={BOOK_CALL_LINK}>Book a call</CustomButton>
                        </div>
                        <div className={styles.ayContactWrapper}>
                            {list?.map(({ title, img, subtitle }, index) => {
                                return (
                                    <div key={index}>
                                        <div className={styles.ayContactItemIcon}>
                                            <img src={listImages[img]} alt="" />
                                        </div>
                                        <h4 className={styles.ayContactItemTitle}>{title}</h4>
                                        <p>{subtitle}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className={styles.stillMoreWrapper}>
                    <h2>Still got more questions?</h2>
                    <p>Feel free to speak with our customer success manager</p>
                    <CustomButton to={BOOK_CALL_LINK}>Schedule a meeting</CustomButton>
                </div>
            </div>
        </Layout>
    );
};

export default TermsTemplate;

export const pageQuery = graphql`
    query term($id: String!) {
        terms(id: { eq: $id }) {
            id
            title
            body
            fields {
                slug
            }
        }

        allTerms: allTerms(sort: { fields: title, order: ASC }) {
            nodes {
                id
                title
                fields {
                    slug
                }
            }
        }
    }
`;
