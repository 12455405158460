import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Ready to get to the next level with AY",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Choose the products that are right for you",
    },
] as const;

const PricingHero: BFC<typeof inputs> = ({ title, subtitle }) => {
    return (
        <div className="container">
            <div className={styles.mainContent}>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
        </div>
    );
};

Builder.registerComponent(PricingHero, {
    name: "pricingHero",
    inputs: toMutable(inputs),
});
