import { FC, ReactNode, useEffect } from "react";
import SSRProvider from "react-bootstrap/SSRProvider";
import type { Bg, HubspotContentType } from "types/common";
import { CookieNotice } from "./CookieNotice";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Layout: FC<{
    children: ReactNode;
    bg: Bg;
    contentType?: HubspotContentType;
}> = ({ children, bg, contentType }) => {
    useEffect(() => {
        // https://knowledge.hubspot.com/reports/install-the-hubspot-tracking-code
        //@ts-expect-error
        const _hsq = (window._hsq = window._hsq || []);
        _hsq.push(["setContentType", contentType ?? "standard-page"]);
    }, []);
    return (
        <>
            <SSRProvider>
                {bg === "light" ? (
                    <div className="content-light">
                        <Navbar />
                        {children}
                    </div>
                ) : (
                    <>
                        <div className="content-dark">
                            <Navbar />
                            {children}
                        </div>
                    </>
                )}
                <CookieNotice />
                <Footer />
            </SSRProvider>
            <noscript>
                <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-PMGC9G3"
                    height="0"
                    width="0"
                    style={{
                        display: "none",
                        visibility: "hidden",
                    }}
                />
            </noscript>
        </>
    );
};

export default Layout;
