// extracted by mini-css-extract-plugin
export var active = "styles-module--active--369a3";
export var description = "styles-module--description--241d8";
export var firstGlow = "styles-module--first-glow--563b3";
export var job = "styles-module--job--e4228";
export var jobInfo = "styles-module--job-info--74d9a";
export var jobInfoText = "styles-module--job-info-text--e14f4";
export var jobInfoWrapper = "styles-module--job-info-wrapper--089fc";
export var jobNavigation = "styles-module--job-navigation--c6f19";
export var jobNavigationText = "styles-module--job-navigation-text--e2794";
export var jobs = "styles-module--jobs--8ab90";
export var secondGlow = "styles-module--second-glow--e4046";
export var suptitle = "styles-module--suptitle--cf24f";
export var switcher = "styles-module--switcher--4d9f4";
export var switcherPoint = "styles-module--switcher-point--6f8c0";
export var switcherWrapper = "styles-module--switcher-wrapper--9354d";
export var title = "styles-module--title--b455e";
export var wrapper = "styles-module--wrapper--32de0";