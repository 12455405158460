import { graphql, useStaticQuery } from "gatsby";
import HrHeading from "components/HrHeading";
import SuccessStoriesCard from "components/SuccessStoriesCard/SuccessStoriesCard.builder";
import TransparentGradientButton from "components/TransparentGradientButton";
import type { SuccessStoriesData } from "types/common";
import * as styles from "./styles.module.scss";

const CustomerStoriesBlog = () => {
    const { allSuccessStories }: { allSuccessStories: { nodes: SuccessStoriesData[] } } = useStaticQuery(graphql`
        query successStories {
            allSuccessStories {
                nodes {
                    id
                    clientLogoHover
                    clientLogoFileExternal {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
                        }
                    }
                    video
                    pdf
                    relatedFeatures
                    sidebarImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(quality: 90)
                        }
                    }
                    title
                    description
                    category
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const stories: SuccessStoriesData[] = allSuccessStories.nodes;

    return (
        <div className="container">
            <div className={styles.wrapper}>
                <HrHeading
                    heading="Customer Stories"
                    buttonText="All Customer Stories"
                    buttonTo="/success-stories/"
                    bg="light"
                    bgColor="#fff"
                    headingAs="h1"
                    marginBottom="20px"
                    hideButtonOnMobile={true}
                />

                <div className={styles.contentWrapper}>
                    <div className="me-0 me-lg-4 mb-4 mb-lg-0">
                        <SuccessStoriesCard story={stories[0]} />
                    </div>
                    <div className="mb-4 mb-lg-0">
                        <SuccessStoriesCard story={stories[1]} />
                    </div>
                </div>

                <div className="mx-auto d-flex d-lg-none justify-content-center mt-4 mb-4">
                    <TransparentGradientButton
                        text={"All Customer Stories"}
                        bg={"light"}
                        bgColor={"#fff"}
                        width={"298px"}
                        to={"/success-stories/"}
                        contentWidth={"300px"}
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomerStoriesBlog;
