import { useEffect, useRef, useState } from "react";

const fallbackCopyTextToClipboard = (text: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand("copy");
        return successful;
    } catch (err) {
        // noop
    }

    document.body.removeChild(textArea);
    return false;
};

export const useCopyToClipboard = () => {
    const [wasCopied, setCopied] = useState(false);

    const timeout = useRef<any>(null);

    const onCopy = async (text: string) => {
        if (!navigator.clipboard) {
            const wasCopied = fallbackCopyTextToClipboard(text);
            setCopied(wasCopied);
        } else {
            await navigator.clipboard.writeText(text);
            setCopied(true);
        }

        timeout.current = setTimeout(() => {
            setCopied(false);
            timeout.current = null;
        }, 2000);
    };

    useEffect(() => {
        clearTimeout(timeout.current);
    }, []);

    return {
        wasCopied,
        onCopy,
    };
};
