import { Link } from "gatsby";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Want to explore further?",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Discover free resources for deep-dive industry knowledge ",
    },
    {
        name: "cards",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
            },
            {
                name: "icon",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
            {
                name: "description",
                type: "text",
            },
            {
                name: "link",
                type: "text",
                required: true,
            },
        ],
    },
] as const;

export const HelpCentreExploreFurther: BFC<typeof inputs> = ({ title, subtitle, cards }) => {
    return (
        <div className={styles.wrapper}>
            <div className="container">
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>
                <div className={styles.cards}>
                    {cards?.map(({ title, description, icon, link }, index) => (
                        <Link to={link} className={styles.card} key={index}>
                            <h3 className={styles.cardTitle}>{title}</h3>
                            <div className={styles.cardIcon}>
                                <BuilderImage image={icon} alt={title} lazy />
                            </div>
                            <p className={styles.cardDescription}>{description}</p>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(HelpCentreExploreFurther, {
    name: "helpCentreExploreFurther",
    inputs: toMutable(inputs),
});
