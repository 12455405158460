import { useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import type { BannersData } from "types/common";
import "./banner.scss";

const Banner = () => {
    const data = useStaticQuery(graphql`
        query AllBannersQuery {
            allBanner(sort: { date: DESC }) {
                nodes {
                    id
                    link
                    title
                    featuredImage
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `);
    const banners = data.allBanner.nodes;

    const buttonPrevRef = useRef<any>(null);
    const buttonNextRef = useRef<any>(null);
    const swiperInstance = useRef<any>(null);

    useEffect(() => {
        swiperInstance.current.params.navigation.prevEl = buttonPrevRef.current;
        swiperInstance.current.params.navigation.nextEl = buttonNextRef.current;
        swiperInstance.current.navigation.destroy();
        swiperInstance.current.navigation.init();
        swiperInstance.current.navigation.update();
    }, []);
    return (
        <div className="component-wrapper mb-2 overflow-hidden custom-banner-wrapper">
            <div className="gradient left" />
            <div className="gradient right" />
            <div className="banner position-relative">
                <div className="container position-relative">
                    <div ref={buttonPrevRef} className="swiper-prev" />

                    <Swiper
                        modules={[Autoplay, Navigation]}
                        navigation={{
                            prevEl: buttonPrevRef.current,
                            nextEl: buttonNextRef.current,
                        }}
                        slidesPerView="auto"
                        spaceBetween={30}
                        slidesPerGroup={1}
                        loop={true}
                        loopFillGroupWithBlank={true}
                        breakpoints={{
                            340: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            1024: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        onBeforeInit={swiper => {
                            swiperInstance.current = swiper;
                        }}
                    >
                        {banners.map((it: BannersData) => {
                            let image;

                            if (
                                (it.featuredImageFileExternal as any).childImageSharp === null &&
                                it.featuredImage.includes(".gif")
                            ) {
                                image = it.featuredImage;
                            } else {
                                image = getImage(it.featuredImageFileExternal);
                            }

                            return (
                                <SwiperSlide key={it.id}>
                                    <a href={it.link} target="_blank" rel="noreferrer">
                                        {typeof image === "string" ? (
                                            <img className="card-img" src={image} alt="Card image" loading="lazy" />
                                        ) : (
                                            <GatsbyImage
                                                loading="lazy"
                                                image={image!}
                                                alt={"heading image"}
                                                className="card-img"
                                            />
                                        )}
                                    </a>
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div ref={buttonNextRef} className="swiper-next" />
                </div>
            </div>
        </div>
    );
};
export default Banner;
