/**
 * SEO component that queries for data with
 * Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import type { FC, ReactNode } from "react";
import useSiteMetadata from "./SiteMetadata";

const SEO: FC<{
    titleSuffixed: boolean;
    title: string;
    canonical: string;
    description?: string;
    children?: ReactNode;
    noIndex?: boolean;
}> = ({ titleSuffixed, title, canonical, description, children, noIndex = false }) => {
    const { title: defaultTitle, description: defaultDescription, siteUrl } = useSiteMetadata();

    const seo = {
        title: (title ?? defaultTitle) + (titleSuffixed ? " - Assertive Yield" : ""),
        description: description ?? defaultDescription,
    };
    const usingChildren = children ?? (
        <>
            <meta property="og:image" content={`${siteUrl}/img/og-image.png`} />
            <meta property="og:type" content="website" />
        </>
    );
    return (
        <>
            <link rel="preconnect" href="https://fonts.gstatic.com/" crossOrigin="true" />
            <link rel="preconnect" href="https://cdn.builder.io" crossOrigin="true" />
            <title>{seo.title}</title>
            {canonical == null ? <></> : <link rel="canonical" href={siteUrl + canonical} />}
            {noIndex ? <meta name="robots" content="noindex" /> : <></>}
            <meta name="description" content={seo.description} />
            <meta property="og:title" content={seo.title} />
            <meta property="og:description" content={seo.description} />
            <meta
                property="og:image"
                content="https://res.cloudinary.com/dddoo7efp/image/upload/v1701326966/AY_b_p_V_black_3x_py5k4y.png"
            />
            <meta property="og:image:width" content="1115" />
            <meta property="og:image:height" content="598" />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:title" content={seo.title} />
            <meta name="twitter:description" content={seo.description} />
            <link rel="apple-touch-icon" sizes="180x180" href={`${siteUrl}/img/ay_favicon_color_bluepurple.svg`} />
            <link rel="icon" type="image/png" href={`${siteUrl}/img/ay_favicon_color_bluepurple.svg`} sizes="32x32" />
            <link rel="icon" type="image/png" href={`${siteUrl}/img/ay_favicon_color_bluepurple.svg`} sizes="16x16" />
            <link rel="mask-icon" href={`${siteUrl}/img/ay_favicon_color_bluepurple.svg`} color="#ff4400" />
            {usingChildren}
        </>
    );
};

export default SEO;
