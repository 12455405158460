import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import * as styles from "./hero.module.scss";

const items = [
    {
        id: 1,
        content: "Supercharge Efficiency",
        class: styles.realtime,
    },
    {
        id: 2,
        content: "Take Control",
        class: styles.granular,
    },
    {
        id: 3,
        content: "Gain Flexibility",
        class: styles.revenue,
    },
    {
        id: 4,
        content: "Gain Transparency",
        class: styles.realtime,
    },
    {
        id: 5,
        content: "Enhance UX",
        class: styles.granular,
    },
    {
        id: 6,
        content: "Scale Engagement",
        class: styles.realtime,
    },
    {
        id: 7,
        content: "Slash Costs",
        class: styles.granular,
    },
    {
        id: 8,
        content: "Go Greener",
        class: styles.revenue,
    },
];
const HeroAnimation = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const id = setInterval(() => {
            setIndex(state => {
                if (state >= items.length - 1) return 0;
                return state + 1;
            });
        }, 2000);
        return () => clearInterval(id);
    }, []);

    return (
        <AnimatePresence initial={false}>
            <motion.div
                key={items[index].id}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -20, opacity: 0 }}
                transition={{ ease: "easeInOut" }}
                className={`${items[index].class} ${styles.hero_animation_pos}`}
            >
                {items[index].content}
            </motion.div>
        </AnimatePresence>
    );
};
export default HeroAnimation;
