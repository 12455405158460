// extracted by mini-css-extract-plugin
export var leftArrowText = "styles-module--left-arrow-text--e4139";
export var leftArrowWrapper = "styles-module--left-arrow-wrapper--8fb67";
export var leftArrowWrapperMobile = "styles-module--left-arrow-wrapper-mobile--7866b";
export var paginationText = "styles-module--pagination-text--8ff94";
export var paginationTextActive = "styles-module--pagination-text-active--a79bb";
export var paginationTextMobile = "styles-module--pagination-text-mobile--425a2";
export var paginationTextWrapper = "styles-module--pagination-text-wrapper--96340";
export var paginationTextWrapperMobile = "styles-module--pagination-text-wrapper-mobile--7b06f";
export var paginationWrapper = "styles-module--pagination-wrapper--dae74";
export var rightArrowText = "styles-module--right-arrow-text--0b84b";
export var rightArrowWrapper = "styles-module--right-arrow-wrapper--a220e";
export var rightArrowWrapperMobile = "styles-module--right-arrow-wrapper-mobile--709ce";