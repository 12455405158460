import type { FC, ReactNode } from "react";
import { Builder, withChildren } from "@builder.io/react";
import AllInOneSolutions from ".";

const HomeAllInOneSolutions: FC<{ children: ReactNode; isLight: boolean }> = ({ children, isLight }) => {
    return (
        <AllInOneSolutions isLight={isLight} page="home">
            {children}
        </AllInOneSolutions>
    );
};
const HomeAllInOneSolutionsWithChildren = withChildren(HomeAllInOneSolutions);
Builder.registerComponent(HomeAllInOneSolutionsWithChildren, {
    name: "HomeAllInOneSolutions",
    inputs: [
        {
            name: "isLight",
            type: "boolean",
            defaultValue: false,
        },
    ],
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element",
            component: { name: "Text", options: { text: "I am child text block!" } },
        },
    ],
});
