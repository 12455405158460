// extracted by mini-css-extract-plugin
export var active = "styles-module--active--98cbd";
export var alignWrapper = "styles-module--align-wrapper--7f4a5";
export var card = "styles-module--card--fe102";
export var cardTitle = "styles-module--card-title--2a0b6";
export var cardWrapper = "styles-module--card-wrapper--4b18c";
export var companyLogo = "styles-module--company-logo--f8ccb";
export var gradientBorder = "styles-module--gradient-border--1c9e3";
export var image = "styles-module--image--b788d";
export var subtitle = "styles-module--subtitle--fb0e6";
export var switcher = "styles-module--switcher--a2800";
export var switcherPoint = "styles-module--switcher-point--7cc79";
export var title = "styles-module--title--d9e56";
export var wrapper = "styles-module--wrapper--54e12";