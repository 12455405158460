// extracted by mini-css-extract-plugin
export var bg = "styles-module--bg--af575";
export var close = "styles-module--close--37266";
export var darkBg = "styles-module--darkBg--4273f";
export var description = "styles-module--description--3dcef";
export var descriptionDark = "styles-module--descriptionDark--5b68c";
export var descriptionLight = "styles-module--descriptionLight--1646d";
export var faq = "styles-module--faq--25e50";
export var faqWrapper = "styles-module--faqWrapper--2a336";
export var header = "styles-module--header--26cae";
export var interactIcon = "styles-module--interactIcon--09cf5";
export var item = "styles-module--item--1ef84";
export var light = "styles-module--light--37cfb";
export var mainDescription = "styles-module--mainDescription--d4d0d";
export var open = "styles-module--open--80dc6";
export var title = "styles-module--title--361b4";