import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import better from "./images/better.svg";
const inputs = [
    {
        name: "text1",
        type: "longText",
        defaultValue: "Increase QPS limits, become more competitive and yield way better",
    },
    {
        name: "text2",
        type: "longText",
        defaultValue: "Stop searching for a solution to reach optimal QPS limits.",
    },
    {
        name: "text3",
        type: "longText",
        defaultValue: "Increase QPS limits, become more competitive and yield way better",
    },
    {
        name: "text4",
        type: "longText",
        defaultValue: "Stop searching for a solution to reach optimal QPS limits.",
    },
] as const;

const TrafficShapingChart: BFC<typeof inputs> = ({ text1, text2, text3, text4 }) => {
    return (
        <div className="component-wrapper pb-0">
            <div className="container">
                <div className="row justify-content-center mb-5 text-center">
                    <div className="col-12">
                        <h2>{text1}</h2>
                        <p>{text2}</p>
                        <img src={better} alt="" className="mb-3 img-fluid" />
                        <h5>{text3}</h5>
                        <p>{text4}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(TrafficShapingChart, {
    name: "TrafficShapingChart",
    inputs: toMutable(inputs),
});
