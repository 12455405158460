import type { FC, ReactNode } from "react";
import * as styles from "./styles.module.scss";
const LegalContentItem: FC<{ children: ReactNode; title: string }> = ({ children, title }) => {
    return (
        <div className="mb-5">
            <div className={["figma-h2", styles.text_black, "mb-3"].join(" ")}>{title}</div>
            <div className={[styles.text_black].join(" ")}>{children}</div>
        </div>
    );
};

export default LegalContentItem;
