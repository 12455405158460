import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import Clients from ".";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Who is already playing a different game",
    },
    {
        name: "topmargin",
        type: "boolean",
        defaultValue: false,
    },
    {
        name: "bg",
        type: "text",
        enum: ["content-light", "content-dark"],
        defaultValue: "content-light",
    },
    {
        name: "showLink",
        type: "boolean",
        enum: ["true", "false"],
        defaultValue: false,
    },
    {
        name: "linkText",
        type: "text",
        defaultValue: "Discover our customer stories",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
    },
    {
        name: "linkColor",
        type: "text",
        enum: ["green", "yellow"],
        defaultValue: "green",
    },
] as const;

const ConfigurableClients: BFC<typeof inputs> = props => <Clients {...props} />;

Builder.registerComponent(ConfigurableClients, {
    name: "ConfigurableClients",
    inputs: toMutable(inputs),
});
