import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Experience data filtering beyond limits",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Advanced Custom Filters",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            'Filter time frame, data on Device, OS, Referrer,UTM, Custom Values and adjust your implementation on these segments for the optimal yield. Apply advanced filtering to include data that "starts with," "ends with," or "contains" special characters, as well as data that "matches Regex." There are just no restrictions with our filters!',
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Schedule a Demo",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1338,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 636,
    },
] as const;
const SliceAndDiceCustomFilters: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    buttonTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle}</p>
                            <h2>{title}</h2>
                            <p className="mb-5">{subtitle}</p>
                            <CustomButton buttonStyle="btn--primary" to={buttonTo}>
                                {buttonText}
                            </CustomButton>
                        </div>
                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(SliceAndDiceCustomFilters, {
    name: "sliceAndDiceCustomFilters",
    inputs: toMutable(inputs),
});
