import type { FC } from "react";
import type { PageProps } from "gatsby";
import ReadyToWin from "components/blocks/ReadyToWin";
import Layout from "components/Layout";
import LegalContentItem from "components/LegalContentItem";
import LegalContentLayout from "components/LegalContentLayout";
import LegalHeader from "components/LegalHeader";
import Seo from "components/seo";
import * as styles from "./styles.module.scss";

const Disclaimer = () => {
    return (
        <Layout bg="dark">
            <LegalHeader title="Assertive Yield" subHeading="" />
            <LegalContentLayout>
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 text-center mt-4 preamble">
                    <h4 className={["mt-0 mb-3", styles.text_black, "figma-h2"].join(" ")}>Imprint</h4>
                </div>

                <div className={["col-lg-12", styles.text_black].join(" ")}>
                    <div>
                        <h5 className={[styles.text_black, "figma-h3"].join(" ")}>Company Information</h5>
                        <p className="mb-3">
                            Assertive Yield B.V.
                            <br />
                            Van Speijkstraat 44 B
                            <br />
                            2518 GD Den Haag
                            <br />
                            Netherlands
                        </p>
                        <p className="mb-3">
                            E-Mail: hello@a&#115;&#115;&#101;&#114;&#116;ive
                            <span style={{ display: "none" }}>d</span>yield.com
                        </p>
                        <p className="mb-3">
                            Director: Nils Lind
                            <br />
                            CoC: 743012680000
                            <br />
                            VAT: NL859845655B01
                        </p>
                    </div>
                </div>
                <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 text-center mt-4 preamble">
                    <h4 className={["mt-0 mb-5", styles.text_black, "figma-h2"].join(" ")}>Disclaimer</h4>
                </div>
                <LegalContentItem title="Accountability for content">
                    <p className="mb-3">
                        The contents of our pages have been created with the utmost care. However, we cannot guarantee
                        the contents' accuracy, completeness or topicality. According to statutory provisions, we are
                        furthermore responsible for our own content on these web pages. In this context, please note
                        that we are accordingly not obliged to monitor merely the transmitted or saved information of
                        third parties, or investigate circumstances pointing to illegal activity. Our obligations to
                        remove or block the use of information under generally applicable laws remain unaffected by this
                        as per §§ 8 to 10 of the Telemedia Act (TMG).
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Accountability for links">
                    <p className="mb-3">
                        Responsibility for the content of external links (to web pages of third parties) lies solely
                        with the operators of the linked pages. No violations were evident to us at the time of linking.
                        Should any legal infringement become known to us, we will remove the respective link
                        immediately.
                    </p>
                </LegalContentItem>
                <LegalContentItem title="Copyright">
                    <p className="mb-3">
                        Our web pages and their contents are subject to German copyright law. Unless expressly permitted
                        by law (§ 44a et seq. of the copyright law), every form of utilizing, reproducing or processing
                        works subject to copyright protection on our web pages requires the prior consent of the
                        respective owner of the rights. Individual reproductions of a work are allowed only for private
                        use, so must not serve either directly or indirectly for earnings. Unauthorized utilization of
                        copyrighted works is punishable (§ 106 of the copyright law).
                    </p>
                </LegalContentItem>
            </LegalContentLayout>
            <ReadyToWin />
        </Layout>
    );
};
export const Head: FC<PageProps> = ({ location }) => (
    <Seo noIndex title="Disclaimer" titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": "${location.pathname}",
          "url": "${location.pathname}",
          "name": "Disclaimer",
          "datePublished": "2023-01-16T23:37:53+00:00",
          "dateModified": "2023-03-21T16:10:45+00:00",
          "description": "Assertive Yield is a global company, created by publishers to publishers. Pioneer in offering Yield Revenue Management in Real-Time, from all revenue sources, with extraordinary granular data, as never seen before.",
          "inLanguage": "en-US",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.assertiveyield.com/#website",
            "url": "https://www.assertiveyield.com/",
            "name": "Assertive Yield",
            "description": "Investment Management Solutions",
            "inLanguage": "en-US",
          "publisher": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "image": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "URL": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
              },
              "query-input": {
                "@type": "PropertyValueSpecification",
                "valueRequired": "http://schema.org/True",
                "valueName": "search_term_string"
              }
            }
          }
        },
        "about": {
          "@type": "Organization",
          "@id": "https://www.assertiveyield.com/#organization",
          "name": "Assertive Yield",
          "url": "https://www.assertiveyield.com/",
          "sameAs": [
            "https://www.youtube.com/@assertiveyield",
            "https://www.linkedin.com/company/assertive-yield/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
            "inLanguage": "en-US",
            "url": "https://assertiveyield.com/img/og-image.png",
            "contentUrl": "https://assertiveyield.com/img/og-image.png",
            "width": 479,
            "height": 189,
            "caption": "Assertive Yield"
          }
      },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "@id": "https://www.assertiveyield.com/#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Assertive Yield",
              "item": {
                "@id": "https://www.assertiveyield.com/",
                "@type": "Thing"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Disclaimer"
            }
          ]
        },
        "potentialAction": {
          "@type": "ReadAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
          }
        }
      }
      `}
        </script>
    </Seo>
);

export default Disclaimer;
