import type { FC } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import type { EbookData } from "types/common";
import * as styles from "./styles.module.scss";

const EbookThumbnail: FC<{
    shouldHideXs: boolean;
    ebook: EbookData;
}> = ({ ebook, shouldHideXs }) => {
    const image = getImage(ebook.featuredImageFileExternal);

    return (
        <div className={["mb-5 mb-xl-0", shouldHideXs ? "d-none d-xl-block" : ""].join(" ")}>
            <a className={[styles.download_link].join(" ")} href={ebook.link} target="_blank" rel="noreferrer">
                <div className={[styles.image_wrapper, "mb-4"].join(" ")}>
                    <GatsbyImage image={image!} alt="ebook image" />
                </div>
                Download
            </a>
        </div>
    );
};

export default EbookThumbnail;
