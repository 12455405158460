import CustomButton from "components/CustomButton";
import * as styles from "./styles.module.scss";

const UnlockRemarkableResults = () => {
    return (
        <div className="component-wrapper pb-0">
            <div className="container">
                <div className={styles.wrapper}>
                    <h3 className={styles.title}>Unlock Remarkable Results </h3>

                    <p className={styles.subtitle}>
                        To learn more about how we can help your business achieve remarkable results
                    </p>

                    <CustomButton to="/schedule-meeting">Request a Demo</CustomButton>
                </div>
            </div>
        </div>
    );
};

export default UnlockRemarkableResults;
