// extracted by mini-css-extract-plugin
export var items = "styles-module--items--1a425";
export var mobileWrapper = "styles-module--mobileWrapper--9bca0";
export var newGameItem = "styles-module--newGameItem--899f7";
export var newGameItemIcon = "styles-module--newGameItemIcon--a823f";
export var newGameItemTextTitle = "styles-module--newGameItemTextTitle--35fa5";
export var newGameTitle = "styles-module--newGameTitle--71f99";
export var newGameWrapper = "styles-module--newGameWrapper--5b6e4";
export var oldGameItem = "styles-module--oldGameItem--7061f";
export var oldGameItemIcon = "styles-module--oldGameItemIcon--1796c";
export var oldGameItemTextTitle = "styles-module--oldGameItemTextTitle--c2eba";
export var oldGameTitle = "styles-module--oldGameTitle--95cc3";
export var oldGameWrapper = "styles-module--oldGameWrapper--17b4a";
export var subContent = "styles-module--subContent--0ae29";
export var subtitle = "styles-module--subtitle--4abd3";
export var title = "styles-module--title--0bfd8";
export var titles = "styles-module--titles--923d7";
export var wrapper = "styles-module--wrapper--cda8f";