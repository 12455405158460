import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { HelpCentreExploreFurther } from "components/HelpCentreExploreFurther/HelpCentreExploreFurther.builder";
import { toMutable } from "types/common";
import thumbUp from "./images/career-success-application-thumb-up.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Thank You for Your Application!",
    },
    {
        name: "subtitle1",
        type: "text",
        defaultValue: "Our team has received your application and we are excited to review it.",
    },
    {
        name: "subtitle2",
        type: "text",
        defaultValue:
            "If you are selected to move forward in the application process, we will contact you directly to schedule an interview.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Take me home",
    },
    {
        name: "buttonLink",
        type: "text",
        defaultValue: "/",
        required: true,
    },
    {
        name: "cards",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
            },
            {
                name: "icon",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
            {
                name: "description",
                type: "text",
            },
            {
                name: "link",
                type: "text",
                required: true,
            },
        ],
        defaultValue: [
            {
                title: "AY Blog",
                description: "Free contents about the industry concepts, trends and opportunities",
                icon: "",
                link: "/blog/all",
            },
            {
                title: "Product Updates",
                description:
                    "What's new on AY? This collection is an overview of new products and features release in AY tools.",
                icon: "",
                link: "/product-updates/all",
            },
            {
                title: "E-books, Guides and Videos",
                description: "Rich content about your main challenges to guide you and make your grow",
                icon: "",
                link: "/blog",
            },
        ],
    },
] as const;

const CareerSuccessApplication: BFC<typeof inputs> = ({
    title,
    subtitle1,
    subtitle2,
    buttonText,
    buttonLink,
    cards,
}) => {
    return (
        <div className="container">
            <div className={styles.mainContent}>
                <div className={styles.iconWrapper}>
                    <img src={thumbUp} alt="" />
                </div>
                <h1 className={styles.title}>{title}</h1>
                <p
                    style={{
                        marginBottom: "20px",
                    }}
                    className={styles.description}
                >
                    {subtitle1}
                </p>
                <p className={styles.description}>{subtitle2}</p>
            </div>
            <div className={styles.btnWrapper}>
                <CustomButton to={buttonLink}>{buttonText}</CustomButton>
            </div>

            <HelpCentreExploreFurther
                title={"Explore Further"}
                subtitle={"Discover free resources for deep-dive industry knowledge "}
                cards={cards}
            />
        </div>
    );
};

Builder.registerComponent(CareerSuccessApplication, {
    name: "careerSuccessApplication",
    inputs: toMutable(inputs),
});
