import type { VitalsCalculatedMetrics } from "../../shared";
import { CalculationType, type IMetric, OptimizationObjective } from "../../types";

export const VitalsRawMetrics = [
    "vitals_lcp_measurable",
    "vitals_lcp_sum",
    "vitals_lcp_good",
    "vitals_lcp_medium",
    "vitals_lcp_bad",
    "vitals_fid_measurable",
    "vitals_fid_sum",
    "vitals_fid_good",
    "vitals_fid_medium",
    "vitals_fid_bad",
    "vitals_cls_measurable",
    "vitals_cls_sum",
    "vitals_cls_good",
    "vitals_cls_medium",
    "vitals_cls_bad",
    "vitals_inp_measurable",
    "vitals_inp_sum",
    "vitals_inp_good",
    "vitals_inp_medium",
    "vitals_inp_bad",
    "vitals_ttfb_measurable",
    "vitals_ttfb_sum",
    "vitals_ttfb_good",
    "vitals_ttfb_medium",
    "vitals_ttfb_bad",
    "vitals_fcp_measurable",
    "vitals_fcp_sum",
    "vitals_fcp_good",
    "vitals_fcp_medium",
    "vitals_fcp_bad",
] as const;

export type VitalsRawMetrics = (typeof VitalsRawMetrics)[number];

export const WebVitalsMetrics = {
    vitals_lcp: {
        label: "WV Avg. LCP",
        explanation: "Avg. Largest Contentful Paint, in seconds. Pages should have a LCP of 2.5 seconds or less.",
        formula: m => m.vitals_lcp_sum / m.vitals_lcp_measurable,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    vitals_lcp_good_rate: {
        label: "WV LCP Good",
        explanation: "Rate of Largest Contentful Paint which were 2.5 seconds or less.",
        formula: m => m.vitals_lcp_good / m.vitals_lcp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    vitals_lcp_medium_rate: {
        label: "WV LCP Medium",
        explanation: "Rate of Largest Contentful Paint which were between 2.5 seconds and 4 seconds.",
        formula: m => m.vitals_lcp_medium / m.vitals_lcp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    vitals_lcp_bad_rate: {
        label: "WV LCP Bad",
        explanation: "Rate of Largest Contentful Paint which were 4 seconds or more.",
        formula: m => m.vitals_lcp_bad / m.vitals_lcp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_fid: {
        label: "WV Avg. FID",
        explanation: "Avg. First Input Delay, in milliseconds. Pages should have a FID of 100 milliseconds or less",
        formula: m => m.vitals_fid_sum / m.vitals_fid_measurable,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    vitals_fid_good_rate: {
        label: "WV FID Good",
        explanation: "Rate of First Input Delay which were 100 milliseconds or less",
        formula: m => m.vitals_fid_good / m.vitals_fid_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    vitals_fid_medium_rate: {
        label: "WV FID Medium",
        explanation: "Rate of First Input Delay which were between 100 milliseconds and 300 milliseconds",
        formula: m => m.vitals_fid_medium / m.vitals_fid_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_fid_bad_rate: {
        label: "WV FID Bad",
        explanation: "Rate of First Input Delay which were 300 milliseconds or more",
        formula: m => m.vitals_fid_bad / m.vitals_fid_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_cls: {
        label: "WV Avg. CLS",
        explanation: "Avg. Cumulative Layout Shift. Pages should have a CLS of 0.1 or less.",
        formula: m => m.vitals_cls_sum / m.vitals_cls_measurable,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    vitals_cls_good_rate: {
        label: "WV CLS Good",
        explanation: "Rate of Cumulative Layout Shift which were 0.1 or less",
        formula: m => m.vitals_cls_good / m.vitals_cls_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    vitals_cls_medium_rate: {
        label: "WV CLS Medium",
        explanation: "Rate of Cumulative Layout Shift which were between 0.1 and 0.25",
        formula: m => m.vitals_cls_medium / m.vitals_cls_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_cls_bad_rate: {
        label: "WV CLS Bad",
        explanation: "Rate of Cumulative Layout Shift which were 0.25 or more",
        formula: m => m.vitals_cls_bad / m.vitals_cls_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_inp: {
        label: "WV Avg. INP",
        explanation: "Avg. Interaction to Next Paint. Pages should have a INP of 200 milliseconds or less.",
        formula: m => m.vitals_inp_sum / m.vitals_inp_measurable,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    vitals_inp_good_rate: {
        label: "WV INP Good",
        explanation: "Rate of Interaction to Next Paint which were 200 milliseconds or less",
        formula: m => m.vitals_inp_good / m.vitals_inp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    vitals_inp_medium_rate: {
        label: "WV INP Medium",
        explanation: "Rate of Interaction to Next Paint which were between 200 milliseconds and 500 milliseconds",
        formula: m => m.vitals_inp_medium / m.vitals_inp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_inp_bad_rate: {
        label: "WV INP Bad",
        explanation: "Rate of Interaction to Next Paint which were 500 milliseconds or more",
        formula: m => m.vitals_inp_bad / m.vitals_inp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_ttfb: {
        label: "WV Avg. TTFB",
        explanation: "Avg. Time to First Byte. Pages should have a TTFB of 800 milliseconds or less.",
        formula: m => m.vitals_ttfb_sum / m.vitals_ttfb_measurable,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    vitals_ttfb_good_rate: {
        label: "WV TTFB Good",
        explanation: "Rate of Time to First Byte which were 800 milliseconds or less",
        formula: m => m.vitals_ttfb_good / m.vitals_ttfb_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    vitals_ttfb_medium_rate: {
        label: "WV TTFB Medium",
        explanation: "Rate of Time to First Byte which were between 800 milliseconds and 1800 milliseconds",
        formula: m => m.vitals_ttfb_medium / m.vitals_ttfb_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_ttfb_bad_rate: {
        label: "WV TTFB Bad",
        explanation: "Rate of Time to First Byte which were 1800 milliseconds or more",
        formula: m => m.vitals_ttfb_bad / m.vitals_ttfb_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_fcp: {
        label: "WV Avg. FCP",
        explanation: "Avg. First Contentful Paint. Pages should have a FCP of 1800 milliseconds seconds or less.",
        formula: m => m.vitals_fcp_sum / m.vitals_fcp_measurable,
        calculationType: CalculationType.Mean,
        objective: OptimizationObjective.Minimize,
    },
    vitals_fcp_good_rate: {
        label: "WV FCP Good",
        explanation: "Rate of First Contentful Paint which were 1800 milliseconds or less",
        formula: m => m.vitals_fcp_good / m.vitals_fcp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Maximize,
    },
    vitals_fcp_medium_rate: {
        label: "WV FCP Medium",
        explanation: "Rate of First Contentful Paint which were between 1800 milliseconds and 3000 milliseconds",
        formula: m => m.vitals_fcp_medium / m.vitals_fcp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
    vitals_fcp_bad_rate: {
        label: "WV FCP Bad",
        explanation: "Rate of First Contentful Paint which were 3000 milliseconds or more",
        formula: m => m.vitals_fcp_bad / m.vitals_fcp_measurable,
        calculationType: CalculationType.ProportionRatio,
        objective: OptimizationObjective.Minimize,
    },
} satisfies Record<string, IMetric<VitalsCalculatedMetrics, VitalsRawMetrics>>;
