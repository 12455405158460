import type { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import AllContent from "components/AllContent";
import NewsThumbnail from "components/NewsThumbnail";
import Seo from "components/seo";
import type { NewsData } from "types/common";
import * as styles from "./styles.module.scss";

const TITLE = "AY in the News";

const AllNewsPage = () => {
    const data = useStaticQuery(graphql`
        query AllNewsQuery {
            allNews(sort: { date: DESC }) {
                nodes {
                    id
                    link
                    title
                    hoverLogo
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);

    const news = data.allNews.nodes;

    return (
        <AllContent title={TITLE}>
            <Row className={styles.row}>
                {news.map((newsItem: NewsData) => {
                    return (
                        <Col
                            key={newsItem.id}
                            xs={12}
                            md={6}
                            style={{
                                marginBottom: "32px",
                            }}
                        >
                            <NewsThumbnail newsItem={newsItem} />
                        </Col>
                    );
                })}
            </Row>
        </AllContent>
    );
};

export const Head: FC<PageProps> = ({ location }) => (
    <Seo title={TITLE} titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": "${location.pathname}",
          "url": "${location.pathname}",
          "name": "News",
          "datePublished": "2023-01-16T23:37:53+00:00",
          "dateModified": "2023-03-21T16:10:45+00:00",
          "description": "Get the coolest, most insightful and latest in sell-side Adtech delivered to your inbox each month.",
          "inLanguage": "en-US",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.assertiveyield.com/#website",
            "url": "https://www.assertiveyield.com/",
            "name": "Assertive Yield",
            "description": "Investment Management Solutions",
            "inLanguage": "en-US",
          "publisher": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "image": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "URL": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
              },
              "query-input": {
                "@type": "PropertyValueSpecification",
                "valueRequired": "http://schema.org/True",
                "valueName": "search_term_string"
              }
            }
          }
        },
        "about": {
          "@type": "Organization",
          "@id": "https://www.assertiveyield.com/#organization",
          "name": "Assertive Yield",
          "url": "https://www.assertiveyield.com/",
          "sameAs": [
            "https://www.youtube.com/@assertiveyield",
            "https://www.linkedin.com/company/assertive-yield/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
            "inLanguage": "en-US",
            "url": "https://assertiveyield.com/img/og-image.png",
            "contentUrl": "https://assertiveyield.com/img/og-image.png",
            "width": 479,
            "height": 189,
            "caption": "Assertive Yield"
          }
      },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "@id": "https://www.assertiveyield.com/#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Assertive Yield",
              "item": {
                "@id": "https://www.assertiveyield.com/",
                "@type": "Thing"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "News"
            }
          ]
        },
        "potentialAction": {
          "@type": "ReadAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
          }
        }
      }
      `}
        </script>
    </Seo>
);
export default AllNewsPage;
