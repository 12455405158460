import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import firstGlow from "./images/academy-hero-first-glow.svg";
import secondGlow from "./images/academy-hero-second-glow.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Careers",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Find the right role for you",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Want to work with some of the best global talent and build software used by all the companies you know and love? Join the team — we’re hiring!",
    },
] as const;

const AcademyHero: BFC<typeof inputs> = ({ suptitle, title, subtitle }) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <img src={firstGlow} alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <img src={secondGlow} alt="" />
            </div>
            <div className={styles.mainContent}>
                <p className={styles.suptitle}>{suptitle}</p>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
        </div>
    );
};

Builder.registerComponent(AcademyHero, {
    name: "academyHero",
    inputs: toMutable(inputs),
});
