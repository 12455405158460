import { Col, Container, Row } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { Builder } from "@builder.io/react";
import HrHeading from "components/HrHeading";
import NewsThumbnail from "components/NewsThumbnail";
import TransparentGradientButton from "components/TransparentGradientButton";
import { NewsData, toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "AY in the News",
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "All News",
        required: true,
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/news/all",
        required: true,
    },
] as const;

const AyInTheNews: BFC<typeof inputs> = ({ title, buttonText, linkTo }) => {
    const data = useStaticQuery(graphql`
        query AyInTheNewsQuery {
            allNews(sort: { date: DESC }, limit: 3) {
                nodes {
                    id
                    link
                    title
                    hoverLogo
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
        }
    `);

    const news = data.allNews.nodes;

    return (
        <div
            style={{
                paddingTop: 0,
            }}
            className={[styles.container_wrapper].join(" ")}
        >
            <Container className="py-5">
                <HrHeading
                    heading={title}
                    buttonText={buttonText}
                    buttonTo={linkTo}
                    bg="light"
                    bgColor="#fff"
                    width="98px"
                    hideButtonOnMobile={true}
                />
                <Row>
                    {news.map((newsItem: NewsData) => {
                        return (
                            <Col key={newsItem.id} xs={12} md={4} className="mb-5 mb-xl-0">
                                <NewsThumbnail newsItem={newsItem} />
                            </Col>
                        );
                    })}
                </Row>

                <div className="mx-auto d-flex d-lg-none justify-content-center mt-4 mb-4">
                    <TransparentGradientButton
                        text={"All News"}
                        bg={"light"}
                        bgColor={"#fff"}
                        width={"298px"}
                        to={"/news/all/"}
                        contentWidth={"300px"}
                    />
                </div>
            </Container>
        </div>
    );
};

export default AyInTheNews;

Builder.registerComponent(AyInTheNews, {
    name: "ayInTheNews",
    inputs: toMutable(inputs),
});
