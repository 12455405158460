import { FC, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import type { PageProps } from "gatsby";
import { BOOK_CALL_LINK, CONTACT_US_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import Layout from "components/Layout";
// eslint-disable-next-line import/no-unresolved
import { AnalyticsPricing } from "components/pricing/content/analyticsPricing";
// eslint-disable-next-line import/no-unresolved
import { OptimizePricing } from "components/pricing/content/optimizePricing";
import Seo from "components/seo";
import "components/pricing/pricing.scss";
import gradleft from "./images/grad-left.png";
import gradright from "./images/grad-right.png";
const Index = () => {
    const [key, setKey] = useState("home");
    const [keyProduct, setKeyProduct] = useState("intelligence");

    return (
        <Layout bg="dark">
            <div className="content-dark">
                <div className="component-wrapper mt-70 mb-5 mb-lg-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-8 col-lg-6">
                                <h1 className="mb-4">Ready to get to the next level with AY</h1>
                                <p className="mb-5">Choose the products that are right for you</p>
                                <CustomButton buttonSize="btn--large" to={CONTACT_US_LINK}>
                                    Contact us
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-light pricing_page pt-5 pt-lg-0  overflow-hidden position-relative">
                <img src={gradleft} className={`gradient left`} style={{ top: "150px" }} alt="gradient" />
                <img src={gradright} className={`gradient right`} style={{ top: "150px" }} alt="gradient" />

                <Tabs
                    defaultActiveKey="home"
                    id="plans"
                    className={` nav-pills nav_tabs_custom`}
                    onSelect={k => setKey(k!)}
                    activeKey={key}
                >
                    <Tab eventKey="home" title="Products & Plans" tabClassName={`product_plans`}>
                        <Tabs
                            defaultActiveKey="intelligence"
                            id="products_plan"
                            className={` nav-pills nav_tabs_products`}
                            onSelect={k => setKeyProduct(k!)}
                            activeKey={keyProduct}
                        >
                            <Tab eventKey="intelligence" title="Yield Intelligence" tabClassName={`product_plan`}>
                                <div className="d-block  mt-5 mb-5 container">
                                    <h2 className="text-dark text-center">Yield Intelligence</h2>
                                    <p className="text-dark text-center">
                                        An insane amount of metrics and filters provide deep actionable insights into
                                        your website revenue generation.
                                    </p>
                                    <AnalyticsPricing />
                                    <h4 className="text-dark mt-4">All fees/costs are in USD excl. VAT.</h4>

                                    <p className="text-dark">
                                        1. The results provided by the pricing calculator are for informational purposes
                                        only. Your actual cost is based on metered usage at the stated rates.
                                        <br />
                                        2. The Storage Cost is dependent on data retention, here one month. The meter
                                        counts the actual amount of impressions stored at the given time.
                                    </p>
                                    <div className="text-center">
                                        <h2 className="text-dark  text-center mt-5">
                                            Not sure which plan is right for you?
                                        </h2>
                                        <p className="text-dark  text-center mb-5">
                                            Feel free to speak with our customer success manager
                                        </p>
                                        <CustomButton to={BOOK_CALL_LINK}>Talk to Sales</CustomButton>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="optimizer" title="Yield Optimizer">
                                <div className="d-block text-center mt-5 container">
                                    <h2 className="text-dark text-center">Yield Optimizer</h2>
                                    <OptimizePricing />
                                </div>
                            </Tab>
                            <Tab eventKey="manager" title="Yield Manager">
                                <div className="d-block text-center mt-6 mb-5 container">
                                    <h2 className="text-dark">Ready to be ahead in the game?</h2>
                                    <p className="text-dark mb-5">Make your data work for you!</p>
                                    <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                        Talk to Sales
                                    </CustomButton>
                                </div>
                            </Tab>
                            <Tab eventKey="shaping" title="Traffic Shaping">
                                <div className="d-block text-center mt-6 mb-5 container">
                                    <h2 className="text-dark">Let's play to win?</h2>
                                    <p className="text-dark mb-5">Make your data work for you!</p>
                                    <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                        Talk to Sales
                                    </CustomButton>
                                </div>
                            </Tab>
                            <Tab eventKey="acuisition" title="Traffic Acquisition">
                                <div className="d-block text-center mt-6 mb-5">
                                    <h2 className="text-dark">Let's play to win?</h2>
                                    <p className="text-dark mb-5">Make your data work for you!</p>
                                    <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                        Talk to Sales
                                    </CustomButton>
                                </div>
                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="bundles" title="Bundles">
                        <div className="d-block text-center mt-6 mb-5 container">
                            <h2 className="text-dark">Ready to Win ?</h2>
                            <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                Talk to Sales
                            </CustomButton>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    );
};
export const Head: FC<PageProps> = ({ location }) => (
    <Seo title="Pricing" titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {`
        {
          "@context": "https://schema.org",
          "@type": "WebPage",
          "@id": "${location.pathname}",
          "url": "${location.pathname}",
          "name": "Pricing",
          "datePublished": "2023-01-16T23:37:53+00:00",
          "dateModified": "2023-03-21T16:10:45+00:00",
          "description": "",
          "inLanguage": "en-US",
          "isPartOf": {
            "@type": "WebSite",
            "@id": "https://www.assertiveyield.com/#website",
            "url": "https://www.assertiveyield.com/",
            "name": "Assertive Yield",
            "description": "Investment Management Solutions",
            "inLanguage": "en-US",
          "publisher": {
            "@type": "Organization",
            "@id": "https://www.assertiveyield.com/#organization",
            "name": "Assertive Yield",
            "url": "https://www.assertiveyield.com/",
            "sameAs": [
              "https://www.youtube.com/@assertiveyield",
              "https://www.linkedin.com/company/assertive-yield/"
            ],
            "logo": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "url": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "image": {
              "@type": "ImageObject",
              "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
              "inLanguage": "en-US",
              "URL": "https://assertiveyield.com/img/og-image.png",
              "contentUrl": "https://assertiveyield.com/img/og-image.png",
              "width": 479,
              "height": 189,
              "caption": "Assertive Yield"
            },
            "potentialAction": {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://www.assertiveyield.com/?s={search_term_string}"
              },
              "query-input": {
                "@type": "PropertyValueSpecification",
                "valueRequired": "http://schema.org/True",
                "valueName": "search_term_string"
              }
            }
          }
        },
        "about": {
          "@type": "Organization",
          "@id": "https://www.assertiveyield.com/#organization",
          "name": "Assertive Yield",
          "url": "https://www.assertiveyield.com/",
          "sameAs": [
            "https://www.youtube.com/@assertiveyield",
            "https://www.linkedin.com/company/assertive-yield/"
          ],
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
            "inLanguage": "en-US",
            "url": "https://assertiveyield.com/img/og-image.png",
            "contentUrl": "https://assertiveyield.com/img/og-image.png",
            "width": 479,
            "height": 189,
            "caption": "Assertive Yield"
          }
      },
        "breadcrumb": {
          "@type": "BreadcrumbList",
          "@id": "https://www.assertiveyield.com/#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Assertive Yield",
              "item": {
                "@id": "https://www.assertiveyield.com/",
                "@type": "Thing"
              }
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Pricing"
            }
          ]
        },
        "potentialAction": {
          "@type": "ReadAction",
          "target": {
            "@type": "EntryPoint",
            "urlTemplate": "https://www.assertiveyield.com${location.pathname}"
          }
        }
      }
      `}
        </script>
    </Seo>
);

export default Index;
