import { FC, useEffect, useRef } from "react";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomButton from "components/CustomButton";
import next from "images/next.png";
import prev from "images/prev.png";
import type { BlogPostData } from "types/common";
import "./staytuned.scss";
import * as styles from "./styles.module.scss";

const StayTuned: FC<{ inputs: any }> = ({ inputs: { title, subtitle, buttonText, buttonLink } }) => {
    const buttonPrevRef = useRef<any>(null);
    const buttonNextRef = useRef<any>(null);
    const swiperInstance = useRef<any>(null);

    useEffect(() => {
        swiperInstance.current.params.navigation.prevEl = buttonPrevRef.current;
        swiperInstance.current.params.navigation.nextEl = buttonNextRef.current;
        swiperInstance.current.navigation.destroy();
        swiperInstance.current.navigation.init();
        swiperInstance.current.navigation.update();
    }, []);

    const data = useStaticQuery(graphql`
        query BlogPostsQuery {
            hits: allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" }, typeOfPost: { eq: Hit } } }
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        typeOfPost
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 904, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
            productUpdates: allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" }, typeOfPost: { eq: ProductUpdates } } }
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        typeOfPost
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 904, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
            common: allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" }, typeOfPost: { eq: Common } } }
                sort: { fields: frontmatter___date, order: DESC }
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        typeOfPost
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 904, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const renderPosts = (postsArray: BlogPostData[]) => {
        return postsArray.map((post: BlogPostData) => {
            const image = getImage(post.featuredImageFileExternal!);
            const postUrl = post.frontmatter.pageUrl ? "/blog/" + post.frontmatter.pageUrl : "/blog" + post.fields.slug;
            let minifiedDescription = post.frontmatter.description;

            if (minifiedDescription.length > 115) {
                minifiedDescription = minifiedDescription.substring(0, 115);

                minifiedDescription = minifiedDescription.substring(
                    0,
                    Math.min(minifiedDescription.length, minifiedDescription.lastIndexOf(" "))
                );

                minifiedDescription += "...";
            }

            return (
                <SwiperSlide key={post.id}>
                    <div
                        className="stay-tuned-card text-white"
                        onClick={() => {
                            navigate(postUrl);
                        }}
                    >
                        <div className="position-relative img-cont">
                            <Link to={postUrl}>
                                <GatsbyImage loading="lazy" className="card-img" image={image!} alt="Card image" />
                            </Link>
                        </div>
                        <div className="card-body">
                            <h4 className="card-title mt-5 mb-2">{post.frontmatter.title}</h4>
                            <p className="card-text mt-2 mb-4">
                                <span>{minifiedDescription}</span>
                                <span
                                    onClick={() => {
                                        navigate(postUrl);
                                    }}
                                    className={styles.readMore}
                                >
                                    Read More
                                </span>
                            </p>
                        </div>
                    </div>
                </SwiperSlide>
            );
        });
    };

    return (
        <div className="component-wrapper">
            <div className={`${styles.headerWrapper} container`}>
                <div className="d-flex justify-content-center flex-column flex-md-row justify-content-lg-between">
                    <div className="mb-3 text-center text-lg-start">
                        <h2>{title}</h2>
                        <p>{subtitle}</p>
                    </div>
                    <div className="d-none d-lg-block">
                        <CustomButton
                            customWidth="118px"
                            buttonSize="btn--large"
                            buttonStyle="btn--primary"
                            to={buttonLink}
                        >
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
            <div className="div swiper_container mb-3">
                <Swiper
                    modules={[Autoplay, Navigation]}
                    spaceBetween={20}
                    breakpoints={{
                        640: {
                            slidesPerView: 1.2,
                        },
                        768: {
                            slidesPerView: 2.2,
                        },
                        1024: {
                            slidesPerView: 3.2,
                        },
                    }}
                    autoplay={{
                        delay: 2600,
                        disableOnInteraction: false,
                    }}
                    navigation={{
                        prevEl: buttonPrevRef.current,
                        nextEl: buttonNextRef.current,
                    }}
                    onBeforeInit={swiper => {
                        swiperInstance.current = swiper;
                    }}
                >
                    {renderPosts(data.hits.nodes)}
                    {renderPosts(data.common.nodes)}
                    {renderPosts(data.productUpdates.nodes)}
                </Swiper>
            </div>
            <div className="d-block d-lg-none text-center">
                <CustomButton customWidth="300px" buttonSize="btn--large" buttonStyle="btn--primary" to={buttonLink}>
                    {buttonText}
                </CustomButton>
            </div>
            <div className="d-lg-flex d-none justify-content-end container">
                <div ref={buttonPrevRef} className="btn--circle me-3">
                    <img src={prev} alt="" />
                </div>

                <div ref={buttonNextRef} className="btn--circle">
                    <img src={next} alt="" />
                </div>
            </div>
        </div>
    );
};
export default StayTuned;
