import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Make your data work for you!",
    },
    {
        name: "list",
        type: "list",
        subFields: [
            {
                name: "statisticTitle",
                type: "text",
            },
            {
                name: "statisticDescription",
                type: "text",
            },
        ],
        defaultValue: [
            {
                statisticTitle: "+ 250",
                statisticDescription: "Bidders Tracked",
            },
            {
                statisticTitle: "+20 trillion",
                statisticDescription: "Data Points",
            },
            {
                statisticTitle: "+ 150",
                statisticDescription: "Active Users",
            },
            {
                statisticTitle: "+ 35Bn",
                statisticDescription: "Monthly Impressions Tracked",
            },
            {
                statisticTitle: "+ $40Mn",
                statisticDescription: "Monthly Revenue Tracked",
            },
        ],
    },
] as const;

const AboutUsMakeDataWork: BFC<typeof inputs> = ({ title, list }) => {
    return (
        <div className={styles.mainWrapper}>
            <div className="container">
                <div className={`${styles.firstGlow} gradient`}>
                    <StaticImage src="./images/about-us-make-data-work-first-glow.svg" alt="" />
                </div>
                <div className={styles.wrapper}>
                    <h2 className={styles.title}>{title}</h2>
                    <div className={styles.list}>
                        {list?.map(({ statisticTitle, statisticDescription }, index) => (
                            <div className={styles.item} key={index}>
                                <p className={styles.statisticTitle}>{statisticTitle}</p>
                                <p className={styles.statisticDescription}>{statisticDescription}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsMakeDataWork, {
    name: "aboutUsMakeDataWork",
    inputs: toMutable(inputs),
});
