import { ChangeEvent, useEffect, useState } from "react";

type TDebouncedInputProps = {
    value: string;
    onChange: (value: string) => void;
    debounceDelay?: number;
    placeholder?: string;
    closeIcon?: boolean;
    onCloseIconClick?: () => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const DebouncedInput: React.FC<TDebouncedInputProps> = ({
    value,
    onChange,
    debounceDelay = 300,
    placeholder = "",
    closeIcon = false,
    onCloseIconClick,
    ...rest
}) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            onChange(inputValue);
        }, debounceDelay);

        return () => {
            clearTimeout(debounceTimer);
        };
    }, [inputValue, debounceDelay, onChange]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    return closeIcon ? (
        <div>
            <input type="text" value={inputValue} onChange={handleInputChange} placeholder={placeholder} {...rest} />
            {inputValue && (
                <span
                    style={{
                        color: "#e2e2e2",
                        position: "absolute",
                        right: "13px",
                        top: "13px",
                        cursor: "pointer",
                    }}
                    onClick={() => {
                        setInputValue("");

                        onCloseIconClick?.();
                    }}
                >
                    &#10006;
                </span>
            )}
        </div>
    ) : (
        <input type="text" value={inputValue} onChange={handleInputChange} placeholder={placeholder} {...rest} />
    );
};

export default DebouncedInput;
