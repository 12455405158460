// extracted by mini-css-extract-plugin
export var adWrapper = "blog-post-module--adWrapper--f57a0";
export var button = "blog-post-module--button--db47b";
export var close = "blog-post-module--close--e8af5";
export var color_black = "blog-post-module--color_black--88768";
export var container_wrapper = "blog-post-module--container_wrapper--a097f";
export var content = "blog-post-module--content--77cb2";
export var description = "blog-post-module--description--15ac6";
export var faq = "blog-post-module--faq--b71cb";
export var faqWrapper = "blog-post-module--faqWrapper--9cedc";
export var header = "blog-post-module--header--c8581";
export var horizontalBanner = "blog-post-module--horizontalBanner--7141c";
export var hr = "blog-post-module--hr--321e9";
export var image = "blog-post-module--image--b8635";
export var item = "blog-post-module--item--2e826";
export var open = "blog-post-module--open--106a6";
export var relatedPosts = "blog-post-module--relatedPosts--e69c7";
export var sharePostTitle = "blog-post-module--sharePostTitle--1af95";
export var title = "blog-post-module--title--a65b0";