import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Winning SSPs can now reach new heights of revenue from DSPs.",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Incredibly high RPS (thousands per core), low memory consumption (400 MB) and fast response times (microseconds).",
    },
    {
        name: "buttonLink",
        type: "text",
        defaultValue: "/",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Start a Free PoC Today",
    },
] as const;

const TrafficShapingForPubsPlayNewGame: BFC<typeof inputs> = ({ title, subtitle, buttonLink, buttonText }) => {
    return (
        <div className="component-wrapper">
            <div className="container">
                <div className={styles.textWrapper}>
                    <h2 className={styles.title}>{title}</h2>
                    <p>{subtitle}</p>
                </div>
                <div className={styles.buttonWrapper}>
                    <CustomButton
                        buttonSize="btn--large"
                        buttonStyle={"btn--blue-pink"}
                        to={buttonLink}
                        customWidth="278px"
                    >
                        {buttonText}
                    </CustomButton>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(TrafficShapingForPubsPlayNewGame, {
    name: "TrafficShapingForPubsPlayNewGame",
    inputs: toMutable(inputs),
});
