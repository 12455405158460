import { Builder } from "@builder.io/react";
// eslint-disable-next-line import/no-unresolved
import { MovingData } from "components/blocks/moving_data/main";
import "./carousal.scss";

const OptimizerCarousel = () => {
    return (
        <div
            className="content-dark component-wrapper  pt-6 pb-6"
            style={{
                background: "#460085",
                marginTop: "180px",
                marginBottom: "180px",
            }}
        >
            <MovingData />
        </div>
        // <div className="component-wrapper mt-180">
        //   <div className="gradient left" />
        //   <div className="gradient right" />
        //   <div className="d-flex align-items-center opt-anim-container ">
        //     <div className="opt-ay position-relative">
        //       <StaticImage src="./images/frame.svg" alt="" className="img-fluid" />
        //     </div>
        //     <div className="opt-lines position-relative">
        //       <div className="real_time d-flex  justify-content-between">
        //         <img src={live} alt="" className="live" />
        //         REAL TIME
        //       </div>
        //       <div className="arrow-animation">
        //         <img src={rightAnim} alt="" className="element-right" />
        //         <img src={leftAnim} alt="" className="element-left  mt-3" />
        //       </div>
        //       <StaticImage src="./images/lines.svg" alt="" className="img-fluid" />
        //     </div>
        //     <div className="opt-grid flex-grow-1  text-end position-relative">
        //       <StaticImage src="./images/grid.svg" alt="" className="img-fluid" />

        //       <Marquee gradient={false} style={{ position: "absolute" }} speed={40}>
        //         <StaticImage
        //           src="./images/item1.svg"
        //           alt=""
        //           className="img-fluid"
        //         />
        //       </Marquee>
        //       <span className="title-item1">Exchanges</span>
        //       <Marquee
        //         gradient={false}
        //         speed={40}
        //         style={{ position: "absolute" }}
        //         className="item2"
        //       >
        //         {" "}
        //         <StaticImage
        //           src="./images/item2.svg"
        //           alt=""
        //           className="img-fluid"
        //         />
        //       </Marquee>
        //       <span className="title-item2">Metrics</span>

        //       <Marquee
        //         gradient={false}
        //         style={{ position: "absolute" }}
        //         className="item3"
        //         speed={40}
        //       >
        //         <StaticImage
        //           src="./images/item3.svg"
        //           alt=""
        //           className="img-fluid"
        //         />
        //       </Marquee>
        //       <span className="title-item3">Dimensions</span>
        //     </div>
        //   </div>
        // </div>
    );
};

Builder.registerComponent(OptimizerCarousel, {
    name: "OptimizerCarousel",
});
