import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./AllInOneSolutions.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        required: true,
        defaultValue: "All-in-one solution",
    },
    {
        name: "description",
        type: "longText",
        required: true,
        defaultValue:
            "Maximize your revenue with the industry's comprehensive yield solution for publishers—combining data automation, media quality insights, and inventory optimization across all of your inventory, whether direct-sold or programmatic",
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Slice & Dice Data Cube",
                required: true,
            },
            {
                name: "text",
                type: "longText",
                defaultValue:
                    "The Assertive Yield platform assists with granular data analysis by allowing you to slice and dice cube datasets through multidimensional overview, metrics, and filters.",
                required: true,
            },
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
            {
                name: "imgAlt",
                type: "text",
            },
            {
                name: "linkTo",
                type: "string",
                required: true,
                defaultValue: "/",
            },
        ],
    },
] as const;

const AllInOneSolutionsContent: BFC<typeof inputs> = ({ title, description, items }) => {
    return (
        <>
            <div className="row justify-content-center mb-lg-5">
                <div className="col-12 col-lg-8 text-center">
                    <h2>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
            <div className="row align-items-top justify-content-center">
                {(items ?? []).map(it => {
                    return (
                        <div className="col-12 col-lg-4 text-center mb-0 mb-lg-5" key={it.img}>
                            <div className={styles.solgradient}>
                                <img src={it.img} width="120" height="120" alt={`${it.imgAlt} logo`} />
                                <h4 className=" mt-3">{it.title}</h4>
                                <p>{it.text}</p>
                                <a href={it.linkTo} className={styles.linkWrapper}>
                                    <span className={styles.linkText}>Learn more</span>
                                    <StaticImage src="./images/arrow.svg" alt="arrow" />
                                </a>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

Builder.registerComponent(AllInOneSolutionsContent, {
    name: "AllInOneSolutions",
    inputs: toMutable(inputs),
});
