import type { FC } from "react";
import arrowRight from "./images/error-message-arrow-right.svg";
import close from "./images/error-message-close.svg";
import exclamation from "./images/error-message-exclamation.svg";
import * as styles from "./styles.module.scss";

const ErrorMessage: FC<{
    isShowErrorMessage: boolean;
    setShowErrorMessage: (isShowErrorMessage: boolean) => void;
}> = ({ isShowErrorMessage, setShowErrorMessage }) => {
    if (!isShowErrorMessage) {
        return null;
    }

    const reloadPage = () => {
        window.location.reload();
    };

    const closeErrorMessage = () => {
        setShowErrorMessage(false);
    };

    return (
        <div className="container">
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <div className={styles.exclamationWrapper}>
                        <img src={exclamation} alt="exclamation" />
                    </div>
                    <div className={styles.contentWrapper}>
                        <p className={styles.title}>Error</p>
                        <p className={styles.description}>There was a problem submitting your form. </p>
                        <div onClick={reloadPage} className={styles.linkWrapper}>
                            <p className={styles.linkText}>Try again</p>
                            <img src={arrowRight} alt="arrow right" />
                        </div>
                    </div>
                </div>
                <div onClick={closeErrorMessage} className={styles.closeWrapper}>
                    <img src={close} alt="close" />
                </div>
            </div>
        </div>
    );
};

export default ErrorMessage;
