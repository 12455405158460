import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import gmailImage from "./images/ay-glossary-havent-found-gmail.svg";
import instagramImage from "./images/ay-glossary-havent-found-instagram.svg";
import intercomImage from "./images/ay-glossary-havent-found-intercom.svg";
import linkedinImage from "./images/ay-glossary-havent-found-linkedin.svg";
import twitterImage from "./images/ay-glossary-havent-found-twitter.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "string",
        defaultValue: "Haven’t found what you’re looking for?",
    },
    {
        name: "subtitle",
        type: "string",
        defaultValue: "Contact Customer Success for further assistance.",
    },
    {
        name: "buttonText",
        type: "string",
        defaultValue: "Book a call",
    },
    {
        name: "linkTo",
        type: "string",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "list",
        type: "list",
        subFields: [
            {
                name: "img",
                type: "string",
                enum: ["gmailImage", "intercomImage", "linkedinImage", "twitterImage", "instagramImage"],
                required: true,
            },
            {
                name: "title",
                type: "string",
            },
            {
                name: "subtitle",
                type: "string",
            },
        ],
    },
] as const;

export const AyGlossaryHaventFound: BFC<typeof inputs> = ({ title, subtitle, buttonText, linkTo, list }) => {
    const images = {
        gmailImage,
        intercomImage,
        linkedinImage,
        twitterImage,
        instagramImage,
    };

    return (
        <div className={styles.wrapper}>
            <div className="container">
                <div className={styles.contentWrapper}>
                    <div className={styles.mainContent}>
                        <h2 className={styles.title}>{title}</h2>
                        <p>{subtitle}</p>
                        <CustomButton to={linkTo}>{buttonText}</CustomButton>
                    </div>
                    <div className={styles.ayContactWrapper}>
                        {list?.map(({ title, img, subtitle }, index) => {
                            return (
                                <div key={index}>
                                    <div className={styles.ayContactItemIcon}>
                                        <img src={images[img]} alt="" />
                                    </div>
                                    <h4 className={styles.ayContactItemTitle}>{title}</h4>
                                    <p>{subtitle}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AyGlossaryHaventFound, {
    name: "ayGlossaryHaventFound",
    inputs: toMutable(inputs),
});
