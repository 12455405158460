import type { FC } from "react";
import { Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "./styles.module.scss";

const LegalHeader: FC<{ title: string; subHeading: string }> = ({ title, subHeading }) => {
    const blobClasses = ["d-none", "d-xxl-block", styles.blur_blob];

    return (
        <div className={[styles.wrapper].join(" ")}>
            <Container>
                <div className={[styles.content_wrapper].join(" ")}>
                    <div className={[...blobClasses, styles.blob_left_1].join(" ")}>
                        <StaticImage src="images/legal_blob_left.png" alt="" />
                    </div>
                    <div className={[...blobClasses, styles.blob_left_2].join(" ")}>
                        <StaticImage src="images/legal_blob_left_2.png" alt="" />
                    </div>
                    <div className={[...blobClasses, styles.blob_right_1].join(" ")}>
                        <StaticImage src="images/legal_blob_right.png" alt="" />
                    </div>
                    <p className={[styles.date, "mb-3"].join(" ")}>Current as of 20 Jan 2023</p>
                    <h1 className={["figma-h2", "mb-4"].join(" ")}>{title}</h1>
                    <p className={[styles.subheading].join(" ")}>{subHeading}</p>
                </div>
            </Container>
        </div>
    );
};

export default LegalHeader;
