import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Still got more questions?",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue: "Feel free to speak with our customer success manager",
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Schedule a meeting",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: "/contact",
        required: true,
    },
] as const;
const StillGotMoreQuestions: BFC<typeof inputs> = ({ title, subtitle, buttonText, buttonTo }) => {
    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h3 className={styles.title}>{title}</h3>
                <p className={styles.subtitle}>{subtitle}</p>
                <CustomButton to={buttonTo} buttonStyle="btn--primary">
                    {buttonText}
                </CustomButton>
            </div>
        </div>
    );
};

Builder.registerComponent(StillGotMoreQuestions, {
    name: "stillGotMoreQuestions",
    inputs: toMutable(inputs),
});
