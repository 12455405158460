import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Our Story",
    },
    {
        name: "textParagraphs",
        type: "list",
        defaultValue: [
            {
                boldParagraph:
                    "Assertive Yield was born out of the need for publishers to have more transparency regarding advertising data.",
            },
            {
                paragraph:
                    "Assertive Yield was founded in 2019 to provide more transparency, ownership and revenue to digital publishers on their adstack. We believe in data-driven optimization and personalization to track data for digital publishers and increase advertising revenue.",
            },
            {
                paragraph:
                    "We built a next-generation SaaS platform for Yield Intelligence, Traffic Shaping, Revenue Optimization, and Yield Management. Publishers, SSPs, Arbitrage and AdNetworks around the world are being empowered by the tool’s disruptive solutions.",
            },
        ],
        subFields: [
            {
                name: "boldParagraph",
                type: "text",
            },
            {
                name: "paragraph",
                type: "text",
            },
        ],
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "The company was founded in a closed space in the city of Hague, in The Netherlands by our founder, Nils Lind with a mission to achieve something incredibly assertive. The company gradually emerged and grew bigger from a team of 2 to 25 talented senior professionals working from different parts of the world. Our biggest customers are renowned Publishers and SSPs hailing from the United States of America, the United Kingdom, Canada, and Israel among others. Over the last 3 years we have grown organically solely by word of mouth in the Ad Analytics and Publishers network, doubling our profit every year and helping our customers to become data-driven and thrive in their businesses. Currently, Assertive Yield is tracking over 250 bidders, with more than 20 trillion data points for 150+ active users worldwide.",
    },
    {
        name: "videoLink",
        type: "text",
        defaultValue: "/",
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif"],
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const AboutUsOurStory: BFC<typeof inputs> = ({
    subtitle,
    textParagraphs,
    description,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="container">
            <div className={`${styles.fistGlow} gradient`}>
                <StaticImage src="./images/about-us-our-story-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/about-us-our-story-second-glow.svg" alt="" />
            </div>
            <div className={`${styles.thirdGlow} gradient`}>
                <StaticImage src="./images/about-us-our-story-third-glow.svg" alt="" />
            </div>
            <h1 className={styles.title}>
                We are game changers. <br /> We love data, Adtech and transparency.
            </h1>
            <div className={styles.contentWrapper}>
                <div className={styles.leftContent}>
                    <p className={styles.subtitle}>{subtitle}</p>
                    {textParagraphs?.map(({ boldParagraph, paragraph }, index) => {
                        return (
                            <div className={styles.paragraphWrapper} key={index}>
                                {boldParagraph && <p className={styles.boldParagraph}>{boldParagraph}</p>}
                                {paragraph && <p>{paragraph}</p>}
                            </div>
                        );
                    })}
                </div>
                <div className={styles.rightContent}>
                    <div className={styles.image}>
                        <div className="position-relative ">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                <BuilderImage image={imageOrGif} lazy={false} sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <p>{description}</p>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsOurStory, {
    name: "aboutUsOurStory",
    inputs: toMutable(inputs),
});
