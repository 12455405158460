import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Welcome!",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "How can we help you today?",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Assertive yield expert customer success team is all around the globe, ready and excited to help.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Contact support",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
] as const;

const HelpCentreHero: BFC<typeof inputs> = ({ suptitle, title, subtitle, buttonText, linkTo }) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/help-centre-first-glow.svg" alt="" />
            </div>
            <div>
                <p className={styles.suptitle}>{suptitle}</p>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.subtitle}>{subtitle}</p>
                <CustomButton to={linkTo}>{buttonText}</CustomButton>
            </div>
        </div>
    );
};

Builder.registerComponent(HelpCentreHero, {
    name: "helpCentreHero",
    inputs: toMutable(inputs),
});
