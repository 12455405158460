// extracted by mini-css-extract-plugin
export var body = "styles-module--body--bb9dc";
export var fifthGlow = "styles-module--fifth-glow--d75f4";
export var firstGlow = "styles-module--first-glow--ebd73";
export var fourthGlow = "styles-module--fourth-glow--c5521";
export var question = "styles-module--question--aaf87";
export var questionHeader = "styles-module--question-header--4b3ef";
export var questionLink = "styles-module--question-link--e9ab1";
export var questionTitle = "styles-module--question-title--d73a9";
export var questions = "styles-module--questions--c6ffc";
export var secondGlow = "styles-module--second-glow--02d48";
export var stillMoreWrapper = "styles-module--still-more-wrapper--416d4";
export var subtitle = "styles-module--subtitle--16848";
export var thirdGlow = "styles-module--third-glow--851eb";
export var title = "styles-module--title--99fc7";
export var wrapper = "styles-module--wrapper--44555";