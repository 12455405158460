import type { FC, ReactNode } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Builder, withChildren } from "@builder.io/react";
import AllInOneSolutions from ".";

const YieldOptimizerAllInOneSolutions: FC<{ children: ReactNode }> = ({ children }) => (
    <AllInOneSolutions
        rightGradient={
            <div
                className={`gradient left`}
                style={{
                    top: "",
                    bottom: "-200px",
                }}
            >
                <StaticImage src="./images/allsol-gradeint-left.svg" alt="gradient" />
            </div>
        }
        leftGradient={
            <div
                className={`gradient right`}
                style={{
                    top: "0px",
                    bottom: "",
                }}
            >
                <StaticImage src="./images/allsol-gradient-right.svg" alt="gradient" />
            </div>
        }
    >
        {children}
    </AllInOneSolutions>
);
const YieldOptimizerAllInOneSolutionsWithChildren = withChildren(YieldOptimizerAllInOneSolutions);
Builder.registerComponent(YieldOptimizerAllInOneSolutionsWithChildren, {
    name: "YieldOptimizerAllInOneSolutions",
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element",
            component: { name: "Text", options: { text: "I am child text block!" } },
        },
    ],
});
