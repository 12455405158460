import { useEffect, useRef } from "react";
import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NewBlogThumbnail from "components/NewBlogThumbnail";
import TransparentGradientButton from "components/TransparentGradientButton";
import type { BlogPostData } from "types/common";
import prev from "./images/arrow-left.svg";
import next from "./images/arrow-right.svg";
import * as styles from "./styles.module.scss";

const AyBlogNewPosts = () => {
    const data = useStaticQuery(graphql`
        query NewBlogPostsQuery {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
                sort: { frontmatter: { date: DESC } }
                limit: 6
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        typeOfPost
                        featuredImage
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 1160, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const posts: BlogPostData[] = data.allMarkdownRemark.nodes;

    const buttonPrevRef = useRef<any>(null);
    const buttonNextRef = useRef<any>(null);
    const swiperInstance = useRef<any>(null);

    useEffect(() => {
        swiperInstance.current.params.navigation.prevEl = buttonPrevRef.current;
        swiperInstance.current.params.navigation.nextEl = buttonNextRef.current;
        swiperInstance.current.navigation.destroy();
        swiperInstance.current.navigation.init();
        swiperInstance.current.navigation.update();
    }, []);

    return (
        <div
            style={{
                paddingBottom: "48px",
            }}
            className={[styles.content_wrapper].join(" ")}
        >
            <Container>
                <div className={styles.header}>
                    <div>
                        <h1 className={styles.mainTitle}>AY Blog</h1>
                        <p className={styles.mainSubtitle}>
                            Learn about our new product features, the latest in Programmatic tech, solutions, and
                            updates.
                        </p>
                    </div>
                    <div className="d-none d-lg-block">
                        <TransparentGradientButton
                            to="/blog/all"
                            text="All Posts"
                            bg="light"
                            bgColor="#fff"
                            contentWidth="99px"
                            width="97px"
                        />
                    </div>
                </div>
                <div className={`d-none d-lg-flex ${styles.contentWrapper}`}>
                    <div className={styles.smallPosts}>
                        <div className={styles.smallPost}>
                            <NewBlogThumbnail smallPost={true} post={posts[0]} />
                        </div>
                        <div className={styles.smallPost}>
                            <NewBlogThumbnail smallPost={true} post={posts[1]} />
                        </div>
                    </div>
                    <div className={styles.bigPost}>
                        <NewBlogThumbnail post={posts[2]} />
                    </div>
                </div>

                <div className={`div swiper_container ms-0 mb-4 mb-lg-5 d-block d-lg-none`}>
                    <Swiper
                        modules={[Navigation]}
                        slidesPerView="auto"
                        spaceBetween={10}
                        breakpoints={{
                            320: {
                                slidesPerView: 1.05,
                            },
                            992: {
                                slidesPerView: 2,
                            },
                        }}
                        loop={true}
                        navigation={{
                            prevEl: buttonPrevRef.current,
                            nextEl: buttonNextRef.current,
                        }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        onBeforeInit={swiper => {
                            swiperInstance.current = swiper;
                        }}
                    >
                        {posts.map(post => (
                            <SwiperSlide key={post.id}>
                                <NewBlogThumbnail smallPost={true} post={post} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                <div className="d-flex d-lg-none justify-content-end mb-lg-0 mb-5">
                    <div ref={buttonPrevRef} className={`${styles.circleLight} me-3`}>
                        <img className={styles.sliderAction} src={prev} alt="" />
                    </div>

                    <div ref={buttonNextRef} className={`${styles.circleLight}`}>
                        <img className={styles.sliderAction} src={next} alt="" />
                    </div>
                </div>

                <div className="d-block d-lg-none d-flex justify-content-center">
                    <TransparentGradientButton
                        to="/blog/all"
                        text="All Posts"
                        bg="light"
                        bgColor="#fff"
                        contentWidth="300px"
                        width="298px"
                    />
                </div>
            </Container>
        </div>
    );
};

export default AyBlogNewPosts;
