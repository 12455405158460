import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "string",
        defaultValue: "Need more help?",
    },
    {
        name: "subtitle",
        type: "string",
        defaultValue: "Contact Customer Success for further assistance.",
    },
    {
        name: "buttonText",
        type: "string",
        defaultValue: "Book a call",
    },
    {
        name: "linkTo",
        type: "string",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "list",
        type: "list",
        subFields: [
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
            {
                name: "title",
                type: "string",
            },
            {
                name: "subtitle",
                type: "string",
            },
        ],
    },
] as const;

export const AyGlossaryNeedMoreHelp: BFC<typeof inputs> = ({ title, subtitle, buttonText, linkTo, list }) => {
    return (
        <div className={styles.wrapper}>
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/ay-help-center-hero-first-glow.svg" alt="" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/ay-help-center-need-more-help-second-glow.svg" alt="" />
            </div>
            <div className="container">
                <div className={styles.contentWrapper}>
                    <div className={styles.mainContent}>
                        <h2 className={styles.title}>{title}</h2>
                        <p>{subtitle}</p>
                        <CustomButton to={linkTo}>{buttonText}</CustomButton>
                    </div>
                    <div className={styles.ayContactWrapper}>
                        {list?.map(({ title, img, subtitle }, index) => {
                            return (
                                <div key={index}>
                                    <div className={styles.ayContactItemIcon}>
                                        <img src={img} alt="" />
                                    </div>
                                    <h4 className={styles.ayContactItemTitle}>{title}</h4>
                                    <p>{subtitle}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AyGlossaryNeedMoreHelp, {
    name: "ayGlossaryNeedMoreHelp",
    inputs: toMutable(inputs),
});
