import { useState } from "react";
import { Tooltip } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { InformationCircleIcon } from "components/blocks/moving_data/icons";
import { CONTACT_US_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { SliderRenderer } from "components/pricing/slider";
import { rgb } from "components/pricing/util/color";
import { IconTrigger } from "./icon_trigger";
import * as styles from "./content.module.scss";

const asAddonTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
        In combination with Yield Analytics and Monitoring
    </Tooltip>
);

const impressionCpmTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
        The cost of processed impressions (rendered impressions tracked)
    </Tooltip>
);

const costPerModelTooltip = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
        The cost of individually trained models (e.g. bid request reduction, dynamic flooring, revenue prediction and
        forecasting)
    </Tooltip>
);

const formatCost = (cost: number, digits = 2) => {
    return cost.toLocaleString(undefined, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
    });
};

const calcImpressionCPM = ({ multiplicator, slider }: { multiplicator: number; slider: number }) => {
    const impressionCost = 2.5 * slider * multiplicator;
    return impressionCost;
};

const totalCostFomatted = (args: { multiplicator: number; slider: number }) => {
    const impressionCPM = calcImpressionCPM(args);
    const modelCost = 200 * args.multiplicator;
    const totalCost = impressionCPM + modelCost;
    const totalCPM = totalCost / args.slider / 1000;
    return (
        <>
            {formatCost(totalCost)}
            <sup>1</sup>
            <br />
            <small>{formatCost(totalCPM, 4)} CPM</small>
        </>
    );
};

const SLIDER_DEFAULT_VALUE = 250;

export const OptimizePricing: React.FunctionComponent = () => {
    const [slider, setSlider] = useState(SLIDER_DEFAULT_VALUE);

    const impressionCPM = (multiplicator: number) => formatCost(calcImpressionCPM({ multiplicator, slider }));

    const totalCost = (multiplicator: number) => totalCostFomatted({ multiplicator, slider });

    return (
        <>
            <Col md={12} className="text-dark">
                <big>Use the slider below to calculate the cost for your volume of rendered impressions?</big>
            </Col>

            <SliderRenderer value={slider} onChange={setSlider} />
            <Col md={12} style={{ overflowX: "auto" }}>
                <table className="table mb-5  text-dark" style={{ minWidth: "500px" }}>
                    <thead>
                        <tr>
                            <th>Plan</th>
                            <th style={{ textAlign: "center" }}>Stand-Alone</th>
                            <th style={{ textAlign: "center" }}>
                                <IconTrigger tooltip={asAddonTooltip}>
                                    <span className="mr-1">As Addon</span>
                                    <InformationCircleIcon color={rgb(128, 128, 128)} size={17} />
                                </IconTrigger>
                            </th>
                            <th style={{ textAlign: "center" }}>Enterprise</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr id="calc_CostPerModel" className={styles.table_cell}>
                            <td>
                                <IconTrigger tooltip={costPerModelTooltip}>
                                    <span className="mr-1">Model Cost</span>
                                    <InformationCircleIcon color={rgb(128, 128, 128)} size={17} />
                                </IconTrigger>
                                <br />
                                <small>fixed price per model</small>
                            </td>
                            <td>
                                400.00<sup>2</sup>
                                <br />
                                <small>200,- /model</small>
                            </td>
                            <td>
                                200.00<sup>2</sup>
                                <br />
                                <small>200,- /model</small>
                            </td>
                            <td
                                rowSpan={2}
                                style={{
                                    display: "table-cell",
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                }}
                            >
                                up to 50% cheaper
                                <br />
                                <small>(based on volume with commitment)</small>
                                <br />
                                <br />
                                <div style={{ minWidth: "120px" }}>
                                    <CustomButton to={CONTACT_US_LINK}>Contact us</CustomButton>
                                </div>
                            </td>
                        </tr>
                        <tr id="calc_impressionCost" className={styles.table_cell}>
                            <td>
                                <IconTrigger tooltip={impressionCpmTooltip}>
                                    <span className="mr-1">Impression CPM</span>
                                    <InformationCircleIcon color={rgb(128, 128, 128)} size={17} />
                                </IconTrigger>
                                <br />
                                <small>metered processing rate</small>
                            </td>
                            <td>
                                {impressionCPM(2)}
                                <br />
                                <small>0.005 CPM</small>
                            </td>
                            <td>
                                {impressionCPM(1)}
                                <br />
                                <small>0.0025 CPM</small>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot className={styles.table_foot}>
                        <tr id="calc_totalCost" className={styles.table_cell}>
                            <td>Total (USD)</td>
                            <td>{totalCost(2)}</td>
                            <td>{totalCost(1)}</td>
                            <td>Starting at 1200,-</td>
                        </tr>
                    </tfoot>
                </table>
            </Col>

            <Col md={12}>
                All fees/costs are in USD excl. VAT.
                <hr />
                <sup>1</sup> The results provided by the pricing calculator are for informational purposes only. Your
                actual cost is based on metered usage at the stated rates.
                <br />
                <sup>2</sup> The Model Cost depends on the amount of individually trained models. On the Stand-Alone
                plan the minimum is two.
            </Col>
        </>
    );
};
