import type { FC, ReactNode } from "react";
import { Builder, withChildren } from "@builder.io/react";

const ContentDark: FC<{ children: ReactNode }> = ({ children }) => <div className="content-dark">{children}</div>;
const ContentDarkWithChildren = withChildren(ContentDark);

Builder.registerComponent(ContentDarkWithChildren, {
    name: "ContentDark",
    defaultChildren: [
        {
            "@type": "@builder.io/sdk:Element",
            component: { name: "Text", options: { text: "I am child text block!" } },
        },
    ],
});
