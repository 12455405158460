import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import arrowRight from "./images/ay-glossary-knowledge-hero-arrow-right.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "string",
        defaultValue: "AY Glossary - Knowledge Desk",
    },
    {
        name: "subtitle",
        type: "string",
        defaultValue:
            "Browse our knowledge desk for deep-dive industry knowledge on common terminologies you will come across on our tools.",
    },
] as const;

export const AyGlossaryKnowledgeDeskHero: BFC<typeof inputs> = ({ title, subtitle }) => {
    return (
        <>
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-knowledge-hero-first-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-knowledge-hero-second-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.thirdGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-knowledge-hero-third-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.sixthGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-knowledge-hero-sixth-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.seventhGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-knowledge-hero-seventh-glow.svg" alt="glow" />
            </div>
            <div className="container">
                <div className={styles.journeyWrapper}>
                    <div className={styles.journeyRoot}>
                        <Link className={styles.route} to="/help-center">
                            Help Center
                        </Link>
                    </div>
                    <div className={styles.journeyArrowRight}>
                        <img src={arrowRight} alt="" />
                    </div>
                    <div className={styles.journey}>
                        <p className={styles.currentRoute}>AY Glossary</p>
                    </div>
                </div>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.subtitle}>{subtitle}</p>
            </div>
        </>
    );
};

Builder.registerComponent(AyGlossaryKnowledgeDeskHero, {
    name: "ayGlossaryKnowledgeDeskHero",
    inputs: toMutable(inputs),
});
