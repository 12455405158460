// extracted by mini-css-extract-plugin
export var content = "styles-module--content--6268d";
export var description = "styles-module--description--1ab5e";
export var firstGlow = "styles-module--first-glow--a01cc";
export var image = "styles-module--image--ddc77";
export var mainContent = "styles-module--main-content--37cfe";
export var note = "styles-module--note--0e4d1";
export var secondGlow = "styles-module--second-glow--dc7f6";
export var subtitle = "styles-module--subtitle--81622";
export var suptitle = "styles-module--suptitle--c4b5e";
export var title = "styles-module--title--101c1";