import { Gallery, Item } from "react-photoswipe-gallery";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "Customize data access",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Sharing made easy",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Easily save, download, or share your customized data report with your team. With our save button, you can save your information precisely as you arrange them, complete with dimensions, filters, and metrics, without fear of losing essential data. Alternatively, download it for offline use or directly share the embed URL link with your team members for easy collaboration and data access. Restrict access while sharing by locking and unlocking the applied filters, dimensions, and metric",
        required: true,
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Schedule a Demo",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 686,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 521,
    },
] as const;
const SliceAndDiceSharingEasy: BFC<typeof inputs> = ({
    suptitle,
    title,
    subtitle,
    buttonTo,
    switchMagnifier,
    buttonText,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <div className="row align-items-center gx-5">
                        <div className="col-lg-6 order-lg-1 mt-4 mb-4 mt-lg-0 mb-lg-0">
                            <div className="mask-container  position-relative ">
                                {imageOrGifHover ? (
                                    <img
                                        onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                        onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                        src={imageOrGif}
                                        alt={imageOrGifAlt}
                                        className="img-fluid"
                                    />
                                ) : (
                                    <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                                )}
                                {switchMagnifier && !imageOrGifHover && (
                                    <Gallery>
                                        <Item
                                            width={imageOrGifWidth}
                                            height={imageOrGifHeight}
                                            original={imageOrGif}
                                            alt={imageOrGifAlt}
                                        >
                                            {({ ref, open }) => (
                                                // @ts-expect-error
                                                <div className="image_mask" ref={ref} onClick={open}>
                                                    <img src={glass} alt="zoom image" />
                                                </div>
                                            )}
                                        </Item>
                                    </Gallery>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                            <p className={styles.suptitle}>{suptitle}</p>
                            <h2>{title}</h2>
                            <p className="mb-5">{subtitle}</p>
                            <CustomButton buttonStyle="btn--primary" to={buttonTo}>
                                {buttonText}
                            </CustomButton>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(SliceAndDiceSharingEasy, {
    name: "sliceAndDiceSharingEasy",
    inputs: toMutable(inputs),
});
