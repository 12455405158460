import type { FC } from "react";
import { navigate } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { highlightTag } from "components/highlightTagBlog";
import type { BlogPostData } from "types/common";
import arrowRightBlue from "./images/arrow-right-blue.svg";
import * as styles from "./styles.module.scss";

const minifyDescription = (description: string) => {
    if (description.length > 200) {
        return description.slice(0, 200) + "...";
    }
    return description;
};

const HitThumbnailBig: FC<{ post: BlogPostData }> = ({ post }) => {
    const url = post.frontmatter.pageUrl ? "/blog/" + post.frontmatter.pageUrl : "/blog" + post.fields.slug;
    let image;

    if (
        (post.featuredImageFileExternal as any).childImageSharp === null &&
        post.frontmatter.featuredImage?.includes(".gif")
    ) {
        image = post.frontmatter.featuredImage;
    } else {
        image = getImage(post.featuredImageFileExternal!);
    }

    return (
        <div className={styles.blog_thumb_wrapper} onClick={() => navigate(url)}>
            {typeof image === "string" ? (
                <img className={`card-img ${styles.image}`} src={image} alt="Card image" loading="lazy" />
            ) : (
                <GatsbyImage image={image!} alt={"heading image"} className={`card-img ${styles.image}`} />
            )}
            <div>
                <p
                    style={{
                        color: highlightTag(post.frontmatter.tags ? post.frontmatter.tags[0] : ""),
                    }}
                    className={styles.blog_tags}
                >
                    {post.frontmatter.tags ? post.frontmatter.tags[0] : ""}
                </p>
                <p className={styles.blog_title}>{post.frontmatter.title}</p>
                <p className={styles.blog_description}>{minifyDescription(post.frontmatter.description)}</p>
                <div className={styles.arrowWrapper}>
                    <p className={styles.text}>Read more</p>
                    <img src={arrowRightBlue} alt="" />
                </div>
            </div>
        </div>
    );
};

export default HitThumbnailBig;
