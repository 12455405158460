import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import HitThumbnailBig from "components/HitThumbnailBig";
import HitThumbnailSmall from "components/HitThumbnailSmall";
import HrHeading from "components/HrHeading";
import TransparentGradientButton from "components/TransparentGradientButton";
import * as styles from "./styles.module.scss";

const HitBlogPosts = () => {
    const data = useStaticQuery(graphql`
        query HitBlogPotsQuery {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" }, typeOfPost: { eq: Hit } } }
                sort: { frontmatter: { date: DESC } }
                limit: 6
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        typeOfPost
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 1160, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const posts = data.allMarkdownRemark.nodes;

    if (data.allMarkdownRemark.nodes.length === 0) return null;

    return (
        <div className={[styles.content_wrapper].join(" ")}>
            <Container>
                <HrHeading
                    hideButtonOnMobile={true}
                    heading="Hits"
                    buttonText="All Hits"
                    buttonTo="/blog/all"
                    bg="light"
                    bgColor="#fff"
                    headingAs="h1"
                    marginBottom="20px"
                />
                <div className={styles.wrapper}>
                    <div
                        style={{
                            width: "46%",
                        }}
                        className="d-none d-lg-block"
                    >
                        <div
                            style={{
                                borderBottom: "1px solid #D9D9D9",
                                paddingBottom: "12px",
                                marginBottom: "12px",
                            }}
                        >
                            {posts[1] && <HitThumbnailSmall post={posts[1]} />}
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid #D9D9D9",
                                paddingBottom: "12px",
                                marginBottom: "12px",
                            }}
                        >
                            {posts[2] && <HitThumbnailSmall post={posts[2]} />}
                        </div>
                        <div>{posts[3] && <HitThumbnailSmall post={posts[3]} />}</div>
                    </div>
                    <div className={styles.mobilePosts}>
                        <div>{posts[0] && <HitThumbnailBig post={posts[0]} />}</div>
                        <div className="d-block d-lg-none">{posts[1] && <HitThumbnailBig post={posts[1]} />}</div>
                        <div className="d-block d-lg-none">{posts[2] && <HitThumbnailBig post={posts[2]} />}</div>
                        <div className="d-block d-lg-none">{posts[3] && <HitThumbnailBig post={posts[3]} />}</div>
                    </div>
                </div>

                <div className="mx-auto d-flex d-lg-none justify-content-center mt-4 mb-4">
                    <TransparentGradientButton
                        text={"All Hits"}
                        bg={"light"}
                        bgColor={"#fff"}
                        width={"298px"}
                        to={"/blog/all"}
                        contentWidth={"300px"}
                    />
                </div>
            </Container>
        </div>
    );
};

export default HitBlogPosts;
