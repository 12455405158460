import { FC, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, navigate, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import _ from "lodash";
import AdBannerWrapper from "components/adBannerWrapper";
import ProductUpdatesThumbnail from "components/blocks/ProductUpdatesThumbnail";
import BlogPost from "components/BlogPost";
import GoBackLink from "components/GoBackLink";
import Layout from "components/Layout";
import ScrollToTopButton from "components/scroll-to-top-button/scroll-to-top-button";
import Seo from "components/seo";
import minusIcon from "./images/blog-post-minus-icon.svg";
import plusIcon from "./images/blog-post-plus-icon.svg";
import * as styles from "./blog-post.module.scss";

const BlogPostTemplate: FC<PageProps<any>> = ({
    data: { markdownRemark: post, allMarkdownRemark, site, allAuthorsCollection, ads },
}) => {
    const posts = allMarkdownRemark.nodes;
    let image;

    if (post.featuredImageFileExternal.childImageSharp === null && post.frontmatter.featuredImage.includes(".gif")) {
        image = post.frontmatter.featuredImage;
    } else {
        image = getImage(post.featuredImageFileExternal);
    }

    const [openFaq, setOpenFaq] = useState<number[] | undefined>();

    const currentPerson = allAuthorsCollection.nodes.find((item: any) =>
        item.email === post.frontmatter.authorSelect
            ? post.frontmatter.authorSelect.trim()
            : post.frontmatter.authorSelect
    );

    const {
        horizontalAdFullWidthImageFileExternal,
        horizontalAdFullWidthTitle,
        horizontalAdFullWidthLink,
        horizontalAdFullWidthLinkText,
        horizontalAdFullWidthDescription,
    } = ads;

    let counter = 0;

    const horizontalAdFullWidthImage = getImage(horizontalAdFullWidthImageFileExternal);

    return (
        <Layout bg="light" contentType={"blog-post"}>
            <div className={styles.container_wrapper}>
                <Container>
                    <GoBackLink
                        onClick={() => {
                            navigate("/blog/all");
                        }}
                    />
                    <BlogPost
                        currentPerson={currentPerson}
                        ads={ads}
                        post={post}
                        image={
                            typeof image === "string" ? (
                                <img className="card-img mt-5" src={image} alt="Card image" loading="lazy" />
                            ) : (
                                <GatsbyImage image={image!} alt={"heading image"} className="card-img mt-5" />
                            )
                        }
                        site={site}
                    />
                    {post.frontmatter.faq ? (
                        <div className={styles.faqWrapper}>
                            <h2 className={styles.title}>Frequently asked questions</h2>

                            <div className={styles.faq}>
                                {post.frontmatter.faq.map((item: any, index: number) => (
                                    <div
                                        className={styles.item}
                                        onClick={() => {
                                            if (openFaq?.includes(index)) {
                                                setOpenFaq(prev => prev?.filter(item => item !== index));
                                            } else {
                                                setOpenFaq(prev => [...(prev || []), index]);
                                            }
                                        }}
                                        key={index}
                                    >
                                        <div className={styles.header}>
                                            <h3 className={styles.title}>{item.question}</h3>
                                            <img
                                                src={openFaq?.includes(index) ? minusIcon : plusIcon}
                                                alt="action icon"
                                            />
                                        </div>
                                        {
                                            <p
                                                className={[
                                                    styles.description,
                                                    openFaq?.includes(index) ? styles.open : styles.close,
                                                ].join(" ")}
                                            >
                                                {item.answer}
                                            </p>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : null}
                    {ads.horizontalAdFullWidthImageFileExternal ? (
                        <div className={styles.adWrapper}>
                            <AdBannerWrapper isCentralized={false} link={horizontalAdFullWidthLink}>
                                <div className={styles.horizontalBanner}>
                                    <div className={styles.image}>
                                        <GatsbyImage image={horizontalAdFullWidthImage!} alt={"heading image"} />
                                    </div>
                                    <div className={styles.content}>
                                        <h2 className={styles.title}>{horizontalAdFullWidthTitle}</h2>
                                        <p className={styles.description}>{horizontalAdFullWidthDescription}</p>
                                        <a href={horizontalAdFullWidthLink} className={styles.button}>
                                            {horizontalAdFullWidthLinkText}
                                        </a>
                                    </div>
                                </div>
                            </AdBannerWrapper>
                        </div>
                    ) : null}
                    <div className={[styles.relatedPosts, styles.color_black, "figma-h2", "mb-4", "mt-5"].join(" ")}>
                        Related Post
                    </div>
                    <Row>
                        {posts.map((postGeneral: any) => {
                            if (
                                _.intersection(postGeneral.frontmatter.tags, post.frontmatter.tags).length > 0 &&
                                postGeneral.id !== post.id &&
                                counter < 3
                            ) {
                                counter += 1;

                                return (
                                    <Col key={postGeneral.id} lg={4} md={6} sm={12} className="mb-4">
                                        <ProductUpdatesThumbnail post={postGeneral} />
                                    </Col>
                                );
                            }

                            return null;
                        })}
                    </Row>
                </Container>
                <ScrollToTopButton />
            </div>
        </Layout>
    );
};

export const Head: FC<PageProps<any>> = ({ location, data: { markdownRemark: post } }) => {
    let image;

    if (post.featuredImageFileExternal.childImageSharp === null && post.frontmatter.featuredImage.includes(".gif")) {
        image = post.frontmatter.featuredImage;
    } else {
        image = post.featuredImageFileExternal.childImageSharp.gatsbyImageData;
    }

    const cleanSpacesOnTheLeftAndRight = (str: string) => {
        if (!str) return "";

        const regex = /(?:^\s+|\s+$|\n)/gm;

        return str.replace(regex, "");
    };

    return (
        <Seo
            title={post.frontmatter.titleTag ? post.frontmatter.titleTag : post.frontmatter.title}
            description={
                post.frontmatter.metaDescription ? post.frontmatter.metaDescription : post.frontmatter.description
            }
            canonical={location.pathname}
            titleSuffixed={false}
        >
            <meta property="og:image:width" content={String(image.width)} />
            <meta property="og:image:height" content={String(image.height)} />
            <meta property="og:image" content={image.images?.fallback?.src} />
            <meta property="twitter:image" content={image.images?.fallback?.src} />
            <meta property="og:type" content="article" />
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "copyrightYear": "2023",
            "dateModified": "${cleanSpacesOnTheLeftAndRight(post.frontmatter.date)}",
            "datePublished": "${cleanSpacesOnTheLeftAndRight(post.frontmatter.date)}",
            "description": "${
                cleanSpacesOnTheLeftAndRight(post.frontmatter.metaDescription)
                    ? cleanSpacesOnTheLeftAndRight(post.frontmatter.metaDescription)
                    : cleanSpacesOnTheLeftAndRight(post.frontmatter.description)
            }",
            "headline": "${
                cleanSpacesOnTheLeftAndRight(post.frontmatter.titleTag)
                    ? cleanSpacesOnTheLeftAndRight(post.frontmatter.titleTag)
                    : cleanSpacesOnTheLeftAndRight(post.frontmatter.title)
            }",
            "inLanguage": "en-us",
            "mainEntityOfPage": "${location.pathname}",
            "name": "${
                cleanSpacesOnTheLeftAndRight(post.frontmatter.titleTag)
                    ? cleanSpacesOnTheLeftAndRight(post.frontmatter.titleTag)
                    : cleanSpacesOnTheLeftAndRight(post.frontmatter.title)
            }",
            "url": "${location.pathname}",
            "author": {
              "@type": "Person",
              "name": "${cleanSpacesOnTheLeftAndRight(post.frontmatter.author)}",
              "worksFor": {
                "@type": "Organization",
                "name": "Assertive Yield"
              }
            },
            "copyrightHolder": {
              "@type": "Organization",
              "name": "Assertive Yield",
              "URL": "https://www.assertiveyield.com/",
              "sameAs": [
                "https://www.youtube.com/@assertiveyield",
                "https://www.linkedin.com/company/assertive-yield/"
              ]
            },
            "image": {
              "@type": "ImageObject",
              "URL": "${image.images?.fallback?.src}",
              "height": "${image.height}",
              "width": "${image.width}"
            },
            "publisher": {
              "@type": "Organization",
              "name": "Assertive Yield",
              "URL": "https://www.assertiveyield.com/"
            }
          }
        `}
            </script>
        </Seo>
    );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($id: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }

        allMarkdownRemark(
            sort: { frontmatter: { date: DESC } }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
            nodes {
                id
                frontmatter {
                    title
                    description
                    date
                    relatedFeatures
                    tags
                    author
                    featuredImage
                    pageUrl
                }
                featuredImageFileExternal {
                    childImageSharp {
                        gatsbyImageData(quality: 95)
                    }
                }
                fields {
                    slug
                }
            }
        }

        allAuthorsCollection {
            nodes {
                name
                position
                email
                shortbio
                authorimage
            }
        }

        ads {
            verticalAdFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
            horizontalAdFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
            verticalAdLink
            horizontalAdLink
            horizontalAdFullWidthTitle
            horizontalAdFullWidthDescription
            horizontalAdFullWidthLink
            horizontalAdFullWidthLinkText
            horizontalAdFullWidthImageFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
        }

        markdownRemark(id: { eq: $id }) {
            id
            excerpt(pruneLength: 160)
            html
            fields {
                readingTime {
                    minutes
                }
                slug
            }
            featuredImageFileExternal {
                childImageSharp {
                    gatsbyImageData(quality: 95)
                }
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                featuredImage
                relatedFeatures
                tags
                authorSelect
                faq {
                    question
                    answer
                }
                author
                titleTag
                metaDescription
            }
        }
    }
`;
