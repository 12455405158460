import { useEffect, useState } from "react";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import failureIcon from "./images/old-new-game-failure-icon.svg";
import successIcon from "./images/old-new-game-success-icon.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "How you change the game?",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "ALL YOUR REVENUE DATA in one place in Real-Time across every dimension - Prebid, Dynamic Allocation and Direct - with tremendous granular data and hundred metrics extended with your custom data segments and audiences",
    },
    {
        name: "oldGameTitle",
        type: "text",
        defaultValue: "Old Game",
    },
    {
        name: "newGameTitle",
        type: "text",
        defaultValue: "New Game",
    },
    {
        name: "oldGameItems",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Old 3rd Party Data Analysis",
            },
        ],
        defaultValue: [
            {
                title: "Old 3rd Party Data Analysis",
            },
            {
                title: "Old 3rd Party Data Analysis",
            },
            {
                title: "Old 3rd Party Data Analysis",
            },
        ],
    },
    {
        name: "newGameItems",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Real-time 1st Party Data Analysis",
            },
        ],
        defaultValue: [
            {
                title: "Real-time 1st Party Data Analysis",
            },
            {
                title: "Real-time 1st Party Data Analysis",
            },
            {
                title: "Real-time 1st Party Data Analysis",
            },
        ],
    },
    {
        name: "subContentText",
        type: "text",
        defaultValue:
            "Viewability, exposure, user sessions, and page views are the old game metrics. Now you can have access to massive granular data, unlimited metrics across more than 100 dimensions.",
    },
] as const;

const OldNewGame: BFC<typeof inputs> = ({
    title,
    subtitle,
    oldGameTitle,
    newGameTitle,
    oldGameItems,
    newGameItems,
    subContentText,
}) => {
    const [isToggled, setIsToggled] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsToggled(!isToggled);
        }, 2000);
    }, [isToggled]);
    return (
        <>
            <div className="container mb-0 pb-0">
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>
                <div className={styles.wrapper}>
                    <div className={styles.titles}>
                        <h2 className={styles.oldGameTitle}>{oldGameTitle}</h2>
                        <h2 className={styles.newGameTitle}>{newGameTitle}</h2>
                    </div>
                    <div className={styles.items}>
                        <div className={styles.oldGameItem}>
                            <div className={styles.oldGameItemIcon}>
                                <img src={failureIcon} alt="failure icon" />
                            </div>
                            <p className={styles.oldGameItemTextTitle}>{oldGameItems![0].title}</p>
                        </div>
                        <div className={styles.newGameItem}>
                            <div className={styles.newGameItemIcon}>
                                <img src={successIcon} alt="success icon" />
                            </div>
                            <p className={styles.newGameItemTextTitle}>{newGameItems![0].title}</p>
                        </div>
                    </div>

                    <div className={styles.items}>
                        <div className={styles.oldGameItem}>
                            <div className={styles.oldGameItemIcon}>
                                <img src={failureIcon} alt="failure icon" />
                            </div>
                            <p className={styles.oldGameItemTextTitle}>{oldGameItems![1].title}</p>
                        </div>

                        <div className={styles.newGameItem}>
                            <div className={styles.newGameItemIcon}>
                                <img src={successIcon} alt="success icon" />
                            </div>
                            <p className={styles.newGameItemTextTitle}>{newGameItems![1].title}</p>
                        </div>
                    </div>

                    <div className={styles.items}>
                        <div className={styles.oldGameItem}>
                            <div className={styles.oldGameItemIcon}>
                                <img src={failureIcon} alt="failure icon" />
                            </div>
                            <p className={styles.oldGameItemTextTitle}>{oldGameItems![2].title}</p>
                        </div>

                        <div className={styles.newGameItem}>
                            <div className={styles.newGameItemIcon}>
                                <img src={successIcon} alt="success icon" />
                            </div>
                            <p className={styles.newGameItemTextTitle}>{newGameItems![2].title}</p>
                        </div>
                    </div>
                </div>

                <div className={styles.mobileWrapper}>
                    <div className={styles.oldGameWrapper}>
                        <h2 className={styles.oldGameTitle}>{oldGameTitle}</h2>
                        {oldGameItems!.map(({ title }, index) => (
                            <div className={styles.oldGameItem} key={index}>
                                <div className={styles.oldGameItemIcon}>
                                    <img src={failureIcon} alt="failure icon" />
                                </div>
                                <p className={styles.oldGameItemTextTitle}>{title}</p>
                            </div>
                        ))}
                    </div>

                    <div className={styles.newGameWrapper}>
                        <h2 className={styles.newGameTitle}>{newGameTitle}</h2>
                        {newGameItems!.map(({ title }, index) => (
                            <div className={styles.newGameItem} key={index}>
                                <div className={styles.newGameItemIcon}>
                                    <img src={successIcon} alt="success icon" />
                                </div>
                                <p className={styles.newGameItemTextTitle}>{title}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <p className={styles.subContent}>{subContentText}</p>
            </div>
        </>
    );
};

Builder.registerComponent(OldNewGame, {
    name: "oldNewGame",
    inputs: toMutable(inputs),
});
