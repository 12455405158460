import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./optimizer.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Ready to win? Discover Now",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "A brand new playbook to every website that monetize through ads - a digital publisher, an online newspaper, an online dictionary, gaming channels or retailers - that enables you to scale your optimizations and revenue growth",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a call",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "videoId",
        type: "text",
        defaultValue: "wk95wBopKhI",
    },
] as const;

const YieldOptimizerReadyToWin: BFC<typeof inputs> = ({ title, subtitle, buttonTo, buttonText, videoId }) => {
    const { desktop, mobile } = useStaticQuery(graphql`
        query {
            desktop: file(relativePath: { eq: "yo.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
            mobile: file(relativePath: { eq: "yoglobe.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
            }
        }
    `);
    const imageDesktop = getImage(desktop);
    const imageMobile = getImage(mobile);
    return (
        <div className={`${styles.products_video_height} component-wrapper mb-0 pb-0`} style={{ display: "grid" }}>
            <GatsbyImage
                className="d-lg-none"
                style={{
                    gridArea: "1/1",
                    zIndex: -1,
                }}
                alt=""
                image={imageMobile!}
                objectPosition="50% bottom"
            />

            <GatsbyImage
                className="d-none d-lg-block"
                style={{
                    gridArea: "1/1",
                    zIndex: -1,
                }}
                alt=""
                image={imageDesktop!}
            />

            <div
                className="container h-100"
                style={{
                    gridArea: "1/1",
                }}
            >
                <div className="row align-items-center h-100">
                    <div className="col-12 col-lg-6 order-lg-1 order-2">
                        <h2 className="mt-3 mb-3">{title}</h2>
                        <p className="mb-5">{subtitle}</p>
                        <CustomButton buttonStyle="btn--blue-pink" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>

                    {videoId ? (
                        <div className="col-12 order-lg-2 order-1 col-lg-6 mb-lg-0 mb-4">
                            <div className="d-flex justify-content-center">
                                <div className="w-100">
                                    <iframe
                                        title="Old Game vs New Game"
                                        loading="lazy"
                                        className="embed-responsive-item-block"
                                        src={`https://www.youtube.com/embed/${videoId}`}
                                        frameBorder="0"
                                        allow="autoplay; encrypted-media"
                                        allowFullScreen
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(YieldOptimizerReadyToWin, {
    name: "YieldOptimizerReadyToWin",
    inputs: toMutable(inputs),
});
