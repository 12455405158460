import type { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { graphql, PageProps, useStaticQuery } from "gatsby";
import AllContent from "components/AllContent";
import PodcastThumbnail from "components/PodcastThumbnail";
import Seo from "components/seo";
import type { PodcastsData } from "types/common";
import * as styles from "./styles.module.scss";

const TITLE = "Podcasts";

const PodcastsPage = () => {
    const data = useStaticQuery(graphql`
        query allPodcast {
            allPodcast(sort: { date: DESC }) {
                nodes {
                    id
                    date
                    title
                    body
                    description
                    plannedTime
                    linkURL
                    linkText
                }
            }
        }
    `);

    const podcasts = data.allPodcast.nodes;

    return (
        <AllContent title={TITLE}>
            <Row className={styles.row}>
                {podcasts.map((podcastItem: PodcastsData) => {
                    return (
                        <Col key={podcastItem.id} xs={12} md={6}>
                            <PodcastThumbnail podcastItem={podcastItem} />
                        </Col>
                    );
                })}
            </Row>
        </AllContent>
    );
};

export const Head: FC<PageProps> = ({ location }) => (
    <Seo title={TITLE} titleSuffixed canonical={location.pathname}>
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "WebPage",
                "@id": location.pathname,
                url: location.pathname,
                name: TITLE,
                datePublished: "2023-01-16T23:37:53+00:00",
                dateModified: "2023-03-21T16:10:45+00:00",
                description:
                    "Assertive Yield is a global company, created by publishers to publishers. Pioneer in offering Yield Revenue Management in Real-Time, from all revenue sources, with extraordinary granular data, as never seen before.",
                inLanguage: "en-US",
                isPartOf: {
                    "@type": "WebSite",
                    "@id": "https://www.assertiveyield.com/#website",
                    url: "https://www.assertiveyield.com/",
                    name: "Assertive Yield",
                    description: "Investment Management Solutions",
                    inLanguage: "en-US",
                },
                publisher: {
                    "@type": "Organization",
                    "@id": "https://www.assertiveyield.com/#organization",
                    name: "Assertive Yield",
                    url: "https://www.assertiveyield.com/",
                    sameAs: [
                        "https://www.youtube.com/@assertiveyield",
                        "https://www.linkedin.com/company/assertive-yield/",
                    ],
                    logo: {
                        "@type": "ImageObject",
                        "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                        inLanguage: "en-US",
                        url: "https://www.assertiveyield.com/wp-content/uploads/2021/09/assertive-yield-logo.png",
                        contentUrl:
                            "https://www.assertiveyield.com/wp-content/uploads/2021/09/assertive-yield-logo.png",
                        width: 479,
                        height: 189,
                        caption: "Assertive Yield",
                    },
                    image: {
                        "@type": "ImageObject",
                        "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                        inLanguage: "en-US",
                        url: "https://www.assertiveyield.com/wp-content/uploads/2021/09/assertive-yield-logo.png",
                        contentUrl:
                            "https://www.assertiveyield.com/wp-content/uploads/2021/09/assertive-yield-logo.png",
                        width: 479,
                        height: 189,
                        caption: "Assertive Yield",
                    },
                },
                potentialAction: {
                    "@type": "SearchAction",
                    target: {
                        "@type": "EntryPoint",
                        urlTemplate: "https://www.assertiveyield.com/?s={search_term_string}",
                    },
                    "query-input": {
                        "@type": "PropertyValueSpecification",
                        valueRequired: "http://schema.org/True",
                        valueName: "search_term_string",
                    },
                },
                about: {
                    "@type": "Organization",
                    "@id": "https://www.assertiveyield.com/#organization",
                    name: "Assertive Yield",
                    url: "https://www.assertiveyield.com/",
                    sameAs: [
                        "https://www.youtube.com/@assertiveyield",
                        "https://www.linkedin.com/company/assertive-yield/",
                    ],
                    logo: {
                        "@type": "ImageObject",
                        "@id": "https://www.assertiveyield.com/#/schema/logo/image/",
                        inLanguage: "en-US",
                        url: "https://www.assertiveyield.com/wp-content/uploads/2021/09/assertive-yield-logo.png",
                        contentUrl:
                            "https://www.assertiveyield.com/wp-content/uploads/2021/09/assertive-yield-logo.png",
                        width: 479,
                        height: 189,
                        caption: "Assertive Yield",
                    },
                },
                breadcrumb: {
                    "@type": "BreadcrumbList",
                    "@id": "https://www.assertiveyield.com/#breadcrumb",
                    itemListElement: [
                        {
                            "@type": "ListItem",
                            position: 1,
                            item: {
                                "@id": "https://www.assertiveyield.com/",
                                "@type": "Thing",
                                name: "Home",
                            },
                        },
                        {
                            "@type": "ListItem",
                            position: 2,
                            item: {
                                "@id": location.pathname,
                                "@type": "Thing",
                                name: TITLE,
                            },
                        },
                    ],
                },
            })}
        </script>
    </Seo>
);

export default PodcastsPage;
