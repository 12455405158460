// @ts-expect-error
// eslint-disable-next-line no-unused-vars
import React from "react";
import { Script } from "gatsby";

export const wrapPageElement = ({ element }) => {
    return (
        <>
            {element}
            {element.props.location.origin === "https://www.assertiveyield.com" ? (
                <>
                    <Script
                        async
                        defer
                        strategy="idle"
                        src="https://www.googletagmanager.com/gtag/js?id=UA-114303038-2"
                    />
                    <Script
                        async
                        defer
                        strategy="idle"
                        dangerouslySetInnerHTML={{
                            __html: `
                                window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', 'UA-114303038-2');
                            `,
                        }}
                    />
                    <Script
                        async
                        defer
                        strategy="idle"
                        dangerouslySetInnerHTML={{
                            __html: `
                                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                                })(window,document,'script','dataLayer','GTM-PMGC9G3');
                            `,
                        }}
                    />
                    <Script
                        async
                        defer
                        strategy="idle"
                        dangerouslySetInnerHTML={{
                            __html: `
                                !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
                                analytics.load("dG9z830omica4NchwuttNAnnYtNcuMGf");
                                analytics.page();
                                }}();
                            `,
                        }}
                    />
                </>
            ) : null}
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/docsearch.js/2/docsearch.min.css" />
            <Script async defer strategy="idle" src="https://cdn.jsdelivr.net/docsearch.js/2/docsearch.min.js" />
        </>
    );
};
