// extracted by mini-css-extract-plugin
export var btnWrapper = "styles-module--btn-wrapper--ca9e1";
export var firstGlow = "styles-module--first-glow--a3a1f";
export var icon = "styles-module--icon--c1f04";
export var image = "styles-module--image--0d89f";
export var item = "styles-module--item--f31d5";
export var linkText = "styles-module--link-text--590ba";
export var linkWrapper = "styles-module--link-wrapper--088a1";
export var mainContent = "styles-module--main-content--92431";
export var navigationList = "styles-module--navigation-list--28b04";
export var subtitle = "styles-module--subtitle--d5ed0";
export var title = "styles-module--title--66628";
export var wrapper = "styles-module--wrapper--73ad4";