// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--ec5ec";
export var firstGlow = "styles-module--first-glow--c7c1d";
export var image = "styles-module--image--f2621";
export var mainContent = "styles-module--main-content--7d7fa";
export var note = "styles-module--note--3de0d";
export var secondGlow = "styles-module--second-glow--6fe6b";
export var subtitle = "styles-module--subtitle--a1060";
export var suptitle = "styles-module--suptitle--d1b21";
export var title = "styles-module--title--0a820";
export var wrapper = "styles-module--wrapper--359c1";