import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Total Revenue Management solution in real-time with epic data granularity",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Our diverse experience with sell-side players is due to our extensive customer base over the years. Be prepared to “re-write your playbooks”, and forget the game as you know it.",
    },
    {
        name: "boldSubtitle",
        type: "text",
        defaultValue: "Discover why AY Solution are a game changer",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Try it Out, It's Free",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: "/",
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1161,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 714,
    },
] as const;

const ByCompanyTypeHero: BFC<typeof inputs> = ({
    title,
    boldSubtitle,
    subtitle,
    buttonText,
    linkTo,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/by-company-type-first-glow.svg" alt="" />
            </div>
            <div className={styles.wrapper}>
                <div className={styles.mainContent}>
                    <h1 className={styles.title}>{title}</h1>
                    <p className={styles.subtitle}>{subtitle}</p>
                    <p className={styles.boldSubtitle}>{boldSubtitle}</p>
                    <div className={styles.btn}>
                        <CustomButton to={linkTo}>{buttonText}</CustomButton>
                    </div>
                </div>
                <div className={styles.image}>
                    <div className="mask-container  position-relative ">
                        {imageOrGifHover ? (
                            <img
                                onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                src={imageOrGif}
                                alt={imageOrGifAlt}
                                className="img-fluid"
                            />
                        ) : (
                            <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                        )}
                        {switchMagnifier && !imageOrGifHover && (
                            <Gallery>
                                <Item
                                    width={imageOrGifWidth}
                                    height={imageOrGifHeight}
                                    original={imageOrGif}
                                    alt={imageOrGifAlt}
                                >
                                    {({ ref, open }) => (
                                        // @ts-expect-error
                                        <div className="image_mask" ref={ref} onClick={open}>
                                            <img src={glass} alt="zoom image" />
                                        </div>
                                    )}
                                </Item>
                            </Gallery>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(ByCompanyTypeHero, {
    name: "byCompanyTypeHero",
    inputs: toMutable(inputs),
});
