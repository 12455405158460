import * as Yup from "yup";

export const Schema = Yup.object().shape({
    email: Yup.string().email("Email is incorrect").required("This field is required"),
    firstName: Yup.string().required("This field is required").min(2, "Name is too short").max(50, "Name is too long"),
    lastName: Yup.string()
        .required("This field is required")
        .min(2, "Last name is too short")
        .max(50, "Last name is too long"),
    city: Yup.string().required("This field is required"),
    country: Yup.string().required("This field is required"),
    resume: Yup.mixed().required("This field is required"),
    linkedin: Yup.string()
        .required("This field is required")
        .test("linkedin", "LinkedIn is incorrect", value => {
            if (value) {
                return new RegExp("^([\\w]+\\.)?linkedin\\.com\\/(pub|in|profile)\\/([-a-zA-Z0-9]+)\\/*", "gm").test(
                    value
                );
            }
            return true;
        }),
    website: Yup.string().test("website", "Website is incorrect", value => {
        if (value) {
            return new RegExp("^(www\\.)?([a-zA-Z0-9]+\\.)+[a-zA-Z0-9]{2,4}\\/*", "gm").test(value);
        }
        return true;
    }),
    message: Yup.string().required("This field is required").max(700, "Message is too long"),
    referral_e_mail: Yup.string(),
    applyPolicy: Yup.boolean().oneOf([true], "You must agree to the terms and conditions"),
    category: Yup.string().required("This field is required"),
    captcha: Yup.string().required("Please verify that you are not a robot"),
});
