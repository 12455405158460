import type { FC } from "react";
import type { PageProps } from "gatsby";
import { BuilderComponent } from "@builder.io/react";
import Layout from "components/Layout";
import Seo from "components/seo";

function PageTemplate() {
    return (
        <Layout bg="dark">
            {/* @ts-ignore */}
            <BuilderComponent model="symbol" />
        </Layout>
    );
}

export const Head: FC<PageProps> = ({ location }) => {
    return <Seo noIndex title="Edit symbol" canonical={location.pathname} titleSuffixed={true} />;
};

export default PageTemplate;
