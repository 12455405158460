import { Builder } from "@builder.io/react";
import { Link, navigate } from "@reach/router";
import { useLocation } from "@reach/router";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Explore AY Solutions",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "Data-driven Publishers and SSPs area maximizing revenue by using a single platform to collect, analyze, and optimize their operations.",
    },
    {
        name: "navigationList",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Publishers",
            },
            {
                name: "linkTo",
                type: "text",
                defaultValue: "/",
                required: true,
            },
        ],
        defaultValue: [
            {
                title: "Publishers",
                linkTo: "/by-company-type-publishers",
            },
            {
                title: "Supply Side Platforms",
                linkTo: "/by-company-type-ssp",
            },
        ],
    },
] as const;

const ByCompanyTypeExploreSolutions: BFC<typeof inputs> = ({ title, subtitle, navigationList }) => {
    const routes = ["/by-company-type-publishers", "/by-company-type-ssp"];
    const isBrowser = typeof window !== "undefined";

    const location = isBrowser ? useLocation() : undefined;

    const currentRoute = routes.find(route => location?.pathname.includes(route));

    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h2>{title}</h2>
                <p>{subtitle}</p>
                <ul className={`d-none d-md-inline-flex ${styles.navigationWrapper}`}>
                    {navigationList?.map(({ title, linkTo }) => (
                        <li className={styles.navigationItem} key={title}>
                            <Link
                                className={
                                    currentRoute === linkTo ? styles.navigationLinkActive : styles.navigationLink
                                }
                                to={linkTo}
                            >
                                {title}
                            </Link>
                        </li>
                    ))}
                </ul>

                <select
                    className={`d-block d-md-none ${styles.selectWrapper}`}
                    onChange={e => navigate(e.target.value)}
                    defaultValue={location?.pathname ?? ""}
                >
                    {navigationList?.map(({ title, linkTo }) => (
                        <option value={linkTo} key={title}>
                            {title}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

Builder.registerComponent(ByCompanyTypeExploreSolutions, {
    name: "byCompanyTypeExploreSolutions",
    inputs: toMutable(inputs),
});
