import { useState } from "react";
import Select from "react-select";
import { graphql, useStaticQuery } from "gatsby";
import { Builder } from "@builder.io/react";
import SuccessStoriesCard from "components/SuccessStoriesCard/SuccessStoriesCard.builder";
import { SuccessStoriesData, toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [] as const;

const SuccessStoriesCompanies: BFC<typeof inputs> = () => {
    const { allSuccessStories }: { allSuccessStories: { nodes: SuccessStoriesData[] } } = useStaticQuery(graphql`
        query successStories {
            allSuccessStories {
                nodes {
                    id
                    clientLogoHover
                    clientLogoFileExternal {
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
                        }
                    }
                    video
                    pdf
                    relatedFeatures
                    sidebarImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(quality: 90)
                        }
                    }
                    title
                    description
                    category
                    fields {
                        slug
                    }
                }
            }
        }
    `);

    const options = Array.from(
        new Set(allSuccessStories.nodes.filter(node => node.category).map(node => node.category))
    ).map(category => ({ value: category, label: category }));

    options.push({ label: "All", value: "all" });

    const colorStyles = {
        option: (styles: any, { isFocused }: { isFocused: boolean }) => {
            return {
                ...styles,
                backgroundColor: isFocused ? "#F9FAFB" : null,
                color: "#000",
            };
        },
    };

    const items = allSuccessStories.nodes;

    const [filteredItems, setFilteredItems] = useState(items);

    const filterItem = (selectedCategory: any) => {
        const filtered =
            selectedCategory.value === "all" ? items : items.filter(node => node.category === selectedCategory.value);
        setFilteredItems(filtered);
    };
    return (
        <div className="content-light pricing_page pt-5 pt-lg-0  overflow-hidden position-relative">
            <div className="container">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <Select
                                options={options}
                                isSearchable={false}
                                className="mt-6 mb-6 z-2 position-relative"
                                styles={colorStyles}
                                onChange={filterItem}
                                placeholder="Choose Tag to Filter by"
                                components={{
                                    IndicatorSeparator: () => null,
                                }}
                            />
                        </div>
                    </div>

                    <div className={styles.stories}>
                        {!!filteredItems && filteredItems.length > 0 ? (
                            filteredItems.map(item => {
                                return (
                                    <div key={item.id} className={styles.story}>
                                        <SuccessStoriesCard story={item} />
                                    </div>
                                );
                            })
                        ) : (
                            <p className="text-dark">
                                Nothing in this category<b> This Text can be changed</b>
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(SuccessStoriesCompanies, {
    name: "successStoriesCompanies",
    inputs: toMutable(inputs),
});
