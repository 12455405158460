// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--a254c";
export var firstGlow = "styles-module--first-glow--ce0ba";
export var fourthGlow = "styles-module--fourth-glow--978de";
export var image = "styles-module--image--04cee";
export var mainContent = "styles-module--main-content--efef3";
export var note = "styles-module--note--94e2a";
export var secondGlow = "styles-module--second-glow--38124";
export var subtitle = "styles-module--subtitle--989bd";
export var suptitle = "styles-module--suptitle--9841a";
export var thirdGlow = "styles-module--third-glow--d747e";
export var title = "styles-module--title--86424";
export var wrapper = "styles-module--wrapper--6054c";