import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import gradient from "./images/type-right-gradient.svg";
import * as styles from "./empower.module.scss";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "How does Yield Manager help to empower your business?",
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Powerful & Frictionless Workflow",
                required: true,
            },
            {
                name: "subtitle",
                type: "longText",
                defaultValue:
                    "Speed up and improve workflows. Get faster time to market with no code on page, preview mode to test and QA, detailed change management, version history and review processes. Speed Up A/B testing Processes",
            },
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
        ],
    },
    {
        name: "description",
        type: "text",
        default:
            "Join the revolution in programmatic advertising with AY Prebid Server and experience the future of ad revenue optimization!",
    },
] as const;
const EmpowerAyPrebid: BFC<typeof inputs> = ({ title, items, description }) => {
    return (
        <div className={`${styles.strategy} component-wrapper`}>
            <div className={`gradient ${styles.right}`}>
                <img src={gradient} alt="" />
            </div>
            <div className="container">
                <div className="row text-lg-center mb-6">
                    <h2 className="text-center mb-5">{title}</h2>
                    {items.map(it => {
                        return (
                            <div className="mb-5 mb-lg-0 col-lg-4 position-relative" key={it.img}>
                                <div className={styles.cardblock}>
                                    <img src={it.img} alt="" className="m-lg-auto d-block" />
                                    <h4 className="text-lg-center mb-2">{it.title}</h4>
                                    <p>{it.subtitle}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <p className={styles.description}>{description}</p>
            </div>
        </div>
    );
};
Builder.registerComponent(EmpowerAyPrebid, {
    name: "EmpowerAyPrebid",
    inputs: toMutable(inputs),
});
