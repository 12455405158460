import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective } from "../types";

const RAW_METRICS = {
    predict_requests: {},
    result_requests: {},
    predict_errors: {},
    predict_bidders: {},
    _rows: {},
    predict_latencies_p50: {},
    predict_latencies_p95: {},
    predict_latencies_p99: {},
    result_errors: {},
    result_latencies_p50: {},
    result_latencies_p95: {},
    result_latencies_p99: {},
    predict_samples: {},
} as const satisfies Record<string, IRawMetric>;
export type RequestReductionServerLatencyRawMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = [
    "request_reduction_server_latency_predict_requests",
    "request_reduction_server_latency_predict_errors",
    "request_reduction_server_latency_predict_bidders",
    "request_reduction_server_latency_predict_latencies_p50",
    "request_reduction_server_latency_predict_latencies_p99",
    "request_reduction_server_latency_predict_latencies_p95",
    "request_reduction_server_latency_result_requests",
    "request_reduction_server_latency_predict_result_ratio",
    "request_reduction_server_latency_result_latencies_p50",
    "request_reduction_server_latency_result_errors",
    "request_reduction_server_latency_result_latencies_p99",
    "request_reduction_server_latency_result_latencies_p95",
    "request_reduction_server_latency_average_bidder_per_auctions",
    "request_reduction_server_latency_sampled_traffic_pct",
] as const;
export type RequestReductionServerLatencyMetricsType = (typeof CALC_IDS)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        request_reduction_server_latency_predict_requests: {
            label: "Predict Requests",
            explanation: "Requests for prediction",
            formula: m => m.predict_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        request_reduction_server_latency_predict_result_ratio: {
            label: "Predict / Result Ratio",
            explanation: "The ratio of Predict Requests and Result Requests.",
            formula: m => m.predict_requests / m.result_requests,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.NoObjective,
        },
        request_reduction_server_latency_predict_errors: {
            label: "Predict Errors",
            explanation: "Prediction requests that errored out",
            formula: m => m.predict_errors,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_predict_bidders: {
            label: "Predict Bidders",
            explanation: "Number of bidders that participated in the predicted auctions",
            formula: m => m.predict_bidders,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_latency_predict_latencies_p50: {
            label: "Predict Latencies P50 (ms)",
            explanation: "P50 of the predict latencies, in milliseconds",
            formula: m => m.predict_latencies_p50 / m.predict_requests,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_predict_latencies_p95: {
            label: "Predict Latencies P95 (ms)",
            explanation: "P95 of the predict latencies, in milliseconds",
            formula: m => m.predict_latencies_p95 / m.predict_requests,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_predict_latencies_p99: {
            label: "Predict Latencies P99 (ms)",
            explanation: "P99 of the predict latencies, in milliseconds",
            formula: m => m.predict_latencies_p99 / m.predict_requests,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_result_requests: {
            label: "Result Requests",
            explanation: "Requests for results",
            formula: m => m.result_requests,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        request_reduction_server_latency_result_errors: {
            label: "Result Errors",
            explanation: "Results that errored out",
            formula: m => m.result_errors,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_result_latencies_p50: {
            label: "Result Latencies P50 (ms)",
            explanation: "P50 of the latencies of results, in milliseconds",
            formula: m => m.result_latencies_p50 / m.result_requests,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_result_latencies_p95: {
            label: "Result Latencies P95 (ms)",
            explanation: "P95 of the latencies of results, in milliseconds",
            formula: m => m.result_latencies_p95 / m.result_requests,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_result_latencies_p99: {
            label: "Result Latencies P99 (ms)",
            explanation: "P99 of the latencies of results, in milliseconds",
            formula: m => m.result_latencies_p99 / m.result_requests,
            calculationType: CalculationType.SpecialRate,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        request_reduction_server_latency_average_bidder_per_auctions: {
            label: "Average Bidders per Auctions",
            explanation: "Average number of bidders per auction",
            formula: m => m.predict_bidders / m.predict_requests,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        request_reduction_server_latency_sampled_traffic_pct: {
            label: "Sampled Traffic %",
            explanation: "Percentage of traffic that was sampled",
            formula: m => (m.predict_samples / m.predict_requests) * 100,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
    });
