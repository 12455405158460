import { FC, useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";

const MessageList: FC<{
    title: string;
    messages: Array<{ title: string }>;
    delay: number;
}> = ({ title, messages, delay }) => {
    const [visibleMessages, setVisibleMessages] = useState<Array<number>>([]);
    const observerRef = useRef<IntersectionObserver>();

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    const index = parseInt((entry.target as HTMLElement).dataset.index!);
                    if (entry.isIntersecting) {
                        setTimeout(() => {
                            setVisibleMessages(prev => [...prev, index]);
                        }, delay * index);
                    }
                });
            },
            { threshold: 0.2 }
        );

        observerRef.current = observer;
    }, [delay, visibleMessages]);

    useEffect(() => {
        if (observerRef.current) {
            messages.forEach((_, index) => {
                const element = document.querySelector(`.${styles.messageWrapper}[data-index="${index}"]`);
                if (!element) return;

                observerRef.current!.observe(element);
            });
        }
    }, [messages]);

    return (
        <>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.messagesWrapper}>
                {!visibleMessages.length && <span className={styles.message}>...</span>}
                {messages.map((message, index) => (
                    <div key={index} data-index={index} className={styles.messageWrapper}>
                        {!visibleMessages.includes(index) && (
                            <span
                                className={styles.message}
                                style={{
                                    opacity: visibleMessages.includes(index - 1) ? 1 : 0,
                                }}
                            >
                                ...
                            </span>
                        )}
                        <span
                            className={styles.message}
                            style={{
                                height: visibleMessages.includes(index) ? "auto" : "0px",
                                opacity: visibleMessages.includes(index) ? 1 : 0,
                            }}
                        >
                            <div className={styles.cornerPolygon} />
                            {message.title}
                        </span>
                    </div>
                ))}
            </div>
        </>
    );
};

export default MessageList;
