import { useState } from "react";
import { Builder } from "@builder.io/react";
import { openIntercomChat } from "components/intercom";
import { toMutable } from "types/common";
import acrossImage from "./images/33across.svg";
import adSenseImage from "./images/adSense.png";
import amazonImage from "./images/amazon.svg";
import aniviewImage from "./images/aniview.svg";
import appnexusImage from "./images/appnexus.svg";
import avantisImage from "./images/avantis.svg";
import baiduImage from "./images/baidu.svg";
import blockthroughImage from "./images/blockthrough.svg";
import bridImage from "./images/brid.svg";
import brightCoveImage from "./images/brightCove.png";
import confiantImage from "./images/confiant.png";
import connatixImage from "./images/connatix.svg";
import emailWebhook from "./images/emailWebhook.svg";
import emxImage from "./images/emx.svg";
import escalatedImage from "./images/escalated.svg";
import exCoImage from "./images/exCo.svg";
import freestarImage from "./images/freestar.svg";
import googleAdManagerImage from "./images/googleAdManager.svg";
import googleAdsImage from "./images/googleAds.png";
import gumGUmImage from "./images/gumGUm.svg";
import imaSDKImage from "./images/imaSDK.png";
import indexExchangeImage from "./images/indexExchange.svg";
import intentIQImage from "./images/intentIQ.png";
import justPremiumImage from "./images/justPremium.svg";
import jwPlayerImage from "./images/jwPlayer.svg";
import kargoImage from "./images/kargo.svg";
import magniteImage from "./images/magnite.svg";
import mediaNetImage from "./images/mediaNet.svg";
import metaImage from "./images/meta.svg";
import minutemediaImage from "./images/minutemedia.svg";
import moreImage from "./images/more.svg";
import nativoImage from "./images/nativo.svg";
import onemobileImage from "./images/onemobile.svg";
import onetagImage from "./images/onetag.svg";
import openXImage from "./images/openX.png";
import outbrainImage from "./images/outbrain.svg";
import prebidImage from "./images/prebid.svg";
import primisImage from "./images/primis.svg";
import pubmaticImage from "./images/pubmatic.svg";
import riseImage from "./images/rise.svg";
import selectMediaImage from "./images/selectMedia.svg";
import sharethroughImage from "./images/sharethrough.svg";
import slackImage from "./images/slack.svg";
import snapchatImage from "./images/snapchat.svg";
import sovrnImage from "./images/sovrn.svg";
import stnImage from "./images/stn.svg";
import taboolaImage from "./images/taboola.svg";
import teadsImage from "./images/teads.svg";
import tiktokImage from "./images/tiktok.svg";
import tripleLiftImage from "./images/tripleLift.svg";
import twitterImage from "./images/twitter.svg";
import underdogMedia from "./images/underdogMedia.png";
import undertoneImage from "./images/undertone.svg";
import unrulyImage from "./images/unruly.svg";
import viImage from "./images/vi.png";
import vidazooImage from "./images/vidazoo.svg";
import videoJsImage from "./images/videoJs.png";
import yahooImage from "./images/yahoo.svg";
import yieldmoImage from "./images/yieldmo.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Connections",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "As a data company we rely a lot on our own ways to collect data, our predictions and forecasting methodologies and first party cookies. However, to have a monolithic overview of a publisher’s monetization, we are constantly adding new API integrations, custom solutions and we are working with a number of different partners, to enable the best experience for our partners.",
    },
] as const;

const AppsAndIntegrationsConnections: BFC<typeof inputs> = ({ title, subtitle }) => {
    const renderData = [
        {
            title: "Prebid.js",
            image: prebidImage,
            types: ["Revenue", "Yield Intelligence", "Traffic Acquisition", "API connections"],
        },
        {
            title: "Google Ad Manager",
            image: googleAdManagerImage,
            types: ["Revenue", "Yield Intelligence", "Traffic Acquisition", "API connections"],
        },
        {
            title: "Amazon",
            image: amazonImage,
            types: ["Revenue", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Meta (Facebook)",
            image: metaImage,
            types: ["Traffic Acquisition", "API connections"],
        },
        {
            title: "Google Ads",
            image: googleAdsImage,
            types: ["Spend", "Traffic Acquisition", "API connections"],
        },
        {
            title: "Vidazoo",
            image: vidazooImage,
            types: ["Revenue", "Video players", "Prebid", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Primis",
            image: primisImage,
            types: ["Revenue", "Video players", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Outbrain",
            image: outbrainImage,
            types: [
                "Revenue",
                "Spend",
                "Yield Intelligence",
                "Traffic Acquisition",
                "API connections",
                "Video players",
            ],
        },
        {
            title: "Taboola",
            image: taboolaImage,
            types: ["Revenue", "Spend", "Yield Intelligence", "Traffic Acquisition", "API connections"],
        },
        {
            title: "TripleLift",
            image: tripleLiftImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "AppNexus",
            image: appnexusImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Magnite",
            image: magniteImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "OpenX",
            image: openXImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Index Exchange",
            image: indexExchangeImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Teads",
            image: teadsImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Nativo",
            image: nativoImage,
            types: ["Revenue", "Yield Intelligence", "Traffic Acquisition", "API connections"],
        },
        {
            title: "BrightCove",
            image: brightCoveImage,
            types: ["Revenue", "Video players"],
        },
        {
            title: "JW Player",
            image: jwPlayerImage,
            types: ["Revenue", "Video players"],
        },
        {
            title: "Underdog Media",
            image: underdogMedia,
            types: ["Revenue"],
        },
        {
            title: "AdSense",
            image: adSenseImage,
            types: ["Revenue", "Yield Intelligence", "Traffic Acquisition", "API connections"],
        },
        {
            title: "Yahoo",
            image: yahooImage,
            types: ["Revenue", "Spend", "Traffic Acquisition", "API connections"],
        },
        {
            title: "Snapchat",
            image: snapchatImage,
            types: ["Spend", "Traffic Acquisition", "API connections"],
        },
        {
            title: "Twitter",
            image: twitterImage,
            types: ["Spend", "Traffic Acquisition", "API connections"],
        },
        {
            title: "Connatix",
            image: connatixImage,
            types: ["Revenue", "Video players", "Traffic Acquisition"],
        },
        {
            title: "Unruly",
            image: unrulyImage,
            types: ["Revenue", "Video players", "Prebid", "Traffic Acquisition"],
        },
        {
            title: "Baidu",
            image: baiduImage,
            types: ["Spend", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Undertone",
            image: undertoneImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Sovrn",
            image: sovrnImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Confiant",
            image: confiantImage,
            types: ["Others"],
        },
        {
            title: "Intent IQ",
            image: intentIQImage,
            types: ["Others"],
        },
        {
            title: "Sharethrough",
            image: sharethroughImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Pubmatic",
            image: pubmaticImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "VideoJS",
            image: videoJsImage,
            types: ["Revenue", "Video players"],
        },
        {
            title: "Media.net",
            image: mediaNetImage,
            types: ["Revenue", "Prebid"],
        },
        {
            title: "GumGum",
            image: gumGUmImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "33Across",
            types: ["Revenue", "Prebid", "Yield Intelligence"],
            image: acrossImage,
        },
        {
            title: "Yieldmo",
            image: yieldmoImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "TikTok",
            image: tiktokImage,
            types: ["Spend"],
        },
        {
            title: "EMX",
            image: emxImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Freestar",
            image: freestarImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Rise",
            image: riseImage,
            types: ["Revenue", "Video players", "Prebid", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Minute Media",
            image: minutemediaImage,
            types: ["Revenue", "Prebid"],
        },
        {
            title: "OneTag",
            image: onetagImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "OneMobile",
            image: onemobileImage,
            types: ["Revenue", "Prebid"],
        },
        {
            title: "Kargo",
            image: kargoImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "JustPremium",
            image: justPremiumImage,
            types: ["Revenue", "Prebid", "Yield Intelligence"],
        },
        {
            title: "Aniview",
            image: aniviewImage,
            types: ["Revenue", "Yield Intelligence", "Traffic Acquisition", "Video players"],
        },
        {
            title: "STN (Send to News)",
            image: stnImage,
            types: ["Revenue", "Video players", "Traffic Acquisition"],
        },
        {
            title: "IMA SDK",
            image: imaSDKImage,
            types: ["Revenue", "Video players"],
        },
        {
            title: "VI (Video Intelligence)",
            image: viImage,
            types: ["Revenue", "Video players", "Traffic Acquisition"],
        },
        {
            title: "BRID",
            image: bridImage,
            types: ["Revenue", "Video players", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Select Media",
            image: selectMediaImage,
            types: ["Revenue", "Video players", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "ExCo",
            image: exCoImage,
            types: ["Revenue", "Video players", "Prebid", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Slack",
            image: slackImage,
            types: ["Others"],
        },
        {
            title: "Email Webhook",
            image: emailWebhook,
            types: ["Others"],
        },
        {
            title: "Escalated",
            image: escalatedImage,
            type: ["Yield Intelligence"],
        },
        {
            title: "Avantis",
            image: avantisImage,
            types: ["Revenue", "Video players", "Yield Intelligence", "Traffic Acquisition"],
        },
        {
            title: "Blockthrough",
            image: blockthroughImage,
            types: ["Others"],
        },
    ];

    const [activePoint, setActivePoint] = useState("All");

    const switcherPoints = [
        "All",
        "Revenue",
        "Spend",
        "Video players",
        "Prebid",
        "Yield Intelligence",
        "Traffic Acquisition",
        "API connections",
        "SSPs",
        "Others",
    ];

    const filteredData =
        activePoint === "All" ? renderData : renderData.filter(item => item?.types?.includes(activePoint) ?? false);

    return (
        <div className={styles.wrapper}>
            <div className="container">
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.subtitle}>{subtitle}</p>
                <ul className={`${styles.switcher} d-lg-inline-flex d-none`}>
                    {switcherPoints.map(point => (
                        <li
                            key={point}
                            className={`${styles.switcherPoint} ${activePoint === point ? styles.active : ""}`}
                            onClick={() => setActivePoint(point)}
                        >
                            {point}
                        </li>
                    ))}
                </ul>
                <select
                    className={`${styles.switcher} d-lg-none`}
                    value={activePoint}
                    onChange={event => setActivePoint(event.target.value)}
                >
                    {switcherPoints.map(point => (
                        <option key={point} value={point}>
                            {point}
                        </option>
                    ))}
                </select>
                <div className={styles.cardWrapper}>
                    {filteredData.length &&
                        filteredData.map(({ title, image }, index) => {
                            return (
                                <div className={styles.gradientBorder} key={index}>
                                    <div onClick={openIntercomChat} className={`col-3 ${styles.card}`}>
                                        <div className={styles.companyLogo}>
                                            <img
                                                loading="lazy"
                                                src={image}
                                                alt={title}
                                                className={`${styles.image} img-fluid`}
                                            />
                                        </div>
                                        <div>
                                            <p className={styles.cardTitle}>{title}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    {filteredData.length && (
                        <div className={styles.gradientBorder}>
                            <div onClick={openIntercomChat} className={`col-3 ${styles.card}`}>
                                <div className={styles.companyLogo}>
                                    <img
                                        loading="lazy"
                                        src={moreImage}
                                        alt={title}
                                        className={`${styles.image} img-fluid`}
                                    />
                                </div>
                                <div>
                                    <p className={styles.cardTitle}>And much more!</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {!filteredData.length && (
                <div className="text-center">
                    <h2 className="text-dark">Coming Soon!</h2>
                </div>
            )}
        </div>
    );
};

Builder.registerComponent(AppsAndIntegrationsConnections, {
    name: "appsAndIntegrationsConnections",
    inputs: toMutable(inputs),
});
