import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./empower.module.scss";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "How does Yield Manager help to empower your business?",
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Powerful & Frictionless Workflow",
                required: true,
            },
            {
                name: "subtitle",
                type: "longText",
                defaultValue:
                    "Speed up and improve workflows. Get faster time to market with no code on page, preview mode to test and QA, detailed change management, version history and review processes. Speed Up A/B testing Processes",
            },
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
        ],
    },
] as const;
const Empower: BFC<typeof inputs> = ({ title, items }) => {
    return (
        <div className={`${styles.strategy} component-wrapper`}>
            <div className={`gradient ${styles.left}`}>
                <StaticImage src="images/type-left-gradient.png" alt="" />
            </div>
            <div className={`gradient ${styles.right}`}>
                <StaticImage src="images/type-right-gradient.png" alt="" />
            </div>
            <div className="container">
                <div className="row text-lg-center">
                    <h2 className="text-center mb-5">{title}</h2>
                    {items.map(it => {
                        return (
                            <div className="mb-5 mb-lg-0 col-lg-4 position-relative" key={it.img}>
                                <div className={styles.cardblock}>
                                    <img src={it.img} alt="" className="m-lg-auto d-block" />
                                    <h4 className="text-lg-center mb-2">{it.title}</h4>
                                    <p>{it.subtitle}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(Empower, {
    name: "Empower",
    inputs: toMutable(inputs),
});
