export const highlightTag = (tag: string) => {
    switch (tag.toLowerCase()) {
        case "ad revenue management":
            return "#F0BC02";
        case "data intelligence":
            return "#5B77F8";
        case "ay industry insights":
            return "#00CB9E";
        case "efficiency & sustainability":
            return "#00CB9E";
        default:
            return "#000";
    }
};
