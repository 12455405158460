import type { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { micromark } from "micromark";
import SubscribeToAyNews from "components/blocks/SubscribeToAyNews";
import CustomButton from "components/CustomButton";
import GoBackLink from "components/GoBackLink";
import { useCopyToClipboard } from "components/hooks/use-copy-to-clipboard";
import Layout from "components/Layout";
import MarkdownRenderer from "components/MarkdownRenderer";
import Seo from "components/seo";
import type { TCareers } from "types/common";
import FacebookIcon from "./images/careers-template-facebook";
import IsFullTimeIcon from "./images/careers-template-is-full-time.svg";
import LinkIcon from "./images/careers-template-link";
import LinkedinIcon from "./images/careers-template-linkedin";
import LocationIcon from "./images/careers-template-location.svg";
import TeamTypeIcon from "./images/careers-template-team-type.svg";
import TwitterIcon from "./images/careers-template-twitter";
import * as styles from "./careers.module.scss";

const CareersTemplate: FC<{ data: { careers: TCareers } }> = ({ data }) => {
    const { title, description, body, location, isFullTime, category, fields } = data.careers;

    const getPostUrl = (siteUrl: string) => `${siteUrl}careers${fields.slug}`;

    const { onCopy } = useCopyToClipboard();

    const siteUrl = "https://assertiveyield.com/";

    const shareToTwitter = (siteUrl: string) => {
        const careerTitle = encodeURI(title);
        const url = encodeURI(getPostUrl(siteUrl));
        return `https://twitter.com/intent/tweet/?url=${siteUrl}${url}&text=${careerTitle}`;
    };
    const shareToFacebook = (siteUrl: string) => {
        const url = encodeURI(getPostUrl(siteUrl));
        return `https://facebook.com/sharer/sharer.php?u=${siteUrl}${url}`;
    };
    const shareToLinkedin = (siteUrl: string) => {
        const url = encodeURI(getPostUrl(siteUrl));
        const careerTitle = encodeURI(title);
        const careerDescription = encodeURI(description);
        return `https://www.linkedin.com/shareArticle?url=${siteUrl}${url}&title=${careerTitle}&summary=${careerDescription}&source=${url}&mini=true`;
    };

    return (
        <Layout bg="light">
            <div
                className="component-wrapper position-absolute m-0 p-0"
                style={{
                    top: "0",
                    width: "100%",
                    minHeight: "350px",
                }}
            />
            <div className="content-light">
                <div className="component-wrapper mb-0 mt-0">
                    <div className="container mt-5 mt-lg-6">
                        <GoBackLink />
                        <div className="row">
                            <div className="col">
                                <h1
                                    className={`mb-3 mb-lg-6 text-black ${styles.title}`}
                                    style={{ whiteSpace: "pre-line" }}
                                >
                                    {title}
                                </h1>
                            </div>
                        </div>
                        <div className="row gx-5 mt-lg-5">
                            <div className="col-lg-9 order-lg-2 order-2">
                                <div className="stories-content">
                                    <MarkdownRenderer
                                        post={{
                                            html:
                                                body == null
                                                    ? ""
                                                    : micromark(body, {
                                                          allowDangerousHtml: true,
                                                      }),
                                        }}
                                    />
                                    <div className={styles.lookForwardWrapper}>
                                        <h2 className={styles.lookForwardTitle}>We look forward to hearing from you</h2>
                                        <p className={styles.lookForwardDescription}>
                                            At Assertive Yield, we're looking for people with passion, grit, and
                                            integrity. You're encouraged to apply even if your experience doesn't
                                            precisely match the job description. Your skills and passion will stand
                                            out—and set you apart—especially if your career has taken some extraordinary
                                            twists and turns. At Assertive Yield, we welcome diverse perspectives and
                                            people who think rigorously and aren't afraid to challenge assumptions. Join
                                            us.
                                        </p>
                                        <CustomButton to={`/apply${fields.slug}`}>Apply for this role</CustomButton>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 order-lg-2 order-1 mb-5 mb-lg-0">
                                <div>
                                    <div className={styles.sidebarInfoWrapper}>
                                        <img src={LocationIcon} alt="" />
                                        <p className={styles.sidebarInfoText}>{location}</p>
                                    </div>
                                    <div className={styles.sidebarInfoWrapper}>
                                        <img src={IsFullTimeIcon} alt="" />
                                        <p className={styles.sidebarInfoText}>
                                            {isFullTime ? "Full-time" : "Part-time"}
                                        </p>
                                    </div>
                                    <div className={styles.sidebarInfoWrapper}>
                                        <img src={TeamTypeIcon} alt="" />
                                        <p className={styles.sidebarInfoText}>{category}</p>
                                    </div>
                                    <div className={styles.sidebarInfoButton}>
                                        <CustomButton to={`/apply${fields.slug}`}>Apply for this role</CustomButton>
                                    </div>
                                    <div className={styles.sidebarSocialLinks}>
                                        <div
                                            className="career-sidebar-social-link-wrapper"
                                            onClick={() => {
                                                onCopy(getPostUrl(`${siteUrl}`));
                                            }}
                                        >
                                            <LinkIcon />
                                        </div>
                                        <a
                                            className="career-sidebar-social-link-wrapper"
                                            href={shareToTwitter(siteUrl)}
                                        >
                                            <TwitterIcon />
                                        </a>
                                        <a
                                            className="career-sidebar-social-link-wrapper"
                                            href={shareToFacebook(siteUrl)}
                                        >
                                            <FacebookIcon />
                                        </a>
                                        <a
                                            className="career-sidebar-social-link-wrapper"
                                            href={shareToLinkedin(siteUrl)}
                                        >
                                            <LinkedinIcon />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.subscribeWrapper}>
                                <SubscribeToAyNews title="Subscribe to get updates" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export const Head: FC<PageProps<any>> = ({ location, data: { careers: post } }) => {
    const cleanSpacesOnTheLeftAndRight = (str: string) => {
        if (!str) return "";

        const regex = /(?:^\s+|\s+$|\n)/gm;

        return str.replace(regex, "");
    };

    return (
        <Seo title={post.title} description={post.description} canonical={location.pathname} titleSuffixed={false}>
            <meta property="og:type" content="article" />
            <script type="application/ld+json">
                {`
          {
            "@context": "https://schema.org",
            "@type": "Article",
            "copyrightYear": "2023",
            "description": "${cleanSpacesOnTheLeftAndRight(post.description)}",
            "headline": "${cleanSpacesOnTheLeftAndRight(post.title)}",
            "inLanguage": "en-us",
            "mainEntityOfPage": "${location.pathname}",
            "name": "${cleanSpacesOnTheLeftAndRight(post.title)}",
            "url": "${location.pathname}",
            "author": {
              "@type": "Person",
              "name": "Assertive Yield",
              "worksFor": {
                "@type": "Organization",
                "name": "Assertive Yield"
              }
            },
            "copyrightHolder": {
              "@type": "Organization",
              "name": "Assertive Yield",
              "URL": "https://www.assertiveyield.com/",
              "sameAs": [
                "https://www.youtube.com/@assertiveyield",
                "https://www.linkedin.com/company/assertive-yield"
              ]
            },
            "publisher": {
              "@type": "Organization",
              "name": "Assertive Yield",
              "URL": "https://www.assertiveyield.com/"
            }
          }
        `}
            </script>
        </Seo>
    );
};

export default CareersTemplate;

export const pageQuery = graphql`
    query career($id: String!) {
        careers(id: { eq: $id }) {
            id
            title
            description
            body
            category
            emailReceiver
            location
            isFullTime
            fields {
                slug
            }
        }
    }
`;
