// extracted by mini-css-extract-plugin
export var adBannerWrapper = "styles-module--adBannerWrapper--c970f";
export var adBannerWrapperCentralized = "styles-module--adBannerWrapperCentralized--a90c9";
export var closeButtonIcon = "styles-module--closeButtonIcon--5226a";
export var closeButtonIconCentralized = "styles-module--closeButtonIconCentralized--98540";
export var content = "styles-module--content--1e7dc";
export var expand = "styles-module--expand--a9f9a";
export var expandAnimation = "styles-module--expandAnimation--3f9bf";
export var expandCentralized = "styles-module--expandCentralized--81508";
export var expandedInfo = "styles-module--expandedInfo--04eae";
export var infoButtonIcon = "styles-module--infoButtonIcon--41b28";
export var infoButtonIconCentralized = "styles-module--infoButtonIconCentralized--6b9af";