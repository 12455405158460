// extracted by mini-css-extract-plugin
export var client_story = "styles-module--client_story--1dae6";
export var header = "styles-module--header--7c18f";
export var hover = "styles-module--hover--303cb";
export var hover_logo = "styles-module--hover_logo--ff0f9";
export var hover_logo_wrapper = "styles-module--hover_logo_wrapper--93bd4";
export var hover_wrapper = "styles-module--hover_wrapper--30ae4";
export var linkWrapper = "styles-module--link-wrapper--f446d";
export var normal = "styles-module--normal--63e45";
export var stories = "styles-module--stories--da808";
export var story = "styles-module--story--0b2a9";
export var storyLink = "styles-module--story-link--79a3f";
export var storyTitle = "styles-module--story-title--74623";
export var title = "styles-module--title--02c3d";