import { Container } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import AdBannerWrapperHit from "components/adBannerWrapperHit";
import HitThumbnailSmall from "components/HitThumbnailSmall";
import * as styles from "./styles.module.scss";

const OtherPosts = () => {
    const data = useStaticQuery(graphql`
        query HitBlogPostsQuery {
            allMarkdownRemark(
                filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
                sort: { frontmatter: { date: DESC } }
                limit: 6
            ) {
                nodes {
                    id
                    frontmatter {
                        title
                        description
                        date
                        relatedFeatures
                        tags
                        author
                        typeOfPost
                        pageUrl
                    }
                    featuredImageFileExternal {
                        childImageSharp {
                            gatsbyImageData(width: 1160, height: 600)
                        }
                    }
                    fields {
                        slug
                    }
                }
            }

            ads {
                verticalAdFileExternal {
                    childImageSharp {
                        gatsbyImageData(quality: 95)
                    }
                }
                horizontalAdFileExternal {
                    childImageSharp {
                        gatsbyImageData(quality: 95)
                    }
                }
                verticalAdLink
                horizontalAdLink
                horizontalAdFullWidthTitle
                horizontalAdFullWidthDescription
                horizontalAdFullWidthLink
                horizontalAdFullWidthLinkText
                horizontalAdFullWidthImageFileExternal {
                    childImageSharp {
                        gatsbyImageData(quality: 95)
                    }
                }
            }
        }
    `);

    const posts = data.allMarkdownRemark.nodes;
    const ads = data.ads;
    const verticalAd = getImage(ads.verticalAdFileExternal);

    if (data.allMarkdownRemark.nodes.length === 0) return null;

    return (
        <div className={[styles.content_wrapper, "pt-0 pt-lg-3"].join(" ")}>
            <Container>
                <div className={styles.wrapper}>
                    <div className={styles.posts}>
                        <div
                            style={{
                                borderBottom: "1px solid #D9D9D9",
                                paddingBottom: "12px",
                                marginBottom: "12px",
                            }}
                        >
                            {posts[3] && <HitThumbnailSmall post={posts[3]} />}
                        </div>
                        <div
                            style={{
                                borderBottom: "1px solid #D9D9D9",
                                paddingBottom: "12px",
                                marginBottom: "12px",
                            }}
                        >
                            {posts[4] && <HitThumbnailSmall post={posts[4]} />}
                        </div>
                        <div>{posts[5] && <HitThumbnailSmall post={posts[5]} />}</div>
                    </div>
                    <div className={styles.ads}>
                        <AdBannerWrapperHit link={ads.verticalAdLink}>
                            <div className={styles.verticalAd}>
                                <GatsbyImage image={verticalAd!} alt="vertical ad" />
                            </div>
                        </AdBannerWrapperHit>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default OtherPosts;
