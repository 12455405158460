import { Builder } from "@builder.io/react";
import MessagesList from "components/byTeamTypeMessages/byTeamTypeMessages";
import { toMutable } from "types/common";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Ad Operations and Yield Teams use AY to measure and optimize yield in real-time.",
        required: true,
    },
    {
        name: "messages",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                required: true,
            },
        ],
        required: true,
    },
] as const;

const ByTeamTypeAdOperations: BFC<typeof inputs> = ({ title, messages }) => {
    return (
        <div className="container">
            <MessagesList title={title} messages={messages} delay={1000} />
        </div>
    );
};

Builder.registerComponent(ByTeamTypeAdOperations, {
    name: "byTeamTypeAdOperations",
    inputs: toMutable(inputs),
});
