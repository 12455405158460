import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective } from "../types";

const RAW_METRICS = {
    sessions: {
        field: "uniq(assumeNotNull(userAgent), assumeNotNull(latitude), assumeNotNull(longitude))",
    },
    errors: {
        field: "count()",
    },
    same_bid_error_count: {
        field: "countIf(message = 'Encountered the same prebid ad/id winning multiple times!' or message = 'Encountered the same aps ad/id winning multiple times!')",
    },
    fatal_prebid_error_count: {
        field: "countIf(meta_source = 'prebid' AND (like(message, '%Can''t resize it to ad''s dimensions.%') OR like(message, 'adRenderFailed%') OR like(message, 'Cannot find ad%')) )",
    },
} as const satisfies Record<string, IRawMetric>;
export type RawErrorAnalyticsMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["errors", "sessions", "same_bid_error_count", "fatal_prebid_error_count"] as const;
export type ErrorAnalyticsMetrics = (typeof CALC_IDS)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        errors: {
            label: "Errors",
            explanation: "Sum of errors",
            formula: m => m.errors,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        sessions: {
            label: "Sessions",
            explanation: "Sum of sessions",
            formula: m => m.sessions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        same_bid_error_count: {
            label: "Same Bid Errors",
            explanation: "Errors related to same bid (bid re-used)",
            formula: m => m.same_bid_error_count,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        fatal_prebid_error_count: {
            label: "Fatal Prebid Errors",
            explanation: "Fatal prebid errors like failed ad renders",
            formula: m => m.fatal_prebid_error_count,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
    });
