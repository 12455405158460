import { rgb } from "./color";

export const MovingDataColor = {
    exchanges: rgb(2, 205, 160),
    metrics: rgb(2, 205, 160),
    dimensions: rgb(2, 205, 160),
    website: rgb(102, 204, 204),
    redBlinking: rgb(214, 52, 71),
    // to change background we must change
    // the "moving_data_mask" image file too.
    // It's PSD is in /sources/mask.psd
    background: rgb(255, 255, 255),
};

export const EXCHANGES = [
    "Google AdX",
    "Amazon",
    "Facebook Audience Network",
    "Index Exchange",
    "AppNexus",
    "Rubicon",
    "ONE by AOL",
    "Sovrn",
    "TripleLift",
    "Teads",
    "Criteo",
    "Sharethrough",
    "OpenX",
];

export const METRICS = [
    "Session CPM",
    "Viewability",
    "CTR",
    "Timeout",
    "Fill Rate",
    "Win Rate",
    // We repeat data here to make metrics
    // have the same "density" of the other points
    "Session CPM",
    "Viewability",
    "CTR",
    "Timeout",
    "Fill Rate",
    "Win Rate",
];

export const DIMENSIONS = [
    "DSP",
    "Brand",
    "UTM Source",
    "UTM Campaign",
    "Advertiser",
    "Page URL",
    "User Segment",
    "Direct Campaign",
    "Audience Group",
    "DMP Id",
    "Order",
    "Browser",
    "Operating System",
    "Timeout",
    "Layout",
];
