import Topo from "@hapi/topo";
import type { IRawMetric } from "./raw-metrics.service";
import {
    type AllPossibleMetricsMap,
    CalculationType,
    type IMetric,
    type IMetricReturns,
    type MainMetricsType,
    type MetricCalculationFormula,
    type RawMetricsType,
} from "./types";

// preprocess metrics
// regex: use generic regex because variable names change when the js is minified
const re = /\w\.(\w+)/g;

function addFormulaDependenciesAndId(ms: AllPossibleMetricsMap): void {
    Object.entries(ms).forEach(([id, m]) => {
        (m as any).id = id;
    });
    Object.values(ms).forEach(m => {
        if (m.formula) {
            const dependencies: (MainMetricsType | RawMetricsType)[] = [];
            let match;
            do {
                // be aware of the stateful regex
                // https://stackoverflow.com/questions/11477415/why-does-javascripts-regex-exec-not-always-return-the-same-value
                match = re.exec(m.formula.toString());
                //@ts-ignore
                if (match && match[1] && !dependencies.includes(match[1])) {
                    //@ts-ignore
                    dependencies.push(match[1]);
                }
            } while (match);

            (m as any).formulaDependencies = dependencies;
        } else {
            (m as any).formulaDependencies = [];
        }
    });

    Object.values(ms).forEach(m => {
        if (m.calculationType === CalculationType.Sum) {
            m.shareOfVoiceChildren = m.formulaDependencies.filter(
                it => Object.keys(ms).includes(it) && it != m.id
            ) as any;
        } else {
            m.shareOfVoiceChildren = [];
        }
    });
}
export const sortMetricsTopological = <T extends MetricCalculationFormula>(it: readonly T[]): T[] => {
    const sorter = new Topo.Sorter<T>();
    it.forEach(metric => {
        sorter.add(metric, {
            after: metric.formulaDependencies?.filter(dep => dep !== metric.id) ?? [],
            group: metric.id,
        });
    });
    return sorter.nodes;
};

function addRawDependencies(
    ms: AllPossibleMetricsMap,
    rawMetrics: Record<string, IRawMetric>,
    otherMetrics: PlacementBuiltConfig<string, string> | undefined
): void {
    sortMetricsTopological(Object.values(ms)).forEach(m => {
        if (m.formulaDependencies) {
            const rawDependencies: RawMetricsType[] = [];

            m.formulaDependencies.forEach(dep => {
                const isRawMetricKey = rawMetrics.hasOwnProperty(dep);
                if (isRawMetricKey) {
                    rawDependencies.push(dep as any);
                } else if (otherMetrics != null && (otherMetrics as any)[dep]) {
                    const m = (otherMetrics as any)[dep].rawDependencies;
                    if (m == null) {
                        throw new Error(`metric ${dep} is missing rawDependencies`);
                    }
                    rawDependencies.push(...m);
                } else if ((ms as any)[dep]) {
                    const m = (ms as any)[dep].rawDependencies;
                    if (m == null) {
                        throw new Error(
                            `metric ${dep} is missing rawDependencies, check if the 'rawMetrics' are correctly configured`
                        );
                    }
                    rawDependencies.push(...m);
                }
            });
            m.rawDependencies = rawDependencies;
            rawDependencies.forEach(dep => {
                const config = rawMetrics[dep].config;
                if (config) {
                    m.config = { ...m.config, ...config };
                }
            });
        }
    });
}

export class EdaPlacementBuilder {
    constructor() {}

    public withRawMetrics<R extends string>(vals: Record<R, IRawMetric>): RawMetricsStageBuilder<R> {
        return new RawMetricsStageBuilder(vals);
    }
}

class RawMetricsStageBuilder<R extends string> {
    private rawMetrics: Record<R, IRawMetric>;

    constructor(rawMetrics: Record<R, IRawMetric>) {
        this.rawMetrics = rawMetrics;
    }

    public withCalculatedMetricsIds<C extends string>(
        calculatedMetricsIds: readonly C[]
    ): CalculatedMetricsStageBuilder<R, C> {
        return new CalculatedMetricsStageBuilder(this.rawMetrics, calculatedMetricsIds);
    }
}

export type PlacementBuiltConfig<R extends string, C extends string> = {
    metrics: Record<C, IMetricReturns<C, R>>;
    rawMetrics: Record<R, IRawMetric>;
    calculatedMetricsIds: readonly C[];
};

class CalculatedMetricsStageBuilder<R extends string, C extends string> {
    private rawMetrics: Record<R, IRawMetric>;
    private calculatedMetricsIds: readonly C[];

    constructor(rawMetrics: Record<R, IRawMetric>, calculatedMetricsIds: readonly C[]) {
        this.rawMetrics = rawMetrics;
        this.calculatedMetricsIds = calculatedMetricsIds;
    }
    public withCalculatedMetrics(
        metrics: Record<C, IMetric<C, R>>,
        other?: PlacementBuiltConfig<string, string>
    ): PlacementBuiltConfig<R, C> {
        if (
            Object.keys(metrics).length !== this.calculatedMetricsIds.length ||
            !this.calculatedMetricsIds.every(id => Object.keys(metrics).includes(id)) ||
            !Object.keys(metrics).every(id => this.calculatedMetricsIds.includes(id as any))
        ) {
            throw new Error("Keys of metrics do not match calculatedMetricsIds");
        }

        addFormulaDependenciesAndId(metrics);

        addRawDependencies(metrics, this.rawMetrics, other);
        return {
            metrics: metrics as any,
            rawMetrics: this.rawMetrics,
            calculatedMetricsIds: this.calculatedMetricsIds,
        };
    }
}
