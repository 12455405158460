import { BOOK_CALL_LINK } from "components/constants";
import * as styles from "./styles.module.scss";
const BookCallButton = () => {
    return (
        <a href={BOOK_CALL_LINK} target="_blank" className={[styles.button].join(" ")} rel="noreferrer">
            Book a call
        </a>
    );
};

export default BookCallButton;
