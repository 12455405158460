import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import gameleft from "./images/game-left.svg";
import gameright from "./images/game-right.svg";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "It's time to play an entirely new game with AY's unique QPS optimization solution",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Be a stronger player by understanding the demand behind each user to double or triple your results. In today's world of increasing QPS limitations, random sampling to predict supply volume, blocking segments or bidders, FIFO real-time counters, all these initiatives are unreasonably limiting your performance. It has become difficult to excel in all aspects!",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Start free trial",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "subtitle",
                type: "longText",
                defaultValue:
                    "Increase optimal QPS, traded value, wins rates, and infrastructure cost savings as a result of fewer requests to process",
            },
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
        ],
    },
] as const;
const TrafficShapingPlayNewGame: BFC<typeof inputs> = ({ title, subtitle, buttonTo, buttonText, items }) => {
    return (
        <div className="component-wrapper">
            <img src={gameleft} className={`gradient left`} style={{ top: "-150px", bottom: "" }} alt="gradient" />
            <img src={gameright} className={`gradient right`} style={{ top: "", bottom: "-200px" }} alt="gradient" />
            <div className="container ">
                <div className="row justify-content-center align-items-center gx-5 text-center text-lg-start">
                    <div className="col-12 col-lg-5">
                        <h2>{title}</h2>
                        <p className="mb-3 mt-3">{subtitle}</p>
                        <CustomButton buttonSize="btn--large" buttonStyle={"btn--blue-pink"} to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                    <div className="col-12  col-lg-7 mt-5 mt-lg-0">
                        <div className="row">
                            {items.map(it => {
                                return (
                                    <div className="col-12 col-md-6" key={it.img}>
                                        <img src={it.img} alt="" className="img-fluid" />
                                        <p className="mt-4 mb-4">{it.subtitle}</p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(TrafficShapingPlayNewGame, {
    name: "TrafficShapingPlayNewGame",
    inputs: toMutable(inputs),
});
