import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import OurValues1Image from "images/ourValues/our-values-1.svg";
import OurValues2Image from "images/ourValues/our-values-2.svg";
import OurValues3Image from "images/ourValues/our-values-3.svg";
import OurValues4Image from "images/ourValues/our-values-4.svg";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Our Values",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "We are a team of compassionate, enthusiastic, young, and culturally diverse professionals who share the love for knowledge and transparency. We like collaboration, internally among our teams and with our customers to continuously improve our performance.",
    },
    {
        name: "listItemsText",
        type: "list",
        defaultValue: [
            {
                title: "We do love transparency",
                text: "We believe in humble, open, and assertive communication",
            },
            {
                title: "We appreciate collaboration",
                text: "Team Players, hands-on, problem-solvers are buzzwords for us",
            },
            {
                title: "We work to blow our customers’ minds and hearts",
                text: "Customers are the heart of our business, we wear their shoes and work hard to offer a more assertive solutions",
            },
            {
                title: "We are avid learners with hunger of knowledge",
                text: "We furiously keep on learning & improving our services, products and ourselves",
            },
        ],
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Title",
            },
            {
                name: "text",
                type: "text",
                defaultValue: "Text",
            },
        ],
    },
] as const;

const AboutUsOurValues: BFC<typeof inputs> = ({ title, subtitle, listItemsText }) => {
    const images = [OurValues1Image, OurValues2Image, OurValues3Image, OurValues4Image];

    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/about-us-our-values-first-glow.svg" alt="" />
            </div>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>
                <div className={styles.list}>
                    {listItemsText?.map(({ title, text }, index) => (
                        <div key={index} className={styles.item}>
                            <div>
                                <img src={images[index]} loading="lazy" alt="" />
                            </div>
                            <h5 className={styles.itemTitle}>{title}</h5>
                            <p className={styles.itemText}>{text}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsOurValues, {
    name: "aboutUsOurValues",
    inputs: toMutable(inputs),
});
