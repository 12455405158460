// extracted by mini-css-extract-plugin
export var description = "styles-module--description--6b5f7";
export var descriptionText = "styles-module--description-text--8a11f";
export var descriptionTitle = "styles-module--description-title--45b5b";
export var firstGlow = "styles-module--first-glow--e4f23";
export var firstPosition = "styles-module--first-position--357f4";
export var fourthPosition = "styles-module--fourth-position--2d5a8";
export var mainWrapper = "styles-module--main-wrapper--98d4d";
export var mobileContentWrapper = "styles-module--mobile-content-wrapper--d314f";
export var mobileLine = "styles-module--mobile-line--91883";
export var mobileLineFirst = "styles-module--mobile-line-first--ac5cf";
export var mobileSpiral = "styles-module--mobile-spiral--a4191";
export var secondPosition = "styles-module--second-position--93d97";
export var spiral = "styles-module--spiral--1be97";
export var spiralImage = "styles-module--spiral-image--5269a";
export var thirdPosition = "styles-module--third-position--e5cef";
export var wrapper = "styles-module--wrapper--cdd75";