import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import heroImage from "./images/ay-support-hero-main-image.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Welcome to AY Support",
    },
] as const;

export const AySupportHero: BFC<typeof inputs> = ({ title }) => {
    return (
        <div className="container">
            <div className={styles.wrapper}>
                <div className={styles.imageWrapper}>
                    <img src={heroImage} alt="AY Support Hero" />
                </div>
                <h1 className={styles.title}>{title}</h1>
            </div>
        </div>
    );
};

Builder.registerComponent(AySupportHero, {
    name: "aySupportHero",
    inputs: toMutable(inputs),
});
