import type { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { BuilderComponent } from "@builder.io/react";
import Layout from "components/Layout";
import Seo from "components/seo";

function PageTemplate({ data }: PageProps<any>) {
    const models = data?.allBuilderModels;
    const page = models.oneLightpage?.content;

    return (
        <Layout bg="light">
            {/* @ts-ignore */}
            <BuilderComponent model="lightpage" content={page} />
        </Layout>
    );
}

export const Head: FC<PageProps> = ({ location }) => {
    return <Seo noIndex title="Edit Light Page" canonical={location.pathname} titleSuffixed={true} />;
};

export default PageTemplate;
export const pageQuery = graphql`
    query ($path: String!) {
        allBuilderModels {
            oneLightpage(target: { urlPath: $path }, options: { cachebust: true }) {
                content
            }
        }
    }
`;
