import { Builder } from "@builder.io/react";
import MessagesList from "components/byTeamTypeMessages/byTeamTypeMessages";
import { toMutable } from "types/common";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Take me there",
        required: true,
    },
    {
        name: "messages",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                required: true,
            },
        ],
        defaultValue: [
            {
                title: "What is the incremental value of bidder Y in country X for creative size 300x250?",
            },
            {
                title: "Are the bidder response time and bids in check?",
            },
            {
                title: "What is the miss click rate on placement X on desktop with layout Z?",
            },
        ],
        required: true,
    },
] as const;

const ByTeamTypeAudienceDevelopmentMessages: BFC<typeof inputs> = ({ title, messages }) => {
    return (
        <div className="container">
            <MessagesList title={title} messages={messages} delay={1000} />
        </div>
    );
};

Builder.registerComponent(ByTeamTypeAudienceDevelopmentMessages, {
    name: "byTeamTypeAudienceDevelopmentMessages",
    inputs: toMutable(inputs),
});
