// extracted by mini-css-extract-plugin
export var answer = "styles-module--answer--154c1";
export var faq = "styles-module--faq--63ab0";
export var faqs = "styles-module--faqs--0c009";
export var firstGlow = "styles-module--first-glow--9b29d";
export var icon = "styles-module--icon--7c261";
export var question = "styles-module--question--9e709";
export var secondGlow = "styles-module--second-glow--9dca7";
export var subtitle = "styles-module--subtitle--e27b8";
export var title = "styles-module--title--fa8ca";
export var wrapper = "styles-module--wrapper--4c092";