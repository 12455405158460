import { Builder } from "@builder.io/react";
import { CONTACT_US_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import shortVideo from "./videos/short-video-success-stories.webm";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "AY Customer Success Stories",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "We work to help you succeed. It starts with understanding your unique business needs and building a strategy that supports a return on your investment.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Contact us",
    },
    {
        name: "linkTo",
        type: "text",
        defaultValue: CONTACT_US_LINK,
        required: true,
    },
] as const;

const SuccessStoriesHero: BFC<typeof inputs> = ({ title, subtitle, buttonText, linkTo }) => (
    <div className="container">
        <video loop autoPlay muted className={styles.videoBg}>
            <source src={shortVideo} type="video/mp4" />
        </video>
        <div className="row">
            <div className="col-12 col-lg-12">
                <h1 className="mb-4 position-relative z-1">{title}</h1>
                <p className="mb-5 position-relative z-1" style={{ width: "70%" }}>
                    {subtitle}
                </p>

                <CustomButton buttonSize="btn--large" to={linkTo}>
                    {buttonText}
                </CustomButton>
            </div>
        </div>
    </div>
);

Builder.registerComponent(SuccessStoriesHero, {
    name: "successStoriesHero",
    inputs: toMutable(inputs),
});
