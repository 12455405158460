import { Builder } from "@builder.io/react";
import { Link, navigate } from "@reach/router";
import { useLocation } from "@reach/router";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title1",
        type: "text",
        defaultValue: "What out of the box solutions can you expect from AI powered AY products?",
    },
    {
        name: "title2",
        type: "text",
        defaultValue: "Let’s get to know it better!",
    },
    {
        name: "navigationList",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Publishers",
            },
            {
                name: "linkTo",
                type: "text",
                defaultValue: "/",
                required: true,
            },
        ],
        defaultValue: [
            {
                title: "Ad Operations & Yield",
                linkTo: "/by-team-type-ad-operations-yield",
            },
            {
                title: "Audience Development",
                linkTo: "/by-company-type-ssp",
            },
            {
                title: "Media Buying",
                linkTo: "/",
            },
            {
                title: "Product Teams",
                linkTo: "/",
            },
            {
                title: "Sales",
                linkTo: "/",
            },
            {
                title: "Management/Finance",
                linkTo: "/",
            },
        ],
    },
] as const;

const ByTeamTypeSolutions: BFC<typeof inputs> = ({ title1, title2, navigationList }) => {
    const routes = ["/by-company-type-publishers", "/by-company-type-ssp"];
    const isBrowser = typeof window !== "undefined";

    const location = isBrowser ? useLocation() : undefined;

    const currentRoute = routes.find(route => location?.pathname.includes(route));

    return (
        <div className="container">
            <div className={styles.wrapper}>
                <h2>{title1}</h2>
                <h2>{title2}</h2>
                <ul className={`d-none d-lg-inline-flex ${styles.navigationWrapper}`}>
                    {navigationList?.map(({ title, linkTo }) => (
                        <li className={styles.navigationItem} key={title}>
                            <Link
                                className={
                                    currentRoute === linkTo ? styles.navigationLinkActive : styles.navigationLink
                                }
                                to={linkTo}
                            >
                                {title}
                            </Link>
                        </li>
                    ))}
                </ul>

                <select
                    className={`d-block d-lg-none ${styles.selectWrapper}`}
                    onChange={e => navigate(e.target.value)}
                    defaultValue={location?.pathname ?? ""}
                >
                    {navigationList?.map(({ title, linkTo }) => (
                        <option value={linkTo} key={title}>
                            {title}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

Builder.registerComponent(ByTeamTypeSolutions, {
    name: "byTeamTypeSolutions",
    inputs: toMutable(inputs),
});
