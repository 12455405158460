import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Change from old game to the new game with AY-AI Yield Optimization",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Improve bidding efficiency, reduce costs and increase bids by cutting out wasteful requests. Play with your data for free!",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Start for free",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: SIGNUP_LINK,
        required: true,
    },
] as const;

const YieldOptimizerCTA: BFC<typeof inputs> = ({ title, subtitle, buttonTo, buttonText }) => {
    return (
        <div className={`component-wrapper`}>
            <div className="container">
                <div className="row justify-content-center text-center">
                    <div className="col-10 col-lg-7">
                        <h2 className="mt-3 mb-3">{title}</h2>

                        <p className="mb-4">{subtitle}</p>
                        <CustomButton buttonSize="btn--large" buttonStyle="btn--blue-pink" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(YieldOptimizerCTA, {
    name: "YieldOptimizerCTA",
    inputs: toMutable(inputs),
});
