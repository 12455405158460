// extracted by mini-css-extract-plugin
export var boldParagraph = "styles-module--bold-paragraph--fd2db";
export var contentWrapper = "styles-module--content-wrapper--1eac1";
export var fistGlow = "styles-module--fist-glow--0b221";
export var image = "styles-module--image--a99c3";
export var leftContent = "styles-module--left-content--0935f";
export var paragraphWrapper = "styles-module--paragraph-wrapper--a94d5";
export var playButtonOverlay = "styles-module--play-button-overlay--6620a";
export var rightContent = "styles-module--right-content--c11f1";
export var secondGlow = "styles-module--second-glow--c3b94";
export var subtitle = "styles-module--subtitle--2d054";
export var thirdGlow = "styles-module--third-glow--ea9f7";
export var title = "styles-module--title--87966";