import { Builder } from "@builder.io/react";
import ProductsHero from "components/blocks/hero/ProductsHero";
import { BOOK_CALL_LINK } from "components/constants";
import { toMutable } from "types/common";
import gradientRight from "./images/hero-blur.svg";
import icon from "./images/trafficShapingico.svg";

const inputs = [
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "buttonTitle",
        type: "text",
        defaultValue: "Book a demo",
        required: true,
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Shape, optimize your traffic and generate more revenue from your DSP partners using assertive user profiles powered by AY Artificial Intelligence to the smartest matchmaking",
    },
    {
        name: "heading",
        type: "text",
        defaultValue: "Shape a greener traffic and with lower cost",
        required: true,
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
        required: true,
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
] as const;

const TrafficShapingProductHero: BFC<typeof inputs> = ({
    heading,
    description,
    buttonTitle,
    buttonTo,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
}) => {
    return (
        <ProductsHero
            heading={heading}
            description={description ?? ""}
            buttonTitle={buttonTitle}
            buttonTo={buttonTo}
            heroimage={imageOrGif}
            heroimageHover={imageOrGifHover}
            heroimageAlt={imageOrGifAlt}
            showForm={false}
            buttonStyle="btn--blue-pink"
            icon={icon}
            iconTitle="Traffic Shaping "
            gradient={{
                right: gradientRight,
                rightTop: "-280px",
                rightBottom: "",
                left: "",
                leftTop: "0px",
                leftBottom: "",
            }}
        />
    );
};
Builder.registerComponent(TrafficShapingProductHero, {
    name: "TrafficShapingProductHero",
    inputs: toMutable(inputs),
});
