import { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { micromark } from "micromark";
import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import MarkdownRenderer from "components/MarkdownRenderer";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Frequently Asked Questions",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Here are common questions from our prospects that might answer your needs.",
    },
    {
        name: "stillHaveQuestionsTitle",
        type: "text",
        defaultValue: "Still have questions?",
    },
    {
        name: "stillHaveQuestionsSubtitle",
        type: "text",
        defaultValue: "Contact Customer Success for further assistance. We’re here to help!",
    },
    {
        name: "stillHaveQuestionsButtonText",
        type: "text",
        defaultValue: "Book a call",
    },
    {
        name: "stillHaveQuestionsLinkTo",
        type: "string",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
] as const;

export const AyGlossaryAskedQuestions: BFC<typeof inputs> = ({
    title,
    subtitle,
    stillHaveQuestionsTitle,
    stillHaveQuestionsSubtitle,
    stillHaveQuestionsButtonText,
    stillHaveQuestionsLinkTo,
}) => {
    const data = useStaticQuery(graphql`
        query faqs {
            allFaq {
                nodes {
                    id
                    body
                    title
                }
            }
        }
    `);

    type TNodes = {
        id: string;
        title: string;
        body: string;
    };

    const nodes: TNodes[] = data.allFaq.nodes;

    const [activeIndex, setActiveIndex] = useState<number>();

    const switchAccordion = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(undefined);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <div className="container">
            <div className={`${styles.firstGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-asked-first-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.secondGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-asked-second-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.thirdGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-asked-third-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.fourthGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-asked-fourth-glow.svg" alt="glow" />
            </div>
            <div className={`${styles.fifthGlow} gradient`}>
                <StaticImage src="./images/ay-glossary-asked-fifth-glow.svg" alt="glow" />
            </div>
            <div className={styles.wrapper}>
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.subtitle}>{subtitle}</p>

                <div className={styles.questions}>
                    <div className={styles.question}>
                        <div className={styles.questionHeader} onClick={() => switchAccordion(0)}>
                            <h4 className={styles.questionTitle}>Is there a free trial available?</h4>
                            <div>
                                {activeIndex === 0 ? (
                                    <StaticImage src="./images/ay-glossary-asked-questions-remove.svg" alt="remove" />
                                ) : (
                                    <StaticImage src="./images/ay-glossary-asked-questions-add.svg" alt="add" />
                                )}
                            </div>
                        </div>

                        {activeIndex === 0 && (
                            <div>
                                <p>
                                    We provide various solutions that leverage the performance of many different company
                                    types and teams' needs, such as Publishers, SSPs, AdNetworks and Consultants. As
                                    well for each team: AdOps /Programmatic, Audience Development, Editorial, Finance,
                                    Product/UX and Management.
                                </p>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Product</th>
                                            <th>User Persona</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Yield Intelligence</td>
                                            <td>
                                                - AdOps, Product, Editorial, Finance, Management, Audience Development,
                                                Finance, Management and Engineering.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Yield Optimization</td>
                                            <td>AdOps</td>
                                        </tr>
                                        <tr>
                                            <td>Yield Manager</td>
                                            <td>AdOps and Product.</td>
                                        </tr>
                                        <tr>
                                            <td>Traffic Shaping</td>
                                            <td>AdOps and Audience Development.</td>
                                        </tr>
                                        <tr>
                                            <td>Traffic Acquisition</td>
                                            <td>To confirm personas</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <p>
                                    Haven’t found your solution yet? Check our solutions{" "}
                                    <Link className={styles.questionLink} to="/">
                                        By Company
                                    </Link>{" "}
                                    type and{" "}
                                    <Link className={styles.questionLink} to="/">
                                        By Team
                                    </Link>
                                </p>
                            </div>
                        )}
                    </div>

                    {nodes.map(({ title, body, id }, index) => {
                        return (
                            <div key={id} className={styles.question}>
                                <div className={styles.questionHeader} onClick={() => switchAccordion(index + 1)}>
                                    <h4 className={styles.questionTitle}>{title}</h4>
                                    <div>
                                        {activeIndex === index + 1 ? (
                                            <StaticImage
                                                src="./images/ay-glossary-asked-questions-remove.svg"
                                                alt="remove"
                                            />
                                        ) : (
                                            <StaticImage src="./images/ay-glossary-asked-questions-add.svg" alt="add" />
                                        )}
                                    </div>
                                </div>

                                {activeIndex === index + 1 && (
                                    <div className={styles.body}>
                                        <MarkdownRenderer
                                            post={{
                                                html:
                                                    body == null
                                                        ? ""
                                                        : micromark(body, {
                                                              allowDangerousHtml: true,
                                                          }),
                                            }}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                <div className={styles.stillMoreWrapper}>
                    <h2>{stillHaveQuestionsTitle}</h2>
                    <p>{stillHaveQuestionsSubtitle}</p>
                    <CustomButton to={stillHaveQuestionsLinkTo}>{stillHaveQuestionsButtonText}</CustomButton>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AyGlossaryAskedQuestions, {
    name: "ayGlossaryAskedQuestions",
    inputs: toMutable(inputs),
});
