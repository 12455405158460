import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Builder } from "@builder.io/react";
import { BOOK_CALL_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import firstCardImage from "./images/card_1.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "suptitle",
        type: "text",
        defaultValue: "",
    },
    {
        name: "title",
        type: "text",
        defaultValue: "Yield Analytics in Real-Time",
        required: true,
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue:
            "First-party data collected and normalized in real-time. Analyze header bidding, banners, videos, affiliate and native micro-transactions",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Book a demo",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: BOOK_CALL_LINK,
        required: true,
    },
    {
        name: "items",
        type: "list",
        subFields: [
            {
                name: "src",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
                required: true,
            },
        ],
        defaultValue: [
            {
                src: firstCardImage,
            },
        ],
        required: true,
    },
] as const;

const YieldIntelligenceScrollableCard: BFC<typeof inputs> = ({
    title,
    subtitle,
    buttonTo,
    buttonText,
    suptitle,
    items,
}) => {
    const [animationPage, setAnimationPage] = useState(0);

    const itemsWithIds = items?.map((item, index) => ({
        ...item,
        id: index + 1,
    }));

    useEffect(() => {
        for (const it of items) {
            const img = new Image();
            img.src = it.src;
        }
        const id = setInterval(() => {
            setAnimationPage(state => {
                if (state >= itemsWithIds.length - 1) return 0;
                return state + 1;
            });
        }, 2000);
        return () => clearInterval(id);
    }, []);

    return (
        <div className={["component-wrapper"].join(" ")}>
            <div className={["container"].join(" ")}>
                <div className="row align-items-center gx-5">
                    <div className="col-lg-6 order-2 order-lg-1  mt-5 mt-lg-0">
                        <p className={styles.suptitle}>{suptitle}</p>
                        <h2>{title}</h2>
                        <p className="mb-5">{subtitle}</p>
                        <CustomButton buttonStyle="btn--primary" to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                    <div className="col-lg-6 mt-4 mb-4 mt-lg-0 mb-lg-0 order-1 order-lg-1 position-relative">
                        <img className={`img-fluid`} alt="" src={items ? items[0].src : ""} style={{ opacity: 0 }} />
                        <AnimatePresence initial={false}>
                            <motion.div
                                style={{ position: "absolute" }}
                                key={animationPage}
                                initial={{ opacity: 0, top: 40 }}
                                animate={{ opacity: 1, top: 0 }}
                                exit={{ opacity: 0, top: -40 }}
                                transition={{ ease: "easeInOut" }}
                            >
                                <img className={`img-fluid`} alt="" src={items ? items[animationPage]?.src : ""} />
                            </motion.div>
                        </AnimatePresence>
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(YieldIntelligenceScrollableCard, {
    name: "YieldIntelligenceScrollableCard",
    inputs: toMutable(inputs),
});
