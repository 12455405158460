import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Join our awesome team with more than 15 seniors engineers and product experts",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Check our open positions!",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Join AY team",
    },
    {
        name: "buttonLink",
        type: "text",
        defaultValue: "/careers",
        required: true,
    },
] as const;

const AboutUsJoinOurTeam: BFC<typeof inputs> = ({ title, subtitle, buttonText, buttonLink }) => {
    return (
        <div className="content-dark">
            <div className="container">
                <div className={styles.wrapper}>
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.subtitle}>{subtitle}</p>
                    <CustomButton to={buttonLink}>{buttonText}</CustomButton>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(AboutUsJoinOurTeam, {
    name: "aboutUsJoinOurTeam",
    inputs: toMutable(inputs),
});
