import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import arrowRight from "./images/by-team-type-programatic-ad-optimization-arrow-right.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue:
            "AY’s Programmatic Ad Optimization does not let you lose on the slightest chance of improving your revenue from your digital advertisements",
    },
] as const;

const ByTeamTypeProgramaticAdOptimization: BFC<typeof inputs> = ({ title }) => {
    return (
        <>
            <div className="component-wrapper">
                <div className="container ">
                    <h3 className={styles.title}>{title}</h3>
                    <div className={styles.linkWrapper}>
                        <span className={styles.linkText}>Learn more</span>
                        <img src={arrowRight} alt="arrow" />
                    </div>
                </div>
            </div>
        </>
    );
};

Builder.registerComponent(ByTeamTypeProgramaticAdOptimization, {
    name: "byTeamTypeProgramaticAdOptimization",
    inputs: toMutable(inputs),
});
