import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import trafficshapingIco from "./images/trafficShapingico.svg";
import * as styles from "./traffic.module.scss";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Traffic Shaping",
        required: true,
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Green traffic, lower costs",
    },
    {
        name: "description",
        type: "longText",
        defaultValue: "Generate more revenue from your DSP partners through intelligent matchmaking.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Learn more",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: "/traffic-shaping/",
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1391,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 523,
    },
] as const;
const TrafficShapingBlock: BFC<typeof inputs> = ({
    title,
    subtitle,
    description,
    buttonText,
    switchMagnifier,
    buttonTo,
    imageOrGif,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
    imageOrGifHover,
}) => {
    const buttonStyle = "btn--green-pink";

    return (
        <div className="component-wrapper common-bg">
            <div className={`gradient ${styles.right}`}>
                <StaticImage src="images/tr-left-gradient.png" alt="" />
            </div>

            <div className="container panel">
                <div className="row justify-content-center align-items-center gx-5">
                    <div className="col-lg-6 position-relative  mb-4 mb-lg-0   mt-4 mt-lg-0 text-center">
                        <div className="mask-container  position-relative ">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2">
                        <div className="prod-ico d-flex align-items-center mb-3">
                            <img src={trafficshapingIco} alt="" />
                            <h4 className="ms-3 mb-0">{title}</h4>
                        </div>
                        <h2 className="product-title ">{subtitle}</h2>
                        <p className="product-desc  mb-4 mb-lg-5">{description}</p>
                        <CustomButton buttonSize="btn--large" buttonStyle={buttonStyle} to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(TrafficShapingBlock, {
    name: "TrafficShapingBlock",
    inputs: toMutable(inputs),
});
