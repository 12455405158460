import type { FC } from "react";
import leftArrow from "./images/pagination-left-arrow.svg";
import leftArrowMobile from "./images/pagination-left-arrow-mobile.svg";
import rightArrow from "./images/pagination-right-arrow.svg";
import rightArrowMobile from "./images/pagination-right-arrow-mobile.svg";
import * as styles from "./styles.module.scss";

type TProps = {
    itemsPerPage?: number;
    totalItems: number;
    currentPage?: number;
    onPageChange: (page: number) => void;
};

const Pagination: FC<TProps> = ({ itemsPerPage = 6, totalItems = 0, currentPage = 1, onPageChange }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const generatePaginationLinks = () => {
        const links = [];

        for (let i = 1; i <= totalPages; i++) {
            links.push(
                <p
                    key={i}
                    className={[styles.paginationText, currentPage === i ? styles.paginationTextActive : ""].join(" ")}
                    onClick={() => onPageChange(i)}
                >
                    {i}
                </p>
            );
        }

        return links;
    };

    return (
        <div className="container">
            <div className={styles.paginationWrapper}>
                <div
                    onClick={() => onPageChange(currentPage - 1)}
                    style={{
                        visibility: currentPage === 1 ? "hidden" : "visible",
                    }}
                >
                    <div className={styles.leftArrowWrapper}>
                        <img src={leftArrow} alt="" />
                        <p className={styles.leftArrowText}>Previous</p>
                    </div>
                    <div className={styles.leftArrowWrapperMobile}>
                        <img src={leftArrowMobile} alt="" />
                    </div>
                </div>
                <div className={styles.paginationTextWrapper}>{generatePaginationLinks()}</div>
                <div className={styles.paginationTextWrapperMobile}>
                    <p className={styles.paginationTextMobile}>
                        Page {currentPage} of {totalPages}
                    </p>
                </div>
                <div
                    style={{
                        visibility: currentPage === totalPages || !totalItems ? "hidden" : "visible",
                    }}
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    <div className={styles.rightArrowWrapper}>
                        <p className={styles.rightArrowText}>Next</p>
                        <img src={rightArrow} alt="" />
                    </div>
                    <div className={styles.rightArrowWrapperMobile}>
                        <img src={rightArrowMobile} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
