import { Gallery, Item } from "react-photoswipe-gallery";
import { Builder } from "@builder.io/react";
import { Image as BuilderImage } from "@builder.io/react";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Go the extra mile",
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "With Yield Manager you can not only have a good ad stack integration at your disposal but also control the way pixel and pixel events fire up.",
    },
    {
        name: "description2",
        type: "longText",
        defaultValue:
            "Collect RPS data within a session and send it as pixel events, to enable buy-side algorithms to optimize for the highest returns. You can also choose and enable each pixel based on the source.",
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1338,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 636,
    },
] as const;

const trafficAcquisitionExtraMile: BFC<typeof inputs> = ({
    title,
    description,
    description2,
    switchMagnifier,
    imageOrGif,
    imageOrGifHover,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
}) => {
    return (
        <div className="component-wrapper">
            <div className="container">
                <div className="row align-items-center gx-5">
                    <div className="col-lg-6 order-2 order-lg-1  mt-5 mt-lg-0 ">
                        <h2>{title}</h2>
                        <p className={styles.description}>{description}</p>
                        <p className={styles.description}>{description2}</p>
                    </div>
                    <div className="col-lg-6  order-1 order-lg-2 mt-5 mt-lg-0">
                        <div className="mask-container  position-relative ">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(trafficAcquisitionExtraMile, {
    name: "trafficAcquisitionExtraMile",
    inputs: toMutable(inputs),
});
