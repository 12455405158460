import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import gameleft from "./images/game-left.svg";
import gameright from "./images/game-right.svg";
import * as styles from "./traffic.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Winning SSPs can now reach new heights of revenue from DSPs.",
        required: true,
    },
    {
        name: "subtitle",
        type: "longText",
        defaultValue:
            "Incredibly high RPS (thousands per core), low memory consumption (400 MB) and fast response times (microseconds).",
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "QPS Optimization",
                required: true,
            },
            {
                name: "subtitle",
                type: "longText",
                defaultValue:
                    "Let the AY Algorithm pick the best queries to be sent each second by setting the QPS threshold for each DSP and Data center.",
            },
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
        ],
    },
] as const;

const TrafficShapingSspDspTable: BFC<typeof inputs> = ({ title, subtitle, items }) => {
    return (
        <>
            <div className="component-wrapper">
                <img src={gameleft} className={`gradient left`} style={{ top: "-150px", bottom: "" }} alt="gradient" />
                <img
                    src={gameright}
                    className={`gradient right`}
                    style={{ top: "", bottom: "-200px" }}
                    alt="gradient"
                />
                <div className="container">
                    <div className="row justify-content-center mb-5 text-center">
                        <div className="col-12 col-lg-11">
                            <h2>{title}</h2>
                            <p>{subtitle}</p>
                        </div>
                    </div>
                    <div className="row text-center text-lg-start">
                        {items.map(it => {
                            return (
                                <div className="col-12 col-md-6 mb-5" key={it.img}>
                                    <div className={`${styles.card} mb-3`}>
                                        <div className="row g-2">
                                            <div className="col-md-2">
                                                <img
                                                    src={it.img}
                                                    className="img-fluid rounded-start"
                                                    alt={`${it.title} logo`}
                                                />
                                            </div>
                                            <div className="col-md-10">
                                                <div className="card-body">
                                                    <h5 className="card-title mb-2 fw-bold">{it.title}</h5>
                                                    <p className="card-text">{it.subtitle}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};
Builder.registerComponent(TrafficShapingSspDspTable, {
    name: "TrafficShapingSspDspTable",
    inputs: toMutable(inputs),
});
