const LinkedinIcon = () => {
    return (
        <div className="careers-template-icon-wrapper">
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M8.83448 10.8335C9.19235 11.3119 9.64894 11.7078 10.1733 11.9942C10.6976 12.2807 11.2774 12.4511 11.8734 12.4937C12.4693 12.5364 13.0675 12.4504 13.6273 12.2416C14.1871 12.0328 14.6954 11.706 15.1178 11.2835L17.6178 8.78347C18.3768 7.99762 18.7968 6.94511 18.7873 5.85263C18.7778 4.76014 18.3396 3.71509 17.5671 2.94256C16.7945 2.17002 15.7495 1.73182 14.657 1.72233C13.5645 1.71283 12.512 2.13281 11.7261 2.8918L10.2928 4.3168M12.1678 9.1668C11.8099 8.68836 11.3533 8.29248 10.829 8.00602C10.3047 7.71955 9.72489 7.5492 9.12893 7.50652C8.53298 7.46384 7.93482 7.54983 7.37502 7.75865C6.81522 7.96747 6.30688 8.29424 5.88448 8.7168L3.38448 11.2168C2.62548 12.0026 2.20551 13.0552 2.215 14.1476C2.22449 15.2401 2.6627 16.2852 3.43523 17.0577C4.20777 17.8302 5.25282 18.2684 6.3453 18.2779C7.43779 18.2874 8.4903 17.8675 9.27614 17.1085L10.7011 15.6835"
                    stroke="#98A2B3"
                    strokeWidth="1.67"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default LinkedinIcon;
