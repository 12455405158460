import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import * as styles from "./solution.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "AY was built for all teams across your organization",
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Powerful, self-serve product and growth analytics to help you convert, engage, and retain more users.",
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "By Company Type",
                required: true,
            },
            {
                name: "text",
                type: "longText",
                defaultValue:
                    "Publishers, SSPs, Media Buyers, Networks, Consultants: Diverse solutions to help your business to detect monitization opportunities and increase revenue",
                required: true,
            },
            {
                name: "imageOrGif",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
            {
                name: "imageOrGifAlt",
                type: "text",
                defaultValue: "By Company Type",
            },
            {
                name: "imageOrGifWidth",
                type: "number",
                defaultValue: 160,
            },
            {
                name: "imageOrGifHeight",
                type: "number",
                defaultValue: 160,
            },
            {
                name: "subText1",
                type: "text",
                defaultValue: "Publishers",
                required: true,
            },
            {
                name: "subText2",
                type: "text",
                defaultValue: "Supply Side Platforms (SSPs)",
                required: true,
            },
        ],
    },
] as const;

const SolutionType: BFC<typeof inputs> = ({ title, description, items }) => {
    return (
        <div className={`${styles.solutionType} component-wrapper`}>
            <div className={`gradient ${styles.left}`}>
                <StaticImage src="images/type-left-gradient.png" alt="" />
            </div>
            <div className={`gradient ${styles.right}`}>
                <StaticImage src="images/type-right-gradient.png" alt="" />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-lg-4 mt-3 offset-lg-0 text-center text-lg-start">
                        <h2>{title}</h2>
                        <p className="card-text mt-4">{description}</p>
                    </div>
                    {(items ?? []).map((it, index) => {
                        return (
                            <div
                                className={["col-md-6 col-lg-4 col-sm-12 mt-3 ", index == 0 ? "mb-5 mb-md-0" : ""].join(
                                    " "
                                )}
                                key={it.title}
                            >
                                <div className={`card  ${styles.cardblock} d-flex h-100`}>
                                    <div className="card-body pb-5">
                                        <div
                                            style={{
                                                width: it.imageOrGifWidth,
                                                height: it.imageOrGifHeight,
                                            }}
                                        >
                                            <BuilderImage alt={it.imageOrGifAlt} image={it.imageOrGif} />
                                        </div>
                                        <h5 className="card-title">{it.title}</h5>
                                        <p className="card-text pb-3">{it.text}</p>

                                        <span className="link-green d-block mb-2">{it.subText1}</span>

                                        <span className="link-green d-block mb-2">{it.subText2}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(SolutionType, {
    name: "SolutionType",
    inputs: toMutable(inputs),
});
