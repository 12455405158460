// extracted by mini-css-extract-plugin
export var actionsWrapper = "success-stories-module--actionsWrapper--27777";
export var actionsWrapperVideo = "success-stories-module--actionsWrapperVideo--ece41";
export var ads = "success-stories-module--ads--7fb26";
export var copy_link_icon_before = "success-stories-module--copy_link_icon_before--66f0a";
export var copy_link_icon_before_default_state = "success-stories-module--copy_link_icon_before_default_state--dd838";
export var copy_link_icon_before_was_copied = "success-stories-module--copy_link_icon_before_was_copied--9cb83";
export var cursor_pointer = "success-stories-module--cursor_pointer--ef0a2";
export var defaultBorder = "success-stories-module--defaultBorder--69195";
export var downloadPdf = "success-stories-module--downloadPdf--66229";
export var feature = "success-stories-module--feature--697b8";
export var gradientHr = "success-stories-module--gradientHr--03cda";
export var item = "success-stories-module--item--dab63";
export var mobileAds = "success-stories-module--mobileAds--9d31e";
export var mobileAdsWrapper = "success-stories-module--mobileAdsWrapper--b323e";
export var navStructure = "success-stories-module--navStructure--42901";
export var sidebar = "success-stories-module--sidebar--2e71c";
export var sidebarImage = "success-stories-module--sidebarImage--4a1fe";
export var sidebarProducts = "success-stories-module--sidebarProducts--0df76";
export var sidebarRelatedFeatures = "success-stories-module--sidebarRelatedFeatures--d1d38";
export var sidebarWrapper = "success-stories-module--sidebarWrapper--a3781";
export var socialWrapper = "success-stories-module--socialWrapper--7c6af";
export var storyContent = "success-stories-module--storyContent--e5bf6";
export var tableOfContents = "success-stories-module--tableOfContents--a518c";
export var title = "success-stories-module--title--01d13";
export var trafficShaping = "success-stories-module--trafficShaping--25d47";
export var white_rounded_button = "success-stories-module--white_rounded_button--c48ae";
export var yieldIntelligence = "success-stories-module--yieldIntelligence--d35da";
export var yieldManager = "success-stories-module--yieldManager--816e6";
export var yieldOptimizer = "success-stories-module--yieldOptimizer--aed50";