export const REVENUE_BIAS_MULTIPLY = `%%REVENUE_BIAS_MULTIPLY%%`;

export const QUERY_CURRENCY_KEY = `%%QUERY_CURRENCY%%`;
export const TABLE_NAME = `%%TABLE_NAME%%`;
export const maxDaTimestampKey = "%%MAX_DA_TIMESTAMP_PER_ENTITY%%";
export const timezoneKey = "%%TIMEZONE%%";
export const REGEX_TO_CLEAR_BIDDER = "^video_|^cnx_|^aditude_|s2s$|_s2s$|FsClientAux$";

export const lineItemLookup = `if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0), toFloat64(assumeNotNull(revenue))) / 1000`;

export const convertToCurrencyCreator =
    (currencyConversionFactor: (fromCurrency: string) => string) => (fromCurrency: string) =>
        `if( ${fromCurrency} = '', 1.0, ${currencyConversionFactor(fromCurrency)} )`;

export const VitalsCalculatedMetrics = [
    "vitals_lcp",
    "vitals_lcp_good_rate",
    "vitals_lcp_medium_rate",
    "vitals_lcp_bad_rate",
    "vitals_fid",
    "vitals_fid_good_rate",
    "vitals_fid_medium_rate",
    "vitals_fid_bad_rate",
    "vitals_cls",
    "vitals_cls_good_rate",
    "vitals_cls_medium_rate",
    "vitals_cls_bad_rate",
    "vitals_inp",
    "vitals_inp_good_rate",
    "vitals_inp_medium_rate",
    "vitals_inp_bad_rate",
    "vitals_ttfb",
    "vitals_ttfb_good_rate",
    "vitals_ttfb_medium_rate",
    "vitals_ttfb_bad_rate",
    "vitals_fcp",
    "vitals_fcp_good_rate",
    "vitals_fcp_medium_rate",
    "vitals_fcp_bad_rate",
] as const;
export type VitalsCalculatedMetrics = (typeof VitalsCalculatedMetrics)[number];
