import type { FC } from "react";
import { navigate } from "gatsby";
import * as styles from "./styles.module.scss";

const GoBackLink: FC<{ onClick?: () => void }> = ({ onClick }) => (
    <div className={[styles.go_back_link_wrapper].join(" ")}>
        <span
            onClick={() => {
                if (onClick) {
                    onClick();

                    return;
                }

                navigate(-1);
            }}
            className={[styles.go_back_link].join(" ")}
        >
            Go back
        </span>
    </div>
);

export default GoBackLink;
