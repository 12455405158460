// extracted by mini-css-extract-plugin
export var adsWrapper = "styles-module--adsWrapper--3d369";
export var authorData = "styles-module--authorData--c6d46";
export var authorWrapper = "styles-module--authorWrapper--2beb7";
export var avatar = "styles-module--avatar--4bd0d";
export var avatarImage = "styles-module--avatarImage--2a966";
export var copy_link_icon_before = "styles-module--copy_link_icon_before--6455f";
export var copy_link_icon_before_default_state = "styles-module--copy_link_icon_before_default_state--df56d";
export var copy_link_icon_before_was_copied = "styles-module--copy_link_icon_before_was_copied--3410a";
export var cursor_pointer = "styles-module--cursor_pointer--0fb91";
export var dark_text = "styles-module--dark_text--d04f1";
export var defaultBorder = "styles-module--defaultBorder--8d2d4";
export var description = "styles-module--description--5ea99";
export var feature = "styles-module--feature--ba828";
export var features = "styles-module--features--01857";
export var horizontalAd = "styles-module--horizontalAd--807b9";
export var kv_item = "styles-module--kv_item--bf9c0";
export var kv_key = "styles-module--kv_key--d28d1";
export var kv_value = "styles-module--kv_value--137f4";
export var kvs_wrapper = "styles-module--kvs_wrapper--400a0";
export var linksWrapper = "styles-module--linksWrapper--3d4d2";
export var minutes_read = "styles-module--minutes_read--b7b7a";
export var minutes_read_text = "styles-module--minutes_read_text--5bdd7";
export var name = "styles-module--name--299c1";
export var overflow_hidden = "styles-module--overflow_hidden--d5601";
export var person = "styles-module--person--047a6";
export var position = "styles-module--position--57503";
export var post_title = "styles-module--post_title--cefac";
export var relatedFeature = "styles-module--relatedFeature--71638";
export var relatedFeatures = "styles-module--relatedFeatures--fee40";
export var right_sidebar = "styles-module--right_sidebar--cf4d7";
export var shareWrapper = "styles-module--shareWrapper--c23b6";
export var stay_updated_card = "styles-module--stay_updated_card--35b76";
export var stay_updated_card_button = "styles-module--stay_updated_card_button--bd488";
export var stay_updated_card_header = "styles-module--stay_updated_card_header--feab8";
export var stay_updated_card_link = "styles-module--stay_updated_card_link--6b53d";
export var stay_updated_card_text = "styles-module--stay_updated_card_text--c2ce9";
export var tag = "styles-module--tag--c78f6";
export var tags = "styles-module--tags--482ae";
export var tagsWrapper = "styles-module--tagsWrapper--d43fe";
export var title = "styles-module--title--699e0";
export var trafficShaping = "styles-module--trafficShaping--8ee9e";
export var verticalAd = "styles-module--verticalAd--e49eb";
export var white_rounded_button = "styles-module--white_rounded_button--23962";
export var yieldIntelligence = "styles-module--yieldIntelligence--143db";
export var yieldManager = "styles-module--yieldManager--1f72d";
export var yieldOptimizer = "styles-module--yieldOptimizer--ed3ba";