// extracted by mini-css-extract-plugin
export var boldSubtitle = "styles-module--bold-subtitle--75ef1";
export var btn = "styles-module--btn--b32a0";
export var firstGlow = "styles-module--first-glow--91377";
export var image = "styles-module--image--77093";
export var mainContent = "styles-module--main-content--9e66a";
export var note = "styles-module--note--d293b";
export var secondGlow = "styles-module--second-glow--f6282";
export var subtitle = "styles-module--subtitle--18159";
export var suptitle = "styles-module--suptitle--38fad";
export var title = "styles-module--title--ffa5f";
export var wrapper = "styles-module--wrapper--c2d67";