import { Gallery, Item } from "react-photoswipe-gallery";
import { StaticImage } from "gatsby-plugin-image";
import { Image as BuilderImage } from "@builder.io/react";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import glass from "./images/glass.svg";
import trafficAcquisitionIco from "./images/trafficAcquisition.svg";
import * as styles from "./trafficacquisition.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Traffic Acquisition",
        required: true,
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "Acquire more traffic, scale with data in mind",
    },
    {
        name: "description",
        type: "longText",
        defaultValue:
            "Combine Revenue and Spend sources, normalize the data and the RPS, CPC, and ROI (margin) you are achieving for each campaign, adset, ad, publisher, section, or account.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Talk to sales",
        required: true,
    },
    {
        name: "buttonTo",
        type: "text",
        defaultValue: "https://meetings-eu1.hubspot.com/stanislav-kaschiyski",
        required: true,
    },
    {
        name: "switchMagnifier",
        type: "boolean",
        defaultValue: true,
        enum: [
            {
                value: true,
                label: "Yes",
            },
            {
                value: false,
                label: "No",
            },
        ],
    },
    {
        name: "imageOrGif",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifHover",
        type: "file",
        allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
    },
    {
        name: "imageOrGifAlt",
        type: "text",
        defaultValue: "Gain Bidder Intelligence",
    },
    {
        name: "imageOrGifWidth",
        type: "number",
        defaultValue: 1391,
    },
    {
        name: "imageOrGifHeight",
        type: "number",
        defaultValue: 523,
    },
] as const;

const TrafficAcquisitionBlock: BFC<typeof inputs> = ({
    title,
    subtitle,
    description,
    buttonText,
    switchMagnifier,
    buttonTo,
    imageOrGif,
    imageOrGifAlt,
    imageOrGifWidth,
    imageOrGifHeight,
    imageOrGifHover,
}) => {
    return (
        <div className="component-wrapper common-bg">
            <div className={`gradient ${styles.left}`}>
                <StaticImage src="images/acq-left-gradient.png" alt="" />
            </div>

            <div className="container panel">
                <div className="row justify-content-center align-items-center gx-5">
                    <div className="col-lg-6 order-2">
                        <div className="prod-ico d-flex align-items-center mb-3">
                            <img src={trafficAcquisitionIco} alt="" />
                            <h4 className="ms-3 mb-0">{title}</h4>
                        </div>
                        <h2 className="product-title">{subtitle}</h2>
                        <p className="product-desc  mb-4 mb-lg-5">{description}</p>
                        <CustomButton buttonSize="btn--large" buttonStyle={"btn--primary"} to={buttonTo}>
                            {buttonText}
                        </CustomButton>
                    </div>
                    <div className="col-lg-6 position-relative  order-1 order-lg-2  mb-4 mb-lg-0 mt-4 mt-lg-0 text-center">
                        <div className="mask-container  position-relative ">
                            {imageOrGifHover ? (
                                <img
                                    onMouseOver={e => (e.currentTarget.src = imageOrGifHover)}
                                    onMouseOut={e => (e.currentTarget.src = imageOrGif!)}
                                    src={imageOrGif}
                                    alt={imageOrGifAlt}
                                    className="img-fluid"
                                />
                            ) : (
                                <BuilderImage image={imageOrGif} lazy sizes="100%" altText={imageOrGifAlt} />
                            )}
                            {switchMagnifier && !imageOrGifHover && (
                                <Gallery>
                                    <Item
                                        width={imageOrGifWidth}
                                        height={imageOrGifHeight}
                                        original={imageOrGif}
                                        alt={imageOrGifAlt}
                                    >
                                        {({ ref, open }) => (
                                            // @ts-expect-error
                                            <div className="image_mask" ref={ref} onClick={open}>
                                                <img src={glass} alt="zoom image" />
                                            </div>
                                        )}
                                    </Item>
                                </Gallery>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(TrafficAcquisitionBlock, {
    name: "TrafficAcquisitionBlock",
    inputs: toMutable(inputs),
});
