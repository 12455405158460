import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import itemsBg from "./images/make-your-data-work-for-you-bg.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Make your data work for you!",
    },
    {
        name: "list",
        type: "list",
        subFields: [
            {
                name: "statisticTitle",
                type: "text",
            },
            {
                name: "statisticDescription",
                type: "text",
            },
        ],
        defaultValue: [
            {
                statisticTitle: "250 +",
                statisticDescription: "Bidders Tracked",
            },
            {
                statisticTitle: "20 trillion +",
                statisticDescription: "Data Points",
            },
            {
                statisticTitle: "150 +",
                statisticDescription: "Active Users",
            },
            {
                statisticTitle: "35Bn +",
                statisticDescription: "Monthly Impressions Tracked",
            },
            {
                statisticTitle: "$40Mn +",
                statisticDescription: "Monthly Revenue Tracked",
            },
        ],
    },
] as const;

const MakeYourDataWorkForYou: BFC<typeof inputs> = ({ title, list }) => {
    return (
        <div className={styles.wrapper}>
            <div className="container">
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.items}>
                    {list?.map(({ statisticTitle, statisticDescription }, index) => (
                        <div className={styles.item} key={index}>
                            <img src={itemsBg} alt="" className={styles.itemBg} />
                            <h3 className={styles.title}>{statisticTitle}</h3>
                            <p className={styles.description}>{statisticDescription}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Builder.registerComponent(MakeYourDataWorkForYou, {
    name: "makeYourDataWorkForYou",
    inputs: toMutable(inputs),
});
