import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import gradient from "./images/gradient.svg";
import * as styles from "./styles.module.scss";
const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Ready to Play a New Game?",
    },
    {
        name: "description",
        type: "text",
        defaultValue: "Start a free trial today!",
    },
    {
        name: "buttonLink",
        type: "text",
        defaultValue: "/schedule-meeting",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Schedule a meeting",
    },
] as const;
const ReadyToPlayANewGame: BFC<typeof inputs> = ({ title, description, buttonLink, buttonText }) => {
    return (
        <div className={`component-wrapper mb-0 p-0`}>
            <div className={`gradient ${styles.left}`}>
                <img src={gradient} alt="" />
            </div>
            <div className="container">
                <div className="row text-lg-center mb-6">
                    <h2 className={styles.title}>{title}</h2>
                    <p className={styles.description}>{description}</p>
                    <div className="text-center">
                        <CustomButton buttonStyle="btn--yellow-red" to={buttonLink}>
                            {buttonText}
                        </CustomButton>
                    </div>
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(ReadyToPlayANewGame, {
    name: "ReadyToPlayANewGame",
    inputs: toMutable(inputs),
});
