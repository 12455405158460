import { manualISSStats } from "../../utils/iss";
import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import {
    convertToCurrencyCreator,
    lineItemLookup,
    QUERY_CURRENCY_KEY,
    REVENUE_BIAS_MULTIPLY,
    TABLE_NAME,
    VitalsCalculatedMetrics,
} from "../shared";
import { CalculationType, OptimizationObjective } from "../types";
import { WebVitalsMetrics } from "./shared/web-vitals";

const currencyConversionFactorE = (fromCurrency: string) =>
    `dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${QUERY_CURRENCY_KEY})) / dictGetFloat64('currencyConversion2', 'value', tuple(toDate(${TABLE_NAME}.timestamp), ${fromCurrency} ))`;

const convertToCurrency = convertToCurrencyCreator(currencyConversionFactorE);
const getCurrencyFromLineItem = `dictGetStringOrDefault('lineItem', 'currency', toUInt64(assumeNotNull(dfp_lineItemId)), '')`;

const convertLineItemCurrency = convertToCurrency(getCurrencyFromLineItem);

const clickBounceThreshold = 10000;
const lineItemLookupWithCurrency = `if(dictGetString('lineItem', 'costType', toUInt64(assumeNotNull(dfp_lineItemId))) = 'CPM', if(event_impression, toFloat64(dictGetUInt64('lineItem', 'value', toUInt64(assumeNotNull(dfp_lineItemId)))), 0) * ${convertLineItemCurrency}, toFloat64(assumeNotNull(revenue))) / 1000`;

const directRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
const directRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
const lineItemRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;
const lineItemRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem')`;

const apsRevenueField = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'aps')`;
const apsRevenueFieldNoCurrencyConversion = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'aps')`;

const directRevenueFieldLegacy = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
const directRevenueFieldNoCurrencyConversionLegacy = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;

const lineItemRevenueFieldLegacy = `sumIf(${lineItemLookupWithCurrency} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;
const lineItemRevenueFieldNoCurrencyConversionLegacy = `sumIf(${lineItemLookup} ${REVENUE_BIAS_MULTIPLY}, source = 'lineitem' OR source = 'aps')`;

const nativoRevenueCurrencyConverted = (field: string): string => {
    // nativo is always USD
    return `(${field} * ${convertToCurrency("'USD'")})`;
};
const yieldRevenue = `multiIf(source='lineitem',${lineItemLookupWithCurrency}/1000/1000, source='prebid', toFloat64(events.prebid_highestBid/1000/1000/1000), source = 'nativo', toFloat64(${nativoRevenueCurrencyConverted(
    "events.revenue"
)}/1000/1000/1000/1000/1000), toFloat64(events.revenue/1000/1000/1000))`;

const RAW_METRICS = {
    blocked_impressions: {
        field: "countIf(adQuality_blockingType > 0 AND event_impression = 1)",
    },
    impressions_filled: {
        field: "countIf(ad_unfilled = 0 AND event_impression = 1)",
    },
    prebid_cached_count: {
        field: `countIf(prebid_cachedBid AND source = 'prebid' AND event_impression)`,
    },
    ad_unfilled_count: {
        field: "countIf(ad_unfilled = 1 AND event_impression = 1)",
    },
    has_dfp_amazonBid_count: {
        field: "countIf(dfp_amazonBid != '' AND event_impression = 1)",
        hideFromApi: true,
    },
    has_dfp_hash_count: {
        field: "countIf(dfp_hash_raw != '' AND event_impression = 1)",
        hideFromApi: true,
    },
    ad_unfilled_with_prebid_bids_count: {
        field: "countIf(ad_unfilled = 1 AND prebid_highestBid != 0 AND event_impression = 1)",
        hideFromApi: true,
    },
    prebid_user_id_providers_sum_uses: {
        field: "sumIf(length(prebid_userIds), event_impression = 1)",
    },
    prebid_user_id_providers_zero_usages: {
        field: "countIf(length(prebid_userIds) = 0 AND event_impression = 1)",
    },
    vitals_lcp_measurable: {
        field: "countIf(vitals_lcp != -1)",
    },
    vitals_lcp_sum: {
        field: "sumIf(vitals_lcp, vitals_lcp != -1)/1000",
    },
    vitals_lcp_good: {
        field: "countIf(vitals_lcp != -1 AND vitals_lcp <= 2500)",
    },
    vitals_lcp_medium: {
        field: "countIf(vitals_lcp != -1 AND vitals_lcp > 2500 AND vitals_lcp <= 4000)",
    },
    vitals_lcp_bad: {
        field: "countIf(vitals_lcp != -1 AND vitals_lcp > 4000)",
    },
    vitals_fid_measurable: {
        field: "countIf(vitals_fid != -1)",
    },
    vitals_fid_sum: {
        field: "sumIf(vitals_fid, vitals_fid != -1)",
    },
    vitals_fid_good: {
        field: "countIf(vitals_fid != -1 AND vitals_fid <= 100)",
    },
    vitals_fid_medium: {
        field: "countIf(vitals_fid != -1 AND vitals_fid > 100 AND vitals_fid <= 300)",
    },
    vitals_fid_bad: {
        field: "countIf(vitals_fid != -1 AND vitals_fid > 300)",
    },
    vitals_cls_measurable: {
        field: "countIf(vitals_cls != -1)",
    },
    vitals_cls_sum: {
        field: "sumIf(vitals_cls, vitals_cls != -1)",
    },
    vitals_cls_good: {
        field: "countIf(vitals_cls != -1 AND vitals_cls <= 0.1)",
    },
    vitals_cls_medium: {
        field: "countIf(vitals_cls != -1 AND vitals_cls > 0.1 AND vitals_cls <= 0.25)",
    },
    vitals_cls_bad: {
        field: "countIf(vitals_cls != -1 AND vitals_cls > 0.25)",
    },
    vitals_inp_measurable: {
        field: "countIf(vitals_inp != -1)",
    },
    vitals_inp_sum: {
        field: "sumIf(vitals_inp, vitals_inp != -1)",
    },
    vitals_inp_good: {
        field: "countIf(vitals_inp != -1 AND vitals_inp <= 200)",
    },
    vitals_inp_medium: {
        field: "countIf(vitals_inp != -1 AND vitals_inp > 200 AND vitals_inp <= 500)",
    },
    vitals_inp_bad: {
        field: "countIf(vitals_inp != -1 AND vitals_inp > 500)",
    },

    vitals_ttfb_measurable: {
        field: "countIf(vitals_ttfb != -1)",
    },
    vitals_ttfb_sum: {
        field: "sumIf(vitals_ttfb, vitals_ttfb != -1)",
    },
    vitals_ttfb_good: {
        field: "countIf(vitals_ttfb != -1 AND vitals_ttfb <= 800)",
    },
    vitals_ttfb_medium: {
        field: "countIf(vitals_ttfb != -1 AND vitals_ttfb > 800 AND vitals_ttfb <= 1800)",
    },
    vitals_ttfb_bad: {
        field: "countIf(vitals_ttfb != -1 AND vitals_ttfb > 1800)",
    },

    vitals_fcp_measurable: {
        field: "countIf(vitals_fcp != -1)",
    },
    vitals_fcp_sum: {
        field: "sumIf(vitals_fcp, vitals_fcp != -1)",
    },
    vitals_fcp_good: {
        field: "countIf(vitals_fcp != -1 AND vitals_fcp <= 1800)",
    },
    vitals_fcp_medium: {
        field: "countIf(vitals_fcp != -1 AND vitals_fcp > 1800 AND vitals_fcp <= 3000)",
    },
    vitals_fcp_bad: {
        field: "countIf(vitals_fcp != -1 AND vitals_fcp > 3000)",
    },

    min_timestamp: {
        field: "toDateTime(min(timestamp), %%TIMEZONE%%)",
    },
    max_timestamp: {
        field: "toDateTime(max(timestamp), %%TIMEZONE%%)",
    },
    ivt_measurable_impressions: {
        field: "countIf(ivt_category > 0 AND event_impression)",
    },
    givt_impressions: {
        field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'GIVT' AND event_impression)",
    },
    sivt_impressions: {
        field: "countIf(dictGetStringOrDefault('ivt', 'type', tuple(ivt_category), 'Unknown') = 'SIVT' AND event_impression)",
    },
    impressions: {
        field: "countIf(event_impression)",
    },
    impressions_unfilled: {
        field: "countIf(ad_unfilled AND event_impression)",
    },
    impressions_lineitem_house: {
        field: "countIf(event_impression AND dictGetStringOrDefault('lineItem', 'lineItemType', toUInt64(dfp_lineItemId), 'Unknown') = 'HOUSE')",
    },
    pageViews: {
        field: "uniqIf(pageViewId, event_impression)",
    },
    pageViewsExact: {
        field: "uniqExact(pageViewId)",
        hideFromApi: true,
    },
    sessions: {
        field: "uniqIf(sessionId, event_impression)",
    },
    sessions_with_bounced_clicks: {
        // following clicksBounced: user returned to the site within 10 seconds -> click bounced / miss click
        field: `uniqIf(sessionId, event_clickBounced != 0 AND event_clickBounced < ${clickBounceThreshold})`,
        config: {
            clickBounceThreshold,
        },
    },
    session_starts: {
        field: "countIf(session_impressionCount = 1 AND event_impression)",
    },
    pageView_starts: {
        field: "countIf(pageView_impressionCount = 1 AND event_impression)",
    },
    first_five_indicator: {
        field: "sumIf(prebid_highestBid, session_impressionCount < 6 AND event_impression)",
    },
    viewable: {
        field: "countIf(event_viewed > 0 AND viewed_measurable)",
    },
    viewable_measurable_impressions: {
        field: "countIf(event_impression AND viewed_measurable)",
    },
    clicks: {
        field: "countIf(event_clicked > 0 AND ad_unfilled = 0)",
    },
    clicksBounced: {
        // user returned to the site within 10 seconds -> click bounced / miss click
        field: `countIf(event_clickBounced != 0 AND event_clickBounced < ${clickBounceThreshold})`,
        config: {
            clickBounceThreshold,
        },
    },
    clicksReturned: {
        // user returned to the site
        field: "countIf(event_clickBounced > 0)",
    },
    prebid_revenue: {
        field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression)`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_won_impressions: {
        field: "countIf(event_impression AND source = 'prebid')",
    },
    prebid_won_revenue: {
        field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'prebid')`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_lost_impressions: {
        field: "countIf(event_impression AND source != 'prebid')",
    },
    prebid_lost_revenue: {
        field: `sumIf(prebid_highestBid ${REVENUE_BIAS_MULTIPLY}, event_impression AND source != 'prebid')`,
        isRevenue: true,
        isPrebid: true,
    },
    prebid_incremental_value: {
        field: `sumIf((assumeNotNull(prebid_highestBid) - assumeNotNull(prebid_secondHighestBid)) ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'prebid')`,
        isRevenue: true,
        isPrebid: true,
    },
    nativo_revenue: {
        field: `sumIf(${nativoRevenueCurrencyConverted(
            "revenue"
        )} ${REVENUE_BIAS_MULTIPLY}, source = 'nativo')/1000/1000/1000`,
        fieldNoCurrencyConversion: `sumIf(revenue, source = 'nativo')/1000/1000/1000`,
        isRevenue: true,
        isNativo: true,
    },
    nativo_impressions: {
        field: "countIf(event_impression AND source = 'nativo')",
    },
    //*
    outbrain_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY} * ${currencyConversionFactorE(
            "'USD'"
        )}, source = 'outbrain')/1000`,
        fieldNoCurrencyConversion: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'outbrain')/1000`,
        isRevenue: true,
        isOutbrain: true,
    },
    outbrain_impressions: {
        field: "countIf(event_impression AND source = 'outbrain')",
    },
    taboola_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'taboola')/1000`,
        isRevenue: true,
        isTaboola: true,
    },
    taboola_impressions: {
        field: "countIf(event_impression AND source = 'taboola')",
    },
    yahooGemini_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'yahooGemini')/1000`,
        isRevenue: true,
        isYahooGemini: true,
    },
    yahooGemini_impressions: {
        field: "countIf(event_impression AND source = 'yahooGemini')",
    },
    aps_revenue: {
        field: apsRevenueField,
        fieldNoCurrencyConversion: apsRevenueFieldNoCurrencyConversion,
        isRevenue: true,
        isAmazonPublisherServices: true,
    },
    aps_impressions: {
        field: "countIf(event_impression AND source = 'aps')",
    },
    adSense_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'adSense')/1000`,
        isRevenue: true,
        isGoogleAdsense: true,
    },
    adSense_impressions: {
        field: "countIf(event_impression AND source = 'adSense')",
    },
    ayMediation_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'ayMediation_cpa')/1000`,
        isRevenue: true,
        isAyMediation: true,
    },
    ayMediation_impressions: {
        field: "countIf(event_impression AND source = 'ayMediation_cpa')",
    },
    dynamicAllocation_impressions: {
        field: "countIf(event_impression AND source = 'dynamicAllocation')",
    },
    dynamicAllocation_predicted_impressions: {
        field: "countIf(event_impression AND source = 'dynamicAllocation' AND isNotNull(da_predicted))",
    },
    dynamicAllocation_revenue: {
        field: `sumIf(revenue ${REVENUE_BIAS_MULTIPLY}, source = 'dynamicAllocation')/ 1000`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_revenue_sum_of_squares: {
        field: `SUM(if(source = 'dynamicAllocation', if(isNotNull(revenue), pow(revenue / 1000, 2), 0), 0) ${REVENUE_BIAS_MULTIPLY})`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_revenue_with_forecast: {
        field: `sumIf(toFloat64(if( %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp, revenue, toInt64(da_predicted_server))) ${REVENUE_BIAS_MULTIPLY}, source = 'dynamicAllocation') / 1000`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_revenue_with_forecast_client: {
        field: `sumIf(toFloat64(if( %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp, revenue, toInt64(da_predicted) )) ${REVENUE_BIAS_MULTIPLY},source = 'dynamicAllocation') / 1000`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_predicted_revenue: {
        field: `sumIf(da_predicted ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'dynamicAllocation')`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_predicted_revenue_residual: {
        // from https://en.wikipedia.org/wiki/Coefficient_of_determination

        field: `sumIf(
            pow(
                revenue / 1000
                - toFloat64(da_predicted) / 1000,
            2) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_abs_difference: {
        field: `sumIf(
            abs(
                revenue / 1000
                - toFloat64(da_predicted) / 1000
            ) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_server: {
        field: `sumIf(da_predicted_server ${REVENUE_BIAS_MULTIPLY}, event_impression AND source = 'dynamicAllocation')`,
        isRevenue: true,
        isDynamicAllocation: true,
    },
    dynamicAllocation_predicted_revenue_server_residual: {
        // from https://en.wikipedia.org/wiki/Coefficient_of_determination

        field: `sumIf(
            pow(
                revenue / 1000
                - toFloat64(da_predicted_server) / 1000,
            2) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_predicted_revenue_server_abs_difference: {
        field: `sumIf(
            abs(
                revenue / 1000
                - toFloat64(da_predicted_server) / 1000
            ) ${REVENUE_BIAS_MULTIPLY},
            %%MAX_DA_TIMESTAMP_PER_ENTITY%% > timestamp AND event_impression AND (source = 'dynamicAllocation')
        )`,
        isRevenue: true,
        isDynamicAllocation: true,
        hideFromApi: true,
    },
    dynamicAllocation_confirmed_clicks: {
        field: "countIf(event_impression AND dfp_confirmedClick)",
    },
    direct_impressions: {
        field: "countIf(event_impression AND source = 'lineitem')",
    },
    direct_revenue: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: directRevenueField,
        fieldNoCurrencyConversion: directRevenueFieldNoCurrencyConversion,
        isRevenue: true,
        isDirect: true,
    },
    direct_impressions_legacy: {
        field: "countIf(event_impression AND (source = 'lineitem' OR source = 'aps'))",
        hideFromApi: true,
    },
    direct_revenue_legacy: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: directRevenueFieldLegacy,
        fieldNoCurrencyConversion: directRevenueFieldNoCurrencyConversionLegacy,
        isRevenue: true,
        isDirect: true,
        hideFromApi: true,
    },
    lineItem_revenue_legacy: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: lineItemRevenueFieldLegacy,
        fieldNoCurrencyConversion: lineItemRevenueFieldNoCurrencyConversionLegacy,
        isRevenue: true,
        isDirect: true,
        hideFromApi: true,
    },
    lineItem_revenue: {
        // to convert currency, we must multiply the value by (the rate of the "to" currency / the rate of the "from" currency)
        // the "to" currency is hardcoded to EUR and the "from" currency if fetched from the lineItem dictionary
        field: lineItemRevenueField,
        fieldNoCurrencyConversion: lineItemRevenueFieldNoCurrencyConversion,
        isRevenue: true,
        isDirect: true,
    },
    acquisition_cost: {
        field: `sumIf(acquisition_cost * ${convertToCurrency(
            "acquisition_currency"
        )}, session_impressionCount = 1 AND event_impression)`,
        fieldNoCurrencyConversion: `sumIf(acquisition_cost, session_impressionCount = 1 AND event_impression)`,
    },
    acquisition_cost_measurable_session_starts: {
        field: `countIf(events.acquisition_cost > 0 AND session_impressionCount = 1 AND event_impression)`,
    },

    // floor
    floor: {
        field: "sumIf(ad_floor, event_impression)",
    },
    floor_empirical_revenue: {
        field: `SUM(assumeNotNull(if((toFloat64(ad_floor/1000/1000/1000) > ${yieldRevenue}) OR NOT event_impression, 0.0, least(toFloat64(ad_floor/1000/1000/1000), ${yieldRevenue}))))`,
    },
    floor_measurable_impressions: {
        field: "countIf(event_impression AND isNotNull(ad_floor))",
    },
    uniqueExternalId: {
        field: "uniq(externalId)",
    },
} as const satisfies Record<string, IRawMetric>;
export type RawYieldMetrics = keyof typeof RAW_METRICS;

export const CalculatedYieldMetricsIds = [
    "ad_unfilled",
    "ivt_rate",
    "givt_rate",
    "sivt_rate",
    "impressions",
    "impressions_filled",
    "impressions_filled_rate",
    "impressions_unfilled",
    "impressions_unfilled_rate",
    "impressions_unfilled_n_house",
    "impressions_unfilled_n_house_rate",
    "pageViews",
    "pageViewsExact",
    "sessions",
    "session_starts",
    "pageView_starts",
    "ads_per_pageView",
    "ads_per_pageViewExact",
    "ads_per_session",
    "revenue",
    "revenue_with_forecast",
    "clicks",
    "rpm",
    "rpm_with_forecast",
    "valueCpm",
    "pageView_rpm",
    "pageView_valueRpm",
    "session_rpm",
    "session_start_rpm",
    "session_start_rpm_with_forecast",
    "session_rpm_with_forecast",
    "session_valueRpm",
    "pageViews_per_session",
    "pageViewsExact_per_session",
    "viewability",
    "ctr",
    "cbr",
    "clr",
    "direct_impressions",
    "direct_revenue",
    "direct_rpm",
    "direct_valueRpm",
    "direct_winRate",
    "direct_pageView_rpm",
    "direct_pageView_valueRpm",
    "direct_session_rpm",
    "direct_session_valueRpm",
    "programmatic_impressions",
    "programmatic_revenue",
    "programmatic_revenue_with_forecast",
    "programmatic_rpm",
    "programmatic_valueRpm",
    "programmatic_winRate",
    "programmatic_pageView_rpm",
    "programmatic_pageView_valueRpm",
    "programmatic_session_rpm",
    "programmatic_session_rpm_with_forecast",
    "programmatic_session_valueRpm",
    "nativo_impressions",
    "nativo_revenue",
    "nativo_rpm",
    "nativo_pageView_rpm",
    "nativo_session_rpm",
    "nativo_winRate",
    "content_recommendation_impressions",
    "content_recommendation_revenue",
    "content_recommendation_rpm",
    "content_recommendation_pageView_rpm",
    "content_recommendation_session_rpm",
    "content_recommendation_winRate",
    "dynamicAllocation_impressions",
    "dynamicAllocation_revenue",
    "dynamicAllocation_revenue_with_forecast",
    "dynamicAllocation_revenue_with_forecast_client",
    "dynamicAllocation_rpm",
    "dynamicAllocation_valueRpm",
    "dynamicAllocation_winRate",
    "dynamicAllocation_pageView_rpm",
    "dynamicAllocation_pageViewExact_rpm",
    "dynamicAllocation_pageView_valueRpm",
    "dynamicAllocation_session_rpm",
    "dynamicAllocation_session_rpm_with_forecast",
    "dynamicAllocation_session_valueRpm",
    "acquisition_cost",
    "acquisition_cost_measurable_session_starts",
    "prebid_rpm",
    "prebid_cache_rate",
    "prebid_valueRpm",
    "prebid_winRate",
    "prebid_revenue",
    "prebid_pageView_rpm",
    "prebid_pageView_valueRpm",
    "prebid_session_rpm",
    "prebid_session_valueRpm",
    "prebid_won_impressions",
    "prebid_won_rpm",
    "prebid_won_valueRpm",
    "prebid_won_revenue",
    "prebid_won_pageView_rpm",
    "prebid_won_pageViewExact_rpm",
    "prebid_won_pageView_valueRpm",
    "prebid_won_session_rpm",
    "prebid_won_session_valueRpm",
    "prebid_lost_impressions",
    "prebid_lost_rpm",
    "prebid_lost_valueRpm",
    "prebid_lost_revenue",
    "prebid_lost_pageView_rpm",
    "prebid_lost_pageView_valueRpm",
    "prebid_lost_session_rpm",
    "prebid_lost_session_valueRpm",
    "prebid_lost_opportunity_rate",
    "prebid_incremental_value",
    "prebid_incremental_value_rpm",
    "lineItem_revenue",
    "dynamicAllocation_predicted_impressions",
    "dynamicAllocation_predicted_impressions_error",
    "dynamicAllocation_predicted_revenue",
    "dynamicAllocation_predicted_rpm",
    "dynamicAllocation_predicted_revenue_error",
    "dynamicAllocation_predicted_revenue_relative_error",
    "dynamicAllocation_predicted_revenue_r2",
    "dynamicAllocation_predicted_revenue_mae",
    "dynamicAllocation_predicted_revenue_rmse",
    "dynamicAllocation_predicted_revenue_server",
    "dynamicAllocation_predicted_rpm_server",
    "dynamicAllocation_predicted_revenue_error_server",
    "dynamicAllocation_predicted_revenue_r2_server",
    "dynamicAllocation_predicted_revenue_mae_server",
    "dynamicAllocation_predicted_revenue_rmse_server",
    "dynamicAllocation_predicted_revenue_relative_error_server",
    "dynamicAllocation_confirmed_clicks_rate",
    ...VitalsCalculatedMetrics,
    "uniqueExternalId",
    "adSense_impressions",
    "adSense_revenue",
    "adSense_rpm",
    "adSense_pageView_rpm",
    "adSense_session_rpm",
    "adSense_winRate",
    "ayMediation_impressions",
    "ayMediation_revenue",
    "ayMediation_rpm",
    "ayMediation_pageView_rpm",
    "ayMediation_session_rpm",
    "ayMediation_winRate",
    "floor_rpm",
    "floor_impressions",
    "floor_empirical_revenue_ratio",
    "clicks_bounce_session_rate",
    "sessions_with_bounced_clicks",
    "miss_click_impression_pct",
    "blocked_impressions",
    "blocked_impressions_rate",
    "aps_impressions",
    "aps_revenue",
    "aps_rpm",
    "aps_pageView_rpm",
    "aps_session_rpm",
    "aps_winRate",
    "prebid_user_id_providers_avg",
    "prebid_user_id_providers_zero_usages",
    "prebid_user_id_providers_zero_usages_rate",
    "has_dfp_amazonBid_count",
    "has_dfp_hash_count",
] as const;
export type YieldMetricsType = (typeof CalculatedYieldMetricsIds)[number];

const dayInMinutes = 60 * 24;
const sessionTimeSpan = 30;

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CalculatedYieldMetricsIds)
    .withCalculatedMetrics({
        // Yield
        // General
        ad_unfilled: {
            label: "Ad Unfilled",
            explanation: "Count of unfilled ads",
            formula: m => m.ad_unfilled_count,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        prebid_lost_opportunity_rate: {
            label: "Prebid Lost Opportunity",
            explanation:
                "Rate of unfilled impressions with available Prebid bid. High rates can indicate ad server delivery issues.",
            formula: m => m.ad_unfilled_with_prebid_bids_count / m.ad_unfilled_count,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Minimize,
            hideFromApiDocs: true,
            hideFromAlerts: true,
        },
        impressions: {
            label: "Impressions",
            explanation: "Rendered Impressions (including unfilled if explicitly enabled)",
            formula: m => m.impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        blocked_impressions: {
            label: "Blocked Impressions",
            explanation: "Count of blocked impressions",
            formula: m => m.blocked_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        has_dfp_amazonBid_count: {
            label: "DFP Amazon Bid Count",
            explanation: "Count of impressions with DFP Amazon Bids",
            formula: m => m.has_dfp_amazonBid_count,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
            hideFromApiDocs: true,
            hideFromAlerts: true,
        },
        has_dfp_hash_count: {
            label: "Ay Hash Count",
            explanation: "Count of impressions with  Ay Hash",
            formula: m => m.has_dfp_hash_count,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
            hideFromApiDocs: true,
            hideFromAlerts: true,
        },
        blocked_impressions_rate: {
            label: "Blocked Impressions Rate",
            explanation: "Rate of blocked impressions",
            formula: m => m.blocked_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            timeSpan: dayInMinutes,
            objective: OptimizationObjective.Minimize,
        },
        impressions_filled: {
            label: "Filled Impressions",
            explanation: "Filled Impressions (only tracked if enabled)",
            formula: m => m.impressions_filled,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        impressions_filled_rate: {
            label: "Filled Impression Rate",
            explanation: "Filled Impression Rate (only tracked if enabled)",
            formula: m => m.impressions_filled / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        impressions_unfilled: {
            label: "Unfilled Impressions",
            explanation: "Unfilled Impressions (only tracked if enabled)",
            formula: m => m.impressions_unfilled,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        impressions_unfilled_rate: {
            label: "Unfilled Impression Rate",
            explanation: "Unfilled Impression Rate (only tracked if enabled)",
            formula: m => m.impressions_unfilled / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        impressions_unfilled_n_house: {
            label: "Unfilled & House Impressions",
            explanation: "Unfilled & House Impressions (only tracked if enabled)",
            formula: m => m.impressions_lineitem_house + m.impressions_unfilled,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        impressions_unfilled_n_house_rate: {
            label: "Unfilled & House Impression Rate",
            explanation: "Unfilled & House Impression Rate (only tracked if enabled)",
            formula: m => m.impressions_unfilled_n_house / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        pageViews: {
            label: "PageViews",
            explanation: "Page views with rendered impressions",
            formula: m => m.pageViews,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        pageViewsExact: {
            label: "PageViews (Exact)",
            explanation: "Page views (exact) with rendered impressions",
            formula: m => m.pageViewsExact,
            calculationType: CalculationType.Sum,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        sessions: {
            label: "Unique Sessions",
            explanation: "Unique sessions with rendered impressions",
            formula: m => m.sessions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        session_starts: {
            label: "New Sessions",
            explanation: "New Session with rendered impressions",
            formula: m => m.session_starts,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        pageView_starts: {
            label: "New Page Views",
            explanation: "New Page Views with rendered impressions",
            formula: m => m.pageView_starts,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        ads_per_pageView: {
            label: "Ads per PageView",
            explanation: "Average amount of ads per page view with rendered impressions.",
            formula: m => m.impressions / m.pageViews,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        ads_per_pageViewExact: {
            label: "Ads per PageView (exact)",
            explanation: "Average amount of ads per page view (exact) with rendered impressions.",
            formula: m => m.impressions / m.pageViewsExact,
            calculationType: CalculationType.Mean,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        ads_per_session: {
            label: "Ads per Session",
            explanation: "Average amount of ads per session with rendered impressions.",
            formula: m => m.impressions / m.sessions,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        revenue: {
            label: "Revenue",
            explanation: "Revenue generated from all tracked sources.",
            formula: m =>
                m.prebid_won_revenue +
                m.dynamicAllocation_revenue +
                m.direct_revenue +
                m.content_recommendation_revenue +
                m.adSense_revenue +
                m.aps_revenue +
                m.nativo_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        revenue_with_forecast: {
            label: "Revenue with DA Forecast",
            explanation: "Revenue generated from all tracked sources incl. DA forecast.",
            formula: m =>
                m.prebid_won_revenue +
                m.dynamicAllocation_revenue_with_forecast +
                m.direct_revenue +
                m.content_recommendation_revenue +
                m.adSense_revenue +
                m.aps_revenue +
                m.nativo_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        clicks: {
            label: "Clicks",
            explanation: "Clicks",
            formula: m => m.clicks,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        rpm: {
            label: "RPM",
            explanation: "Revenue per mille (thousand) impressions.",
            formula: m => (m.revenue / m.impressions) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        rpm_with_forecast: {
            label: "RPM with DA Forecast",
            explanation: "Revenue with Prediction per mille (thousand) impressions with DA forecast.",
            formula: m => (m.revenue_with_forecast / m.impressions) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        valueCpm: {
            label: "Value-RPM",
            explanation: "RPM multiplied by viewability.",
            formula: m => m.rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        pageView_rpm: {
            label: "PageView RPM",
            explanation: "Revenue per mille (thousand) page views",
            formula: m => (m.revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        pageView_valueRpm: {
            label: "PageView Value-RPM",
            explanation: "PageView RPM multiplied by viewability.",
            formula: m => m.pageView_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        session_rpm: {
            label: "Session RPM",
            explanation: "Revenue per mille (thousand) sessions",
            formula: m => (m.revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        session_start_rpm: {
            label: "New Session RPM",
            explanation: "Revenue per mille (thousand) new sessions",
            formula: m => (m.revenue / m.session_starts) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        session_start_rpm_with_forecast: {
            label: "New Session RPM with Forecast",
            explanation: "Revenue per mille (thousand) new sessions incl. DA Forecast",
            formula: m => (m.revenue_with_forecast / m.session_starts) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
            issStats: manualISSStats.mock_web_avg,
        },
        session_rpm_with_forecast: {
            label: "Session RPM with DA Forecast",
            explanation: "Revenue per mille (thousand) sessions incl. DA Forecast",
            formula: m => (m.revenue_with_forecast / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        session_valueRpm: {
            label: "Session Value-RPM",
            explanation: "Session RPM multiplied by viewability.",
            formula: m => m.session_rpm * m.viewability,
            timeSpan: sessionTimeSpan,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        pageViews_per_session: {
            label: "PageViews per Session",
            explanation: "Average amount of monetized PageViews per Session",
            formula: m => m.pageViews / m.sessions,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        pageViewsExact_per_session: {
            label: "PageViews (exact) per Session",
            explanation: "Average amount of monetized PageViews (exact) per Session",
            formula: m => m.pageViewsExact / m.sessions,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        viewability: {
            label: "Viewability",
            explanation: "Proportion of impressions in view according to MRC standards.",
            formula: m => m.viewable / m.viewable_measurable_impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        ctr: {
            label: "CTR %",
            explanation: "CTR in percent",
            formula: m => (m.clicks / m.impressions_filled) * 100,
            calculationType: CalculationType.ProportionPercentage,
            objective: OptimizationObjective.Maximize,
        },
        miss_click_impression_pct: {
            label: "Miss-Click Impression %",
            explanation: "Rate of miss-clicks by impression, in percent",
            formula: m => (m.clicksBounced / m.impressions) * 100,
            calculationType: CalculationType.ProportionPercentage,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        cbr: {
            label: "Miss-Click Rate",
            explanation: "Miss clicks divided by total clicks.",
            formula: m => m.clicksBounced / m.clicks,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        clicks_bounce_session_rate: {
            label: "Miss-Click Session Rate",
            explanation: "Sessions with miss clicks divided by total sessions.",
            formula: m => m.sessions_with_bounced_clicks / m.sessions,
            calculationType: CalculationType.ProportionRatio,
            timeSpan: sessionTimeSpan,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        sessions_with_bounced_clicks: {
            label: "Sessions with Miss-Clicks",
            explanation: "Number of sessions that had at least one miss-click.",
            formula: m => m.sessions_with_bounced_clicks,
            timeSpan: sessionTimeSpan,
            calculationType: CalculationType.Sum,
            decimalPlaces: 3,
            objective: OptimizationObjective.Minimize,
        },
        clr: {
            label: "Click-Leave Rate",
            explanation: "The share of users not returning to the site after an ad click.",
            formula: m => (m.clicks - m.clicksReturned) / m.clicks,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Minimize,
        },
        ivt_rate: {
            label: "IVT Rate",
            explanation: "Rate of invalid traffic over entire traffic",
            formula: m => (m.givt_impressions + m.sivt_impressions) / m.ivt_measurable_impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Minimize,
        },
        givt_rate: {
            label: "GIVT Rate",
            explanation: "Rate of general invalid traffic over entire traffic",
            formula: m => m.givt_impressions / m.ivt_measurable_impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Minimize,
        },
        sivt_rate: {
            label: "SIVT Rate",
            explanation: "Rate of sophisticated invalid traffic over entire traffic",
            formula: m => m.sivt_impressions / m.ivt_measurable_impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Minimize,
        },
        ...WebVitalsMetrics,
        // Direct
        direct_impressions: {
            label: "D Impressions",
            explanation: "Impressions won by direct demand.",
            formula: m => m.direct_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        direct_revenue: {
            label: "D Revenue",
            explanation: "Revenue from non prebid and dynamic allocation line items.",
            formula: m => m.direct_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        direct_rpm: {
            label: "D RPM",
            explanation: "Direct revenue per mille (thousand) impressions.",
            formula: m => (m.direct_revenue / m.direct_impressions) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        direct_valueRpm: {
            label: "D Value-RPM",
            explanation: "Direct RPM multiplied by viewability.",
            formula: m => m.direct_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        direct_winRate: {
            label: "D Win Rate",
            explanation: "Win Rate of Direct over other demand.",
            formula: m => m.direct_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        direct_pageView_rpm: {
            label: "D PageView RPM",
            explanation: "Direct revenue per mille (thousand) page views",
            formula: m => (m.direct_revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        direct_pageView_valueRpm: {
            label: "D PageView Value-RPM",
            explanation: "Direct PageView RPM multiplied by viewability.",
            formula: m => m.direct_pageView_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        direct_session_rpm: {
            label: "D Session RPM",
            explanation: "Direct revenue per mille (thousand) sessions",
            formula: m => (m.direct_revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        direct_session_valueRpm: {
            label: "D Session Value-RPM",
            explanation: "Direct Session RPM multiplied by viewability.",
            formula: m => m.direct_session_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        // Programmatic
        programmatic_impressions: {
            label: "P Impressions",
            explanation: "Impressions won by Programmatic Demand.",
            formula: m =>
                m.prebid_won_impressions +
                m.dynamicAllocation_impressions +
                m.adSense_impressions +
                m.aps_impressions +
                m.content_recommendation_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_revenue: {
            label: "P Revenue",
            explanation: "Programmatic revenue.",
            formula: m =>
                m.prebid_won_revenue +
                m.dynamicAllocation_revenue +
                m.adSense_revenue +
                m.aps_revenue +
                m.content_recommendation_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_revenue_with_forecast: {
            label: "P Revenue with DA Forecast",
            explanation: "Programmatic Revenue generated from all tracked sources incl. DA forecast.",
            formula: m =>
                m.prebid_won_revenue +
                m.dynamicAllocation_revenue_with_forecast +
                m.adSense_revenue +
                m.aps_revenue +
                m.content_recommendation_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_rpm: {
            label: "P RPM",
            explanation: "Programmatic revenue per mille (thousand) impressions.",
            formula: m => (m.programmatic_revenue / m.programmatic_impressions) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_valueRpm: {
            label: "P Value-RPM",
            explanation: "Programmatic RPM multiplied by viewability.",
            formula: m => m.programmatic_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_winRate: {
            label: "P Win Rate",
            explanation: "Win Rate of Programmatic over other demand.",
            formula: m => m.programmatic_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_pageView_rpm: {
            label: "P PageView RPM",
            explanation: "Programmatic revenue per mille (thousand) page views",
            formula: m => (m.programmatic_revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_pageView_valueRpm: {
            label: "P PageView Value-RPM",
            explanation: "Programmatic PageView RPM multiplied by viewability.",
            formula: m => m.programmatic_pageView_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_session_rpm: {
            label: "P Session RPM",
            explanation: "Programmatic revenue per mille (thousand) sessions",
            formula: m => (m.programmatic_revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_session_rpm_with_forecast: {
            label: "P Session RPM with Forecast",
            explanation: "Programmatic revenue with forecast per mille (thousand) sessions",
            formula: m => (m.programmatic_revenue_with_forecast / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        programmatic_session_valueRpm: {
            label: "P Session Value-RPM",
            explanation: "Programmatic Session RPM multiplied by viewability.",
            formula: m => m.programmatic_session_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        // nativo
        nativo_impressions: {
            label: "NTV Impressions",
            explanation: "Impressions won by Nativo.",
            formula: m => m.nativo_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        nativo_rpm: {
            label: "NTV RPM",
            explanation: "RPM from impressions won by Nativo.",
            formula: m => (m.nativo_revenue / m.nativo_impressions) * 1000,
            issStats: manualISSStats.nativo_revenue_per_nativo_impression,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        nativo_revenue: {
            label: "NTV Revenue",
            explanation: "Nativo Revenue",
            formula: m => m.nativo_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        nativo_pageView_rpm: {
            label: "NTV PageView RPM",
            explanation: "Nativo PageView RPM from impressions won by Nativo.",
            formula: m => (m.nativo_revenue / m.pageViews) * 1000,
            issStats: manualISSStats.nativo_revenue_per_nativo_pageView,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        nativo_session_rpm: {
            label: "NTV Session RPM",
            explanation: "Session RPM from impressions won by Nativo.",
            formula: m => (m.nativo_revenue / m.sessions) * 1000,
            issStats: manualISSStats.nativo_revenue_per_nativo_session,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        nativo_winRate: {
            label: "NTV Win Rate",
            explanation: "Win Rate of Nativo over other demand.",
            formula: m => m.nativo_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        // Ad sense
        adSense_impressions: {
            label: "AdS Impressions",
            explanation: "Impressions won by AdSense.",
            formula: m => m.adSense_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        adSense_rpm: {
            label: "AdS RPM",
            explanation: "RPM from impressions won by AdSense.",
            formula: m => (m.adSense_revenue / m.adSense_impressions) * 1000,
            issStats: manualISSStats.adSense_revenue_per_adSense_impression,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        adSense_revenue: {
            label: "AdS Revenue",
            explanation: "AdSense Revenue",
            formula: m => m.adSense_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        adSense_pageView_rpm: {
            label: "AdS PageView RPM",
            explanation: "AdSense PageView RPM from impressions won by AdSense.",
            formula: m => (m.adSense_revenue / m.pageViews) * 1000,
            issStats: manualISSStats.adSense_revenue_per_adSense_pageView,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        adSense_session_rpm: {
            label: "AdS Session RPM",
            explanation: "Session RPM from impressions won by AdSense.",
            formula: m => (m.adSense_revenue / m.sessions) * 1000,
            issStats: manualISSStats.adSense_revenue_per_adSense_session,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        adSense_winRate: {
            label: "AdS Win Rate",
            explanation: "Win Rate of AdSense over other demand.",
            formula: m => m.adSense_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        // Ay Mediation
        ayMediation_impressions: {
            label: "AYM Impressions",
            explanation: "Impressions won by AY Mediation.",
            formula: m => m.ayMediation_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        ayMediation_rpm: {
            label: "AYM RPM",
            explanation: "RPM from impressions won by AY Mediation.",
            formula: m => (m.ayMediation_revenue / m.ayMediation_impressions) * 1000,
            issStats: manualISSStats.ayMediation_revenue_per_ayMediation_impression,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        ayMediation_revenue: {
            label: "AYM Revenue",
            explanation: "AY Mediation Revenue",
            formula: m => m.ayMediation_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        ayMediation_pageView_rpm: {
            label: "AYM PageView RPM",
            explanation: "AY Mediation. PageView RPM from impressions won by AY Mediation.",
            formula: m => (m.ayMediation_revenue / m.pageViews) * 1000,
            issStats: manualISSStats.ayMediation_revenue_per_ayMediation_pageView,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        ayMediation_session_rpm: {
            label: "AYM Session RPM",
            explanation: "Session RPM from impressions won by AY Mediation.",
            formula: m => (m.ayMediation_revenue / m.sessions) * 1000,
            issStats: manualISSStats.ayMediation_revenue_per_ayMediation_session,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        ayMediation_winRate: {
            label: "AYM Win Rate",
            explanation: "Win Rate of AY Mediation. over other demand.",
            formula: m => m.ayMediation_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        // Amazon Publisher Services
        aps_impressions: {
            label: "APS Impressions",
            explanation: "Impressions won by Amazon Publisher Services.",
            formula: m => m.aps_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        aps_rpm: {
            label: "APS RPM",
            explanation: "RPM from impressions won by Amazon Publisher Services.",
            formula: m => (m.aps_revenue / m.aps_impressions) * 1000,
            issStats: manualISSStats.aps_revenue_per_aps_impression,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        aps_revenue: {
            label: "APS Revenue",
            explanation: "Amazon Publisher Services Revenue",
            formula: m => m.aps_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        aps_pageView_rpm: {
            label: "APS PageView RPM",
            explanation: "PageView RPM from impressions won by Amazon Publisher Services.",
            formula: m => (m.aps_revenue / m.pageViews) * 1000,
            issStats: manualISSStats.aps_revenue_per_aps_pageView,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        aps_session_rpm: {
            label: "APS Session RPM",
            explanation: "Session RPM from impressions won by Amazon Publisher Services.",
            formula: m => (m.aps_revenue / m.sessions) * 1000,
            issStats: manualISSStats.aps_revenue_per_aps_session,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        aps_winRate: {
            label: "APS Win Rate",
            explanation: "Win Rate of Amazon Publisher Services over other demand.",
            formula: m => m.aps_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        // Content recommendation (Outbrain, ...)
        content_recommendation_impressions: {
            label: "CR Impressions",
            explanation: "Impressions won by Content Recommendation.",
            formula: m => m.outbrain_impressions + m.taboola_impressions + m.yahooGemini_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        content_recommendation_rpm: {
            label: "CR RPM",
            explanation: "RPM from impressions won by Content Recommendation.",
            formula: m => (m.content_recommendation_revenue / m.content_recommendation_impressions) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        content_recommendation_revenue: {
            label: "CR Revenue",
            explanation: "Content Recommendation Revenue",
            formula: m => m.outbrain_revenue + m.taboola_revenue + m.yahooGemini_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        content_recommendation_pageView_rpm: {
            label: "CR PageView RPM",
            explanation: "Content Recommendation Revenue PageView RPM ",
            formula: m => (m.content_recommendation_revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        content_recommendation_session_rpm: {
            label: "CR Session RPM",
            explanation: "Session RPM from impressions won by Content Recommendation.",
            formula: m => (m.content_recommendation_revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        content_recommendation_winRate: {
            label: "CR Win Rate",
            explanation: "Win Rate of Content Recommendation over other demand.",
            formula: m => m.content_recommendation_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        // Dynamic Allocation
        dynamicAllocation_impressions: {
            label: "DA Impressions",
            explanation: "Impressions won by Dynamic Allocation (AdX/AdSense).",
            formula: m => m.dynamicAllocation_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_revenue: {
            label: "DA Revenue",
            explanation: "Dynamic Allocation (AdX/AdSense) revenue.",
            formula: m => m.dynamicAllocation_revenue,
            calculationType: CalculationType.Sum,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_revenue_with_forecast: {
            label: "DA Revenue with Forecast",
            explanation: "Dynamic Allocation (AdX/AdSense) revenue with forecast.",
            formula: m => m.dynamicAllocation_revenue_with_forecast,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_revenue_with_forecast_client: {
            label: "DA Revenue with Forecast (Client)",
            explanation: "Dynamic Allocation (AdX/AdSense) revenue with forecast of Client-Side model.",
            formula: m => m.dynamicAllocation_revenue_with_forecast_client,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_rpm: {
            label: "DA RPM",
            explanation: "Dynamic Allocation revenue per mille (thousand) impressions.",
            formula: m => (m.dynamicAllocation_revenue / m.dynamicAllocation_impressions) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_valueRpm: {
            label: "DA Value-RPM",
            explanation: "Dynamic Allocation RPM multiplied by viewability.",
            formula: m => m.dynamicAllocation_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_winRate: {
            label: "DA Win Rate",
            explanation: "Win Rate of Dynamic Allocation over other demand.",
            formula: m => m.dynamicAllocation_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_pageView_rpm: {
            label: "DA PageView RPM",
            explanation: "Dynamic Allocation revenue per mille (thousand) page views",
            formula: m => (m.dynamicAllocation_revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_pageViewExact_rpm: {
            label: "DA PageView (exact) RPM",
            explanation: "Dynamic Allocation revenue per mille (thousand) page views (exact)",
            formula: m => (m.dynamicAllocation_revenue / m.pageViewsExact) * 1000,
            calculationType: CalculationType.Mean,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_pageView_valueRpm: {
            label: "DA PageView Value-RPM",
            explanation: "Dynamic Allocation PageView RPM multiplied by viewability.",
            formula: m => m.dynamicAllocation_pageView_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_session_rpm: {
            label: "DA Session RPM",
            explanation: "Dynamic Allocation revenue per mille (thousand) sessions",
            formula: m => (m.dynamicAllocation_revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_session_rpm_with_forecast: {
            label: "DA Session RPM with Forecast",
            explanation: "Dynamic Allocation revenue per mille (thousand) sessions",
            formula: m => (m.dynamicAllocation_revenue_with_forecast / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_session_valueRpm: {
            label: "DA Session Value-RPM",
            explanation: "Dynamic Allocation Session RPM multiplied by viewability.",
            formula: m => m.dynamicAllocation_session_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            timeSpan: sessionTimeSpan,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        acquisition_cost: {
            label: "Acquisition Cost",
            explanation: "Acquisition cost based on the configured URL parameters.",
            formula: m => m.acquisition_cost,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Minimize,
        },
        acquisition_cost_measurable_session_starts: {
            label: "Acquisition Cost Available",
            explanation: "Count of events where acquisition cost is available.",
            formula: m => m.acquisition_cost_measurable_session_starts,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        floor_impressions: {
            label: "Floored Impressions",
            explanation: "Impressions with floor price.",
            formula: m => m.floor_measurable_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        floor_rpm: {
            label: "Floor Price",
            explanation: "Floor Price CPM.",
            formula: m => m.floor / m.floor_measurable_impressions,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        floor_empirical_revenue_ratio: {
            label: "Floor Empirical Revenue Ratio",
            explanation: "Floor Empirical Revenue.",
            formula: m => m.floor_empirical_revenue / m.revenue,
            calculationType: CalculationType.SpecialRate,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        // Prebid
        // Prebid Total
        prebid_user_id_providers_avg: {
            label: "PB Avg. Provided UID's",
            explanation: "Avg. number of Prebid provided User ID's per impression.",
            formula: m => m.prebid_user_id_providers_sum_uses / m.impressions,
            calculationType: CalculationType.Mean,
            issStats: manualISSStats.prebid_user_id_providers_avg,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_user_id_providers_zero_usages: {
            label: "PB No UID Impressions",
            explanation: "Number of impressions without a provided Prebid User ID.",
            formula: m => m.prebid_user_id_providers_zero_usages,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_user_id_providers_zero_usages_rate: {
            label: "PB No UID Impressions Rate",
            explanation: "Rate of impressions without a provided Prebid User ID .",
            formula: m => m.prebid_user_id_providers_zero_usages / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_rpm: {
            label: "PB RPM",
            explanation: "Prebid revenue per mille (thousand) impressions.",
            formula: m => (m.prebid_revenue / m.impressions) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        prebid_cache_rate: {
            label: "PB Cache Rate",
            explanation: "The number of impressions that were from a cached bid.",
            formula: m => m.prebid_cached_count / m.prebid_won_impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_valueRpm: {
            label: "PB Value-RPM",
            explanation: "Prebid RPM multiplied by viewability.",
            formula: m => m.prebid_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        prebid_winRate: {
            label: "PB Win Rate",
            explanation: "Win Rate of Prebid over other demand.",
            formula: m => m.prebid_won_impressions / m.impressions,
            calculationType: CalculationType.ProportionRatio,
            objective: OptimizationObjective.Maximize,
        },
        prebid_revenue: {
            label: "PB Revenue",
            explanation:
                "Sum of the highest Prebid bids. In other words the revenue prebid would have generated without any other demand.",
            formula: m => m.prebid_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        prebid_pageView_rpm: {
            label: "PB PageView RPM",
            explanation: "Prebid revenue per mille (thousand) page views",
            formula: m => (m.prebid_revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        prebid_pageView_valueRpm: {
            label: "PB PageView Value-RPM",
            explanation: "Prebid PageView RPM multiplied by viewability.",
            formula: m => m.prebid_pageView_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        prebid_session_rpm: {
            label: "PB Session RPM",
            explanation: "Prebid revenue per mille (thousand) sessions",
            formula: m => (m.prebid_revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        prebid_session_valueRpm: {
            label: "PB Session Value-RPM",
            explanation: "Prebid Session RPM multiplied by viewability.",
            formula: m => m.prebid_session_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        // Prebid Won
        prebid_won_impressions: {
            label: "PBW Impressions",
            explanation: "Impressions won by Prebid.",
            formula: m => m.prebid_won_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_rpm: {
            label: "PBW RPM",
            explanation: "RPM from impressions won by Prebid.",
            formula: m => (m.prebid_won_revenue / m.prebid_won_impressions) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_valueRpm: {
            label: "PBW Value-RPM",
            explanation: "RPM from impressions won by Prebid multiplied by viewability.",
            formula: m => m.prebid_won_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_revenue: {
            label: "PBW Revenue",
            explanation: "Prebid-Won Revenue",
            formula: m => m.prebid_won_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_pageView_rpm: {
            label: "PBW PageView RPM",
            explanation: "Prebid-Won PageView RPM from impressions won by Prebid.",
            formula: m => (m.prebid_won_revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_pageViewExact_rpm: {
            label: "PBW PageView (exact) RPM",
            explanation: "Prebid-Won PageView (exact) RPM from impressions won by Prebid.",
            formula: m => (m.prebid_won_revenue / m.pageViewsExact) * 1000,
            calculationType: CalculationType.Mean,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_pageView_valueRpm: {
            label: "PBW PageView Value-RPM",
            explanation: "PageView RPM from impressions won by Prebid multiplied by viewability.",
            formula: m => m.prebid_won_pageView_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_session_rpm: {
            label: "PBW Session RPM",
            explanation: "Session RPM from impressions won by Prebid.",
            formula: m => (m.prebid_won_revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        prebid_won_session_valueRpm: {
            label: "PBW Session Value-RPM",
            explanation: "Session RPM from impressions won by Prebid multiplied by viewability.",
            formula: m => m.prebid_won_session_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.Maximize,
        },
        // Prebid Lost
        prebid_lost_impressions: {
            label: "PBL Impressions",
            explanation: "Rendered impressions won by AdX, direct.. (not prebid).",
            formula: m => m.prebid_lost_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_lost_rpm: {
            label: "PBL RPM",
            explanation: "RPM of the highest Prebid bids which did not win.",
            formula: m => (m.prebid_lost_revenue / m.prebid_lost_impressions) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_lost_valueRpm: {
            label: "PBL Value-RPM",
            explanation: "RPM of the highest Prebid bids which did not win multiplied by viewability.",
            formula: m => m.prebid_lost_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_lost_revenue: {
            label: "PBL Revenue",
            explanation: "Revenue Prebid would have generated if it had won.",
            formula: m => m.prebid_lost_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_lost_pageView_rpm: {
            label: "PBL PageView RPM",
            explanation: "Page view RPM of the highest Prebid bids which did not win.",
            formula: m => (m.prebid_lost_revenue / m.pageViews) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_lost_pageView_valueRpm: {
            label: "PBL PageView Value-RPM",
            explanation: "Page view RPM of the highest Prebid bids which did not win multiplied by.",
            formula: m => m.prebid_lost_pageView_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_lost_session_rpm: {
            label: "PBL Session RPM",
            explanation: "Session RPM of the highest Prebid bids which did not win",
            formula: m => (m.prebid_lost_revenue / m.sessions) * 1000,
            calculationType: CalculationType.Mean,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_lost_session_valueRpm: {
            label: "PBL Session Value-RPM",
            explanation: "Session RPM of the highest Prebid bids which did not win multiplied by Viewability.",
            formula: m => m.prebid_lost_session_rpm * m.viewability,
            calculationType: CalculationType.SpecialRate,
            timeSpan: sessionTimeSpan,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_incremental_value: {
            label: "PB Incremental Value",
            explanation:
                "The difference between the highest and second-highest bid in revenue. Giving an indication of the incremental value of the highest bid as well as bidder.",
            formula: m => m.prebid_incremental_value / 1000,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
        prebid_incremental_value_rpm: {
            label: "PB Incremental Value RPM",
            explanation:
                "The difference between the highest and second-highest bid in revenue, divided by number of prebid won impressions, multiplied by 1000.",
            formula: m => (m.prebid_incremental_value / m.prebid_won_impressions) * 1000,
            calculationType: CalculationType.Mean,
            objective: OptimizationObjective.NoObjective,
        },
        // other
        lineItem_revenue: {
            label: "Line Item Revenue",
            explanation: "The revenue based on the line items CPM",
            formula: m => m.lineItem_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.Maximize,
        },
        uniqueExternalId: {
            label: "Unique External Ids",
            explanation: "Count of unique external ids",
            formula: m => m.uniqueExternalId,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        dynamicAllocation_predicted_impressions: {
            label: "DA Predicted Impressions",
            explanation: "Predicted Impressions won by Dynamic Allocation (AdX/AdSense).",
            formula: m => m.dynamicAllocation_predicted_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        dynamicAllocation_predicted_impressions_error: {
            label: "DA Predicted Impressions Error",
            explanation: "Difference between actual and predicted impressions",
            formula: m => m.dynamicAllocation_impressions - m.dynamicAllocation_predicted_impressions,
            calculationType: CalculationType.Sum,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            objective: OptimizationObjective.Minimize,
        },
        // start da client prediction
        dynamicAllocation_predicted_revenue: {
            label: "DA Predicted Revenue (Client)",
            explanation: "Predicted Dynamic Allocation (AdX/AdSense) revenue.",
            formula: m => m.dynamicAllocation_predicted_revenue / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        dynamicAllocation_predicted_rpm: {
            label: "DA Predicted RPM (Client)",
            explanation: "Predicted Dynamic Allocation (AdX/AdSense) RPM.",
            formula: m => (m.dynamicAllocation_predicted_revenue / m.dynamicAllocation_impressions) * 1000,
            calculationType: CalculationType.SpecialRate,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            objective: OptimizationObjective.NoObjective,
        },
        dynamicAllocation_predicted_revenue_error: {
            label: "DA Predicted Revenue Error (Client)",
            explanation: "Difference between actual and predicted revenue",
            formula: m =>
                m.dynamicAllocation_revenue === 0
                    ? null
                    : Math.abs(
                          m.dynamicAllocation_revenue_with_forecast_client - m.dynamicAllocation_predicted_revenue
                      ),
            calculationType: CalculationType.Sum,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            objective: OptimizationObjective.Minimize,
        },
        dynamicAllocation_predicted_revenue_r2: {
            label: "DA Predicted R-Squared (Client)",
            explanation: "Coefficient of determination",
            formula: m => {
                // from https://en.wikipedia.org/wiki/Coefficient_of_determination
                const ssRes = m.dynamicAllocation_predicted_revenue_residual;
                const mm = 1000000.0;
                // from https://www.thoughtco.com/sum-of-squares-formula-shortcut-3126266
                const ssTot =
                    m.dynamicAllocation_revenue_sum_of_squares -
                    (1 / m.dynamicAllocation_impressions) * Math.pow(m.dynamicAllocation_revenue * mm, 2);

                const r2 = 1 - ssRes / ssTot;

                if (Number.isNaN(r2)) {
                    return null;
                }

                return r2;
            },
            hideFromApiDocs: true,
            hideFromAlerts: true,
            calculationType: CalculationType.SpecialRate,
            keepFormulaFalsyAsFalsy: true,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_predicted_revenue_mae: {
            label: "DA Predicted KMAE (Client)",
            explanation: "Mean Absolute Error * 1000",
            formula: m =>
                m.dynamicAllocation_predicted_revenue_abs_difference === 0
                    ? null
                    : m.dynamicAllocation_predicted_revenue_abs_difference / 1000.0 / m.dynamicAllocation_impressions,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            calculationType: CalculationType.SpecialRate,
            keepFormulaFalsyAsFalsy: true,
            objective: OptimizationObjective.Minimize,
        },
        dynamicAllocation_predicted_revenue_rmse: {
            label: "DA Predicted KRMSE (Client)",
            explanation: "Root Mean Squared Error * 1000",
            formula: m =>
                m.dynamicAllocation_predicted_revenue_residual === 0
                    ? null
                    : Math.sqrt(
                          m.dynamicAllocation_predicted_revenue_residual / 1000000.0 / m.dynamicAllocation_impressions
                      ),
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Minimize,
        },
        dynamicAllocation_predicted_revenue_relative_error: {
            label: "DA Predicted Revenue Relative Error (Client)",
            explanation: "Difference between actual and predicted revenue",
            formula: m =>
                m.dynamicAllocation_revenue === 0
                    ? null
                    : Math.abs(
                          m.dynamicAllocation_predicted_revenue - m.dynamicAllocation_revenue_with_forecast_client
                      ) / m.dynamicAllocation_revenue_with_forecast_client,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Minimize,
        },
        // end da client prediction
        // start da server prediction
        dynamicAllocation_predicted_revenue_server: {
            label: "DA Predicted Revenue (Server)",
            explanation: "Predicted Dynamic Allocation (AdX/AdSense) revenue.",
            formula: m => m.dynamicAllocation_predicted_revenue_server / 1000,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        dynamicAllocation_predicted_rpm_server: {
            label: "DA Predicted RPM (Server)",
            explanation: "Predicted Dynamic Allocation (AdX/AdSense) RPM.",
            formula: m => (m.dynamicAllocation_predicted_revenue_server / m.dynamicAllocation_impressions) * 1000,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.NoObjective,
        },
        dynamicAllocation_predicted_revenue_error_server: {
            label: "DA Predicted Revenue Error (Server)",
            explanation: "Difference between actual and predicted revenue",
            formula: m =>
                m.dynamicAllocation_revenue === 0
                    ? null
                    : Math.abs(
                          m.dynamicAllocation_revenue_with_forecast - m.dynamicAllocation_predicted_revenue_server
                      ),
            calculationType: CalculationType.Sum,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            objective: OptimizationObjective.Minimize,
        },
        dynamicAllocation_predicted_revenue_r2_server: {
            label: "DA Predicted R-Squared (Server)",
            explanation: "Coefficient of determination",
            formula: m => {
                // from https://en.wikipedia.org/wiki/Coefficient_of_determination
                const ssRes = m.dynamicAllocation_predicted_revenue_server_residual;
                const mm = 1000000.0;
                // from https://www.thoughtco.com/sum-of-squares-formula-shortcut-3126266
                const ssTot =
                    m.dynamicAllocation_revenue_sum_of_squares -
                    (1 / m.dynamicAllocation_impressions) * Math.pow(m.dynamicAllocation_revenue * mm, 2);
                const r2 = 1 - ssRes / ssTot;

                if (Number.isNaN(r2)) {
                    return null;
                }

                return r2;
            },
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Maximize,
        },
        dynamicAllocation_predicted_revenue_mae_server: {
            label: "DA Predicted KMAE (Server)",
            explanation: "Mean Absolute Error * 1000",
            formula: m =>
                m.dynamicAllocation_predicted_revenue_server_abs_difference === 0
                    ? null
                    : m.dynamicAllocation_predicted_revenue_server_abs_difference /
                      1000.0 /
                      m.dynamicAllocation_impressions,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Minimize,
        },
        dynamicAllocation_predicted_revenue_rmse_server: {
            label: "DA Predicted KRMSE (Server)",
            explanation: "Root Mean Squared Error * 1000",
            formula: m =>
                m.dynamicAllocation_predicted_revenue_server_residual === 0
                    ? null
                    : Math.sqrt(
                          m.dynamicAllocation_predicted_revenue_server_residual /
                              1000000.0 /
                              m.dynamicAllocation_impressions
                      ),
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Minimize,
        },
        dynamicAllocation_predicted_revenue_relative_error_server: {
            label: "DA Predicted Revenue Relative Error (Server)",
            explanation: "Difference between actual and predicted revenue",
            formula: m =>
                m.dynamicAllocation_revenue_with_forecast === 0
                    ? null
                    : Math.abs(
                          m.dynamicAllocation_predicted_revenue_server - m.dynamicAllocation_revenue_with_forecast
                      ) / m.dynamicAllocation_revenue_with_forecast,
            hideFromApiDocs: true,
            hideFromAlerts: true,
            keepFormulaFalsyAsFalsy: true,
            calculationType: CalculationType.SpecialRate,
            objective: OptimizationObjective.Minimize,
        },
        dynamicAllocation_confirmed_clicks_rate: {
            label: "Confirmed Clicks Rate",
            explanation: "Rate of confirmed clicks over Dynamic Allocation impressions",
            formula: m =>
                m.dynamicAllocation_impressions === 0
                    ? null
                    : m.dynamicAllocation_confirmed_clicks / m.dynamicAllocation_impressions,
            keepFormulaFalsyAsFalsy: true,
            calculationType: CalculationType.ProportionRatio,
            decimalPlaces: 3,
            objective: OptimizationObjective.Maximize,
        },
    });
