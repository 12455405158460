import { EdaPlacementBuilder } from "../lib";
import type { IRawMetric } from "../raw-metrics.service";
import { CalculationType, OptimizationObjective } from "../types";

const RAW_METRICS = {
    actual_revenue: {},
    actual_impressions: {},
    actual_revenue_ssp_currency: {},
} as const satisfies Record<string, IRawMetric>;
export type RawAdRevenueMetrics = keyof typeof RAW_METRICS;

const CALC_IDS = ["actual_revenue", "actual_impressions", "actual_revenue_ssp_currency"] as const;
export type FinancialReportMetrics = (typeof CALC_IDS)[number];

export default new EdaPlacementBuilder()
    .withRawMetrics(RAW_METRICS)
    .withCalculatedMetricsIds(CALC_IDS)
    .withCalculatedMetrics({
        actual_revenue: {
            label: "Actual Revenue (USD)",
            explanation: "Actual revenue represented from SSP's connections in USD",
            formula: m => m.actual_revenue,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        actual_impressions: {
            label: "Actual Impressions",
            explanation: "Actual impressions represented from SSP's connections",
            formula: m => m.actual_impressions,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
        actual_revenue_ssp_currency: {
            label: "Actual Revenue (SSP Currency)",
            explanation: "Actual revenue represented from SSP's connections in SSP currency",
            formula: m => m.actual_revenue_ssp_currency,
            calculationType: CalculationType.Sum,
            objective: OptimizationObjective.NoObjective,
        },
    });
