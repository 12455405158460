// extracted by mini-css-extract-plugin
export var card = "styles-module--card--c7d9c";
export var container_wrapper = "styles-module--container_wrapper--c95e7";
export var groupTitle = "styles-module--groupTitle--ede77";
export var no_text_decoration = "styles-module--no_text_decoration--48bdb";
export var play_button_overlay = "styles-module--play_button_overlay--79d54";
export var secondGroupTitle = "styles-module--secondGroupTitle--24349";
export var videoPreview = "styles-module--videoPreview--b00fa";
export var video_thumb_wrapper = "styles-module--video_thumb_wrapper--7c1d0";
export var video_title = "styles-module--video_title--4a9bc";
export var videosGroup = "styles-module--videosGroup--fcd21";
export var videosWrapper = "styles-module--videosWrapper--5d14f";