// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--4e09e";
export var firstGlow = "styles-module--first-glow--e8b6b";
export var fourthGlow = "styles-module--fourth-glow--f0307";
export var image = "styles-module--image--6d33d";
export var mainContent = "styles-module--main-content--079df";
export var note = "styles-module--note--db1cc";
export var secondGlow = "styles-module--second-glow--7f082";
export var subtitle = "styles-module--subtitle--a87aa";
export var suptitle = "styles-module--suptitle--b87ba";
export var title = "styles-module--title--8424e";
export var wrapper = "styles-module--wrapper--5a757";