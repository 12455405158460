import { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { BOOK_CALL_LINK, CONTACT_US_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import Layout from "components/Layout";
// eslint-disable-next-line import/no-unresolved
import "components/pricing/pricing.scss";
import gradleft from "./images/grad-left.png";
import gradright from "./images/grad-right.png";
const Index = () => {
    const [key, setKey] = useState("home");
    const [keyProduct, setKeyProduct] = useState("intelligence");

    return (
        <Layout bg="dark">
            <div className="content-dark">
                <div className="component-wrapper mt-70 mb-5 mb-lg-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-8 col-lg-6">
                                <h1 className="mb-4">Ready to get to the next level with AY</h1>
                                <p className="mb-5">Choose the products that are right for you</p>
                                <CustomButton buttonSize="btn--large" to={CONTACT_US_LINK}>
                                    Contact us
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-light pricing_page pt-5 pt-lg-0  overflow-hidden position-relative">
                <img src={gradleft} className={`gradient left`} style={{ top: "150px" }} alt="gradient" />
                <img src={gradright} className={`gradient right`} style={{ top: "150px" }} alt="gradient" />

                <Tabs
                    defaultActiveKey="home"
                    id="plans"
                    className={` nav-pills nav_tabs_custom`}
                    onSelect={k => setKey(k!)}
                    activeKey={key}
                >
                    <Tab eventKey="home" title="Products & Plans" tabClassName={`product_plans`}>
                        <Tabs
                            defaultActiveKey="intelligence"
                            id="products_plan"
                            className={` nav-pills nav_tabs_products`}
                            onSelect={k => setKeyProduct(k!)}
                            activeKey={keyProduct}
                        >
                            <Tab eventKey="intelligence" title="Yield Intelligence" tabClassName={`product_plan`}>
                                <div className="d-block text-center mt-6 mb-5 container">
                                    <h2 className="text-dark">Let's play to win?</h2>
                                    <p className="text-dark mb-5">Make your data work for you!</p>
                                    <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                        Talk to Sales
                                    </CustomButton>
                                </div>
                            </Tab>
                            <Tab eventKey="optimizer" title="Yield Optimizer">
                                <div className="d-block text-center mt-6 mb-5 container">
                                    <h2 className="text-dark">Let's play to win?</h2>
                                    <p className="text-dark mb-5">Make your data work for you!</p>
                                    <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                        Talk to Sales
                                    </CustomButton>
                                </div>
                            </Tab>
                            <Tab eventKey="manager" title="Yield Manager">
                                <div className="d-block text-center mt-6 mb-5 container">
                                    <h2 className="text-dark">Ready to be ahead in the game?</h2>
                                    <p className="text-dark mb-5">Make your data work for you!</p>
                                    <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                        Talk to Sales
                                    </CustomButton>
                                </div>
                            </Tab>
                            <Tab eventKey="shaping" title="Traffic Shaping">
                                <div className="d-block text-center mt-6 mb-5 container">
                                    <h2 className="text-dark">Let's play to win?</h2>
                                    <p className="text-dark mb-5">Make your data work for you!</p>
                                    <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                        Talk to Sales
                                    </CustomButton>
                                </div>
                            </Tab>
                        </Tabs>
                    </Tab>
                    <Tab eventKey="bundles" title="Bundles">
                        <div className="d-block text-center mt-6 mb-5 container">
                            <h2 className="text-dark">Ready to Win ?</h2>
                            <CustomButton buttonSize="btn--large" to={BOOK_CALL_LINK}>
                                Talk to Sales
                            </CustomButton>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    );
};

export default Index;
