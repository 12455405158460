import { Builder } from "@builder.io/react";
import { toMutable } from "types/common";
import StayTuned from ".";

export const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Stay Tuned",
    },
    {
        name: "subtitle",
        type: "text",
        defaultValue: "The latest industry news, interviews, technologies, and resources.",
    },
    {
        name: "buttonText",
        type: "text",
        defaultValue: "Read More",
    },
    {
        name: "buttonLink",
        type: "text",
        defaultValue: "/blog",
    },
] as const;

const HomeStayTuned: BFC<typeof inputs> = inputs => <StayTuned inputs={inputs} />;
Builder.registerComponent(HomeStayTuned, {
    name: "HomeStayTuned",
    inputs: toMutable(inputs),
});
