import type { CSSProperties, FC } from "react";
import { Link } from "gatsby";
import type { Bg } from "types/common";
import * as styles from "./styles.module.scss";
const TransparentGradientButton: FC<{
    text: string;
    to: string;
    bg: Bg;
    bgColor: string;
    width?: string;
    contentWidth?: string;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}> = ({ text, to, bg, bgColor, onClick, width, contentWidth }) => {
    const link = (style: CSSProperties) =>
        String(to).startsWith("http") ? (
            <a
                className={[styles.button, bg === "light" ? styles.button_light_bg : styles.button_dark_bg].join(" ")}
                style={style}
                onClick={onClick}
                href={to}
                target="_blank"
                rel="noreferrer"
            >
                {text}
            </a>
        ) : (
            <Link
                className={[styles.button, bg === "light" ? styles.button_light_bg : styles.button_dark_bg].join(" ")}
                style={style}
                to={to}
                onClick={onClick}
            >
                {text}
            </Link>
        );
    return (
        <div className={[styles.wrapper_all].join(" ")}>
            <div
                className={[
                    styles.button_wrapper,
                    bg == "light" ? styles.button_wrapper_hover : styles.button_wrapper_hover_blur_more,
                ].join(" ")}
            >
                {link({ opacity: 0, width })}
            </div>
            {contentWidth ? (
                <div
                    style={{
                        width: contentWidth,
                    }}
                    className={[styles.button_wrapper, styles.button_no_blur].join(" ")}
                >
                    {link({ backgroundColor: bgColor })}
                </div>
            ) : (
                <div className={[styles.button_wrapper, styles.button_no_blur].join(" ")}>
                    {link({ backgroundColor: bgColor })}
                </div>
            )}
        </div>
    );
};
export default TransparentGradientButton;
