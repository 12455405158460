import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "gatsby";
import { Builder } from "@builder.io/react";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import calendarIcon from "./images/calendarIcon.svg";
import closeIcon from "./images/closeIcon.svg";
import grayMinus from "./images/grayMinus.svg";
import greenCheckmark from "./images/greenCheckmark.svg";
import messageIcon from "./images/messageIcon.svg";
import yellowCheckmark from "./images/yellowCheckmark.svg";
import * as styles from "./styles.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Next-Gen Publisher's Suite",
    },
    {
        name: "plans",
        type: "list",
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Manager",
            },
            {
                name: "headers",
                type: "list",
                subFields: [
                    {
                        name: "title",
                        type: "text",
                        defaultValue: "",
                    },
                    {
                        name: "icon",
                        type: "file",
                        allowedFileTypes: ["svg"],
                        defaultValue: "",
                    },
                    {
                        name: "features",
                        type: "list",
                        subFields: [
                            {
                                name: "feature",
                                type: "text",
                                defaultValue: "",
                            },
                            {
                                name: "explanation",
                                type: "text",
                                defaultValue: "",
                            },
                            {
                                name: "isPro",
                                type: "boolean",
                                defaultValue: true,
                            },
                            {
                                name: "isOne",
                                type: "boolean",
                                defaultValue: true,
                            },
                            {
                                name: "isPublishers",
                                type: "boolean",
                                defaultValue: true,
                            },
                            {
                                name: "isssps",
                                type: "boolean",
                                defaultValue: true,
                            },
                        ],
                    },
                ],
            },
        ],
    },
] as const;

const managerPlans = ["", "PRO", "ONE"];
const analysePlans = ["", "PRO", "ONE"];
const optimizePlans = ["", "PUBLISHERS", "SSPs"];

const PricingProducts: BFC<typeof inputs> = ({ title, plans }) => {
    const [selectedPlan, setselectedPlan] = useState(plans?.[0].title);
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <>
            <div className="content-light">
                <div className="container">
                    <div className={styles.wrapper}>
                        <h2 className={styles.title}>{title}</h2>

                        <div className={styles.selectContainer}>
                            <ul className={styles.selectWrapper}>
                                {plans?.map((plan, index) => (
                                    <li
                                        key={index}
                                        className={`${styles.selectItem} ${
                                            selectedPlan === plan.title ? styles.active : ""
                                        }`}
                                        onClick={() => setselectedPlan(plan.title)}
                                    >
                                        {plan.title}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {selectedPlan === "Manager" && (
                            <table className={styles.table}>
                                <thead className={styles.fixedHeader}>
                                    <tr>
                                        {managerPlans.map((header, index) => (
                                            <th key={index}>
                                                <p className={styles.tableTitle}>{header}</p>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                {plans
                                    ?.filter(plan => plan.title === selectedPlan)
                                    .map(plan =>
                                        plan.headers?.map((header, index) => (
                                            <tbody className={styles.tableGroup} key={header.title! + index}>
                                                <tr>
                                                    <th>
                                                        <div className={styles.header}>
                                                            {header.title ? (
                                                                <p className={styles.headerText}>{header.title}</p>
                                                            ) : (
                                                                <img src={header.icon} alt="icon" />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th />
                                                    <th />
                                                </tr>

                                                {header.features?.map((feature, index) => (
                                                    <tr key={index}>
                                                        <td>{feature.feature}</td>
                                                        <td className={styles.checkMark}>
                                                            <img
                                                                src={
                                                                    header.title === "Add-on Support Services"
                                                                        ? yellowCheckmark
                                                                        : feature.isPro
                                                                          ? greenCheckmark
                                                                          : grayMinus
                                                                }
                                                            />
                                                        </td>
                                                        <td className={styles.checkMark}>
                                                            <img
                                                                src={
                                                                    header.title === "Add-on Support Services"
                                                                        ? yellowCheckmark
                                                                        : feature.isOne
                                                                          ? greenCheckmark
                                                                          : grayMinus
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        ))
                                    )}
                                <tr>
                                    <td />
                                    <td className={styles.buttonWrapper}>
                                        <CustomButton
                                            buttonSize="btn--large"
                                            customWidth="200px"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Get started
                                        </CustomButton>
                                    </td>
                                    <td className={styles.buttonWrapper}>
                                        <CustomButton
                                            buttonSize="btn--large"
                                            customWidth="200px"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Get started
                                        </CustomButton>
                                    </td>
                                </tr>
                            </table>
                        )}

                        {selectedPlan === "Analyse" && (
                            <table className={styles.table}>
                                <thead className={styles.fixedHeader}>
                                    <tr>
                                        {analysePlans.map((header, index) => (
                                            <th key={index}>
                                                <p className={styles.tableTitle}>{header}</p>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                {plans
                                    ?.filter(plan => plan.title === selectedPlan)
                                    .map(plan =>
                                        plan.headers?.map((header, index) => (
                                            <tbody className={styles.tableGroup} key={index + header.title!}>
                                                <tr>
                                                    <th>
                                                        <div className={styles.header}>
                                                            {header.title ? (
                                                                <p className={styles.headerText}>{header.title}</p>
                                                            ) : (
                                                                <img src={header.icon} alt="icon" />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th />
                                                    <th />
                                                </tr>

                                                {header.features?.map((feature, index) => (
                                                    <tr key={index}>
                                                        <td>{feature.feature}</td>
                                                        <td className={styles.checkMark}>
                                                            <img
                                                                src={
                                                                    header.title === "Add-on Support Services"
                                                                        ? yellowCheckmark
                                                                        : feature.isPro
                                                                          ? greenCheckmark
                                                                          : grayMinus
                                                                }
                                                            />
                                                        </td>
                                                        <td className={styles.checkMark}>
                                                            <img
                                                                src={
                                                                    header.title === "Add-on Support Services"
                                                                        ? yellowCheckmark
                                                                        : feature.isOne
                                                                          ? greenCheckmark
                                                                          : grayMinus
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        ))
                                    )}
                                <tr>
                                    <td />
                                    <td className={styles.buttonWrapper}>
                                        <CustomButton
                                            buttonSize="btn--large"
                                            customWidth="200px"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Get started
                                        </CustomButton>
                                    </td>
                                    <td className={styles.buttonWrapper}>
                                        <CustomButton
                                            buttonSize="btn--large"
                                            customWidth="200px"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Get started
                                        </CustomButton>
                                    </td>
                                </tr>
                            </table>
                        )}

                        {selectedPlan === "AI Optimize" && (
                            <table className={styles.table}>
                                <thead className={styles.fixedHeader}>
                                    <tr>
                                        {optimizePlans.map((header, index) => (
                                            <th key={index}>
                                                <p className={styles.tableTitle}>{header}</p>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                {plans
                                    ?.filter(plan => plan.title === selectedPlan)
                                    .map(plan =>
                                        plan.headers?.map((header, index) => (
                                            <tbody className={styles.tableGroup} key={header.title! + index}>
                                                <tr>
                                                    <th>
                                                        <div className={styles.header}>
                                                            {header.title ? (
                                                                <p className={styles.headerText}>{header.title}</p>
                                                            ) : (
                                                                <img src={header.icon} alt="icon" />
                                                            )}
                                                        </div>
                                                    </th>
                                                    <th />
                                                    <th />
                                                </tr>

                                                {header.features?.map((feature, index) => (
                                                    <tr key={index}>
                                                        <td>{feature.feature}</td>
                                                        <td className={styles.checkMark}>
                                                            <img
                                                                src={
                                                                    header.title === "Add-on Support Services"
                                                                        ? yellowCheckmark
                                                                        : feature.isPublishers
                                                                          ? greenCheckmark
                                                                          : grayMinus
                                                                }
                                                            />
                                                        </td>
                                                        <td className={styles.checkMark}>
                                                            <img
                                                                src={
                                                                    header.title === "Add-on Support Services"
                                                                        ? yellowCheckmark
                                                                        : feature.isssps
                                                                          ? greenCheckmark
                                                                          : grayMinus
                                                                }
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        ))
                                    )}
                                <tr>
                                    <td />
                                    <td className={styles.buttonWrapper}>
                                        <CustomButton
                                            buttonSize="btn--large"
                                            customWidth="200px"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Get started
                                        </CustomButton>
                                    </td>
                                    <td className={styles.buttonWrapper}>
                                        <CustomButton
                                            buttonSize="btn--large"
                                            customWidth="200px"
                                            onClick={() => setIsModalOpen(true)}
                                        >
                                            Get started
                                        </CustomButton>
                                    </td>
                                </tr>
                            </table>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                onHide={() => setIsModalOpen(false)}
                show={isModalOpen}
                size="lg"
                dialogClassName={styles.modalStyles}
                centered
            >
                <Modal.Body className={styles.modalBodyStyles}>
                    <span onClick={() => setIsModalOpen(false)} className={styles.closeIcon}>
                        <img src={closeIcon} />
                    </span>

                    <h3 className={styles.modalTitle}>Get Started</h3>

                    <div className={styles.cardsWrapper}>
                        <Link className={styles.card} to="/schedule-meeting">
                            <span className={styles.cardImage}>
                                <img src={calendarIcon} />
                            </span>

                            <h5 className={styles.cardTitle}>New Client</h5>

                            <p className={styles.cardDescription}>Book a call with our sales team</p>
                        </Link>

                        <Link className={styles.card} to="/">
                            <span className={styles.cardImage}>
                                <img src={messageIcon} />
                            </span>

                            <h5 className={styles.cardTitle}>Existing Client</h5>

                            <p className={styles.cardDescription}>Contact our customer support team</p>
                        </Link>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

Builder.registerComponent(PricingProducts, {
    name: "pricingProducts",
    inputs: toMutable(inputs),
});
