import { StaticImage } from "gatsby-plugin-image";
import { Builder } from "@builder.io/react";
import { SIGNUP_LINK } from "components/constants";
import CustomButton from "components/CustomButton";
import { toMutable } from "types/common";
import * as styles from "./strategy.module.scss";

const inputs = [
    {
        name: "title",
        type: "text",
        defaultValue: "Implement your strategy using the right technology",
    },
    {
        name: "items",
        type: "list",
        defaultValue: [],
        required: true,
        subFields: [
            {
                name: "title",
                type: "text",
                defaultValue: "Cutting Edge Technology",
                required: true,
            },
            {
                name: "items",
                type: "list",
                defaultValue: [],
                required: true,
                subFields: [
                    {
                        name: "items",
                        type: "list",
                        defaultValue: [],
                        required: true,
                        subFields: [
                            {
                                name: "content",
                                type: "text",
                                defaultValue: "Low Latency Reporting",
                                required: true,
                            },
                        ],
                    },
                ],
            },
            {
                name: "buttonText",
                type: "text",
                defaultValue: "Free Trial",
                required: true,
            },
            {
                name: "buttonTo",
                type: "text",
                defaultValue: SIGNUP_LINK,
                required: true,
            },
            {
                name: "img",
                type: "file",
                allowedFileTypes: ["jpg", "png", "jpeg", "gif", "svg"],
            },
        ],
    },
] as const;
const Strategy: BFC<typeof inputs> = ({ title, items }) => {
    return (
        <div className={`${styles.strategy} component-wrapper m-0`}>
            <div className={`gradient ${styles.left}`}>
                <StaticImage src="images/type-left-gradient.png" alt="" />
            </div>
            <div className={`gradient ${styles.right}`}>
                <StaticImage src="images/type-right-gradient.png" alt="" />
            </div>
            <div className="container">
                <div className="row gx-2">
                    <h2 className="text-center mb-5">{title}</h2>
                    {(items ?? []).map(it => {
                        return (
                            <div className="mb-4 mb-lg-0 col-lg-3 position-relative" key={it.title}>
                                <div className={styles.cardblock}>
                                    <img src={it.img} alt="" className="m-auto d-block" />
                                    <h4 className="text-center mb-2">{it.title}</h4>
                                    <ul className="list-group  ">
                                        {it.items.map(li => {
                                            return (
                                                <li
                                                    style={{
                                                        marginBottom: "8px",
                                                    }}
                                                    key={li.items.map(it => it.content).join(" ")}
                                                >
                                                    {li.items.map((it, index) => {
                                                        if (index == 0) {
                                                            return <h5 key={it.content}>{it.content}</h5>;
                                                        }
                                                        return <p key={it.content}>{it.content}</p>;
                                                    })}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <div className={`${styles.button_wraper} top-100 start-50 translate-middle`}>
                                        <CustomButton
                                            buttonSize="btn--large"
                                            buttonStyle="btn--primary"
                                            to={it.buttonTo}
                                        >
                                            {it.buttonText}
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
Builder.registerComponent(Strategy, {
    name: "Strategy",
    inputs: toMutable(inputs),
});
